export class Logger {
  public static isEnabled = false;

  public static log(...messages: any[]): void {
    if (this.isEnabled) {
      console.log(...messages);
    }
  }

  public static warn(...messages: any[]): void {
    if (this.isEnabled) {
      console.warn(...messages);
    }
  }

  public static error(...messages: any[]): void {
    if (this.isEnabled) {
      console.error(...messages);
    }
  }
}
