













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ListMenu extends Vue {
  @Prop() disabled!: boolean;

  private menuIsShowed = null;
}
