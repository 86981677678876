




















import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/user-api.service';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import AnonymousContainer from '../components/AnonymousContainer.vue';
import AnonymousHeader from '../components/AnonymousHeader.vue';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

@Component({
  components: {
    AnonymousContainer,
    AnonymousHeader
  }
})
export default class ActivateAccount extends Vue {
  public activationId = this.$route.params.id;
  public loading = true;
  private service = new UserApiService();
  public userManagementRoute = UserManagementRoute;

  async activate() {
    this.loading = true;

    const result = await this.service.activate(this.activationId);
    if (result) {
      NotificationService.success(translate('Modules.Login.ActivateSuccess'));
      this.$router.push({ name: UserManagementRoute.login });
    }

    this.loading = false;
  }

  mounted() {
    this.activate();
  }
}
