import {
  DiscountAdminDto,
  DiscountAdminUpdateInputModel,
  DiscountAdminClient,
  OrderListItemAdminDataDto
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class DiscountApiService extends ApiServiceBase {
  private client = new DiscountAdminClient();
  public async GetDiscountList(): Promise<DiscountAdminDto[] | undefined> {
    try {
      const discountList = await this.client.getDiscountList();
      return discountList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async updateDiscount(inputModel: DiscountAdminUpdateInputModel): Promise<DiscountAdminDto | undefined> {
    try {
      const discountList = await this.client.updateDiscount(inputModel);
      return discountList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getDiscount(inputModel: string): Promise<DiscountAdminDto | undefined> {
    try {
      const user = await this.client.getDiscount(inputModel);
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getPayments(inputModel: string): Promise<OrderListItemAdminDataDto[] | undefined> {
    try {
      const user = await this.client.getPaymentOrdersList(inputModel);
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async addDiscountData(inputModel: DiscountAdminDto): Promise<DiscountAdminDto | undefined> {
    try {
      const user = await this.client.addDiscount(
        '',
        inputModel.email,
        inputModel.code,
        inputModel.discount,
        inputModel.isActive
      );
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
