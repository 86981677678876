export class Levels {
  constructor(
    level: number,
    backgroundSpeed: number,
    enemyCountX: number,
    enemyCountY: number,
    enemySpeedX: number,
    enemySpeedY: number,
    speedUpEnemy: boolean,
    enemyCanShoot: boolean,
    enemyBulletSpeed = 250
  ) {
    this.level = level;
    this.backgroundSpeed = backgroundSpeed;
    this.enemyCountX = enemyCountX;
    this.enemyCountY = enemyCountY;
    this.enemySpeedX = enemySpeedX;
    this.enemySpeedY = enemySpeedY;
    this.speedUpEnemy = speedUpEnemy;
    this.enemyCanShoot = enemyCanShoot;
    this.enemyBulletSpeed = enemyBulletSpeed;
  }
  level!: number;
  backgroundSpeed!: number;
  enemyCountX!: number;
  enemyCountY!: number;
  enemySpeedX!: number;
  enemySpeedY!: number;
  speedUpEnemy!: boolean;
  enemyCanShoot!: boolean;
  enemyBulletSpeed!: number;
}
