










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormAutocompleteSelectPreview extends Vue {
  label = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Label');
  value = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString();
  items: string[] = [
    this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString(),
    this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value1').toString(),
    this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value2').toString()
  ];
  itemText = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Text').toString();
  itemValue = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString();
}
