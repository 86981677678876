import { translate } from '@/plugins/i18n';

export default class StringValidators {
  public static required(value: string): boolean | string {
    return !!value || translate('Modules.Validation.Required');
  }

  public static minLength(value: string, length: number): boolean | string {
    return value?.length >= length || translate('Modules.Validation.MinLength', { minLength: length });
  }

  public static maxLength(value: string, length: number): boolean | string {
    return value?.length <= length || translate('Modules.Validation.MaxLength', { maxLength: length });
  }

  public static email(value: string): boolean | string {
    return (
      (!!value && /^\S+@\S+$/.test(value)) || translate('Modules.Validation.Email')
    );
  }
  public static zipCodePl(value: string): boolean | string {
    return (
        (!!value && /^[0-9]{2}-[0-9]{3}$/.test(value)) || translate('Modules.Validation.ZipCodePl')
    );
  }
  public static minNumber(value: number, min: number): boolean | string {
    return value >= min || translate('Modules.Validation.MinNumber', { min });
  }
  public static maxNumber(value: number, max: number): boolean | string {
    return value <= max || translate('Modules.Validation.MaxNumber', { max });
  }
}
