

















































import { Component, Vue, Prop } from 'vue-property-decorator';

import KitchenSinkHtml from '@/modules/kitchen-sink/components/KitchenSinkHtml.vue';
import KitchenSinkPreview from '@/modules/kitchen-sink/components/KitchenSinkPreview.vue';
import KitchenSinkTypeScript from '@/modules/kitchen-sink/components/KitchenSinkTypeScript.vue';
import KitchenSinkStyle from '@/modules/kitchen-sink/components/KitchenSinkStyle.vue';
import KitchenSinkSnippet from '@/modules/kitchen-sink/components/KitchenSinkSnippet.vue';

@Component({
  components: {
    KitchenSinkHtml,
    KitchenSinkPreview,
    KitchenSinkTypeScript,
    KitchenSinkSnippet,
    KitchenSinkStyle
  }
})
export default class KitchenSinkList extends Vue {
  @Prop() item!: any;
  tab = null;
}
