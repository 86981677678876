import {
  GameRuntimeClient,
  PlayerInstanceAnswerDto,
  PlayerInstanceAnswerInputModel,
  PlayerInstanceDto,
  PlayerInstanceInputModel,
  PlayerInstanceLogDto,
  PlayerInstanceLogInputModel,
  QuestionDto,
  RuntimeGameInstanceDto,
  PlayerInstanceDetailsInputModel
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';
import { Base64 } from 'js-base64';

export default class GameApiService extends ApiServiceBase {
  private client = new GameRuntimeClient();

  public async getGameInstancePrivate(instanceId: string): Promise<RuntimeGameInstanceDto | undefined> {
    try {
      const instance = await this.client.getGameInstancePrivate(instanceId);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getGameInstancePublic(instanceId: string): Promise<RuntimeGameInstanceDto | undefined> {
    try {
      const instance = await this.client.getGameInstancePublic(instanceId);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async createPlayerInstance(inputModel: PlayerInstanceInputModel): Promise<PlayerInstanceDto | undefined> {
    try {
      const instance = await this.client.createPlayerInstance(inputModel);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getQuestionByProfession(
    professionId: string,
    playerInstanceId: string
  ): Promise<QuestionDto | undefined> {
    try {
      const instance = await this.client.getQuestionByProfession(professionId, playerInstanceId);
      if (instance.result) {
        const question = (await JSON.parse(Base64.decode(instance.result) ?? '')) as QuestionDto;

        return question;
      }
      return undefined;
    } catch (error) {
      if (error.result === 'Errors.NoQuestionFound') {
        return undefined;
      }
      this.HandleError(error);
    }
  }
  public async addPlayerInstanceAnswer(
    inputModel: PlayerInstanceAnswerInputModel
  ): Promise<PlayerInstanceAnswerDto | undefined> {
    try {
      const instance = await this.client.addPlayerInstanceAnswer(inputModel);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async addPlayerInstanceLog(
    inputModel: PlayerInstanceLogInputModel
  ): Promise<PlayerInstanceLogDto | undefined> {
    try {
      const instance = await this.client.addPlayerInstanceLog(inputModel);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async addPlayerInstanceDetails(
    inputModel: PlayerInstanceDetailsInputModel,
    sendEmail: boolean
  ): Promise<PlayerInstanceDto | undefined> {
    try {
      const instance = await this.client.addPlayerInstanceDetails(inputModel, sendEmail);
      return instance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
