




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DetailRowPreview extends Vue {
  label = this.$t(`KitchenSink.Previews.DetailRowPreview.Label`);
  value = this.$t(`KitchenSink.Previews.DetailRowPreview.Value`);
}
