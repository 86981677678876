var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.GamesTable.DeepStatistics.AnswerList'))+" "),_c('v-btn',{staticClass:"text-capitalize justify-start ml-4",attrs:{"small":"","dark":"","color":"green","loading":_vm.exporting,"disabled":_vm.data.length === 0},on:{"click":_vm.emitExportCsv}},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.ExportBtn'))+" ")],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"loading":_vm.localLoading,"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"sort-by":['dateTimeLog'],"items-per-page":_vm.tablesSize,"sort-desc":[true],"footer-props":{
      'items-per-page-text': _vm.$t('dataIterator.rowsPerPageText'),
      'page-text': _vm.$t('dataIterator.pageText'),
      'items-per-page-options': [5, 10, 25, { text: this.$t('dataIterator.AllText'), value: -1 }]
    }},on:{"update:itemsPerPage":function($event){_vm.tablesSize=$event},"update:items-per-page":function($event){_vm.tablesSize=$event}},scopedSlots:_vm._u([{key:"item.answer",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.answer)+" ")]}},{key:"item.isCorrectAnswer",fn:function(ref){
    var item = ref.item;
return [(item.isCorrectAnswer)?_c('div',{staticClass:"pa-1 green rounded-circle d-inline-block"},[_c('v-icon',{attrs:{"color":'white'},domProps:{"textContent":_vm._s('mdi-checkbox-marked-circle-outline')}})],1):_c('div',{staticClass:"pa-1 red rounded-circle d-inline-block"},[_c('v-icon',{attrs:{"color":'white'},domProps:{"textContent":_vm._s('mdi-checkbox-blank-circle-outline')}})],1)]}},{key:"item.dateTimeLog",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNormal")(item.dateTimeLog))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }