

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DetailRow extends Vue {
  @Prop() readonly labelIcon!: string;
  @Prop({ default: 'mdi-open-in-new' }) readonly linkIcon!: string;
  @Prop() readonly label!: string;
  @Prop() readonly value!: any;
  @Prop() public routeTo?: any;
  @Prop({ default: false }) readonly useCheckBox!: boolean;
  checkIsBoolean(): boolean {
    if (this.useCheckBox && typeof this.value === typeof this.useCheckBox) {
      return true;
    }
    return false;
  }
}
