

























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ListContainerPreview extends Vue {
  items = [
    {
      column1: 'Item 1 Column 1',
      column2: 'Item 1 Column 2'
    },
    {
      column1: 'Item 2 Column 1',
      column2: 'Item 2 Column 2'
    }
  ];
}
