import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { i18n } from '@/plugins/i18n';

export class GameMainSettingsModel {
  public title!: string;
  public subtitle!: string;
  public logo!: string;
  public logoLink!: string;
  public professionQuestion!: string;
  public formHeader!: string;
  public emailLabel!: string;
  public nameLabel!: string;
  public termsLink!: string;
  public termsLinkText!: string;
  public termsAgreementText!: string;
  public startBtnLabel!: string;
  public instructionText!: string;
  public answerLimitFlag!: boolean;
  public answerLimitTime!: number;
  public gameCustomizableElements: GameCustomizableElement[] = [];
  public gameOverTitle!: string;
  public gameOverDescription!: string;
  public gameOverCV!: boolean;
  public gameOverEmailCV!: boolean;
  public gameOverNameLabel!: string;
  public gamerOverScoreLabel!: string;
  public gamerOverCVLabel!: string;
  public gamerOverCVUploadLabel!: string;
  public gamerOverCVUploadFileLabel!: string;
  public gamerOverCVButton!: string;
  public gamerOverBackButton!: string;
  
  constructor() {
    this.title = i18n.t('Modules.Runtime.Menu.Header').toString();
    this.subtitle = i18n.t('Modules.Runtime.Menu.HeaderSub').toString();
    this.logo = require('@/assets/cf-logo.svg');
    this.logoLink = 'https://www.codefusion.pl/';
    this.professionQuestion = i18n.t('Modules.Runtime.Menu.JobHeader').toString();
    this.formHeader = i18n.t('Modules.Runtime.Menu.FormHeader').toString();
    this.emailLabel = i18n.t('Modules.Runtime.Menu.EmailLabel').toString();
    this.nameLabel = i18n.t('Modules.Runtime.Menu.NameLabel').toString();
    this.termsLink = 'https://ingame.hr/termsandconditions/';
    this.termsAgreementText = i18n.t('Modules.Runtime.Menu.RodoConsent').toString();
    this.termsLinkText = i18n.t('Modules.Runtime.Menu.TermsConsditions').toString();
    this.startBtnLabel = i18n.t('Modules.Runtime.Menu.StartBtn').toString();
    this.instructionText = i18n.t('Modules.Runtime.Game.Modal.QuestionInfo').toString();
    this.answerLimitFlag = true;
    this.answerLimitTime = 15;
    this.gameCustomizableElements = [];
    this.gameOverTitle = 'GAME OVER';
    this.gameOverDescription = i18n.t('Modules.Runtime.Game.Modal.GameOverInfo').toString();
    this.gameOverCV = true;
    this.gameOverEmailCV = false;
    this.gameOverNameLabel = i18n.t('Modules.Runtime.Game.Modal.Name').toString();
    this.gamerOverScoreLabel = i18n.t('Modules.Runtime.Game.Modal.Score').toString();
    this.gamerOverCVLabel = i18n.t('Modules.Runtime.Game.Modal.AddCV').toString();
    this.gamerOverCVUploadLabel = i18n.t('Modules.Runtime.Game.Modal.UploadCV').toString();
    this.gamerOverCVUploadFileLabel = i18n.t('Modules.Runtime.Game.Modal.InsertCV').toString();
    this.gamerOverCVButton = i18n.t('Modules.Runtime.Game.Modal.CVButton').toString();
    this.gamerOverBackButton = i18n.t('Modules.Runtime.Game.Modal.BackBtn').toString();
  }
}
