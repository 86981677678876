import { TextController } from '@/modules/game-play/games/framework/helpers/textcontroller';
import { LifeSystem } from '@/modules/game-play/games/framework/engines/life';
import { AudioSystem } from '@/modules/game-play/games/framework/engines/audio';
import { GameTheme } from '@/modules/game-play/models/game-theme';
import { ErrorControl } from '@/modules/game-play/games/framework/control/errorControl';
import {
  GameCustomizableElement,
  GameCustomizableElementType
} from '@/modules/game-play/games/game-engine-customizable-element';

export class EngineController {
  protected static textController: TextController;
  protected static currentScene: Phaser.Scene;
  protected static theme?: GameTheme;
  protected static CustomziableTypes?: GameCustomizableElement[];
  protected static audioNamespaceClass?: Class;

  static initialize(scene: Phaser.Scene, theme?: GameTheme, customziableTypes?: GameCustomizableElement[]): void {
    this.currentScene = scene;
    this.theme = theme;
    this.CustomziableTypes = customziableTypes;
    this.textController = new TextController(this.currentScene, this.theme);
  }

  static createText(text: string | undefined, isEnd: boolean) {
    this.textController.centerText(text, isEnd);
  }

  static updateTimePoints(pionts: number, level: number, enemyDestroyed: number, maxEnemy: number) {
    this.textController.updateTimePoints(pionts, level, enemyDestroyed, maxEnemy);
  }

  static updateTimeDistance(pionts: number, level: number, distance: number) {
    this.textController.updateTimeDistance(pionts, level, distance);
  }
  static createAudioSystem(audioNamespaceClass: any) {
    const result = AudioSystem.initialize(this.currentScene, audioNamespaceClass, this.theme?.overridenSounds);
    if (!result) {
      ErrorControl.createConsoleError(ErrorControl.getType().Create, 'Audio system', true);
    }
    this.audioNamespaceClass = audioNamespaceClass;
  }
  static loadAudioSystem() {
    const result = AudioSystem.addAudio(this.currentScene, this.audioNamespaceClass, this.theme?.overridenSounds);
    if (!result) {
      ErrorControl.createConsoleError(ErrorControl.getType().Create, 'load Audio System', true);
    }
  }
  static createLifeSystem(spriteName: string, maxLife: number) {
    const result = LifeSystem.initialize(this.currentScene, spriteName, maxLife, this.CustomziableTypes);
    if (!result) {
      ErrorControl.createConsoleError(ErrorControl.getType().Create, 'life system', true);
    }
  }

  static lifeLost(emptyPool: boolean) {
    const result = LifeSystem.haveMoreLife();
    //True if continue game
    this.showTextLostLife(result, emptyPool);
    return result;
  }

  static playAudio(name: string) {
    const result = AudioSystem.playAudio(this.currentScene, name);
    if (!result) {
      ErrorControl.createConsoleError(ErrorControl.getType().Get, 'Audio doesnt exist', true);
    }
  }

  static isPlaying(name: string) {
    const result = AudioSystem.checkAudioStatus(this.currentScene, name);
    return result;
  }

  static showTextLostLife(status: boolean, emptyPool: boolean) {
    if (this.CustomziableTypes) {
      LifeSystem.lostLife();
      let text: string | undefined = '';
      if (!emptyPool) {
        text = this.CustomziableTypes.find((val: { name: string }) => val.name == 'AnswerTextWrong')?.value.toString();
      } else {
        text = this.CustomziableTypes.find((val: { name: string }) => val.name == 'NoMoreQuestions')?.value.toString();
      }
      this.createText(text, status);
      return true;
    } else {
      ErrorControl.createConsoleError(ErrorControl.getType().Undefined, 'CustomTypes', false);
      return false;
    }
  }
}
