






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DiscountAdminDto } from '@/services/Api';
@Component
export default class DiscountDetails extends Vue {
  @Prop() discountDetailsData!: DiscountAdminDto;
  @Prop() numerPayments!: number;
}
