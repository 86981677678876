import { RouteConfig } from 'vue-router';
import AdminUserList from '@/modules/admin/users/views/AdminUserList.vue';
import AdminEditPage from '@/modules/admin/users/views/AdminEditPage.vue';
import AdminDetailsPage from '@/modules/admin/users/views/AdminDetailsPage.vue';
import AdminDashboard from '@/modules/admin/AdminDashboard.vue';
import DiscountList from '@/modules/admin/discounts/views/DiscountList.vue';
import DiscountEditPage from '@/modules/admin/discounts/views/DiscountEditPage.vue';
import DiscountDetailsPage from '@/modules/admin/discounts/views/DiscountDetailsPage.vue';
import DiscountAddPage from '@/modules/admin/discounts/views/DiscountAddPage.vue';
import PaymentsList from '@/modules/admin/payments/views/PaymentsList.vue';
import PaymentsDetailsPage from '@/modules/admin/payments/views/PaymentsDetailsPage.vue';
import AdminGameInstancesList from '@/modules/admin/game-instances/views/AdminGameInstancesList.vue';
import AdminGameInstanceDetailsPage from '@/modules/admin/game-instances/views/AdminGameInstanceDetailsPage.vue';
import DefaultView from '@/modules/layout/DefaultView.vue';
import { AdminUsersRoute } from './admin-users-router.enum';

export const adminUsersRoutes: Array<RouteConfig> = [
  {
    path: '/admin',
    component: DefaultView,
    meta: { breadCrumb: 'Layout.Toolbar.Routes.Admin' },
    children: [
      {
        path: '',
        name: AdminUsersRoute.adminDashboard,
        component: AdminDashboard
      },
      {
        path: '/admin/users',
        component: DefaultView,
        meta: { allowAnonymous: false },
        children: [
          {
            path: '',
            name: AdminUsersRoute.userAdmin,
            component: AdminUserList
          },
          {
            path: ':id',
            name: AdminUsersRoute.adminEdit,
            component: AdminEditPage,
            meta: { allowAnonymous: false }
          },
          {
            path: 'details=:id',
            name: AdminUsersRoute.adminDetails,
            component: AdminDetailsPage,
            meta: { allowAnonymous: false }
          }
        ]
      },
      {
        path: '/admin/discounts',
        component: DefaultView,
        meta: { allowAnonymous: false },
        children: [
          {
            path: '',
            name: AdminUsersRoute.discountsList,
            component: DiscountList
          },
          {
            path: ':id',
            name: AdminUsersRoute.discountEdit,
            component: DiscountEditPage,
            meta: { allowAnonymous: false }
          },
          {
            path: 'etails=:id',
            name: AdminUsersRoute.discountDetails,
            component: DiscountDetailsPage,
            meta: { allowAnonymous: false }
          },
          {
            path: 'add',
            name: AdminUsersRoute.discountAdd,
            component: DiscountAddPage,
            meta: { allowAnonymous: false }
          }
        ]
      },
      {
        path: '/admin/payments',
        component: DefaultView,
        meta: { allowAnonymous: false },
        children: [
          {
            path: '',
            name: AdminUsersRoute.paymentsList,
            component: PaymentsList
          },
          {
            path: ':id',
            name: AdminUsersRoute.paymentsDetails,
            component: PaymentsDetailsPage,
            meta: { allowAnonymous: false }
          }
        ]
      },
      {
        path: '/admin/game-instances',
        component: DefaultView,
        meta: { allowAnonymous: false },
        children: [
          {
            path: '',
            name: AdminUsersRoute.gameInstances,
            component: AdminGameInstancesList
          },
          {
            path: ':gameInstanceId',
            name: AdminUsersRoute.adminGameInstanceDetails,
            props: true,
            component: AdminGameInstanceDetailsPage,
            meta: { allowAnonymous: false }
          }
        ]
      }
    ]
  }
];
