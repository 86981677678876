export class ImagesBase64 {
  static readonly ship = require('@/assets/games/space-rescue/sprites/space-ship.png');
  static readonly enemy = require('@/assets/games/space-rescue/sprites/invader.png');
  static readonly stars = require('@/assets/games/space-rescue/stars/star4.png');
  static readonly bullet = require('@/assets/games/space-rescue/fire/fire1.png');
  static readonly enemyBullet = require('@/assets/games/space-rescue/fire/fire2.png');
  static readonly heart = require('@/assets/games/space-rescue/ui/life.png');

  static readonly enemyBg = require('@/assets/games/space-rescue/ui/shadow.png');
}
