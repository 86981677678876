











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormConditionsCheckboxPreview extends Vue {
  value = true;
  newTab = true;
  disabled = false;
  link = 'http://www.google.pl';
  linkText = this.$t('KitchenSink.Previews.FormConditionsCheckboxPreview.PrivacyPolicy');
  label = this.$t('KitchenSink.Previews.FormConditionsCheckboxPreview.Iagreeto');
}
