






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class KitchenSinkShortCuts extends Vue {
  @Prop() navigationList!: string[];
  innerWidth = 0;
  getId(num: number): string {
    return '#sink' + num;
  }

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  handleResize() {
    this.innerWidth = window.innerWidth;
  }
}
