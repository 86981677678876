


















import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { Component, Prop, Vue } from 'vue-property-decorator';
import StringValidators from '@/modules/layout/validators/string-validators';
import { GameType } from '@/services/Api';
@Component
export default class NumberTypeCustomize extends Vue {
  @Prop() readonly item!: GameCustomizableElement;
  @Prop() readonly type!: GameType;
  inputValue = 0;
  rules = {
    minNumber: (model: number) => StringValidators.minNumber(model, this.item.minSize),
    maxNumber: (model: number) => StringValidators.maxNumber(model, this.item.maxSize)
  };

  mounted() {
    this.inputValue = this.item.value as number;
  }

  emitInput(value: string) {
    this.item.value = value;
  }
}
