import { RouteConfig } from 'vue-router';
import Login from '@/modules/user-management/views/Login.vue';
import Register from '@/modules/user-management/views/Register.vue';
import ActivateAccount from '@/modules/user-management/views/ActivateAccount.vue';
import ForgotPasswordRequest from '@/modules/user-management/views/ForgotPasswordRequest.vue';
import ForgotPasswordConfirm from '@/modules/user-management/views/ForgotPasswordConfirm.vue';
import ChangePassword from '@/modules/user-management/views/ChangePassword.vue';
import { UserManagementRoute } from './user-management-router.enum';

export const userManagementRoutes: Array<RouteConfig> = [
  {
    path: '/login',
    name: UserManagementRoute.login,
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/register',
    name: UserManagementRoute.register,
    component: Register,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/activate=:id',
    name: UserManagementRoute.activateAccount,
    component: ActivateAccount,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/forgot-password',
    name: UserManagementRoute.forgotPasswordRequest,
    component: ForgotPasswordRequest,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/forgot-password-confirm=:id',
    name: UserManagementRoute.forgotPasswordConfirm,
    component: ForgotPasswordConfirm,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/change-password',
    name: UserManagementRoute.changePassword,
    component: ChangePassword
  }
];
