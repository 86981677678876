export class WallObjectsTilesprite extends Phaser.GameObjects.TileSprite {
  constructor(scene: Phaser.Scene, width: number, backgroundName: string) {
    super(scene, 1, 1, width, scene.textures.get(backgroundName).source[0].height, backgroundName);
    scene.physics.add.existing(this);
    scene.add.existing(this);
    this.setActive(false);
    this.setVisible(false);
    scene.registry.events.on('unlockAll', this.deleteObjects, this);
  }
  backgroundSpeed!: number;

  createWall(startPoint: number, width: number, backgroundSpeed: number) {
    this.setActive(true);
    this.setVisible(true);

    this.x = startPoint + width / 2;
    this.displayWidth = width;
    this.displayHeight = 20;
    this.backgroundSpeed = backgroundSpeed;
  }
  preUpdate(time: number, delta: number) {
    if (this.y >= this.scene.game.canvas.height) {
      this.destroy();
    } else {
      if (!this.scene.data.get('pause')) {
        this.y += this.backgroundSpeed;
      }
    }
  }
  deleteObjects() {
    this.destroy();
  }
}
