











































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DiscountApiService from '../services/admin-discount-api.service';
import { DiscountAdminDto } from '@/services/Api';
import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import { store } from '@/store/store';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
@Component({})
export default class DiscountListRow extends Vue {
  @Prop() readonly data!: DiscountAdminDto[];
  confirmTitle = '';
  private apiService = new DiscountApiService();
  private search = '';
  private tableSize = 10;
  public adminUsersRoute = AdminUsersRoute;
  dialog = false;
  get headers() {
    return [
      {
        text: this.$t('Modules.AdminPanel.Discounts.Code'),
        align: 'start',
        value: 'code',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.Discounts.email'),
        value: 'email',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.Discounts.discount'),
        value: 'discount',
        sort: DataTableSortingHelper.compareByDefault
      },
      {
        text: this.$t('Modules.AdminPanel.Discounts.Active'),
        value: 'isActive',
        sort: DataTableSortingHelper.compareByDefault
      },
      { value: 'button', sortable: false }
    ];
  }

  async mounted() {
    this.tableSize = store.getTableSize('Admin-discounts');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-discounts', this.tableSize);
  }
  closeMenus() {
    this.confirmTitle = '';
    this.dialog = false;
  }
  customFilter(value: any, search: string | null, item: DiscountAdminDto) {
    if (!search) {
      return true;
    }

    return (
      DataTableFilterHelper.filterByString(item.code, search) ||
      DataTableFilterHelper.filterByString(item.email, search) ||
      DataTableFilterHelper.filterByString(item.discount.toString(), search)
    );
  }
}
