

























import { OrderListItemDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PaymentStatus extends Vue {
  @Prop() public order!: OrderListItemDto;
  @Prop() public loading?: boolean;
  @Prop() public disabled?: boolean;

  public statusIcon = 'mdi-help-circle';
  public statusIconColor = 'blue-grey';
  public canBeCancelled = false;

  public mounted(): void {
    switch (this.order.status) {
      case 'New':
      case 'WaitingForConfirmation':
        this.canBeCancelled = true;
        break;
      case 'Pending':
        this.statusIcon = 'mdi-clock';
        this.statusIconColor = 'blue';
        this.canBeCancelled = true;
        break;
      case 'Completed':
        this.statusIcon = 'mdi-check-circle';
        this.statusIconColor = 'green';
        break;
      case 'Canceled':
        this.statusIcon = 'mdi-close-circle';
        this.statusIconColor = 'orange';
        break;
      case 'Rejected':
        this.statusIcon = 'mdi-thumb-down';
        this.statusIconColor = 'red';
        break;
    }
  }

  public cancelOrder() {
    return this.$emit('cancelOrder');
  }
}
