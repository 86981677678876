







import CookiesConsent from '@/modules/layout/components/cookies-consent/CookiesConsent.vue';
import { AnalyticsHelper, FacebookPixelEvent } from '@/plugins/analytics-facebook';
import AnalyticsGoogleHelper from '@/plugins/analytics-google';
import { ConfigurationDto, Environment, SettingsClient, SwaggerResponse } from '@/services/Api';
import { Logger } from '@/services/logger';
import { store } from '@/store/store';
import { reaction } from 'mobx';
import { bootstrap, optIn, optOut, setOptions } from 'vue-gtag';
import { Component, Vue } from 'vue-property-decorator';
import { StringUtils } from '../utils/string-utils';
import FullHDSnackbar from './fullHDsnackbar.vue';

@Component({ components: { CookiesConsent, FullHDSnackbar } })
export default class AppInitializer extends Vue {
  private boostrappingInProgress = false;
  private pixelBootstrapped = false;
  showSnackbarHD = false;
  private configuration = new ConfigurationDto({
    version: null,
    googleAnalyticsId: null,
    facebookPixelId: null,
    environmentName: Environment.Development,
    ppUrl: ''
  });

  public mounted(): void {
    if (store.isSizeChekcEnabled) {
      window.innerWidth < 1366 || window.innerHeight < 768
        ? (this.showSnackbarHD = true)
        : (this.showSnackbarHD = false);
    }

    this.fetchConfiguration().then(config => {
      this.configuration = config;

      store.setAppVersion(config.version);
      store.setPPUrl(config.ppUrl);
      Logger.isEnabled = config.environmentName === Environment.Development;

      this.setupGoogleAnalytics();
      this.setupFacebookPixel();
    });

    reaction(
      () => store.googleAnalyticsEnabled,
      () => this.setupGoogleAnalytics()
    );

    reaction(
      () => store.facebookPixelEnabled,
      () => this.setupFacebookPixel()
    );
  }
  get isStoreLoaded() {
    return store.isStoreLoaded();
  }

  private setupGoogleAnalytics(): void {
    if (this.boostrappingInProgress) return;

    const canEnable = this.canEnableGoogleAnalytics();
    if (canEnable && !AnalyticsGoogleHelper.analyticsBootstrapped) {
      this.boostrapGoogleAnalytics();
    } else if (AnalyticsGoogleHelper.analyticsBootstrapped) {
      if (canEnable) optIn();
      else optOut();
    }
  }

  private boostrapGoogleAnalytics(): void {
    const id = this.configuration.googleAnalyticsId as string;
    this.boostrappingInProgress = true;

    setOptions({
      config: {
        id: id,
        params: {
          // The google analytics script is automatically sending page_view events, but for SPAs (Single Page Applications)
          // page_view events should be sent manually - https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
          // Setting send_page_view to false will not trigger the first hit on the page.
          // The vue-gtag library is using the router to send the page_view events.
          send_page_view: false
          // debug_mode: true
        }
      }
    });

    bootstrap()
      .then(() => {
        Logger.log('Google Analytics has been bootstrapped');
        AnalyticsGoogleHelper.analyticsBootstrapped = true;
        this.boostrappingInProgress = false;
      })
      .catch(error => {
        Logger.error('Error while bootstrapping Google Analytics', error);
        AnalyticsGoogleHelper.analyticsBootstrapped = false;
        this.boostrappingInProgress = false;
      });
  }

  private canEnableGoogleAnalytics(): boolean {
    return (
      this.configuration.environmentName === Environment.Production &&
      StringUtils.isNullOrWhiteSpace(this.configuration.googleAnalyticsId) === false &&
      store.googleAnalyticsEnabled === true
    );
  }

  private setupFacebookPixel(): void {
    if (this.pixelBootstrapped) return;

    if (this.canEnableFacebookPixel()) {
      AnalyticsHelper.initFacebookPixel(this.configuration.facebookPixelId as string);
      AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.pageView);
      this.pixelBootstrapped = true;
    }
  }

  private canEnableFacebookPixel(): boolean {
    return (
      this.configuration.environmentName === Environment.Production &&
      StringUtils.isNullOrWhiteSpace(this.configuration.facebookPixelId) === false &&
      store.facebookPixelEnabled === true
    );
  }

  private fetchConfiguration(): Promise<ConfigurationDto> {
    const client = new SettingsClient();

    const handleResponse = (response: SwaggerResponse<ConfigurationDto> | null) =>
      response?.status === 200 ? response.result : this.configuration;

    return new Promise(resolve => {
      client
        .getConfiguration()
        .catch(error => error)
        .then(response => resolve(handleResponse(response)));
    });
  }
}
