export class EndLevelObject extends Phaser.Physics.Arcade.Sprite {
  private speedY = 5;
  isDestroyed: boolean;
  emiterBack = new Phaser.Events.EventEmitter();
  stopAllObjects = false;
  firstSpeed = 5;
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    spriteName: string,
    spriteWidth: number,
    spriteHeigth: number,
    speedBackground: number
  ) {
    super(scene, x, -10, '');
    this.isDestroyed = false;
    scene.physics.add.existing(this);
    scene.add.existing(this);
    this.addSprite(spriteName, spriteWidth, spriteHeigth);
    this.setActive(false);
    this.setVisible(false);
    this.scene.registry.events.on('stopAll', this.stopAll, this);
    this.scene.registry.events.on('unlockAll', this.unlockAll, this);
    this.scene.registry.events.on('backToOldSpeed', this.backToOldSpeed, this);
    this.scene.registry.events.on('changeSpeed', this.changeSpeed, this);
    this.scene.registry.events.on('objectChangeSpeed', this.objectChangeSpeed, this);
    this.speedY = speedBackground;
    this.firstSpeed = this.speedY;
  }
  backToOldSpeed() {
    if (this.body != undefined) {
      this.setVelocityY(0);
      this.speedY = this.firstSpeed;
    }
  }
  objectChangeSpeed(parameter: any) {
    this.setVelocityY(0);
    this.firstSpeed = parameter.speed;
    this.speedY = parameter.speed;
  }
  changeSpeed(parameter: any) {
    this.speedY = (parameter.speed / 100) * 3;
    if (this.body != undefined) {
      if (parameter.back) {
        this.setVelocityY(parameter.speed * 1.6);
      } else {
        this.setVelocityY(parameter.speed * 2);
      }
    }
  }
  stopAll() {
    this.stopAllObjects = true;
  }
  unlockAll() {
    this.stopAllObjects = false;
  }
  addSprite(name: string, spriteWidth: number, heigth: number) {
    this.setTexture(name);
    this.displayWidth = spriteWidth;
    this.displayHeight = heigth;
  }
  createObject(x: number) {
    this.body.reset(x, -10);
    this.isDestroyed = false;
    this.stopAllObjects = false;
    this.speedY = this.firstSpeed;
    this.setActive(true);
    this.setVisible(true);
  }
  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.y >= this.scene.game.canvas.height + 30) {
      this.setActive(false);
      this.setVisible(false);
      this.body.reset(0, -50);
    } else if (!this.stopAllObjects) {
      this.y += this.speedY;
    }
  }
}
