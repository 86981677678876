import  {ObjectsGroup}  from '../Models/ObjectsGroup';
import ObjectFactory from '../Models/ObjectFactory';
export class ObjectsOutside {
  constructor(
    spriteName: string,
    width: number,
    heigth: number,
    scene: any
  ) {
    this.spriteName = spriteName;
    this.factoryVehicle = ObjectFactory.Build(scene, width, heigth, spriteName);
  }
  factoryVehicle!: ObjectsGroup;
  spriteName!: string;
}
