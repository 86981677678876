







































import { InstanceGameStatisticsDto } from '@/services/Api';
import { Duration, Moment } from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import UserApiService from '@/modules/statistics/services/statistic-api.service';

@Component
export default class StatisticsOneGameTable extends Vue {
  @Prop() readonly data!: InstanceGameStatisticsDto[];
  @Prop() readonly achieviedLevel!: number;
  @Prop() readonly GameOver!: boolean;
  apiService = new UserApiService();

  private search = '';
  goToGameStatistic(instanceId: string) {
    this.$emit('game-statistics', instanceId);
  }
  get headers() {
    return [
      {
        text: 'Email',
        align: 'left',
        value: 'email'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Profession'),
        value: 'profession'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.StartingTime'),
        value: 'startingTime'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.PlayingTime'),
        value: 'playingTime'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.TotalAnswers'),
        value: 'totalAnswers'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.CorrectAnswers'),
        value: 'correctAnswers'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Score'),
        value: 'score'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Level'),
        value: 'level'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Gameover'),
        value: 'end'
      }
    ];
  }
  private availableColors = [
    'teal',
    'light-green',
    'purple',
    'blue-grey',
    'brown',
    'yellow',
    'purple',
    'cyan',
    'red',
    'deep-orange',
    'deep-purple',
    'blue',
    '#9089E7',
    '#E85C39'
  ];
  takenColors: { [id: string]: string } = {};

  getColor(profession: string) {
    if (this.takenColors[profession]) {
      return this.takenColors[profession];
    } else {
      if (profession) {
        const color = this.availableColors.pop() ?? 'pink';
        this.takenColors[profession] = color;
      }
    }
    return this.takenColors[profession];
  }

  formatDuration(item: Duration) {
    if (item == null) {
      return '00:00';
    }
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }
}
