var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.PlayerTable.Header'))+" "),_c('v-btn',{staticClass:"text-capitalize justify-start ml-4 game-statistics-players-export-btn",attrs:{"small":"","dark":"","color":"green","loading":_vm.exporting,"disabled":_vm.data.length === 0},on:{"click":_vm.emitExportCsv}},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.ExportBtn'))+" ")],1),_c('v-btn',{staticClass:"text-capitalize justify-start ml-4 game-statistics-players-export-btn white--text",attrs:{"href":("mailto:" + _vm.selectedEmails + "?subject=" + (_vm.$t('Modules.Statistics.PlayerProfile.EmailSubject')) + "&body=" + (_vm.$t(
          'Modules.Statistics.PlayerProfile.EmailBodyGeneric'
        ))),"small":"","color":"#1AABBC ","loading":_vm.exporting,"disabled":_vm.selected.length === 0}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-email-outline")]),_vm._v(" Send message ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Modules.Statistics.PlayerTable.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.localLoading)?_c('v-data-table',{staticClass:"v-list__group__header",attrs:{"custom-sort":_vm.customSort,"sort-by":['score'],"sort-desc":[true],"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.tablesSize,"show-select":"","item-key":"email","footer-props":{
      'items-per-page-text': _vm.$t('dataIterator.rowsPerPageText'),
      'page-text': _vm.$t('dataIterator.pageText'),
      'custom-highlight-row': '',
      'items-per-page-options': [5, 10, 25, { text: this.$t('dataIterator.AllText'), value: -1 }]
    }},on:{"update:itemsPerPage":function($event){_vm.tablesSize=$event},"update:items-per-page":function($event){_vm.tablesSize=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"v-lowercase body-2",attrs:{"user":"","text":""},on:{"click":function($event){return _vm.goToPlayerStatistic(item.email, item.profession)}}},[_vm._v(" "+_vm._s(item.email)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey","small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.profession",fn:function(ref){
    var item = ref.item;
return _vm._l((item.playedProfession),function(key,i){return _c('div',{key:i},[_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(key)+" ")])],1)})}},{key:"item.isFavorite",fn:function(ref){
    var item = ref.item;
return [(item.isFavorite)?_c('v-img',{attrs:{"src":require("@/assets/statistics/trophy-gold.svg"),"height":"50","width":"50"}}):_c('v-img',{attrs:{"src":require("@/assets/statistics/trophy.svg"),"height":"50","width":"50"}})]}},{key:"item.rating",fn:function(ref){
    var item = ref.item;
return [(item.rating > 0)?_c('div',{staticClass:"d-flex align-end"},[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.rating)))]),_c('v-icon',{attrs:{"color":_vm.rating(item.rating).color}},[_vm._v(_vm._s(_vm.rating(item.rating).icon))])],1):_vm._e()]}},{key:"item.finishedGames",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finishedGames)+"/"+_vm._s(item.startedGames)+" ")]}},{key:"item.totalTimeForGames",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.totalTimeForGames))+" ")]}},{key:"item.averageTimeForGame",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.averageTimeForGame))+" ")]}},{key:"item.addedCV",fn:function(ref){
    var item = ref.item;
return [(item.addedCV)?_c('div',{staticClass:"pa-1 green rounded-circle d-inline-block"},[_c('v-icon',{attrs:{"color":'white'},domProps:{"textContent":_vm._s('mdi-checkbox-marked-circle-outline')}})],1):_c('div',{staticClass:"pa-1 red rounded-circle d-inline-block"},[_c('v-icon',{attrs:{"color":'white'},domProps:{"textContent":_vm._s('mdi-checkbox-blank-circle-outline')}})],1)]}},{key:"item.button",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"dense":"","justify":"space-around","align":"center"}},[_c('list-menu',[_c('v-list',[_c('v-list-item',{staticClass:"statistics-player-list-details-btn",on:{"click":function($event){return _vm.goToPlayerStatistic(item.email, item.profession)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-text")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.Statistics.ExtendStatistic.Details')))])],1)],1)],1)],1)],1)]}}],null,false,1342590718),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }