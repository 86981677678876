import Vue from 'vue';
import EditCardMain from '@/modules/layout/components/EditCardMain.vue';
import EditCardSection from '@/modules/layout/components/EditCardSection.vue';
import EmptyListContainer from '@/modules/layout/components/EmptyListContainer.vue';
import ListMenu from '@/modules/layout/components/ListMenu.vue';
import ListItemHeader from '@/modules/layout/components/ListItemHeader.vue';
import TableItemHeader from '@/modules/layout/components/TableItemHeader.vue';
import FormTextInput from '@/modules/layout/components/forms/FormTextInput.vue';
import FormTextInfo from '@/modules/layout/components/forms/FormTextInfo.vue';
import FormAutocompleteSelect from '@/modules/layout/components/forms/FormAutocompleteSelect.vue';
import FormSelect from '@/modules/layout/components/forms/FormSelect.vue';
import FormConditionsCheckbox from '@/modules/layout/components/forms/FormConditionsCheckbox.vue';
import SkeletonLoaderDetails from '@/modules/layout/components/SkeletonLoaderDetails.vue';
import IntersectMenuSection from '@/modules/layout/components/IntersectMenuSection.vue';
import ListItem from '@/modules/layout/components/ListItem.vue';
import TableItemLink from '@/modules/layout/components/TableItemLink.vue';
import ListContainer from '@/modules/layout/components/ListContainer.vue';
import FormCheckbox from '@/modules/layout/components/forms/FormCheckbox.vue';
import DetailRow from '@/modules/layout/components/DetailRow.vue';
import CustomDataTable from '@/modules/layout/components/CustomDataTable.vue';
import TableItemHeaderLink from '@/modules/layout/components/TableItemHeaderLink.vue';

Vue.component('edit-card-main', EditCardMain);
Vue.component('edit-card-section', EditCardSection);
Vue.component('empty-list-container', EmptyListContainer);
Vue.component('list-menu', ListMenu);
Vue.component('list-item-header', ListItemHeader);
Vue.component('table-item-header', TableItemHeader);
Vue.component('form-text-input', FormTextInput);
Vue.component('form-text-info', FormTextInfo);
Vue.component('form-autocomplete-select', FormAutocompleteSelect);
Vue.component('form-select', FormSelect);
Vue.component('form-conditions-checkbox', FormConditionsCheckbox);
Vue.component('skeleton-loader-details', SkeletonLoaderDetails);
Vue.component('intersect-menu-section', IntersectMenuSection);
Vue.component('list-container', ListContainer);
Vue.component('list-item', ListItem);
Vue.component('table-item-link', TableItemLink);
Vue.component('form-checkbox', FormCheckbox);
Vue.component('detail-row', DetailRow);
Vue.component('custom-data-table', CustomDataTable);
Vue.component('table-item-header-link', TableItemHeaderLink);
