



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormTextInput extends Vue {
  @Prop({ default: 'Label Text' }) label!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop() value!: string;
  @Prop() rules!: object[];

  updateValue(value: string) {
    this.$emit('input', value);
  }
}
