import { Bullet } from '../Models/Bullet';
import { SoundManager } from '../Global/soundManager';
export class BulletGroup extends Phaser.Physics.Arcade.Group {
  bulletWidth: number;
  bulletHeight: number;

  constructor(scene: Phaser.Scene, bulletTexture: any, bulletWidth: number, bulletHeight: number) {
    super(scene.physics.world, scene);
    this.createMultiple({
      frameQuantity: 10,
      key: bulletTexture,
      active: false,
      visible: false,
      classType: Bullet
    });
    this.bulletWidth = bulletWidth;
    this.bulletHeight = bulletHeight;
  }
  deleteObject() {
    this.getChildren().forEach(function(value) {
      const val = value as Bullet;
      val.killBullet();
    });
  }
  fireBullet(x: number, y: number, angle: number, speed: number) {
    const bullet = this.getFirstDead(false);
    if (bullet) {
      bullet.fire(x, y, this.bulletWidth, this.bulletHeight, angle, speed);
      this.scene.sound.add(SoundManager.shootName).play();
    }
  }
}
