




import { Component, Vue } from 'vue-property-decorator';
import { KitchenSinkRoute } from '@/modules/kitchen-sink/kitchen-sink-router.enum';

@Component
export default class EmptyListContainerPreview extends Vue {
  text = this.$t('KitchenSink.Previews.EmptyListContainerPreview.Text');
  routeName = KitchenSinkRoute.kitchenSink;
}
