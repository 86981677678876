import 'phaser';
import { GameTriggerType } from '../game-engine-base';
import { Ship } from './Models/shipcontroller';
import { KeySide } from './Models/KeySide';
import { EnemyGroup } from './Models/EnemyGroup';
import { Background } from './Models/background';
import { Base } from './Global/base-controll-class';
import { SoundManager } from './Global/soundManager';
import { SpaceRescueVariables } from './Global/SpaceRescueVariables';
import { GameCustomizableElement, GameCustomizableElementType } from '../game-engine-customizable-element';
import { GameTheme } from '../../models/game-theme';
import { EngineController } from '@/modules/game-play/games/framework/engine-controller';
import { Enemy } from './Models/Enemy';

//Game Main class
export class GameScene extends Phaser.Scene {
  constructor(
    protected askQuestion: () => void,
    protected gameOverDialog: (score: number) => void,
    protected saveGameLog: (score: number, trigger: GameTriggerType) => void,
    getCustomziableTypes: GameCustomizableElement[],
    protected theme?: GameTheme
  ) {
    super({
      key: 'Game',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      }
    });
    this.getCustomziableTypes = getCustomziableTypes;
  }

  getCustomziableTypes: any;
  level!: number;
  ship!: Ship;
  baseControllClass!: Base;
  background!: Background;
  enemys!: EnemyGroup;
  image: any;
  emiterEvent: any;
  lives!: Phaser.GameObjects.Group;
  killedEnemy!: number;
  points!: number;
  lastSpeedBoost = 0;
  allSprites!: GameCustomizableElement[];
  allText!: GameCustomizableElement[];
  cursorKeys!: any;
  deltaTime!: number;
  blockKey = false;

  //Init all parametrs
  init(params: any): void {
    this.killedEnemy = 0;
    this.points = 0;
    this.level = 1;
  }

  //Load all texture before launch game
  preload() {
    //Inicializate Game Framework
    EngineController.initialize(this, this.theme, this.getCustomziableTypes);

    this.physics.world.setFPS(60);
    this.physics.world.setBoundsCollision();

    this.allSprites = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Image
    );
    this.allText = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Text
    );

    //Load all sprite texture

    this.allSprites.forEach(element => {
      if (element.value != null || element.value != '') {
        this.textures.addBase64(element.name, element.value);
      }
    });

    //Load audio by AudioManager class
    const shootSound = this.theme?.overridenSounds[SoundManager.shootName] ?? SoundManager.shoot;
    const killEnemySound = this.theme?.overridenSounds[SoundManager.killEnemyName] ?? SoundManager.killEnemy;
    const explosionSound = this.theme?.overridenSounds[SoundManager.explosionName] ?? SoundManager.explosion;
    this.load.audio(SoundManager.shootName, shootSound);
    this.load.audio(SoundManager.killEnemyName, killEnemySound);
    this.load.audio(SoundManager.explosionName, explosionSound);

    //Load scene data
    this.data.set('pause', false);
  }

  //Main function scene to create GameObjects
  create() {
    this.registry.events.on('textControllerReplay', this.textControllerReplay, this);
    //initialize base class
    const liveData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.LivesName) {
        return entity;
      }
    });
    this.baseControllClass = new Base(liveData.value);

    //create ship and asigns it position and speed
    const shipData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.shipSpriteName) {
        return entity;
      }
    });

    //Get all data abount Bullet
    const bulletData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.bulletSpriteName) {
        return entity;
      }
    });
    this.ship = new Ship(
      this,
      this.baseControllClass.playerPozX,
      this.baseControllClass.playerPozY,
      this.baseControllClass.playerSpeed,
      this.baseControllClass.playerMaxSpeed,
      this.baseControllClass.playerBorderMax,
      this.baseControllClass.playerBorderMin,
      shipData.width,
      shipData.heigth,
      bulletData.width,
      bulletData.heigth,
      this.theme
    );

    //create bacgroun for this scene
    const starsData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.starsSpriteName) {
        return entity;
      }
    });
    this.background = new Background(this, starsData.width, starsData.heigth);

    //initialize emiter used on scene
    this.emiterEvent = new Phaser.Events.EventEmitter();

    //sets behavior if UnderCollisionEnemy string is emited
    this.emiterEvent.on('UnderCollisionEnemy', this.underCollisionEnemy, this);

    //Event on end
    this.registry.events.on('endGameDialog', this.endGameDialog, this);

    //initialize enemy class with scen and emiter used by it
    const enemyData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.enemySpriteName) {
        return entity;
      }
    });
    const enemyBgData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.enemyBgSpriteName) {
        return entity;
      }
    });
    const enemyBulletData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.enemyBulletSpriteName) {
        return entity;
      }
    });
    this.enemys = new EnemyGroup(
      this,
      this.emiterEvent,
      enemyData.width,
      enemyData.heigth,
      enemyBgData.width,
      enemyBgData.heigth,
      enemyBulletData.width,
      enemyBulletData.heigth
    );

    //create enemys
    this.createEnemy();

    //Collider player with enemy
    this.physics.add.overlap(this.ship, this.enemys, (bullet: any, enemy: any) => {
      if (!this.data.get('pause')) {
        this.data.set('pause', true);
        this.physics.pause();
        this.makeQuestion();
        this.sound.add(SoundManager.explosionName).play();
      }
    });

    //Add event to fire bullets on SPACE
    this.input.keyboard.on('keydown-SPACE', (pointer: any) => {
      if (!this.data.get('pause')) {
        this.ship.bullets.fireBullet(this.ship.x, this.ship.y - 25, 90, -300);
      }
    });

    EngineController.createLifeSystem(SpaceRescueVariables.heartSpriteName, this.baseControllClass.life);

    //Show text on start
    this.physics.pause();
    this.data.set('pause', true);
    EngineController.createText(
      this.allText.find((val: { name: string }) => val.name == 'StartText')?.value.toString(),
      false
    );

    //  Check the Registry and hit our callback every time the 'score' value is updated
    this.registry.events.on('AnswerEvent', this.respondAnswer, this);

    this.cursorKeys = this.input.keyboard.createCursorKeys();

    this.saveGameLog(1, GameTriggerType.GameStart);

    //Create time
    this.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        const isGamePaused: boolean = this.data.get('pause');
        const isShootingEnabled = this.baseControllClass.levels[this.level - 1].enemyCanShoot;
        const noEnemyBulletsAreOnScreen = this.enemys.bullets.getChildren().find(object => object.active) == undefined;
        
        if(!isGamePaused && isShootingEnabled && noEnemyBulletsAreOnScreen) {
          const bulletSpeed = this.baseControllClass.levels[this.level - 1].enemyBulletSpeed;
          this.enemys.fireRandomBullet(bulletSpeed);
        }  
      }
    });
  }

  //Crate and dipaly lives
  createLives(): void {
    //Get all data
    const heartData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == SpaceRescueVariables.heartSpriteName) {
        return entity;
      }
    });

    //create group, set it max vlaue and default key image
    this.lives = this.add.group({
      defaultKey: SpaceRescueVariables.heartSpriteName,
      active: true,
      maxSize: this.baseControllClass.life
    });

    //add to group live images on certain positin and scale
    for (let q = 0; q < this.baseControllClass.life; q++) {
      const tempImage = this.add.image(40 * q + 30, 25, SpaceRescueVariables.heartSpriteName);
      tempImage.displayHeight = heartData.heigth;
      tempImage.displayWidth = heartData.width;
      this.lives.add(tempImage);
    }
  }

  //Update function scene
  update(time: number, delta: number): void {
    this.deltaTime = delta;

    //check if game is paused
    if (!this.data.get('pause')) {
      this.points += 5;
      //gets keys presed by player

      //Manage keyboard and player movement
      if (this.cursorKeys.left.isDown && !this.blockKey) {
        this.ship.move(KeySide.left, delta);
        this.blockKey = true;
      } else if (this.cursorKeys.right.isDown && !this.blockKey) {
        this.ship.move(KeySide.right, delta);
        this.blockKey = true;
      } else if (!this.blockKey) {
        this.ship.move(KeySide.stop, delta);
        this.blockKey = true;
      } else {
        this.blockKey = false;
      }

      //check if enemy hit the screen border
      this.enemys.checkAllEnemy();

      //Check all enemys are killed
      this.checkAllEnemyAreKiled();
    }

    //Background Controller
    this.updateBackgroun();

    //Update bar
    this.updatePlayersScoreUI();
  }

  //Check all enemys are killed
  checkAllEnemyAreKiled(): void {
    const totalUsed = this.enemys.getTotalUsed();

    if (totalUsed == 0 && !this.data.get('pause')) {
      this.data.set('pause', true);
      this.physics.pause();
      this.level += 1;
      this.nextLevel(this.allText.find((val: { name: string }) => val.name == 'NextLevel')?.value.toString() + " " + this.level,true);
    } else {
      if (this.checkLevelReturn()) {
        if (this.baseControllClass.levels[this.level - 1].speedUpEnemy) {
          this.sendSpeedUpNotification();
        }
      } else {
        if (this.baseControllClass.levels[this.baseControllClass.levels.length - 1].speedUpEnemy) {
          this.sendSpeedUpNotification();
        }
      }
    }
  }
  sendSpeedUpNotification() {
    const totalUsed = this.enemys.getTotalUsed();
    let lastSpeed = 0;
    this.baseControllClass.enemySpeedBarier.forEach(val => {
      if (totalUsed <= val[0]) {
        lastSpeed = val[1];
      }
    });
    if (lastSpeed > this.lastSpeedBoost) {
      this.registry.events.emit('speedUpEnemy', { speed: lastSpeed });
      this.lastSpeedBoost = lastSpeed;
    }
  }
  //Background Controller
  updateBackgroun(): void {
    if (this.checkLevelReturn()) {
      Phaser.Actions.IncY(
        this.background.group.getChildren(),
        this.baseControllClass.levels[this.level - 1].backgroundSpeed
      );
    } else {
      Phaser.Actions.IncY(
        this.background.group.getChildren(),
        this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed
      );
    }
    this.background.group.children.iterate((alien: any) => {
      if (alien.y > this.game.canvas.height) {
        this.background.group.killAndHide(alien);
      }
    });
  }

  //Update bar
  updatePlayersScoreUI(): void {
    if (this.checkLevelReturn()) {
      EngineController.updateTimePoints(
        this.points,
        this.level,
        this.killedEnemy,
        this.baseControllClass.levels[this.level - 1].enemyCountX *
          this.baseControllClass.levels[this.level - 1].enemyCountY
      );
    } else {
      EngineController.updateTimePoints(
        this.points,
        this.level,
        this.killedEnemy,
        this.baseControllClass.levels[this.baseControllClass.levels.length - 1].enemyCountX *
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].enemyCountY
      );
    }
  }

  //Destroy Gameobjects in collision bullet with enemy
  destroyElements(bullet: any, enemy: any) {
    if (!this.data.get('pause')) {
      enemy.destroyObject();
      bullet.killBullet();
      this.sound.add(SoundManager.explosionName).play();
    }
  }

  //Process callback from collision bullet with enemy
  processCollider() {
    if (!this.data.get('pause')) {
      this.points += 200;
      this.killedEnemy += 1;
    }
  }

  //Runs if enemy and player colides
  underCollisionEnemy() {
    if (!this.data.get('pause')) {
      this.data.set('pause', true);
      this.physics.pause();
      this.makeQuestion();
    }
  }

  //Set new level after question or player destroyed all enemys
  nextLevel(textToShow: string | undefined, isNextLevel: boolean) {
    //pause game
    this.data.set('pause', true);

    this.ship.move(KeySide.stop, 0);
    if(isNextLevel){
      this.saveGameLog(Math.round(this.points), GameTriggerType.NewLevel);
    }
    //pause game phisic
    this.physics.pause();
    this.killedEnemy = 0;
    this.ship.bullets.deleteObject();
    this.enemys.bullets.deleteObject();
    this.createEnemy();
    if (textToShow != '') {
      EngineController.createText(textToShow, false);
    }
  }

  //check if we dont overstep baseControllClass array
  checkLevelReturn() {
    if (this.level - 1 <= this.baseControllClass.levels.length - 1) {
      return true;
    }
    return false;
  }

  //Create new enemys and sets thiers basic vlaues
  createEnemy() {
    if (this.enemys.getLength() != 0) {
      this.enemys.deleteObject();
    }
    this.lastSpeedBoost = 0;
    //check if we are in boundaries of baseControllClass array if not it gets last itme in array and use it
    if (this.checkLevelReturn()) {
      this.enemys.createEnemys(
        this.baseControllClass.levels[this.level - 1].enemyCountX,
        this.baseControllClass.levels[this.level - 1].enemyCountY,
        this.baseControllClass.levels[this.level - 1].enemySpeedX,
        this.baseControllClass.levels[this.level - 1].enemySpeedY
      );
    } else {
      const lastLevel = this.baseControllClass.levels[this.baseControllClass.levels.length - 1];
      const levelMultiplier = this.level - this.baseControllClass.levels.length;
      const multiplier = 1 + this.baseControllClass.enemySpeedMultiplicator * levelMultiplier;

      this.enemys.createEnemys(
        lastLevel.enemyCountX,
        lastLevel.enemyCountY,
        lastLevel.enemySpeedX * multiplier,
        lastLevel.enemySpeedY * multiplier
      );
    }

    //Collider bullets with enemy
    this.physics.add.overlap(this.ship.bullets, this.enemys, this.destroyElements, this.processCollider, this);

    //Collider bullets with player
    this.physics.add.overlap(this.enemys.bullets, this.ship, undefined, this.underCollisionEnemy, this);

    //Collider player bullets with enemy bullets
    this.physics.add.overlap(this.ship.bullets, this.enemys.bullets, (b1: any, b2: any) => {
      b1.killBullet();
      b2.killBullet();
    });
  }

  //Respond answer from server
  respondAnswer(data: boolean, answerPoolEmpty: boolean) {
    this.data.set('pause', true);
    this.physics.pause();
    if (answerPoolEmpty) {
      this.saveGameLog(Math.round(this.points), GameTriggerType.LiveLost);
      if (EngineController.lifeLost(true)) {
        this.data.set('pause', true);
        this.physics.pause();
      }
      this.nextLevel('',false);
    } else {
      switch (data) {
        case true:
          this.points += 500;
          this.saveGameLog(Math.round(this.points), GameTriggerType.LiveSaved);
          this.nextLevel(
            this.allText.find((val: { name: string }) => val.name == 'AnswerTextCorrect')?.value.toString(),false
          );
          break;
        case false:
          this.saveGameLog(Math.round(this.points), GameTriggerType.LiveLost);
          if (EngineController.lifeLost(false)) {
            this.data.set('pause', true);
            this.physics.pause();
            break;
          }
          this.nextLevel(this.allText.find((val: { name: string }) => val.name == 'AnswerTextWrong')?.value.toString(),false);
          break;
      }
    }
  }

  //Mark Question
  makeQuestion() {
    //this.registry.set("answer",-1);
    this.physics.pause();
    this.data.set('pause', true);
    this.askQuestion();
  }

  endGameDialog() {
    this.gameOverDialog(this.points);
    this.scene.pause();
    this.saveGameLog(Math.round(this.points), GameTriggerType.GameOver);
    this.game.destroy(true, false);
  }
  textControllerReplay() {
    this.physics.resume();
    this.data.set('pause', false);
  }
}
