import DefaultView from '@/modules/layout/DefaultView.vue';
import PaymentsHistoryDetails from '@/modules/payments-history/views/PaymentsHistoryDetails.vue';
import PaymentsHistoryList from '@/modules/payments-history/views/PaymentsHistoryList.vue';
import { RawLocation, RouteConfig } from 'vue-router';
import { PaymentsHistoryRoute } from './payments-history-router.enum';

export const paymentsHistoryRoutes: Array<RouteConfig> = [
  {
    path: '/payments-history',
    component: DefaultView,
    children: [
      {
        path: '',
        name: PaymentsHistoryRoute.paymentsHistoryList,
        component: PaymentsHistoryList,
        props: true
      },
      {
        path: ':orderId',
        name: PaymentsHistoryRoute.paymentsHistoryDetails,
        component: PaymentsHistoryDetails,
        props: true
      },
      {
        path: ':orderId/callback',
        redirect: from =>
          ({
            name: PaymentsHistoryRoute.paymentsHistoryDetails,
            params: {
              orderId: from.params.orderId,
              paymentStatus: from.query.error != null ? 'error' : 'success'
            },
            query: undefined,
            replace: true
          } as RawLocation)
      }
    ]
  }
];
