import { GameType } from '@/services/Api';
import { GameCustomizableElement } from '../games/game-engine-customizable-element';
import { RiverRunVariables } from '../games/river-run/Global/RiverRunVariables';
import { SpaceRescueVariables } from '../games/space-rescue/Global/SpaceRescueVariables';
import { MazeGameVariables } from '../games/maze-game/Global/MazeGameVariables';
import { GameTheme } from '../models/game-theme';

export default class GameThemeService {
  // Space rescue
  private static desertShip = require('@/assets/games/space-rescue/themes/desert/sprites/ship.png');
  private static desertBullet = require('@/assets/games/space-rescue/themes/desert/sprites/axe.png');
  private static desertEnemyBullet = require('@/assets/games/space-rescue/themes/desert/sprites/enemy-bullet.png');
  private static desertShadow = require('@/assets/games/space-rescue/themes/desert/sprites/shadow.png');
  private static desertEnemy = require('@/assets/games/space-rescue/themes/desert/sprites/spider.png');
  private static desertStar = require('@/assets/games/space-rescue/themes/desert/sprites/sand.png');
  private static desertLife = require('@/assets/games/space-rescue/themes/desert/sprites/life.png');
  private static desertBackground = require('@/assets/games/space-rescue/themes/desert/background.svg');
  private static desertBackgroundForm = require('@/assets/games/space-rescue/themes/desert/form-background-blur.svg');
  private static desertBackgroundQuestion = require('@/assets/games/space-rescue/themes/desert/question-background-blur.svg');
  private static desertBackgroundGame = require('@/assets/games/space-rescue/themes/desert/game-background-blur.svg');

  private static witchShip = require('@/assets/games/space-rescue/themes/little-witch/sprites/little-witch.png');
  private static witchBullet = require('@/assets/games/space-rescue/themes/little-witch/sprites/spell.png');
  private static witchEnemyBullet = require('@/assets/games/space-rescue/themes/little-witch/sprites/enemy-bullet.png');
  private static witchShadow = require('@/assets/games/space-rescue/themes/little-witch/sprites/shadow.png');
  private static witchEnemy = require('@/assets/games/space-rescue/themes/little-witch/sprites/witch.png');
  private static witchStar = require('@/assets/games/space-rescue/themes/little-witch/sprites/crow.png');
  private static witchLife = require('@/assets/games/space-rescue/themes/little-witch/sprites/life.png');
  private static witchBackground = require('@/assets/games/space-rescue/themes/little-witch/background.svg');
  private static witchBackgroundForm = require('@/assets/games/space-rescue/themes/little-witch/form-background-blur.svg');
  private static witchBackgroundQuestion = require('@/assets/games/space-rescue/themes/little-witch/question-background-blur.svg');
  private static witchBackgroundGame = require('@/assets/games/space-rescue/themes/little-witch/game-background-blur.svg');

  private static princessShip = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/princess.png');
  private static princessBullet = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/heart.png');
  private static princessEnemyBullet = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/enemy-bullet.png');
  private static princessShadow = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/shadow.png');
  private static princessEnemy = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/frog.png');
  private static princessStar = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/glitter.png');
  private static princessLife = require('@/assets/games/space-rescue/themes/froggy-princess/sprites/life.png');
  private static princessBackground = require('@/assets/games/space-rescue/themes/froggy-princess/background.svg');
  private static princessBackgroundForm = require('@/assets/games/space-rescue/themes/froggy-princess/form-background-blur.svg');
  private static princessBackgroundQuestion = require('@/assets/games/space-rescue/themes/froggy-princess/question-background-blur.svg');
  private static princessBackgroundGame = require('@/assets/games/space-rescue/themes/froggy-princess/game-background-blur.svg');

  private static spaceRescueBackground = require('@/assets/games/space-rescue/background.svg');
  private static spaceRescueBackgroundForm = require('@/assets/games/space-rescue/form-background-blur.svg');
  private static spaceRescueBackgroundQuestion = require('@/assets/games/space-rescue/question-background-blur.svg');
  private static spaceRescueBackgroundGame = require('@/assets/games/space-rescue/game-background-blur.svg');
  // River Run
  private static speedRaceEnemy1 = require('@/assets/games/river-run/themes/speed-race/sprites/car_yellow.png');
  private static speedRaceEnemy2 = require('@/assets/games/river-run/themes/speed-race/sprites/car_red.png');
  private static speedRaceEnemy3 = require('@/assets/games/river-run/themes/speed-race/sprites/car_green.png');
  private static speedRacePlayer = require('@/assets/games/river-run/themes/speed-race/sprites/car_white.png');
  private static speedRacePeople1 = require('@/assets/games/river-run/themes/speed-race/sprites/small-plants.png');
  private static speedRaceTree = require('@/assets/games/river-run/themes/speed-race/sprites/rock.svg');
  private static speedRaceHeart = require('@/assets/games/river-run/themes/speed-race/sprites/car_heart.png');
  private static speedRaceObject1 = require('@/assets/games/river-run/themes/speed-race/sprites/oil.png');
  private static speedRaceRoad = require('@/assets/games/river-run/themes/speed-race/sprites/road.png');
  private static speedRaceGrassLeft = require('@/assets/games/river-run/themes/speed-race/sprites/grassL.png');
  private static speedRaceGrassRight = require('@/assets/games/river-run/themes/speed-race/sprites/grassR.png');
  private static speedRaceOdometer = require('@/assets/games/river-run/themes/speed-race/sprites/odometer.png');
  private static speedRaceOdometerLine = require('@/assets/games/river-run/themes/speed-race/sprites/odometerline.png');
  private static speedRaceEndLevelObj = require('@/assets/games/river-run/themes/speed-race/sprites/flag.png');
  private static speedRaceStaminaBottom = require('@/assets/games/river-run/themes/speed-race/sprites/speedRace-stamina-bottom.png');
  private static speedRaceStaminaTop = require('@/assets/games/river-run/themes/speed-race/sprites/speedRace-stamina-top.png');
  private static speedRaceBackground = require('@/assets/games/river-run/themes/speed-race/background.svg');
  private static speedRaceBackgroundForm = require('@/assets/games/river-run/themes/speed-race/form-background-blur.svg');
  private static speedRaceBackgroundQuestion = require('@/assets/games/river-run/themes/speed-race/question-background-blur.svg');

  private static piratesEnemy1 = require('@/assets/games/river-run/themes/pirates/sprites/ship_enemy1.png');
  private static piratesEnemy2 = require('@/assets/games/river-run/themes/pirates/sprites/ship_enemy2.png');
  private static piratesEnemy3 = require('@/assets/games/river-run/themes/pirates/sprites/ship_enemy3.png');
  private static piratesPlayer = require('@/assets/games/river-run/themes/pirates/sprites/ship.png');
  private static piratesPeople1 = require('@/assets/games/river-run/themes/pirates/sprites/small-plants.png');
  private static piratesTree = require('@/assets/games/river-run/themes/pirates/sprites/rocks.png');
  private static piratesHeart = require('@/assets/games/river-run/themes/pirates/sprites/ship_heart.png');
  private static piratesObject1 = require('@/assets/games/river-run/themes/pirates/sprites/object.png');
  private static piratesWater = require('@/assets/games/river-run/themes/pirates/sprites/water.png');
  private static piratesGrassLeft = require('@/assets/games/river-run/themes/pirates/sprites/grassL.png');
  private static piratesGrassRight = require('@/assets/games/river-run/themes/pirates/sprites/grassR.png');
  private static piratesOdometer = require('@/assets/games/river-run/themes/pirates/sprites/odometer.png');
  private static piratesOdometerLine = require('@/assets/games/river-run/themes/pirates/sprites/odometerline.png');
  private static piratesEndLevelObj = require('@/assets/games/river-run/themes/pirates/sprites/flag.png');
  private static piratesStaminaBottom = require('@/assets/games/river-run/themes/pirates/sprites/pirates-stamina-bottom.png');
  private static piratesStaminaTop = require('@/assets/games/river-run/themes/pirates/sprites/pirates-stamina-top.png');
  private static piratesBackground = require('@/assets/games/river-run/themes/pirates/background.svg');
  private static piratesBackgroundForm = require('@/assets/games/river-run/themes/pirates/form-background-blur.svg');
  private static piratesBackgroundQuestion = require('@/assets/games/river-run/themes/pirates/question-background-blur.svg');

  private static skyFlightEnemy1 = require('@/assets/games/river-run/themes/skyFlight/sprites/plane_enemy1.png');
  private static skyFlightEnemy2 = require('@/assets/games/river-run/themes/skyFlight/sprites/plane_enemy2.png');
  private static skyFlightEnemy3 = require('@/assets/games/river-run/themes/skyFlight/sprites/plane_enemy3.png');
  private static skyFlightPlayer = require('@/assets/games/river-run/themes/skyFlight/sprites/plane.png');
  private static skyFlightPeople1 = require('@/assets/games/river-run/themes/skyFlight/sprites/threeBirds.png');
  private static skyFlightTree = require('@/assets/games/river-run/themes/skyFlight/sprites/LittleBirds.png');
  private static skyFlightHeart = require('@/assets/games/river-run/themes/skyFlight/sprites/planeheart.png');
  private static skyFlightObject1 = require('@/assets/games/river-run/themes/skyFlight/sprites/stormcloud.png');
  private static skyFlightSky = require('@/assets/games/river-run/themes/skyFlight/sprites/Sky.png');
  private static skyFlightCloudLeft = require('@/assets/games/river-run/themes/skyFlight/sprites/CloudL.png');
  private static skyFlightCloudRight = require('@/assets/games/river-run/themes/skyFlight/sprites/CloudR.png');
  private static skyFlightOdometer = require('@/assets/games/river-run/themes/skyFlight/sprites/odometer.png');
  private static skyFlightOdometerLine = require('@/assets/games/river-run/themes/skyFlight/sprites/odometerline.png');
  private static skyFlightEndLevelObj = require('@/assets/games/river-run/themes/skyFlight/sprites/flag.png');
  private static skyFlightBackground = require('@/assets/games/river-run/themes/skyFlight/background.svg');
  private static skyFlightBackgroundMenu = require('@/assets/games/river-run/themes/skyFlight/background-menu.svg');
  private static skyFlightBackgroundForm = require('@/assets/games/river-run/themes/skyFlight/form-background-blur.svg');
  private static skyFlightBackgroundQuestion = require('@/assets/games/river-run/themes/skyFlight/question-background-blur.svg');
  private static skyFlightStaminaBottom = require('@/assets/games/river-run/themes/skyFlight/sprites/plane-stamina-bottom.png');
  private static skyFlightStaminaTop = require('@/assets/games/river-run/themes/skyFlight/sprites/plane-stamina-top.png');

  public static getTheme(type: GameType): GameTheme | undefined {
    switch (type) {
      case GameType.DesertAdventure:
        return this.getDesertTheme();
      case GameType.SpaceRescue:
        return this.getSpaceTheme();
      case GameType.LittleWitch:
        return this.getLittleWitchTheme();
      case GameType.FroggyPrincess:
        return this.getFroggyPrincessTheme();
      case GameType.Pirates:
        return this.getPiratesTheme();
      case GameType.SpeedRace:
        return this.getSpeedRaceTheme();
      case GameType.MazeGame:
        return this.getSpaceTheme();
      case GameType.SkyFlight:
        return this.getSkyFlightTheme();
      default:
        return undefined;
    }
  }
  private static getDesertTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Russo One',
      fontSize: '18px',
      fontStartSize: '70px',
      textColor: '#564C4C',
      textShadowColor: '#443F2D00',
      announcementColor: '#503F35',
      playerAnimation: false,
      background: this.desertBackground,
      styles: {
        '--game-input-background-color': 'rgba(255, 255, 255, 0.253)',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#db7b1c',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#bd6811da',
        '--game-primary-color': 'rgb(216, 136, 16)',
        '--game-secondary-color': 'rgb(216, 155, 40)',
        '--game-third-color': 'rgb(216, 136, 16)',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': 'rgb(3, 3, 3)',
        '--game-score-shadow-color': 'none',
        '--game-background': `url(${this.desertBackground})`,
        '--game-background-menu': `url(${this.desertBackground})`,
        '--game-background-color': `url(${this.desertBackgroundGame})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.desertBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.desertBackgroundQuestion})`,
        '--question-modal-title': 'rgb(255, 204, 109)',
        '--game-left-intro-title-font': `'Russo One', sans-serif`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': 'white'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(SpaceRescueVariables.shipSpriteName, this.desertShip, 34, 48, true, 7),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.enemySpriteName, this.desertEnemy, 40, 40, true, 8),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.starsSpriteName, this.desertStar, 15, 15, false),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.bulletSpriteName, this.desertBullet, 20, 25, true, 10),
        GameCustomizableElement.CreateImage(
          SpaceRescueVariables.enemyBulletSpriteName,
          this.desertEnemyBullet,
          20,
          25,
          true,
          11
        ),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.enemyBgSpriteName, this.desertShadow, 150, 150, false),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.heartSpriteName, this.desertLife, 30, 25, true, 9)
      ],
      overridenSounds: {
        shoot: require('@/assets/games/space-rescue/themes/desert/sounds/shoot.ogg'),
        explosion: require('@/assets/games/space-rescue/themes/desert/sounds/explosion.ogg'),
        killEnemy: require('@/assets/games/space-rescue/themes/desert/sounds/invaderkilled.ogg')
      }
    };
    return theme;
  }
  private static getLittleWitchTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Kelly Slab',
      fontSize: '20px',
      fontStartSize: '80px',
      textColor: '#fff',
      textShadowColor: '#000000',
      announcementColor: '#e391ff',
      playerAnimation: false,
      background: this.witchBackground,
      styles: {
        '--game-input-background-color': 'rgba(255, 255, 255, 0.253)',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#bc40e5',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#852aa2da',
        '--game-primary-color': '#c160e0',
        '--game-secondary-color': '#c160e0',
        '--game-third-color': '#852aa2',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': 'rgb(3, 3, 3)',
        '--game-score-shadow-color': 'none',
        '--game-background-color': `url(${this.witchBackgroundGame})`,
        '--game-background': `url(${this.witchBackground})`,
        '--game-background-menu': `url(${this.witchBackground})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.witchBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.witchBackgroundQuestion})`,
        '--question-modal-title': '#e097f7',
        '--game-left-intro-title-font': `'Kelly Slab', cursive`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': 'white'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(SpaceRescueVariables.shipSpriteName, this.witchShip, 34, 40, true, 7),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.enemySpriteName, this.witchEnemy, 52, 48, true, 8),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.starsSpriteName, this.witchStar, 16, 4, false),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.bulletSpriteName, this.witchBullet, 72, 42, true, 10),
        GameCustomizableElement.CreateImage(
          SpaceRescueVariables.enemyBulletSpriteName,
          this.witchEnemyBullet,
          72,
          42,
          true,
          11
        ),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.enemyBgSpriteName, this.witchShadow, 150, 150, false),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.heartSpriteName, this.witchLife, 30, 25, true, 9)
      ],
      overridenSounds: {
        shoot: require('@/assets/games/space-rescue/themes/little-witch/sounds/shoot.ogg'),
        explosion: require('@/assets/games/space-rescue/themes/little-witch/sounds/explosion1.ogg'),
        killEnemy: require('@/assets/games/space-rescue/themes/little-witch/sounds/killedEnemy.ogg')
      }
    };
    return theme;
  }

  private static getFroggyPrincessTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Lobster',
      fontSize: '20px',
      fontStartSize: '80px',
      textColor: '#564C4C',
      textShadowColor: '#443F2D00',
      announcementColor: '#d8107faa',
      playerAnimation: false,
      background: this.princessBackground,
      styles: {
        '--game-input-background-color': '#22282554',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#D8107F',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#A5CE33',
        '--game-primary-color': '#d8107f',
        '--game-secondary-color': '#d8107f',
        '--game-third-color': '#A5CE33',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': 'rgb(0, 0, 0)',
        '--game-score-shadow-color': '#d8107f',
        '--game-background-color': `url(${this.princessBackgroundGame})`,
        '--game-background': `url(${this.princessBackground})`,
        '--game-background-menu': `url(${this.princessBackground})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.princessBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.princessBackgroundQuestion})`,
        '--question-modal-title': '#A5CE33',
        '--game-left-intro-title-font': `'Lobster', cursive`,
        '--game-left-intro-title-color': '#d8107f',
        '--terms-link-color': 'white'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(SpaceRescueVariables.shipSpriteName, this.princessShip, 34, 58, true, 7),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.enemySpriteName, this.princessEnemy, 35, 28, true, 8),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.starsSpriteName, this.princessStar, 24, 24, false),
        GameCustomizableElement.CreateImage(
          SpaceRescueVariables.bulletSpriteName,
          this.princessBullet,
          14,
          18,
          true,
          10
        ),
        GameCustomizableElement.CreateImage(
          SpaceRescueVariables.enemyBulletSpriteName,
          this.princessEnemyBullet,
          18,
          22,
          true,
          11
        ),
        GameCustomizableElement.CreateImage(
          SpaceRescueVariables.enemyBgSpriteName,
          this.princessShadow,
          150,
          150,
          false
        ),
        GameCustomizableElement.CreateImage(SpaceRescueVariables.heartSpriteName, this.princessLife, 30, 25, true, 9)
      ],
      overridenSounds: {
        shoot: require('@/assets/games/space-rescue/themes/froggy-princess/sounds/shoot.ogg'),
        explosion: require('@/assets/games/space-rescue/themes/froggy-princess/sounds/explosion.ogg'),
        killEnemy: require('@/assets/games/space-rescue/themes/froggy-princess/sounds/killedEnemy.ogg')
      }
    };
    return theme;
  }

  private static getSpaceTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Jura',
      fontSize: '20px',
      fontStartSize: '70px',
      textColor: '#ffffff',
      textShadowColor: '#ffffff',
      announcementColor: '#21ecf3',
      playerAnimation: true,
      background: this.spaceRescueBackground,
      styles: {
        '--game-input-background-color': 'rgba(14,173,99,.253)',
        '--game-input-border-color': '#16bc79',
        '--game-input-checked-color': '#11bd5bda',
        '--game-checkbox-hover-color': '#11bd5bda',
        '--game-job-background-color': 'rgba(14,173,99,.253)',
        '--game-start-button-color': '#16bc79',
        '--game-job-border-color': '#16bc79',
        '--game-job-selected-color': '#16bc79',
        '--game-primary-color': 'rgba(14, 173, 99, 0.253)',
        '--game-secondary-color': '#16bc79',
        '--game-third-color': '#30ff8ada',
        '--game-font': `'Turret Road', cursive`,
        '--game-shadow-color': '#6fffc6',
        '--game-score-color': '#f39009',
        '--game-score-shadow-color': '#21ecf3',
        '--game-background-color': `url(${this.spaceRescueBackgroundGame})`,
        '--game-background': `url(${this.spaceRescueBackground})`,
        '--game-background-menu': `url(${this.spaceRescueBackground})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.spaceRescueBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.spaceRescueBackgroundQuestion})`,
        '--question-modal-title': '#6fffc6',
        '--game-left-intro-title-font': `'Jura', sans-serif`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': '#19fffb'
      },
      overridenElements: [],
      overridenSounds: {}
    };

    return theme;
  }

  private static getPiratesTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Play',
      fontSize: '18px',
      fontStartSize: '80px',
      textColor: '#ffffff',
      textShadowColor: '#070019',
      announcementColor: '#A7733C',
      playerAnimation: false,
      background: this.piratesBackground,
      styles: {
        '--game-input-background-color': 'rgba(255, 255, 255, 0.253)',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#00BCFF',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#00BCFF',
        '--game-primary-color': '#00BCFF',
        '--game-secondary-color': '#00BCFF',
        '--game-third-color': '#00BCFF',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': 'rgb(3, 3, 3)',
        '--game-score-shadow-color': 'none',
        '--game-background-color': 'transparent',
        '--game-background': `url(${this.piratesBackground})`,
        '--game-background-menu': `url(${this.piratesBackground})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.piratesBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.piratesBackgroundQuestion})`,
        '--question-modal-title': '#F7E5C0',
        '--game-left-intro-title-font': `'Play', sans-serif`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': 'white'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy1SpriteName, this.piratesEnemy1, 60, 100, true, 8),
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy2SpriteName, this.piratesEnemy2, 60, 100, true, 9),
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy3SpriteName, this.piratesEnemy3, 60, 100, true, 10),
        GameCustomizableElement.CreateImage(RiverRunVariables.playerSpriteName, this.piratesPlayer, 60, 100, true, 7),
        GameCustomizableElement.CreateImage(RiverRunVariables.person1, this.piratesPeople1, 30, 48, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.heartSpriteName, this.piratesHeart, 30, 30, true, 12),
        GameCustomizableElement.CreateImage(RiverRunVariables.treeSpriteName, this.piratesTree, 100, 100, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.object1SpriteName, this.piratesObject1, 60, 60, true, 11),
        GameCustomizableElement.CreateImage(RiverRunVariables.roadSpriteName, this.piratesWater, 60, 60, false),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.bgBackSpriteNameLeft,
          this.piratesGrassLeft,
          30,
          30,
          false
        ),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.bgBackSpriteNameRight,
          this.piratesGrassRight,
          30,
          30,
          false
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.odometer, this.piratesOdometer, 160, 150, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.odometerLine, this.piratesOdometerLine, 160, 160, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.endLevelObj, this.piratesEndLevelObj, 500, 500, true, 13),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaBottom, this.piratesStaminaBottom, 100, 20, false, 14),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaTop, this.piratesStaminaTop, 100, 20, false, 14),
        GameCustomizableElement.CreateColor(RiverRunVariables.staminaColor, 0x3CD2CF)
      ],
      overridenSounds: {
        engine: require('@/assets/games/river-run/themes/pirates/sounds/oceanlap.ogg'),
        explosion: require('@/assets/games/river-run/themes/pirates/sounds/explosionCrunch_000.ogg'),
        nextLevel: require('@/assets/games/river-run/themes/pirates/sounds/select_007.ogg')
      }
    };
    return theme;
  }

  private static getSpeedRaceTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Russo One',
      fontSize: '18px',
      fontStartSize: '70px',
      textColor: '#ffffff',
      textShadowColor: '#000000',
      announcementColor: '#eec12d',
      playerAnimation: false,
      background: this.speedRaceBackground,
      styles: {
        '--game-input-background-color': 'rgba(255, 255, 255, 0.253)',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#EEC12Dda',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#EEC12Dda',
        '--game-primary-color': '#cca421',
        '--game-secondary-color': '#EEC12D',
        '--game-third-color': '#EEC12D',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': 'rgb(3, 3, 3)',
        '--game-score-shadow-color': 'none',
        '--game-background-color': 'transparent',
        '--game-background': `url(${this.speedRaceBackground})`,
        '--game-background-menu': `url(${this.speedRaceBackground})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `url(${this.speedRaceBackgroundForm})`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': `url(${this.speedRaceBackgroundQuestion})`,
        '--question-modal-title': '#edd62d',
        '--game-left-intro-title-font': `'Russo One', sans-serif`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': 'white'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy1SpriteName, this.speedRaceEnemy1, 60, 100, true, 8),
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy2SpriteName, this.speedRaceEnemy2, 60, 100, true, 9),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.enemy3SpriteName,
          this.speedRaceEnemy3,
          60,
          100,
          true,
          10
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.playerSpriteName, this.speedRacePlayer, 50, 100, true, 7),
        GameCustomizableElement.CreateImage(RiverRunVariables.person1, this.speedRacePeople1, 60, 160, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.heartSpriteName, this.speedRaceHeart, 30, 25, true, 12),
        GameCustomizableElement.CreateImage(RiverRunVariables.treeSpriteName, this.speedRaceTree, 50, 50, false),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.object1SpriteName,
          this.speedRaceObject1,
          80,
          80,
          true,
          11
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.roadSpriteName, this.speedRaceRoad, 60, 60, false),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.bgBackSpriteNameLeft,
          this.speedRaceGrassLeft,
          30,
          30,
          false
        ),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.bgBackSpriteNameRight,
          this.speedRaceGrassRight,
          30,
          30,
          false
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.odometer, this.speedRaceOdometer, 160, 150, false),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.odometerLine,
          this.speedRaceOdometerLine,
          160,
          160,
          false
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.endLevelObj, this.speedRaceEndLevelObj, 100, 50, true, 13),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaBottom, this.speedRaceStaminaBottom, 100, 20, false, 14),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaTop, this.speedRaceStaminaTop, 100, 20, false, 14),
        GameCustomizableElement.CreateColor(RiverRunVariables.staminaColor, 0xFF9900)
      ],
      overridenSounds: {
        engine: require('@/assets/games/river-run/themes/speed-race/sounds/engine1.ogg'),
        explosion: require('@/assets/games/river-run/themes/speed-race/sounds/explosionCrunch_000.ogg'),
        nextLevel: require('@/assets/games/river-run/themes/speed-race/sounds/select_007.ogg')
      }
    };
    return theme;
  }

  private static getSkyFlightTheme(): GameTheme {
    const theme: GameTheme = {
      font: 'Russo One',
      fontSize: '18px',
      fontStartSize: '70px',
      textColor: '#2C6F9B',
      textShadowColor: '0',
      announcementColor: '#2C6F9B',
      playerAnimation: false,
      background: this.skyFlightBackground,
      styles: {
        '--game-input-background-color': 'rgba(255, 255, 255, 0.253)',
        '--game-input-border-color': 'white',
        '--game-input-checked-color': 'white',
        '--game-checkbox-hover-color': 'white',
        '--game-job-background-color': 'transparent',
        '--game-start-button-color': '#eeeeee',
        '--game-start-button-font-color': '#305679',
        '--game-job-border-color': 'transparent',
        '--game-job-selected-color': '#eeeeee',
        '--game-job-selected-font-color': '#305679',
        '--game-primary-color': '#ACE7FF',
        '--game-secondary-color': '#ACE7FF',
        '--game-third-color': '#eeeeee50',
        '--game-font': `'Play', sans-serif`,
        '--game-shadow-color': 'transparent',
        '--game-score-color': '#BB1803',
        '--game-score-shadow-color': 'none',
        '--game-background-color': 'transparent',
        '--game-background': `url(${this.skyFlightBackground})`,
        '--game-background-menu': `url(${this.skyFlightBackgroundMenu})`,
        '--game-shadow-opacity': '0',
        '--game-login-container-bg': `rgba(44, 111, 155, .65)`,
        '--valid-message-text-color': 'rgb(240, 240, 240)',
        '--valid-message-background-color': 'rgba(206, 52, 60, .9)',
        '--question-background-color': '#2C6F9B',
        '--question-modal-title': '#ACE7FF',
        '--game-left-intro-title-font': `'Russo One', sans-serif`,
        '--game-left-intro-title-color': 'white',
        '--terms-link-color': 'white',
        '--game-over': '#ACE7FF'
      },
      overridenElements: [
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy1SpriteName, this.skyFlightEnemy1, 60, 100, true, 8),
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy2SpriteName, this.skyFlightEnemy2, 60, 100, true, 9),
        GameCustomizableElement.CreateImage(RiverRunVariables.enemy3SpriteName, this.skyFlightEnemy3, 60, 100, true, 10),
        GameCustomizableElement.CreateImage(RiverRunVariables.playerSpriteName, this.skyFlightPlayer, 65, 80, true, 7),
        GameCustomizableElement.CreateImage(RiverRunVariables.person1, this.skyFlightPeople1, 70, 70, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.heartSpriteName, this.skyFlightHeart, 35, 30, true, 12),
        GameCustomizableElement.CreateImage(RiverRunVariables.treeSpriteName, this.skyFlightTree, 80, 80, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.object1SpriteName, this.skyFlightObject1, 80, 80, true, 11),
        GameCustomizableElement.CreateImage(RiverRunVariables.roadSpriteName, this.skyFlightSky, 60, 60, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.bgBackSpriteNameLeft, this.skyFlightCloudLeft, 30, 30, false),
        GameCustomizableElement.CreateImage(
          RiverRunVariables.bgBackSpriteNameRight,
          this.skyFlightCloudRight,
          30,
          30,
          false
        ),
        GameCustomizableElement.CreateImage(RiverRunVariables.odometer, this.skyFlightOdometer, 160, 150, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.odometerLine, this.skyFlightOdometerLine, 160, 160, false),
        GameCustomizableElement.CreateImage(RiverRunVariables.endLevelObj, this.skyFlightEndLevelObj, 70, 60, true, 13),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaBottom, this.skyFlightStaminaBottom, 100, 20, false, 14),
        GameCustomizableElement.CreateImage(RiverRunVariables.staminaTop, this.skyFlightStaminaTop, 100, 20, false, 14),
        GameCustomizableElement.CreateColor(RiverRunVariables.staminaColor, 0xFB2105)
      ],
      overridenSounds: {
        engine: require('@/assets/games/river-run/themes/skyFlight/sounds/speed.wav'),
        explosion: require('@/assets/games/river-run/themes/skyFlight/sounds/crash.wav'),
        nextLevel: require('@/assets/games/river-run/themes/skyFlight/sounds/select_007.ogg')
      }
    };
    return theme;
  }
}
