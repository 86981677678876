











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import PaymentStatusAdminData from '@/modules/admin/discounts/components/PaymentStatusAdminData.vue';
import { OrderListItemAdminDataDto } from '@/services/Api';
import { store } from '@/store/store';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
@Component({
  components: {
    PaymentStatusAdminData
  }
})
export default class PaymentsDetails extends Vue {
  @Prop() userPaymentData!: OrderListItemAdminDataDto[];
  private searchPayments = '';
  private tableSize = 5;
  get headersPayments() {
    return [
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Order'),
        value: 'orderNumber',
        align: 'start',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.BuyerIdentifier'),
        value: 'buyerIdentifier',
        align: 'start',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Product'),
        value: 'gameInstanceTitle',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.OrderDate'),
        value: 'createdAt',
        sort: DataTableSortingHelper.compareByDate
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Price'),
        value: 'priceInfo',
        sort: DataTableSortingHelper.compareByPriceInfo
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.InvoiceCreated'),
        value: 'isInvoiceCreated',
        sort: DataTableSortingHelper.compareByString
      },
      { text: this.$t('Modules.AdminPanel.UserDetails.Status'), value: 'status' }
    ];
  }
  async mounted() {
    this.tableSize = store.getTableSize('Admin-discount-payments');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-discount-payments', this.tableSize);
  }
  customSort(items: any[], index: string[], isDesc: boolean[], locale: any, sorters: any) {
    if (index[0] === 'status') {
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByDate(a.updatedAt, b.updatedAt, isDesc[0]);
      });
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByString(a.paymentStatus ?? '', b.paymentStatus ?? '', isDesc[0], locale);
      });
    } else {
      items = DataTableSortingHelper.sortByDefault(items, index, isDesc, locale, sorters);
    }
    return items;
  }
}
