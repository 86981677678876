export class CookieModel {
  constructor(
    public languageCode: string,
    /**
     * Flag indicating that the user has accepted the google analytics consent.
     *
     * If undefined or false, then the tracking events cannot be sent to google analytics.
     */
    public googleAnalyticsAccepted: boolean,
        /**
     * Flag indicating that the user has accepted the facebook pixel consent.
     *
     * If undefined or false, then the tracking events cannot be sent to facebook pixel.
     */
    public facebookPixelAccepted: boolean,
    /**
     * Flag indicating whether the cookie banner was shown or not.
     *
     * If undefined or false, then the cookie banner is displayed on application init
     */
    public wasCookieBannerShown: boolean,
    /**
     * Flag indicating that the user who is visiting the page is from the company.
     *
     * If true, then the google analytics are disabled.
     */
    public internalTraffic: boolean | undefined
  ) {}
}
