




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TableItemHeaderPreview extends Vue {
  offerTitle = this.$t('KitchenSink.Previews.TableItemHeaderPreview.Title');
  orderNumber = this.$t('KitchenSink.Previews.TableItemHeaderPreview.SubTitle');
}
