import 'phaser';
import { GameTriggerType } from '../game-engine-base';
import { GameCustomizableElement, GameCustomizableElementType } from '../game-engine-customizable-element';
import { Player } from './Models/playercontroller';
import { RiverRunVariables } from './Global/RiverRunVariables';
import { Base } from './Global/BaseControllClass';
import { KeySide } from './Models/KeySide';
import { BackgroundRoad } from './Models/backgroundRoad';
import { BackgroundBack } from './Models/backgroundBack';
import EnemyVehicleFactory from './Models/Enemy/EnemyVehicleFactory';
import ObjectFactory from './Models/ObjectFactory';
import { GameTheme } from '../../models/game-theme';
import { SoundManager } from './Global/soundManager';
import Animations from './Global/Animations';
import { EndLevelObject } from './Global/EndLevelObject';
import { EngineController } from '@/modules/game-play/games/framework/engine-controller';

//Game Main class
export class GameScene extends Phaser.Scene {
  constructor(
    protected askQuestion: () => void,
    protected gameOverDialog: (score: number) => void,
    protected saveGameLog: (score: number, trigger: GameTriggerType) => void,
    getCustomziableTypes: GameCustomizableElement[],
    protected theme?: GameTheme
  ) {
    super({
      key: 'Game',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      }
    });
    this.getCustomziableTypes = getCustomziableTypes;
    this.textFont = '20px Play, sans-serif';
    this.textFontStart = '80px Play, sans-serif';
  }
  peopleOrObjects = false;
  level!: number;
  levelEvent!: any;
  playTime = 0;
  respawnEvent!: any;
  respawnObjectEvent!: any;
  spawnPointRand!: number;
  points!: number;
  player!: Player;
  emiterEvent: any;
  backgroundRoad!: BackgroundRoad;
  backgroundBack: Array<BackgroundBack> = [];
  getCustomziableTypes!: any;
  textFont: string;
  textFontStart: string;
  cursorKeys!: any;
  baseControllClass!: Base;
  allSpritesImages: GameCustomizableElement[] = [];
  allText!: GameCustomizableElement[];
  allColors!: GameCustomizableElement[];
  randomObjects = 0;
  distance = 0;
  distanceToPass = 0;
  endLevelObject!: EndLevelObject;
  stopSpawn = false;
  speedIncrease = 70;
  defaultSpeed = 100;
  defaultLowestSpeed = 60;
  speedDecrease = 1;
  backToOldOnce = false;
  odometerData!: any;
  odometerLine!: any;
  staminaBottom!: any;
  staminaTop!: any;
  staminaAnimation!: any;
  lastKeySide!: KeySide;
  brakeStaminaBar!: Phaser.GameObjects.Graphics;
  brakePenaltyGoing!: boolean;
  speedUpOdometerAnimation = false;
  currentSpeed = 100;
  maxBoostSpeed = 150;
  minBoostSpeed = 50;
  playOnce = true;
  playAudio = false;
  changeRespawnStateBoost = false;
  waitForStartGame = true;
  //Init all parametrs
  init(params: any): void {
    this.points = 0;
    this.level = 1;
  }

  //Load all texture before launch game
  preload() {
    this.physics.world.setFPS(60);
    this.physics.world.setBoundsCollision();

    //Inicializate Game Framework
    EngineController.initialize(this, this.theme, this.getCustomziableTypes);

    //Load audio by AudioManager class
    EngineController.createAudioSystem(SoundManager);

    this.allText = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Text
    );
    this.allColors = this.getCustomziableTypes.filter(
        (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Color
    );

    this.allSpritesImages = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Image
    );
    this.allSpritesImages.forEach(element => {
      if (element.value != null || element.value != '') {
        if (
          element.name == RiverRunVariables.roadSpriteName ||
          element.name == RiverRunVariables.bgBackSpriteNameLeft ||
          element.name == RiverRunVariables.bgBackSpriteNameRight
        ) {
          this.load.image(element.name, element.value.toString());
        } else {
          this.textures.addBase64(element.name, element.value);
        }
      }
    });

    //Load scene data
    this.data.set('pause', false);
  }

  //Main function scene to create GameObjects
  create() {
    this.registry.events.on('endGameDialog', this.endGameDialog, this);
    this.registry.events.on('textControllerReplay', this.textControllerReplay, this);
    this.baseControllClass = new Base(
      this.getCustomziableTypes.find(
        (element: GameCustomizableElement) => element.name == RiverRunVariables.LivesName
      ).value,
      this,
      this.getCustomziableTypes
    );
    //Create background
    this.backgroundBack.push(
      new BackgroundBack(
        this,
        this.baseControllClass.backgroundBackWidth,
        this.baseControllClass.backgroundHeigth,
        RiverRunVariables.bgBackSpriteNameLeft,
        75,
        400
      )
    );
    this.backgroundBack.push(
      new BackgroundBack(
        this,
        this.baseControllClass.backgroundBackWidth,
        this.baseControllClass.backgroundHeigth,
        RiverRunVariables.bgBackSpriteNameRight,
        725,
        400
      )
    );
    //create bacgroun for this scene
    this.backgroundRoad = new BackgroundRoad(
      this,
      this.baseControllClass.backgroundWidth,
      this.baseControllClass.backgroundHeigth,
      RiverRunVariables.roadSpriteName
    );

    //create ship and asigns it position and speed
    this.player = new Player(
      this,
      this.baseControllClass.playerPozX,
      this.baseControllClass.playerPozY,
      this.baseControllClass.playerSpeedOnX,
      this.baseControllClass.playerMaxSpeedOnX,
      this.baseControllClass.playerSpeedOnY,
      this.baseControllClass.playerMaxSpeedOnY,
      this.baseControllClass.playerBorderMaxOnX,
      this.baseControllClass.playerBorderMinOnX,
      this.baseControllClass.playerBorderMaxOnY,
      this.baseControllClass.playerBorderMinOnY,
      this.baseControllClass.playerWidth,
      this.baseControllClass.playerHeigth
    );
    this.endLevelObject = new EndLevelObject(
      this,
      this.baseControllClass.flagSpawn,
      0,
      RiverRunVariables.endLevelObj,
      this.baseControllClass.flagWidth,
      this.baseControllClass.flagHeight,
      this.baseControllClass.levels[this.level - 1].backgroundSpeed
    );
    //initialize emiter used on scene
    this.emiterEvent = new Phaser.Events.EventEmitter();
    //sets behavior if UnderCollisionEnemy string is emited
    this.emiterEvent.on('UnderCollisionEnemy', this.underCollisionEnemy, this);

    //Create time
    this.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        this.playTime += 1;
      }
    });

    if (this.checkLevelReturn()) {
      this.cursorKeys = this.input.keyboard.createCursorKeys();
      this.createOverlapsPlayer();
    }

    this.registry.events.on('AnswerEvent', this.respondAnswer, this);
    EngineController.createLifeSystem(RiverRunVariables.heartSpriteName, this.baseControllClass.life);

    //Show text on start
    this.physics.pause();
    this.data.set('pause', true);
    EngineController.createText(
      this.allText.find((val: { name: string }) => val.name == 'StartText')?.value.toString(),
      false
    );

    this.saveGameLog(1, GameTriggerType.GameStart);

    this.distanceToPass = this.baseControllClass.levels[this.level - 1].distanceToNextLevel;

    if (this.baseControllClass.showOdometer) {
      //Load odometer
      this.odometerData = this.allSpritesImages.find(
        (element: GameCustomizableElement) => element.name == RiverRunVariables.odometer
      );
      const odometer = this.add
        .sprite(
          this.game.canvas.width - 80,
          this.game.canvas.height - this.odometerData.heigth / 2 + 20,
          RiverRunVariables.odometer
        )
        .setDepth(2);
      odometer.displayHeight = this.odometerData.heigth;
      odometer.displayWidth = this.odometerData.width;

      this.odometerLine = this.add
        .sprite(
          this.game.canvas.width - 80,
          this.game.canvas.height - this.odometerData.heigth / 2 + 27,
          RiverRunVariables.odometerLine
        )
        .setDepth(3);
      this.odometerLine.displayHeight = this.odometerData.heigth;
      this.odometerLine.displayWidth = this.odometerData.width;
      this.odometerSet(this.getMaxSpeedLevel(), this.currentSpeed);
    }
    if (this.checkLevelReturn()) {
      this.minBoostSpeed = this.baseControllClass.levels[this.level - 1].backgroundSpeed * 10;
      this.maxBoostSpeed = this.baseControllClass.levels[this.level - 1].backgroundSpeed * 40;
    } else {
      this.minBoostSpeed = this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 10;
      this.maxBoostSpeed = this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 40;
    }

    this.createStaminaBar();

    this.changeSpeedNextLevel();
    this.time.removeAllEvents();
  }
  createStaminaBar(): void {
    //Load stamina
    this.staminaBottom = this.allSpritesImages.find(
      (element: GameCustomizableElement) => element.name == RiverRunVariables.staminaBottom
    );
    const staminaBottom = this.add
      .sprite(
        80,
        this.game.canvas.height - 40,
        
        RiverRunVariables.staminaBottom
      ).setDepth(2)
    staminaBottom.displayHeight = this.staminaBottom.heigth;
    staminaBottom.displayWidth = this.staminaBottom.width;

    const staminaTopData = this.allSpritesImages.find(
      (element: GameCustomizableElement) => element.name == RiverRunVariables.staminaTop
    );
    this.staminaTop = this.add
      .sprite(
        80,
        this.game.canvas.height - 40,
        RiverRunVariables.staminaTop
      ).setDepth(4)
    this.staminaTop.displayHeight = staminaTopData!.heigth;
    this.staminaTop.displayWidth = staminaTopData!.width;

    const brakeStaminaBarDefaultRect = new Phaser.Geom.Rectangle(32, this.game.canvas.height - 48, 96, 16);
    const staminaColor = this.allColors.find(
        (element: GameCustomizableElement) => element.name == RiverRunVariables.staminaColor
    );
    
    let staminaColorCorrectType = null;
    if (typeof staminaColor?.value === "string") {
      staminaColorCorrectType = parseInt(staminaColor.value)
    } else {
      staminaColorCorrectType = staminaColor?.value;
    }
    
    this.brakeStaminaBar = this.add
      .graphics({
        fillStyle: {
          color: staminaColorCorrectType,
          alpha: 1
        }
      })
      .fillRoundedRect(0, 0, 96, 16, 3)
      .setDepth(3);
    this.brakeStaminaBar.x = brakeStaminaBarDefaultRect.x;
    this.brakeStaminaBar.y = brakeStaminaBarDefaultRect.y;

  }
  
  createOverlapsPlayer() {
    this.baseControllClass.levels[
      this.checkLevelReturn() ? this.level - 1 : this.baseControllClass.levels.length - 1
    ].arrayCars.forEach(x => {
      if (this.baseControllClass.turnOnSoundPassing) {
        this.physics.add.overlap(this.player.soundObjects, x.factoryVehicle, (player: any, enemy: any) => {
          if (!enemy.passingSound) {
            EngineController.playAudio(SoundManager.passingName);
            enemy.passingSound = true;
          }
        });
      }

      if (x.speedObject != 0) {
        this.physics.add.overlap(this.player, x.factoryVehicle, (player: any, enemy: any) => {
          if (!this.data.get('pause')) {
            Animations.animateOilSpin(this.player, this);
            EngineController.playAudio(SoundManager.explosionName);
            this.registry.events.emit('stopAll');
            this.respawnObjectEvent.remove(true);
            this.data.set('pause', true);
            this.physics.pause();
            this.makeQuestion();
          }
        });
      } else {
        this.physics.add.overlap(this.player, x.factoryVehicle, (player: any, enemy: any) => {
          if (!this.data.get('pause')) {
            EngineController.playAudio(SoundManager.explosionName);
            this.registry.events.emit('stopAll');
            this.respawnObjectEvent.remove(true);
            this.data.set('pause', true);
            this.physics.pause();
            this.makeQuestion();
          }
        });
      }
    });

    EngineController.loadAudioSystem();
  }
  //Update function scene
  update(time: number, delta: number): void {
    if (!this.data.get('pause')) {
      if (this.waitForStartGame || !this.respawnEvent) {
        const value = this.getSpawnTime();
        if (value > 100) {
          this.createRespawnEvent(this.getSpawnTime() * 0.5, true);
        } else {
          this.createRespawnEvent(this.baseControllClass.levels[this.level - 1].spawnTime[this.getSpawnTime()], false);
        }

        this.createRespawnEventObjects(this.baseControllClass.levels[this.level - 1].backgroundSpeed);

        this.waitForStartGame = false;
      }

      this.points += 5;

      this.handlePlayerMovementInput(delta);

      //Speed distance increase when key is pressed
      this.speedIncreaseAll(delta);

      if (this.distance >= this.distanceToPass - 0.1 && !this.stopSpawn) {
        this.stopSpawn = true;
        this.endLevelObject.createObject(this.baseControllClass.flagSpawn);
        this.respawnEvent.remove(true);
      }
      if (this.stopSpawn) {
        if (this.endLevelObject.body.y >= this.player.body.y) {
          this.registry.events.emit('stopAll');
          const nextLevel = this.level + 1;

          this.nextLevelIncrease(
            this.allText.find((val: { name: string }) => val.name == 'NextLevel')?.value.toString() + ' ' + nextLevel
          );
          if (this.checkLevelReturn()) {
            this.distanceToPass += this.baseControllClass.levels[this.level - 1].distanceToNextLevel;
          } else {
            this.distanceToPass += this.baseControllClass.levels[
              this.baseControllClass.levels.length - 1
            ].distanceToNextLevel;
          }

          this.stopSpawn = false;
        }
      }
      //speed back when key is unpushed
      this.speedIncreaseBack(delta);
    }

    this.updatePlayersScoreUI();
  }
  handlePlayerMovementInput(delta: number): void {
    let currentKeySides = KeySide.stop;

    const areLeftAndRightPressedAtTheSameTime = this.cursorKeys.left.isDown && this.cursorKeys.right.isDown;
    const areUpAndDownPressedAtTheSameTime = this.cursorKeys.up.isDown && this.cursorKeys.down.isDown;
    const noCursorKeysDown =
      !this.cursorKeys.left.isDown &&
      !this.cursorKeys.right.isDown &&
      !this.cursorKeys.up.isDown &&
      !this.cursorKeys.down.isDown;

    if (!areLeftAndRightPressedAtTheSameTime) {
      if (this.cursorKeys.left.isDown) {
        currentKeySides |= KeySide.left;
      } else if (this.cursorKeys.right.isDown) {
        currentKeySides |= KeySide.right;
      }
    }

    if (!areUpAndDownPressedAtTheSameTime) {
      if (this.cursorKeys.up.isDown) {
        currentKeySides |= KeySide.up;

        if (this.baseControllClass.showOdometer && (this.lastKeySide & KeySide.up) !== KeySide.up) {
          this.checkAnimationSpeed(this.defaultSpeed);
          this.odometerSet(this.currentSpeed, this.getMaxSpeedLevel() + 30);
        }
      } else if (this.cursorKeys.down.isDown) {
        currentKeySides |= KeySide.down;
        if (this.baseControllClass.showOdometer && (this.lastKeySide & KeySide.down) !== KeySide.down) {
          this.checkAnimationSpeed(50);
          this.odometerSet(this.currentSpeed, this.getMaxSpeedLevel() - 15);
        }
      }
    }

    if (noCursorKeysDown) {
      if (this.baseControllClass.showOdometer && (this.lastKeySide & KeySide.stop) !== KeySide.stop) {
        this.tweens.killTweensOf(this.odometerLine);
        this.currentSpeed = this.odometerLine.angle;
        this.odometerSet(this.currentSpeed, this.getMaxSpeedLevel());
      }
    } else {
      currentKeySides &= ~KeySide.stop;
    }
    
    if (this.player.brakeStamina < 100 && !this.player.staminaBlockade && !this.player.slowDown){
      this.player.brakeStamina += 0.2;
      this.staminaBarSetScale(this.player.brakeStamina / 100, (this.player.brakeStamina + 0.4) / 100);
    
    }
    if (this.player.brakeStamina < 20 && this.player.staminaBlockade){
      this.player.brakeStamina += 0.1;
      this.staminaBarSetScale(this.player.brakeStamina / 100, (this.player.brakeStamina + 0.2) / 100);
      if(this.player.brakeStamina >= 20) this.player.staminaBlockade = false;
    };
    this.player.move(currentKeySides, delta);
    this.lastKeySide = currentKeySides;
  }
  odometerSet(from: number, to: number) {
    this.tweens.add({
      targets: this.odometerLine,
      angle: { from: from, to: to },
      ease: 'Linear',
      duration: 700
    });
    this.currentSpeed = to;
  }
  staminaBarSetScale(from: number, to: number) {
    this.tweens.add({
      targets: this.brakeStaminaBar,
      scaleX: { from: from, to: to },
      ease: 'Linear',
      duration: 700
    });
  }
  getMaxSpeedLevel() {
    if (this.checkLevelReturn()) {
      return this.baseControllClass.levels[this.level - 1].odometerSpeed;
    } else {
      return this.baseControllClass.levels[this.baseControllClass.levels.length - 1].odometerSpeed;
    }
  }
  getMaxSpeedBgLevel() {
    if (this.checkLevelReturn()) {
      return this.baseControllClass.levels[this.level - 1].backgroundSpeed;
    } else {
      return this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed;
    }
  }
  speedIncreaseBack(delta: number) {
    if (this.player.slowDown && !this.player.staminaBlockade) {
      this.player.brakeStamina -= 0.5;
      this.staminaBarSetScale(this.player.brakeStamina / 100, (this.player.brakeStamina - 0.7) / 100);
      if (this.player.brakeStamina <= 0) {
        this.player.staminaBlockade = true;
        return;
      }
      this.checkAnimationSpeed(this.minBoostSpeed);
      this.backgroundRoad.tilePositionY -= this.speedDecreaseDelta(delta);
      this.backgroundBack.forEach(val => {
        val.tilePositionY -= this.speedDecreaseDelta(delta);
      });
      this.registry.events.emit('changeSpeed', { speed: this.speedIncrease, back: true });
      if (this.speedIncrease >= this.minBoostSpeed) {
        this.speedIncrease -= this.getMaxSpeedBgLevel() / 2;
      }
    } else if (this.player.speedBack) {
      this.playOnce = true;
      this.checkAnimationSpeed(this.maxBoostSpeed);
      this.backgroundRoad.tilePositionY -= this.speedIncreaseDelta(delta);
      this.backgroundBack.forEach(val => {
        val.tilePositionY -= this.speedIncreaseDelta(delta);
      });
      this.registry.events.emit('changeSpeed', { speed: this.speedIncrease });
      if (this.speedIncrease <= this.maxBoostSpeed) {
        if (
          !this.sound.get(SoundManager.nextLevelName).isPlaying &&
          !this.sound.get(SoundManager.engineName).isPlaying
        ) {
          this.sound.get(SoundManager.engineName).play();
        }
        if (this.playOnce) {
          this.playOnce = false;
          if (!this.changeRespawnStateBoost) {
            const value = this.getSpawnTime();
            if (value > 100) {
              this.createRespawnEvent(this.getSpawnTime() * 0.5, true);
            } else {
              if (this.checkLevelReturn()) {
                this.createRespawnEvent(
                  this.baseControllClass.levels[this.level - 1].spawnTime[this.getSpawnTime()] * 0.5,
                  true
                );
              } else {
                this.createRespawnEvent(
                  this.baseControllClass.levels[this.baseControllClass.levels.length - 1].spawnTime[
                    this.getSpawnTime()
                  ] * 0.5,
                  true
                );
              }
            }
            this.changeRespawnStateBoost = true;
          }
        }
        this.speedIncrease += this.getMaxSpeedBgLevel();
      }
    } else {
      if (this.backToOldOnce) {
        this.backToOldOnce = false;
      }
      if (this.speedIncrease > this.defaultSpeed) {
        this.checkAnimationSpeed(this.defaultSpeed);
        this.backgroundRoad.tilePositionY -= this.speedIncreaseDelta(delta);
        this.backgroundBack.forEach(val => {
          val.tilePositionY -= this.speedIncreaseDelta(delta);
        });
        this.registry.events.emit('changeSpeedObj', { speed: this.speedIncrease, back: false });
        this.speedIncrease -= this.getMaxSpeedBgLevel() * 10;
      } else {
        if (!this.backToOldOnce) {
          if (this.changeRespawnStateBoost) {
            this.time.addEvent({
              delay: 200,
              loop: false,
              callback: () => {
                const value = this.getSpawnTime();
                if (value > 100) {
                  this.createRespawnEvent(this.getSpawnTime(), false);
                } else {
                  if (this.checkLevelReturn()) {
                    this.createRespawnEvent(
                      this.baseControllClass.levels[this.level - 1].spawnTime[this.getSpawnTime()],
                      false
                    );
                  } else {
                    this.createRespawnEvent(
                      this.baseControllClass.levels[this.baseControllClass.levels.length - 1].spawnTime[
                        this.getSpawnTime()
                      ],
                      false
                    );
                  }
                }

                this.changeRespawnStateBoost = false;
              }
            });
          }
          this.registry.events.emit('backToOldSpeed');
          this.backToOldOnce = true;
          if (this.speedUpOdometerAnimation) {
            this.speedUpOdometerAnimation = !this.speedUpOdometerAnimation;
          }
        }
        if (this.checkLevelReturn()) {
          this.setSpeed(this.baseControllClass.levels[this.level - 1].backgroundSpeed);
        } else {
          this.setSpeed(this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed);
        }
        this.speedIncrease = this.defaultSpeed;
      }
    }
  }
  checkAnimationSpeed(speedMax: number) {
    if (this.baseControllClass.showOdometer && this.speedUpOdometerAnimation) {
      this.tweens.killTweensOf(this.odometerLine);
      this.currentSpeed = this.odometerLine.angle;
      this.odometerSet(this.currentSpeed, speedMax);
      this.speedUpOdometerAnimation = !this.speedUpOdometerAnimation;
    }
  }
  setSpeed(speed: number) {
    this.backgroundRoad.tilePositionY -= speed;
    this.backgroundBack.forEach(val => {
      val.tilePositionY -= speed;
    });
  }
  speedIncreaseDelta(delta: number) {
    return (this.getMaxSpeedBgLevel() + this.speedIncrease) / delta;
  }
  speedDecreaseDelta(delta: number) {
    return (this.getMaxSpeedBgLevel() + this.speedIncrease * 0.85) / delta;
  }
  speedIncreaseAll(delta: number) {
    if (this.checkLevelReturn()) {
      if (this.speedIncrease > this.defaultSpeed) {
        this.distance +=
          (delta * this.baseControllClass.levels[this.level - 1].backgroundSpeed * 30 + this.speedIncrease * 3) /
          600 /
          3600;
      } else {
        this.distance += (delta * this.baseControllClass.levels[this.level - 1].backgroundSpeed * 10) / 600 / 3600;
      }
    } else {
      if (this.speedIncrease > this.defaultSpeed) {
        this.distance +=
          (delta * this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 30 +
            this.speedIncrease * 3) /
          600 /
          3600;
      } else {
        this.distance +=
          (delta * this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 10) /
          600 /
          3600;
      }
    }
  }
  //Update bar
  updatePlayersScoreUI(): void {
    if (this.checkLevelReturn()) {
      EngineController.updateTimeDistance(Math.round(this.points), this.level, this.distance);
    } else {
      EngineController.updateTimeDistance(Math.round(this.points), this.level, this.distance);
    }
  }

  //Runs if enemy and player colides
  underCollisionEnemy() {
    if (!this.data.get('pause')) {
      this.data.set('pause', true);
      this.physics.pause();
      this.makeQuestion();
    }
  }

  //check if we dont overstep baseControllClass array
  checkLevelReturn() {
    if (this.level - 1 <= this.baseControllClass.levels.length - 1) {
      return true;
    }
    return false;
  }

  createEnemy() {
    if (!this.data.get('pause')) {
      if (this.checkLevelReturn()) {
        const numberMax = (this.baseControllClass.levels[this.level - 1].arrayCars.length - 1) * 100;
        const randomNumber = Phaser.Math.Between(0, numberMax);
        EnemyVehicleFactory.CreateVehicle(
          this.baseControllClass.levels[this.level - 1].arrayCars[Math.round(randomNumber / 100)].factoryVehicle,
          this.baseControllClass.levels[this.level - 1].arrayCars[Math.round(randomNumber / 100)].speedVehicle,
          this.baseControllClass.levels[this.level - 1].arrayCars[Math.round(randomNumber / 100)].speedObject,
          this.baseControllClass.rotateSprite,
          Math.round(randomNumber / 100)
        );
      } else {
        const numberMax =
          (this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayCars.length - 1) * 100;
        const randomNumber = Phaser.Math.Between(0, numberMax);
        EnemyVehicleFactory.CreateVehicle(
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayCars[
            Math.round(randomNumber / 100)
          ].factoryVehicle,
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayCars[
            Math.round(randomNumber / 100)
          ].speedVehicle,
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayCars[
            Math.round(randomNumber / 100)
          ].speedObject,
          this.baseControllClass.rotateSprite,
          Math.round(randomNumber / 100)
        );
      }
    }
  }
  createObjects(speed?: number) {
    if (!this.data.get('pause')) {
      if (this.randomObjects == 0) {
        if (this.checkLevelReturn()) {
          const numberMax = (this.baseControllClass.levels[this.level - 1].arrayObjects.length - 1) * 100;
          const randomNumber = Phaser.Math.Between(0, numberMax);
          ObjectFactory.CreateObject(
            this.baseControllClass.levels[this.level - 1].arrayObjects[Math.round(randomNumber / 100)].factoryVehicle,
            speed
          );
        } else {
          const numberMax =
            (this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayObjects.length - 1) * 100;
          const randomNumber = Phaser.Math.Between(0, numberMax);
          ObjectFactory.CreateObject(
            this.baseControllClass.levels[this.baseControllClass.levels.length - 1].arrayObjects[
              Math.round(randomNumber / 100)
            ].factoryVehicle,
            speed
          );
        }
      }
    }
  }

  //Respond answer from server
  respondAnswer(data: boolean, answerPoolEmpty: boolean) {
    this.data.set('pause', true);
    this.physics.pause();
    if (answerPoolEmpty) {
      this.saveGameLog(Math.round(this.points), GameTriggerType.LiveLost);
      if (EngineController.lifeLost(true)) {
        this.data.set('pause', true);
        this.physics.pause();
      }
      this.nextLevel('');
    } else {
      switch (data) {
        case true:
          this.points += 500;
          this.saveGameLog(Math.round(this.points), GameTriggerType.LiveSaved);
          this.nextLevel(
            this.allText.find((val: { name: string }) => val.name == 'AnswerTextCorrect')?.value.toString()
          );
          break;
        case false:
          this.saveGameLog(Math.round(this.points), GameTriggerType.LiveLost);
          if (EngineController.lifeLost(false)) {
            this.data.set('pause', true);
            this.physics.pause();
            break;
          }
          this.nextLevel(this.allText.find((val: { name: string }) => val.name == 'AnswerTextWrong')?.value.toString());
          break;
      }
    }
    this.eventInit();
  }

  createRespawnEvent(delay: number, twoEvent: boolean) {
    //create enemys
    this.time.removeEvent(this.respawnEvent);
    this.time.clearPendingEvents();
    this.respawnEvent = this.time.addEvent({
      delay: twoEvent ? delay * 0.65 : delay,
      loop: true,
      callback: () => {
        if (!this.data.get('pause')) {
          this.createEnemy();
        }
      }
    });
  }
  createRespawnEventObjects(speed?: number) {
    //create Objects
    this.time.removeEvent(this.respawnObjectEvent);
    this.respawnObjectEvent = this.time.addEvent({
      delay: this.baseControllClass.objectsSpawnTime,
      loop: true,
      callback: () => {
        this.createObjects(speed);
      }
    });
  }
  changeSpeedNextLevel() {
    if (this.checkLevelReturn()) {
      this.registry.events.emit('changeSpeed', {
        speed: this.baseControllClass.levels[this.level - 1].backgroundSpeed
      });
      this.registry.events.emit('objectChangeSpeed', {
        speed: this.baseControllClass.levels[this.level - 1].backgroundSpeed
      });
      this.minBoostSpeed = this.baseControllClass.levels[this.level - 1].backgroundSpeed * 10;
      this.maxBoostSpeed = this.baseControllClass.levels[this.level - 1].backgroundSpeed * 40;
    } else {
      this.registry.events.emit('changeSpeed', {
        speed: this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed
      });
      this.registry.events.emit('objectChangeSpeed', {
        speed: this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed
      });
      this.minBoostSpeed = this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 10;
      this.maxBoostSpeed = this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed * 40;
    }
    this.odometerSet(this.currentSpeed, this.getMaxSpeedLevel());
  }
  //Set new level after question or player destroyed all enemys
  nextLevel(textToShow: string | undefined) {
    //pause game
    this.data.set('pause', true);
    this.player.move(KeySide.stop, 0);
    //pause game physic
    this.physics.pause();
    this.respawnEvent.remove(true);
    this.registry.events.emit('nextLevel');
    this.registry.events.emit('deleteEnemy');
    if (textToShow != '') {
      EngineController.createText(textToShow, false);
    }

    this.changeSpeedNextLevel();
  }
  eventInit() {
    this.respawnEvent.remove(false);
    const value = this.getSpawnTime();
    if (value > 100) {
      this.createRespawnEvent(this.getSpawnTime(), false);
    } else {
      if (this.checkLevelReturn()) {
        this.createRespawnEvent(this.baseControllClass.levels[this.level - 1].spawnTime[this.getSpawnTime()], false);
        this.createRespawnEventObjects(this.baseControllClass.levels[this.level - 1].backgroundSpeed);
      } else {
        this.createRespawnEvent(
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].spawnTime[this.getSpawnTime()],
          false
        );
        this.createRespawnEventObjects(
          this.baseControllClass.levels[this.baseControllClass.levels.length - 1].backgroundSpeed
        );
      }
    }

    this.changeSpeedNextLevel();
  }
  nextLevelIncrease(textToShow: string | undefined) {
    this.saveGameLog(Math.round(this.points), GameTriggerType.NewLevel);
    this.data.set('pause', true);
    this.physics.pause();
    this.level = this.level + 1;
    this.respawnEvent.remove(true);
    this.registry.events.emit('nextLevel');
    this.eventInit();
    EngineController.createText(textToShow, false);
    this.points += this.distance * 1000;

    EngineController.playAudio(SoundManager.nextLevelName);
    this.createOverlapsPlayer();
    this.changeSpeedNextLevel();
  }

  //Mark Question
  makeQuestion() {
    this.physics.pause();
    this.data.set('pause', true);
    this.askQuestion();
  }
  getSpawnTime(): number {
    if (this.checkLevelReturn()) {
      if (this.baseControllClass.levels[this.level - 1].spawnTime.length) {
        return 0;
      }
      const spawnCount = this.baseControllClass.levels[this.level - 1].spawnTime.length - 1;
      if (spawnCount == 0) {
        return this.baseControllClass.defaultSpawnTime;
      }
      return Phaser.Math.Between(0, spawnCount);
    } else {
      if (this.baseControllClass.levels[this.baseControllClass.levels.length - 1].spawnTime) {
        return 0;
      }
      const spawnCount = this.baseControllClass.levels[this.baseControllClass.levels.length - 1].spawnTime.length - 1;
      if (spawnCount == 0) {
        return this.baseControllClass.defaultSpawnTime;
      }
      return Phaser.Math.Between(0, spawnCount);
    }
  }
  endGameDialog() {
    this.gameOverDialog(Math.round(this.points));
    this.scene.pause();
    this.saveGameLog(Math.round(this.points), GameTriggerType.GameOver);
    this.game.destroy(true, false);
  }
  textControllerReplay() {
    this.physics.resume();
    this.data.set('pause', false);
  }
}
