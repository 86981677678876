



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormConditionsCheckbox extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: Boolean, default: false }) newTab!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String, default: 'http://www.google.pl' }) link!: string;
  @Prop({ type: String, default: 'Privacy Policy' }) linkText!: string;
  @Prop({ type: String, default: 'I agree to the' }) label!: string;

  public get newTabFlag() {
    return this.newTab ? '_blank' : '_self';
  }

  updateValue(value: boolean) {
    this.$emit('input', value);
  }
}
