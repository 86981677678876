













































import UserApiService from '@/modules/statistics/services/statistic-api.service';
import {
  InstanceOneGameStatisticDto,
  InstancePlayerGameStatisticsDto,
  InstancePlayerStatisticsDto,
  InstanceGameStatisticsDto
} from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlobalCountsBar from '../components/GlobalCountsBar.vue';
import StatisticsPlayerTable from '../components/StatisticsPlayerTable.vue';
import StatisticsOneGameTable from '../components/StatisticsOneGameTable.vue';
import StatisticsOneGameDeepTable from '../components/StatisticsOneGameDeepTable.vue';
import StatisticsOneGameLogsTable from '../components/StatisticsOneGameLogsTable.vue';
import NotificationService from '@/services/notification-service';
import { i18n, translate } from '@/plugins/i18n';
import { StatisticsRoute } from '@/modules/statistics/statistics-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { store } from '@/store/store';

@Component({
  components: {
    GlobalCountsBar,
    StatisticsPlayerTable,
    StatisticsOneGameTable,
    StatisticsOneGameDeepTable,
    StatisticsOneGameLogsTable
  }
})
export default class StatisticsGame extends Vue {
  apiService = new UserApiService();
  @Prop() readonly gameInstanceId!: string;
  @Prop() readonly instanceId!: string;
  private generalListInfos: InstanceGameStatisticsDto[] = [];
  private gameInfos!: InstanceOneGameStatisticDto;
  private loading = true;
  private loadingCVButton = false;
  private exporting = false;
  private achieviedLevel = 0;
  private GameOver = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        to: { name: GameInstanceRoute.gameInstanceList },
        exact: true
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Statistics', { gameName: this.gameInfos.gameInstanceTitle }),
        exact: true,
        to: { name: StatisticsRoute.statisticsMain, params: { gameInstanceId: this.gameInstanceId } }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.PlayerStatistics', { playerEmail: this.gameInfos.email }),
        exact: true,
        to: {
          name: StatisticsRoute.statisticsPlayer,
          params: {
            gameInstanceId: this.gameInstanceId,
            playerEmail: this.gameInfos.email,
            playerProfession: this.gameInfos.profession
          }
        }
      },
      { text: i18n.t('Layout.Toolbar.Routes.StatisticsGame'), disabled: true }
    ];
  }

  async mounted() {
    this.loading = true;

    const playersStats = await this.apiService.getInstanceOneGameStatistics(this.gameInstanceId, this.instanceId);
    if (playersStats) {
      this.gameInfos = playersStats;
      this.generalListInfos.push(playersStats);
    }

    if (this.gameInfos.playerInstanceLogs) {
      this.achieviedLevel = this.gameInfos.playerInstanceLogs.filter(val => val.logTrigger == 'new level').length + 1;
      this.GameOver = this.gameInfos.playerInstanceLogs.find(val => val.logTrigger == 'game over') != undefined;
    }
    store.setBreadcrumb(this.breadcrumbData);

    this.loading = false;
  }
  back() {
    if (this.gameInfos.email && this.gameInfos.profession) {
      this.$router.push({
        name: StatisticsRoute.statisticsPlayer,
        params: {
          gameInstanceId: this.gameInstanceId,
          playerEmail: this.gameInfos.email,
          playerProfession: this.gameInfos.profession
        }
      });
    } else {
      NotificationService.success(translate('Modules.Statistics.GamesTable.DeepStatistics.'));
    }
  }

  async exportCsvLogs() {
    this.exporting = true;
    const timeDifference = new Date().getTimezoneOffset();
    const result = await this.apiService.getInstanceOneGameLogsStatisticsCSV(
      this.gameInstanceId,
      this.instanceId,
      -timeDifference
    );
    this.downloadBlob(result?.data, result?.fileName);
    this.exporting = false;
  }
  async exportCsvAnswer() {
    this.exporting = true;
    const timeDifference = new Date().getTimezoneOffset();
    const result = await this.apiService.GetInstanceOneGameAnswerStatisticsCSV(
      this.gameInstanceId,
      this.instanceId,
      -timeDifference
    );
    this.downloadBlob(result?.data, result?.fileName);
    this.exporting = false;
  }
  downloadBlob(blob?: Blob, filename?: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ?? 'export.csv';
    link.type = 'text/csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async downloadCV(playerEmail: string, playerProfession: string) {
    this.loadingCVButton = true;
    const data = await this.apiService.getInstancePlayersCV(this.gameInstanceId, playerEmail);
    if (data) {
      if (data.cv) {
        this.downloadFile(data.cv, 'InGame_CV_' + playerEmail);
      }
    }
    this.loadingCVButton = false;
  }
  downloadFile(value: string, fileName?: string) {
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = value;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
