




























































































import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n, translate } from '@/plugins/i18n';
import { QuestionAnswerInputModel, QuestionUpdateInputModel } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Vue } from 'vue-property-decorator';
import { VForm } from '../../layout/components/forms/v-form';
import QuestionApiService from '../services/question-api.service';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component
export default class EditQuestion extends Vue {
  rules = {
    required: StringValidators.required,
    maxLength120: (model: string) => StringValidators.maxLength(model, 120)
  };

  private questionId!: string;

  public questionText = '';
  public answers: QuestionAnswerInputModel[] = [];

  private readonly apiService = new QuestionApiService();
  private loading = true;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ProfessionList'),
        exact: true,
        disabled: false,
        to: { name: ProfessionsRoute.professionList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Edit'),
        exact: true,
        disabled: true
      }
    ];
  }

  private async mounted() {
    this.loading = true;
    this.questionId = this.$route.params.questionId;

    const question = await this.apiService.readQuestion(this.questionId);

    if (question) {
      this.questionText = question.questionText as NonNullable<string>;
      this.answers = question.questionAnswers as NonNullable<QuestionAnswerInputModel[]>;
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }

  private async submit() {
    const form = this.$refs.form as VForm;
    const professionId = this.$route.params.id;

    if (!form.validate()) {
      FormHelper.scrollToNextError(this);
      return;
    }

    if (this.answers.length < 2) {
      NotificationService.error(translate('Modules.Professions.Errors.AtLeastTwoAnswersMustBeDefined'));
      return;
    }
    if (this.answers.length > 5) {
      NotificationService.error(translate('Modules.Professions.Errors.LessThanFiveAnswersMustBeDefined'));
      return;
    }

    if (!this.atLeastOneAnswerIsCorrect()) {
      NotificationService.error(translate('Modules.Professions.Errors.AtLeastOneAnswerMustBeMarkedAsCorrect'));
      return;
    }

    this.loading = true;

    const inputModel = new QuestionUpdateInputModel({
      questionId: this.questionId,
      questionText: this.questionText,
      answers: this.answers
    });

    const result = await this.apiService.updateQuestion(inputModel);

    if (result) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.updateQuestion, {
        question_id: result.id,
        question_text: inputModel.questionText
      });

      await this.$router.push({ name: ProfessionsRoute.professionDetails, params: { id: professionId } });
      NotificationService.success(translate('Modules.Professions.Notifications.QuestionUpdated'));
    }

    this.loading = false;
  }

  private async cancel() {
    await this.$router.back();
  }

  private addAnswer() {
    const newAnswer = new QuestionAnswerInputModel({
      answerText: 'New answer',
      isCorrect: false
    });

    this.answers.push(newAnswer);
  }

  private deleteAnswer(answer: QuestionAnswerInputModel) {
    const index = this.answers.indexOf(answer);

    this.answers.splice(index, 1);
  }

  private atLeastOneAnswerIsCorrect(): boolean {
    return this.answers.some(item => item.isCorrect);
  }
}
