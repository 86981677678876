




















































import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { PlayerInstanceAnswerInputModel, QuestionAnswerDto, QuestionDto } from '@/services/Api';
import DelayHelper from '@/services/DelayHelper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameMainSettingsModel } from '../games/customize-settings.model';
import GameApiService from '../services/game-api.service';

@Component
export default class QuestionModal extends Vue {
  @Prop() readonly gameSettings?: GameMainSettingsModel;
  @Prop() readonly playerInstanceId!: string;
  @Prop() readonly professionId!: string;
  @Prop({ default: 10 }) readonly timeForQuestion!: number;
  @Prop({ default: true }) readonly answerLimit!: boolean;
  @Prop({ default: false }) readonly testMode!: boolean;
  @Prop() readonly theme!: { [id: string]: string };

  public show = false;
  public timeLeft = 10;
  public timeLeftBarWidth = 100;
  public timeLeftInterval?: NodeJS.Timeout;
  public timeLeftIntervalBar?: NodeJS.Timeout;
  public timeLeftTimeout?: number;
  public currentQuestion: QuestionDto | null = null;
  public choosenAnswer: QuestionAnswerDto | null = null;
  public apiService = new GameApiService();
  private ongoingAnswer = false;
  private loadingNextQuestion = false;
  private nextQuestion?: QuestionDto;

  public mouseSelectAnswer(answer: QuestionAnswerDto) {
    this.choosenAnswer = answer;
    this.answerQuestion();
  }

  async mounted() {
    this.loadingNextQuestion = true;
    this.nextQuestion = await this.apiService.getQuestionByProfession(this.professionId, this.playerInstanceId);
    this.loadingNextQuestion = false;
  }

  public answerQuestion() {
    if (this.ongoingAnswer || !this.show || !this.choosenAnswer) {
      return;
    }

    this.ongoingAnswer = true;
    if (this.testMode) {
      const selected = this.choosenAnswer.isCorrect;
      DelayHelper.delay(250).then(() => {
        this.currentQuestion = null;
        this.choosenAnswer = null;
        this.acceptAnswer(selected);
        this.ongoingAnswer = false;
      });
    } else {
      const inputModel = new PlayerInstanceAnswerInputModel({
        playerInstanceId: this.playerInstanceId,
        answeredQuestionId: this.currentQuestion?.id!,
        isCorrectAnswer: this.choosenAnswer?.isCorrect,
        answeredQuestion: this.currentQuestion?.questionText,
        answer: this.choosenAnswer?.answerText
      });
      this.apiService.addPlayerInstanceAnswer(inputModel).then(result => {
        this.currentQuestion = null;
        this.choosenAnswer = null;
        this.acceptAnswer(result?.isCorrectAnswer as boolean);
        this.ongoingAnswer = false;
      });
    }
  }

  public askQuestion(): void {
    if (this.testMode) {
      const result = new QuestionDto({
        questionText:
          'I am a question that is 120 characters long lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsu?',
        id: '1234456',
        questionAnswers: [
          new QuestionAnswerDto({
            id: '1',
            answerText:
              'Yes! Im max 120 characters, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum!!!',
            isCorrect: true
          }),
          new QuestionAnswerDto({
            id: '2',
            answerText:
              'No!! Im max 120 characters, lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum  lorem ipsum!!!',
            isCorrect: false
          })
        ]
      });
      this.currentQuestion = result as QuestionDto;
      if (result!.questionAnswers?.length) {
        this.choosenAnswer = result?.questionAnswers[0] as QuestionAnswerDto;
      }
      this.show = true;
      this.waitForTimeUp().then(() => this.answerQuestion());
    } else {
      if (this.nextQuestion?.questionText === undefined) {
        this.acceptAnswer(false, true);
        return;
      }

      this.show = true;
      this.waitForQuestionLoad();
    }
  }
  private waitForQuestionLoad() {
    if (this.loadingNextQuestion == false) {
      this.showQuestion();
    } else {
      this.$nextTick(() => setTimeout(this.waitForQuestionLoad, 250));
    }
  }

  private showQuestion() {
    this.currentQuestion = this.nextQuestion as QuestionDto;
    if (this.currentQuestion.questionAnswers?.length) {
      this.choosenAnswer = this.currentQuestion.questionAnswers[0] as QuestionAnswerDto;
    }
    this.waitForTimeUp().then(() => this.answerQuestion());
  }
  private async acceptAnswer(answeredCorrectly: boolean, answerPoolEmpty = false): Promise<void> {
    this.show = false;
    this.$emit('acceptAnswer', answeredCorrectly, answerPoolEmpty);

    this.loadingNextQuestion = true;
    this.nextQuestion = await this.apiService.getQuestionByProfession(this.professionId, this.playerInstanceId);
    this.loadingNextQuestion = false;

    if (answerPoolEmpty) return;
    const event = answeredCorrectly ? AnalyticsEvent.correctAnswer : AnalyticsEvent.incorrectAnswer;
    AnalyticsGoogleHelper.event(event, {
      player_instance_id: this.playerInstanceId,
      profession_id: this.professionId,
      question_id: this.currentQuestion?.id,
      question_text: this.currentQuestion?.questionText,
      player_answer: this.choosenAnswer?.answerText
    });
  }

  public nextAnswer() {
    if (!this.currentQuestion) {
      return;
    }
    let currentIndex = this.currentQuestion!.questionAnswers!.findIndex(a => a.id === this.choosenAnswer!.id);
    const nextIndex = ++currentIndex % this.currentQuestion!.questionAnswers!.length;
    const nextId = this.currentQuestion!.questionAnswers![nextIndex];
    this.choosenAnswer = nextId;
  }
  public previousAnswer() {
    if (!this.currentQuestion) {
      return;
    }
    let currentIndex = this.currentQuestion!.questionAnswers!.findIndex(a => a.id === this.choosenAnswer!.id);
    let nextIndex = --currentIndex % this.currentQuestion!.questionAnswers!.length;
    if (nextIndex < 0) {
      nextIndex = this.currentQuestion!.questionAnswers!.length - 1;
    }
    const nextId = this.currentQuestion!.questionAnswers![nextIndex];
    this.choosenAnswer = nextId;
  }

  public waitForTimeUp() {
    return new Promise<void>(resolve => {
      const keyHandler = (e: any) => {
        if (e.key === 'Enter') {
          document.removeEventListener('keydown', keyHandler);
          resolve();
        } else if (e.key === 'ArrowUp') {
          this.previousAnswer();
        } else if (e.key === 'ArrowDown') {
          this.nextAnswer();
        }
      };
      if (this.answerLimit) {
        this.resetTimers();
        this.timeLeftInterval = setInterval(() => {
          this.timeLeft--;
          if (this.timeLeft <= 0) {
            document.removeEventListener('keydown', keyHandler);
            resolve();
          }
        }, 1000);
        this.timeLeftIntervalBar = setInterval(() => {
          this.timeLeftBarWidth -= 10 / (this.timeForQuestion + 1);
        }, 100);
        this.timeLeftTimeout = setTimeout(resolve, this.timeForQuestion * 1000 + 1000);
      }
      document.addEventListener('keydown', keyHandler);
    });
  }
  private resetTimers() {
    this.timeLeft = this.timeForQuestion;
    this.timeLeftBarWidth = 100;
    if (this.timeLeftInterval) {
      clearInterval(this.timeLeftInterval);
    }
    if (this.timeLeftIntervalBar) {
      clearInterval(this.timeLeftIntervalBar);
    }
    clearTimeout(this.timeLeftTimeout);
  }
}
