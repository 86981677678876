
































import { Component, Vue } from 'vue-property-decorator';
import { MenuItem } from '@/modules/layout/components/MenuItem';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import { KitchenSinkRoute } from '@/modules/kitchen-sink/kitchen-sink-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store/store';

@Component
export default class AdminDashboard extends Vue {
  items: MenuItem[] = [
    {
      title: 'Modules.AdminPanel.Dashboard.Discounts',
      icon: 'mdi-sale',
      routeName: AdminUsersRoute.discountsList,
      disabled: false
    },
    {
      title: 'Modules.AdminPanel.Dashboard.Users',
      icon: 'mdi-account-supervisor',
      routeName: AdminUsersRoute.userAdmin,
      disabled: false
    },
    {
      title: 'Modules.AdminPanel.Dashboard.Payments',
      icon: 'mdi-currency-usd',
      routeName: AdminUsersRoute.paymentsList,
      disabled: false
    },
    {
      title: 'Modules.AdminPanel.Dashboard.GameInstances',
      icon: 'mdi-gamepad-variant-outline',
      routeName: AdminUsersRoute.gameInstances,
      disabled: false
    },

    {
      title: 'Modules.AdminPanel.Dashboard.KitchenSink',
      icon: 'mdi-book-open',
      routeName: KitchenSinkRoute.kitchenSink,
      disabled: false
    }
  ];

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: true
      }
    ];
  }

  mounted() {
    store.setBreadcrumb(this.breadcrumbData);
  }

  goto(route: string) {
    this.$router.push({ name: route });
  }
}
