

























































import { InstancePlayerStatisticsDto } from '@/services/Api';
import { Duration, Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { store } from '@/store/store';

@Component
export default class StatisticsPlayerTable extends Vue {
  @Prop() readonly data!: InstancePlayerStatisticsDto;
  @Prop() readonly exporting!: boolean;
  @Prop() readonly loadingCVButton!: boolean;
  private tablesSize = 5;
  private search = '';
  private localLoading = true;
  goToPlayerStatistic(playerEmail: string, playerProfession: string) {
    this.$emit('player-statistic', playerEmail, playerProfession);
  }
  mounted() {
    this.localLoading = true;
    this.tablesSize = store.getTableSize('Player-statistics');
    this.localLoading = false;
  }
  @Watch('tablesSize')
  onPropertyChanged(value: number, oldValue: string) {
    this.tablesSize = value;
    store.setTables('Player-statistics', this.tablesSize);
  }
  get headers() {
    return [
      {
        text: 'Email',
        align: 'left',
        value: 'email'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.Profession'),
        value: 'profession'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.StartedGames'),
        value: 'startedGames'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.FinishedGames'),
        value: 'finishedGames'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.AvgTime'),
        value: 'averageTimeForGame'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.TotalTime'),
        value: 'totalTimeForGames'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.PercentOfGoodAnswers'),
        value: 'percentOfGoodAnswers'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.CV'),
        value: 'cv'
      }
    ];
  }

  private availableColors = [
    'teal',
    'light-green',
    'purple',
    'blue-grey',
    'brown',
    'yellow',
    'purple',
    'cyan',
    'red',
    'deep-orange',
    'deep-purple',
    'blue',
    '#9089E7',
    '#E85C39'
  ];
  takenColors: { [id: string]: string } = {};

  getColor(profession: string) {
    if (this.takenColors[profession]) {
      return this.takenColors[profession];
    } else {
      if (profession) {
        const color = this.availableColors.pop() ?? 'pink';
        this.takenColors[profession] = color;
      }
    }
    return this.takenColors[profession];
  }

  formatDuration(item: Duration) {
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }
  emitExportCsv() {
    this.$emit('export-csv');
  }
  emitDownloadCv(playerEmail: string, playerProfession: string) {
    this.$emit('download-cv', playerEmail, playerProfession);
  }
}
