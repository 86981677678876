





































import { Component, Vue } from 'vue-property-decorator';
import DiscountApiService from '../services/admin-discount-api.service';
import DiscountDetails from '@/modules/admin/discounts/components/DiscountDetails.vue';
import PaymentsDetails from '@/modules/admin/discounts/components/PaymentsDetails.vue';
import { DiscountAdminDto, OrderListItemAdminDataDto } from '@/services/Api';
import { i18n, translate } from '@/plugins/i18n';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';
@Component({
  components: {
    DiscountDetails,
    PaymentsDetails
  }
})
export default class DiscountDetailsPage extends Vue {
  private adminApiService = new DiscountApiService();
  private loading = true;
  private discountDetailsData!: DiscountAdminDto;
  private paymentsDetailsData!: OrderListItemAdminDataDto[];
  private submitting = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Discounts'),
        exact: true,
        to: { name: AdminUsersRoute.discountsList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        disabled: true
      }
    ];
  }

  async mounted() {
    await this.getDiscount();
    await this.getPayments();
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  private cancel() {
    this.$router.push({ name: AdminUsersRoute.discountsList });
  }
  async getDiscount() {
    const userInstance: DiscountAdminDto | undefined = await this.adminApiService.getDiscount(this.$route.params.id);
    if (userInstance) {
      this.discountDetailsData = userInstance;
    }
  }
  async getPayments() {
    const paymentsInstance: OrderListItemAdminDataDto[] | undefined = await this.adminApiService.getPayments(
      this.$route.params.id
    );
    if (paymentsInstance) {
      this.paymentsDetailsData = paymentsInstance;
    }
  }
}
