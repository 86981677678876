









import { Component, Vue } from 'vue-property-decorator';
import { AdminGameInstanceListItemDto } from '@/services/Api';
import AdminGameInstancesApiService from '../services/admin-game-instances-api.service';
import GameInstancesListRow from '../components/GameInstancesListRow.vue';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { AdminUsersRoute } from '../../admin-users-router.enum';
import { store } from '@/store/store';

@Component({
  components: { GameInstancesListRow }
})
export default class AdminGameInstancesList extends Vue {
  private apiService = new AdminGameInstancesApiService();
  private gameInstancesList!: AdminGameInstanceListItemDto[];
  private loading = true;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.GameInstancesAdmin'),
        exact: true,
        disabled: true
      }
    ];
  }
  async mounted() {
    await this.getGameInstances();
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  async getGameInstances() {
    const gameInstancesGetList = await this.apiService.getGameInstancesList();
    if (gameInstancesGetList) {
      this.gameInstancesList = gameInstancesGetList;
    }
  }
}
