




























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProfessionAdminDataDto } from '@/services/Api';
import { store } from '@/store/store';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
@Component({
  components: {}
})
export default class ProfessionsAdminData extends Vue {
  @Prop() userProfessionData!: ProfessionAdminDataDto[] | undefined;

  private tableSize = 5;
  public professionsRoute = ProfessionsRoute;
  get headersGames() {
    return [
      { text: this.$t('Modules.AdminPanel.UserDetails.Professions'), value: 'displayTitle' },
      { text: this.$t('Modules.AdminPanel.UserDetails.Status'), value: 'isGlobal' },
      { text: this.$t('Modules.AdminPanel.UserDetails.Questions'), value: 'questionsCount' },
      { text: this.$t('Modules.AdminPanel.UserDetails.Players'), value: 'playerInstancesCount' },
      { text: this.$t('Modules.AdminPanel.UserDetails.IsDeleted'), value: 'isDeleted' }
    ];
  }
  async mounted() {
    this.tableSize = store.getTableSize('Admin-user-professions');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-user-professions', this.tableSize);
  }
}
