export class SoundManager {
  static readonly boostName = 'boost';
  static readonly boost = require('@/assets/games/river-run/themes/speed-race/sounds/RaceCarEngine.ogg');
  static readonly explosionName = 'explosion';
  static readonly explosion = require('@/assets/games/space-rescue/sounds/explosion.wav');
  static readonly passingName = 'passing';
  static readonly passingObjects = require('@/assets/games/river-run/themes/speed-race/sounds/rollover3.ogg');
  static readonly nextLevelName = 'nextLevel';
  static readonly nextLevelObjects = require('@/assets/games/river-run/themes/speed-race/sounds/applause10.ogg');
  static readonly engineName = 'engine';
  static readonly engine = require('@/assets/games/river-run/themes/speed-race/sounds/RaceCarEngine.ogg');

  static getVariableName(){
    return [this.explosionName,this.passingName,this.nextLevelName,this.engineName];
  }
  static getVariablePath(){
    return [this.explosion,this.passingObjects,this.nextLevelObjects,this.engine];
  }
}
