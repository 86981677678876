import {
  FileResponse,
  InstanceGameStatisticsDto,
  InstanceGlobalStatisticsDto,
  InstanceGlobalStatisticsForPlayerDto,
  InstanceOneGameStatisticDto,
  InstancePlayerGameStatisticsDto,
  InstancePlayerStatisticsDto,
  InstancePlayersStatisticsDto,
  PlayerInstanceDetailsDto,
  PlayerProfileDto,
  PlayerProfileUpdateInputModel,
  StatisticsClient
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class StatisticApiService extends ApiServiceBase {
  private client = new StatisticsClient();

  public async getInstanceGlobalStatistics(gameInstanceId: string): Promise<InstanceGlobalStatisticsDto | undefined> {
    try {
      const response = await this.client.getInstanceGlobalStatistics(gameInstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceGlobalStatisticsForPlayer(
    gameInstanceId: string,
    instanceId: string
  ): Promise<InstanceGlobalStatisticsForPlayerDto | undefined> {
    try {
      const response = await this.client.getInstanceGlobalStatisticsForPlayer(gameInstanceId, instanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstancePlayersStatistics(
    gameInstanceId: string
  ): Promise<InstancePlayersStatisticsDto[] | undefined> {
    try {
      const response = await this.client.getInstancePlayersStatistics(gameInstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceGameStatistics(gameInstanceId: string): Promise<InstanceGameStatisticsDto[] | undefined> {
    try {
      const response = await this.client.getInstanceGameStatistics(gameInstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async exportGameStatistics(
    gameInstanceId: string,
    localTimeOffset: number
  ): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.exportGamesStatistics(gameInstanceId, localTimeOffset);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async exportPlayersStatistics(gameInstanceId: string): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.exportPlayersStatistics(gameInstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstancePlayerStatisticsCSV(
    gameInstanceId: string,
    email: string
  ): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.getInstancePlayerStatisticsCSV(gameInstanceId, email);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceGameStatisticsCSVByPlayer(
    gameInstanceId: string,
    professionId: string,
    email: string,
    localTimeOffset: number
  ): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.getInstanceGameStatisticsCSVByPlayer(
        gameInstanceId,
        professionId,
        email,
        localTimeOffset
      );
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceOneGameLogsStatisticsCSV(
    gameInstanceId: string,
    instanceId: string,
    localTimeOffset: number
  ): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.getInstanceOneGameLogsStatisticsCSV(
        gameInstanceId,
        instanceId,
        localTimeOffset
      );
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async GetInstanceOneGameAnswerStatisticsCSV(
    gameInstanceId: string,
    instanceId: string,
    localTimeOffset: number
  ): Promise<FileResponse | undefined> {
    try {
      const response = await this.client.getInstanceOneGameAnswerStatisticsCSV(
        gameInstanceId,
        instanceId,
        localTimeOffset
      );
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstancePlayersCV(
    gameInstanceId: string,
    playerEmail: string
  ): Promise<PlayerInstanceDetailsDto | undefined> {
    try {
      const response = await this.client.getInstancePlayersCV(gameInstanceId, playerEmail);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstancePlayersCVById(InstanceId: string): Promise<PlayerInstanceDetailsDto | undefined> {
    try {
      const response = await this.client.getInstancePlayersCVById(InstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceGameCV(
    gameInstanceId: string,
    instanceId: string
  ): Promise<PlayerInstanceDetailsDto | undefined> {
    try {
      const response = await this.client.getInstanceGameCV(gameInstanceId, instanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceGameStatisticsByPlayer(
    gameInstanceId: string,
    playerEmail: string,
    playerProfession: string
  ): Promise<InstancePlayerGameStatisticsDto[] | undefined> {
    try {
      const response = await this.client.getInstanceGameStatisticsByPlayer(
        gameInstanceId,
        playerProfession,
        playerEmail
      );
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstancePlayerStatistics(
    gameInstanceId: string,
    playerEmail: string
  ): Promise<InstancePlayerStatisticsDto[] | undefined> {
    try {
      const response = await this.client.getInstancePlayerStatistics(gameInstanceId, playerEmail);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getInstanceOneGameStatistics(
    gameInstanceId: string,
    instanceId: string
  ): Promise<InstanceOneGameStatisticDto | undefined> {
    try {
      const response = await this.client.getInstanceOneGameStatistics(gameInstanceId, instanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async getPlayerProfile(email: string, gameInstanceId: string): Promise<PlayerProfileDto | undefined> {
    try {
      const response = await this.client.getPlayerProfile(email, gameInstanceId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async UpdatePlayerProfile(inputModel: PlayerProfileUpdateInputModel): Promise<PlayerProfileDto | undefined> {
    try {
      const response = await this.client.updatePlayerProfile(inputModel);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
