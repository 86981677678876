import { render, staticRenderFns } from "./EditCardMainPreview.vue?vue&type=template&id=22d2e882&scoped=true&"
import script from "./EditCardMainPreview.vue?vue&type=script&lang=ts&"
export * from "./EditCardMainPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d2e882",
  null
  
)

export default component.exports