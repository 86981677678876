




































import { Component, Vue } from 'vue-property-decorator';
import AdminUserApiService from '../services/admin-user-api.service';
import UserDataAdminData from '@/modules/admin/users/components/UserDataAdminData.vue';
import GameInstanceAdminData from '@/modules/admin/users/components/GameInstanceAdminData.vue';
import PaymentsAdminData from '@/modules/admin/users/components/PaymentsAdminData.vue';
import ProfessionsAdminData from '@/modules/admin/users/components/ProfessionsAdminData.vue';

import {
  AdminUserDataDto,
  OrderListItemAdminDataDto,
  GameInstanceAdminDataDto,
  ProfessionAdminDataDto
} from '@/services/Api';
import { i18n, translate } from '@/plugins/i18n';
import { AdminUsersRoute } from '../../admin-users-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';
@Component({
  components: {
    UserDataAdminData,
    GameInstanceAdminData,
    PaymentsAdminData,
    ProfessionsAdminData
  }
})
export default class AdminEditPage extends Vue {
  private adminApiService = new AdminUserApiService();
  private loading = true;
  private userDetailsData!: AdminUserDataDto | undefined;
  private userGameInstancesData!: GameInstanceAdminDataDto[] | undefined;
  private userPaymentData!: OrderListItemAdminDataDto[] | undefined;
  private userProfessionData!: ProfessionAdminDataDto[] | undefined;
  private submitting = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.UserAdmin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.userAdmin }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        exact: true,
        disabled: true
      }
    ];
  }

  async mounted() {
    const userInstance: AdminUserDataDto | undefined = await this.adminApiService.getUserData(this.$route.params.id);
    if (userInstance) {
      this.userDetailsData = userInstance;
    }
    const gameInstance: GameInstanceAdminDataDto[] | undefined = await this.adminApiService.getGameInstancesByUser(
      this.$route.params.id
    );
    if (userInstance) {
      this.userGameInstancesData = gameInstance;
    }
    const paymentInstance: OrderListItemAdminDataDto[] | undefined = await this.adminApiService.getPaymentOrders(
      this.$route.params.id
    );
    if (userInstance) {
      this.userPaymentData = paymentInstance;
    }
    const proffesionInstance:
      | ProfessionAdminDataDto[]
      | undefined = await this.adminApiService.getProfessionsOfCurrentUser(this.$route.params.id);
    if (userInstance) {
      this.userProfessionData = proffesionInstance;
    }

    store.setBreadcrumb(this.breadcrumbData);

    this.loading = false;
  }
}
