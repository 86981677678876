import { i18n } from '@/plugins/i18n';
import Cookies from 'js-cookie';
import { CookieModel } from './models/cookie.model';

export default class CookieService {
  public static cookieName = 'settings';

  public static getCookie(): CookieModel | undefined {
    const cookie = Cookies.getJSON(CookieService.cookieName) as CookieModel;
    return cookie;
  }

  public static setCookieProperty<T extends keyof CookieModel>(key: T, value: CookieModel[T]): void {
    const cookie = this.getCookieWithDefault();
    cookie[key] = value;
    this.setCookie(cookie);
  }

  private static setCookie(cookie: CookieModel) {
    const domain = this.getCookieDomain();
    Cookies.set(CookieService.cookieName, cookie, { domain: domain, sameSite: 'strict', expires: 365 });
  }

  private static getCookieWithDefault(): CookieModel {
    const cookie = this.getCookie();
    if (!cookie) {
      const newModel = new CookieModel(i18n.locale, false, false, false, undefined);
      return newModel;
    }

    return cookie;
  }

  private static getCookieDomain() {
    const hostName = window.location.hostname;
    const domain = hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);

    return hostName.startsWith(domain) ? domain : `.${domain}`;
  }
}
