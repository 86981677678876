












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GlobalCountsBarItem extends Vue {
  @Prop() readonly header!: string;
  @Prop() readonly text!: string;
  @Prop() readonly icon!: string;
  @Prop() readonly color!: string;
}
