






































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import UserApiService from '@/modules/statistics/services/statistic-api.service';
import LogsEvent from '../Models/LogsEvent';
import { PlayerInstanceAnswersDto } from '@/services/Api';
import { GameTriggerType } from '@/modules/game-play/games/game-engine-base';
@Component
export default class StatisticsOneGameLogsTable extends Vue {
  @Prop() readonly data!: LogsEvent[];
  @Prop() readonly answers!: PlayerInstanceAnswersDto[];
  @Prop() readonly exporting!: boolean;

  apiService = new UserApiService();
  private search = '';
  mounted() {
    this.calculateTimeForLastEvent();
    this.calculate1to1Answers();
  }
  get headers() {
    return [
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.Trigger'),
        align: 'left',
        value: 'logTrigger'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.Score'),
        value: 'score'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.LogTime'),
        value: 'dateTimeLog'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.TimeBetweenEvent'),
        value: 'eventTime'
      }
    ];
  }

  calculate1to1Answers() {
    this.answers.sort((a, b) => {
      return moment(a.dateTimeLog).diff(b.dateTimeLog);
    });
    let index = 0;
    let questionNumber = 0;
    let level = 1;
    this.data.forEach(val => {
      if (val.logTrigger == 'live lost' || val.logTrigger == 'live saved') {
        if (index <= this.answers.length - 1) {
          val.isCorrectAnswer = this.answers[index].isCorrectAnswer;
          val.question = this.answers[index].answeredQuestion;
          val.answer = this.answers[index].answer;
          questionNumber++;
          val.questionNumber = questionNumber.toString();
          index++;
        }
      }
      if (val.logTrigger == 'new level') {
        level++;
        val.level = level.toString();
      }
    });
  }
  calculateTimeForLastEvent() {
    this.data.sort((a, b) => {
      return moment(a.dateTimeLog).diff(b.dateTimeLog);
    });
    for (let index = 1; index < this.data.length; index++) {
      const data1 = this.data[index].dateTimeLog;
      const data2 = this.data[index - 1].dateTimeLog;
      const duration = moment.duration(data1?.diff(data2));
      this.data[index].eventTime = duration.format('mm:ss');
    }
  }
  getColor(text: string) {
    switch (text) {
      case GameTriggerType.NewLevel:
        return 'yellow';
      case GameTriggerType.LiveLost:
        return 'red';
      case GameTriggerType.GameOver:
        return 'black';
      case GameTriggerType.GameStart:
        return 'black';
      case GameTriggerType.LiveSaved:
        return 'green';
    }
  }
  translateTrigger(text: string) {
    switch (text) {
      case GameTriggerType.NewLevel:
        return this.$t('Modules.Statistics.GamesTable.DeepStatistics.Triggers.NewLevel');
      case GameTriggerType.LiveLost:
        return this.$t('Modules.Statistics.GamesTable.DeepStatistics.Triggers.LifeLost');
      case GameTriggerType.GameOver:
        return this.$t('Modules.Statistics.GamesTable.DeepStatistics.Triggers.GameOver');
      case GameTriggerType.GameStart:
        return this.$t('Modules.Statistics.GamesTable.DeepStatistics.Triggers.GameStart');
      case GameTriggerType.LiveSaved:
        return this.$t('Modules.Statistics.GamesTable.DeepStatistics.Triggers.LifeSaved');
    }
  }
  emitExportCsv() {
    this.$emit('export-csv');
  }
}
