import { SoundManager } from '../Global/soundManager';
export class Bullet extends Phaser.Physics.Arcade.Sprite {
  readonly offsetToDestroyTop: number = -32;
  readonly offsetToDestroyBottom: number = this.scene.game.canvas.height + 32;

  fire(x: number, y: number, width: number, height: number, angle: number, speed: number) {
    this.body.reset(x, y);
    this.setActive(true);
    this.setVisible(true);
    this.setVelocityY(speed);
    this.displayWidth = width;
    this.displayHeight = height;
    this.angle = angle;

    this.scene.sound.add(SoundManager.shootName).play();
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.y <= this.offsetToDestroyTop || this.y >= this.offsetToDestroyBottom) {
      this.setActive(false);
      this.setVisible(false);
    }
  }
  killBullet() {
    this.setActive(false);
    this.setVisible(false);
    this.body.reset(this.scene.game.canvas.width, 0);
  }
}
