
























































import { GameType, PlayerInstanceInputModel, RuntimeGameInstanceDto } from '@/services/Api';
import { Component, Vue } from 'vue-property-decorator';
import GameApiService from './services/game-api.service';
import { store } from '@/store/store';
import GameMenu from './GameMenu.vue';
import GamePlay from './GamePlay.vue';
import PlayableStatus from './playableStatus.enum';
import moment from 'moment';
import FlipCountdown from 'vue2-flip-countdown';
import GameThemeService from './services/game-theme.service';
import { GameTheme } from './models/game-theme';
import Loader from '@/modules/layout/components/Loader.vue';
import GameIsPrivate from '@/modules/layout/components/GameIsPrivate.vue';

@Component({
  components: {
    GameIsPrivate,
    GameMenu,
    FlipCountdown,
    GamePlay,
    Loader
  }
})
export default class GameContainer extends Vue {
  public gameInstanceId = this.$route.params.id;
  public loading = true;
  public playing = false;
  public gameType: GameType | null = null;
  public gameInstance?: RuntimeGameInstanceDto;
  public playerInstanceId?: string;
  public professionId?: string;
  public oldPlayerModel?: PlayerInstanceInputModel = new PlayerInstanceInputModel();
  public playableStatus: PlayableStatus = PlayableStatus.playable;
  public gameIsNotPublic = false;

  apiService = new GameApiService();
  theme?: GameTheme;
  font?: string = '';
  mounted() {
    this.loadGame();
  }

  async loadGame() {
    this.loading = true;
    if (store.isLoggedIn) {
      const result = await this.apiService.getGameInstancePrivate(this.gameInstanceId);
      this.gameType = result?.type ?? GameType.Undefined;
      this.gameInstance = result;
    } else {
      const result = await this.apiService.getGameInstancePublic(this.gameInstanceId);
      this.gameType = result?.type ?? GameType.Undefined;
      this.gameInstance = result;
    }

    if (this.gameType === GameType.Undefined) {
      this.loading = false;
      this.gameIsNotPublic = true;
      return;
    }

    this.checkIfPlayable();

    this.theme = GameThemeService.getTheme(this.gameType);
    this.font = this.theme?.font ?? '';
    const bgMenu = new Image();
    bgMenu.src = this.theme?.background as string;
    bgMenu.onload = () => {
      this.loading = false;
    };
  }

  checkIfPlayable() {
    if (this.gameInstance?.playableFrom && moment().isBefore(this.gameInstance?.playableFrom?.local())) {
      this.playableStatus = PlayableStatus.tooEarly;
    } else if (
      !this.playing &&
      this.gameInstance?.playableTo &&
      moment().isAfter(this.gameInstance?.playableTo?.local())
    ) {
      this.playableStatus = PlayableStatus.tooLate;
    } else {
      this.playableStatus = PlayableStatus.playable;
    }
  }

  get playableFromString() {
    return this.gameInstance?.playableFrom?.local().format('YYYY/MM/DD HH:mm:ss');
  }
  get playableToString() {
    return this.gameInstance?.playableTo?.local().format('YYYY/MM/DD HH:mm:ss');
  }

  get tooEarly() {
    return this.playableStatus === PlayableStatus.tooEarly;
  }
  get tooLate() {
    return this.playableStatus === PlayableStatus.tooLate && !this.playing;
  }

  get showCommonGame() {
    return this.gameType && this.playableStatus == PlayableStatus.playable;
  }
  public onStartGame(playerInstanceId: string, playerModel: PlayerInstanceInputModel) {
    this.playerInstanceId = playerInstanceId;
    this.professionId = playerModel.professionId;
    this.oldPlayerModel = playerModel;
    this.playing = true;
  }
  public onRelaunchGame() {
    this.playerInstanceId = undefined;
    this.playing = false;
    this.checkIfPlayable();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
