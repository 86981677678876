




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormSelectPreview extends Vue {
  label = this.$t('KitchenSink.Previews.FormSelectPreview.Label');
  value = this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString();
  items: string[] = [
    this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString(),
    this.$t('KitchenSink.Previews.FormSelectPreview.Value1').toString(),
    this.$t('KitchenSink.Previews.FormSelectPreview.Value2').toString()
  ];
  itemText = this.$t('KitchenSink.Previews.FormSelectPreview.Text').toString();
  itemValue = this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString();
}
