














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdminUserDataDto } from '@/services/Api';
import { translate } from '@/plugins/i18n';
@Component
export default class AdminEditPage extends Vue {
  @Prop() userDetailsData!: AdminUserDataDto;
}
