import { RouteConfig } from 'vue-router';
import KitchenSink from '@/modules/kitchen-sink/views/kitchen-sink.vue';
import { KitchenSinkRoute } from './kitchen-sink-router.enum';

export const kitchenSinkRoutes: Array<RouteConfig> = [
  {
    path: '/kitchen-sink',
    name: KitchenSinkRoute.kitchenSink,
    component: KitchenSink
  }
];
