import {
    AdminUserListItemDto, AdminUserDataDto, AdminUserUpdateInputModel, UserAdminClient, AdminUserDeleteUserInputModel,
    GameInstanceAdminDataDto, OrderListItemAdminDataDto,ProfessionAdminDataDto
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class AdminUserApiService extends ApiServiceBase {
  private client = new UserAdminClient();
  public async GetUsersList(): Promise<AdminUserListItemDto[] | undefined> {
    try {
      const userList = await this.client.getUsersList();
      return userList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async updateDataUser(inputModel: AdminUserUpdateInputModel): Promise<AdminUserListItemDto | undefined> {
    try {
      const userList = await this.client.updateDataUser(inputModel);
      return userList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async deleteUser(inputModel: AdminUserDeleteUserInputModel): Promise<AdminUserListItemDto | undefined> {
    try {
      const userList = await this.client.deleteUser(inputModel);
      return userList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getUser(inputModel: string): Promise<AdminUserListItemDto | undefined> {
    try {
      const user = await this.client.getUser(inputModel);
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

    public async getUserData(inputModel: string): Promise<AdminUserDataDto | undefined> {
        try {
            const user = await this.client.getUserData(inputModel);
            return user.result;
        } catch (error) {
            this.HandleError(error);
        }
    }

    public async getGameInstancesByUser(inputModel: string): Promise<GameInstanceAdminDataDto[] | undefined> {
        try {
            const user = await this.client.getGameInstancesByUser(inputModel);
            return user.result;
        } catch (error) {
            this.HandleError(error);
        }
    }

    public async getPaymentOrders(inputModel: string): Promise<OrderListItemAdminDataDto[] | undefined> {
        try {
            const user = await this.client.getPaymentOrders(inputModel);
            return user.result;
        } catch (error) {
            this.HandleError(error);
        }
    }

    public async getProfessionsOfCurrentUser(inputModel: string): Promise<ProfessionAdminDataDto[] | undefined> {
      try {
          const user = await this.client.getProfessionsOfCurrentUser(inputModel);
          return user.result;
      } catch (error) {
          this.HandleError(error);
      }
  }
}
