import 'phaser';
import { GameTheme } from '../models/game-theme';
import { GameCustomizableElement } from './game-engine-customizable-element';
abstract class GameEngineBase {
  constructor(
    // Display next question modal
    protected askQuestion: () => void,
    // Display Game Over Modal
    protected gameOverDialog: (score: number) => void,
    // Send game log
    protected saveGameLog: (score: number, trigger: GameTriggerType) => void,
    // Customized elements
    protected customizedElements: GameCustomizableElement[],
    // Game Theme
    protected theme: GameTheme | undefined
  ) {}

  abstract initialize(): void;
  // Called by the GamePlay with the response if the choosen answer was correct
  abstract acceptAnswer(isCorrectAnswer: boolean, answerPoolEmpty: boolean): void;

  abstract getCustomziableTypes(): GameCustomizableElement[];

  protected getCustomizedElements(): GameCustomizableElement[] {
    const defaultElements = this.getCustomziableTypes();
    const themedElements = this.theme?.overridenElements ?? [];
    const customElements = this.customizedElements ?? [];

    const baseElements = GameCustomizableElement.mergeWithOverride(defaultElements, themedElements);
    const elements = GameCustomizableElement.mergeWithOverride(baseElements, customElements);

    return elements;
  }
}
enum GameTriggerType {
  GameOver = 'game over',
  GameStart = 'game start',
  NewLevel = 'new level',
  LiveSaved = 'live saved',
  LiveLost = 'live lost'
}

//Configuration game
export { GameEngineBase, GameTriggerType };
