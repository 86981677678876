


















































import { InstanceGlobalStatisticsDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlobalCountsBarItem from './GlobalCountsBarItem.vue';

@Component({
  components: { GlobalCountsBarItem }
})
export default class GlobalCountsBar extends Vue {
  @Prop() readonly data!: InstanceGlobalStatisticsDto;
}
