import { KeySide } from '../Models/KeySide';
import { RiverRunVariables } from '../Global/RiverRunVariables';
export class Player extends Phaser.Physics.Arcade.Sprite {
  speedOnX: number;
  maxspeedOnX: number;
  speedOnY: number;
  maxspeedOnY: number;
  texturess!: Phaser.Textures.Texture;
  playerBorderMaxOnX!: number;
  playerBorderMinOnX!: number;
  playerBorderMaxOnY!: number;
  playerBorderMinOnY!: number;
  speedBackMax = 100;
  brakeStamina = 100;
  soundObjects: Phaser.GameObjects.Rectangle;
  heightSound = 40;
  speedBack = false;
  slowDown = false;
  staminaBlockade = false;
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    speedOnX: number,
    maxspeedOnX: number,
    speedOnY: number,
    maxspeedOnY: number,
    playerBorderMaxOnX: number,
    playerBorderMinOnX: number,
    playerBorderMaxOnY: number,
    playerBorderMinOnY: number,
    widthPlayer: number,
    heightPlayer: number
  ) {
    super(scene, x, y, RiverRunVariables.playerSpriteName);
    scene.physics.add.existing(this);
    this.speedOnX = speedOnX;
    this.maxspeedOnX = maxspeedOnX;
    this.speedOnY = speedOnY;
    this.maxspeedOnY = maxspeedOnY;
    scene.add.existing(this);
    this.displayHeight = heightPlayer;
    this.displayWidth = widthPlayer;

    this.playerBorderMaxOnX = playerBorderMaxOnX;
    this.playerBorderMinOnX = playerBorderMinOnX;
    this.playerBorderMaxOnY = playerBorderMaxOnY;
    this.playerBorderMinOnY = playerBorderMinOnY;

    this.soundObjects = this.scene.add.rectangle(
      x,
      y - this.heightSound,
      widthPlayer + widthPlayer * 1.5,
      this.heightSound / 10
    );
    this.scene.physics.add.existing(this.soundObjects);
  }
  preUpdate() {
    this.soundObjects.x = this.x;
    this.soundObjects.y = this.y - this.heightSound;
  }
  // Move player with animation
  move(side: KeySide, delta: number) {
    const isLeftPressed = (side & KeySide.left) === KeySide.left;
    const isRightPressed = (side & KeySide.right) === KeySide.right;
    const isUpPressed = (side & KeySide.up) === KeySide.up;
    const isDownPressed = (side & KeySide.down) === KeySide.down;
    // Handle Left / Right movement
    if (isLeftPressed) {
      this.moveLeft(delta);
    } else if (isRightPressed) {
      this.moveRight(delta);
    } else {
      // Neither LEFT, nor RIGHT is pressed
      this.resetHorizontalMovement();
    }
    // Handle Up / Down movement
    if (isUpPressed) {
      this.moveUp(delta);
    } else if (isDownPressed) {
      this.moveDown(delta);
    } else {
      // Neither UP, nor DOWN is pressed
      this.resetVerticalMovement();
    }
  }

  private moveLeft(delta: number): void {
    if (this.body.x >= this.playerBorderMinOnX) {
      // NOT touching left border of player area
      if (this.body.velocity.x >= -this.maxspeedOnX) {
        this.body.velocity.x -= this.speedOnX * delta;
        this.animaionTiltPlayer(KeySide.left);
      }
    } else {
      // Touching left border of player area
      this.resetHorizontalMovement();
    }
  }
  private moveRight(delta: number): void {
    if (this.body.x <= this.playerBorderMaxOnX) {
      // NOT touching right border of player area
      if (this.body.velocity.x <= this.maxspeedOnX) {
        this.body.velocity.x += this.speedOnX * delta;
        this.animaionTiltPlayer(KeySide.right);
      }
    } else {
      // Touching right border of player area
      this.resetHorizontalMovement();
    }
  }
  private moveUp(delta: number): void {
    if (this.body.y >= this.playerBorderMinOnY) {
      // NOT touching top border of player area
      this.speedBack = false;

      if (this.body.velocity.y >= -this.maxspeedOnY) {
        this.body.velocity.y -= this.speedOnY * delta;
      }
    } else {
      // Touching top border of player area
      this.body.velocity.y = 0;
      this.speedBack = true;
    }
  }
  private moveDown(delta: number): void {
    if (this.body.y < this.playerBorderMaxOnY) {
      // NOT touching bottom border of player area
      if (this.body.velocity.y <= this.maxspeedOnY) {
        this.body.velocity.y += this.speedOnY * delta;
      }
    } else {
      // Touching bottom border of player area
      this.body.velocity.y = 0;
      this.slowDown = this.brakeStamina > 0;
    }
  }
  private resetHorizontalMovement(): void {
    this.angle = 0;
    this.body.velocity.x = 0;
  }
  private resetVerticalMovement(): void {
    this.body.velocity.y = 0;
    this.speedBack = false;
    this.slowDown = false;
  }
  private animaionTiltPlayer(side: KeySide): void {
    let tiltAngle = 0;

    if (side === KeySide.left) {
      tiltAngle = -15;
    } else if (side === KeySide.right) {
      tiltAngle = 15;
    }

    this.scene.tweens.add({
      targets: this,
      angle: { from: 0, to: tiltAngle },
      ease: 'Linear',
      duration: 300
    });
  }
}
