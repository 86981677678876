export class SpaceRescueVariables {
  static readonly shipSpriteName = '__SHIP';
  static readonly enemySpriteName = '__ENEMY';
  static readonly enemyBgSpriteName = '__ENEMYBG';
  static readonly starsSpriteName = '__STARS';
  static readonly bulletSpriteName = '__BULLET';
  static readonly enemyBulletSpriteName = '__ENEMYBULLET';
  static readonly heartSpriteName = '__HEART';
  static readonly LivesName = 'LivesNumber';
}
