

























































































































import GameApiService from '../services/game-instance-api.service';
import { StringUtils } from '@/modules/layout/utils/string-utils';
import AnalyticsGoogleHelper, { AnalyticsEvent, getAnalyticsEventParams } from '@/plugins/analytics-google';
import { AnalyticsHelper, FacebookPixelEvent } from '@/plugins/analytics-facebook';
import { PayInputModel } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrderDetails } from '../models/create-order.model';
import { i18n } from '@/plugins/i18n';
import { GameInstanceRoute } from '../game-instance-router.enum';

@Component
export default class OrderSummary extends Vue {
  @Prop() readonly orderDetails!: OrderDetails;
  @Prop() readonly orderNumber!: string;
  @Prop() readonly termsAndConditionChecked!: boolean;
  @Prop() readonly privacyPolicyChecked!: boolean;
  @Prop() readonly finalPriceWithVatInfo!: string;
  @Prop() readonly vatInfo!: string;
  @Prop() readonly vatInfoLabel!: string;
  @Prop() readonly country!: string;
  @Prop() readonly serviceName!: string;
  @Prop() readonly planType!: string;
  //displaySummary false

  public isCreatingNewOrder = false;
  public paymentCreated = false;
  private paymentsApi: GameApiService = new GameApiService();
  public gameInstanceRoute = GameInstanceRoute;

  get currency() {
    return this.orderDetails?.priceInfo.currency;
  }

  get discountValueFormatted() {
    return `${this.orderDetails?.priceInfo?.discountValue} %`;
  }

  public goBack() {
    this.$emit('go-back');
  }

  public pay(): void {
    if (this.isCreatingNewOrder && this.paymentCreated) return;

    const payInputModel = new PayInputModel({
      orderNumber: this.orderNumber,
      gameInstanceId: this.orderDetails?.gameInstanceId as string
    });

    this.isCreatingNewOrder = true;
    this.paymentsApi.pay(payInputModel).then(redirectUri => {
      this.isCreatingNewOrder = false;
      if (StringUtils.isNullOrWhiteSpace(redirectUri)) return;

      this.sendBeginCheckoutAnalyticsEvent();

      this.paymentCreated = true;
      window.location.assign(redirectUri as string);
    });
  }

  public get getPayButtonLabel() {
    if (this.orderDetails.priceInfo.discountValue === 100) {
      return i18n.t('Modules.GameInstance.Order.PayButtonWithFreePurchase').toString();
    } else {
      return i18n.t('Modules.GameInstance.Order.PayButton').toString();
    }
  }

  private sendBeginCheckoutAnalyticsEvent(): void {
    if (this.orderDetails == null) return;

    // params from: https://developers.google.com/gtagjs/reference/ga4-events#begin_checkout
    AnalyticsGoogleHelper.event(
      AnalyticsEvent.beginCheckout,
      getAnalyticsEventParams(this.orderNumber, this.orderDetails)
    );

    // params from: https://developers.facebook.com/docs/facebook-pixel/reference
    AnalyticsHelper.trackWithFacebookPixel(
      FacebookPixelEvent.initiateCheckout,
      AnalyticsHelper.getPurchaseOptions(this.orderNumber, this.orderDetails)
    );
  }
}
