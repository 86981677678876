














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class FullHDSnackbar extends Vue {
  @Prop() showSnackbarHD!: boolean;
  show = false;
  attrs = false;
  change(value: boolean) {
    this.show = value;
  }
  @Watch('showSnackbarHD', { immediate: true })
  public onShowSnackbarHDChange(value: boolean) {
    this.change(value);
  }
}
