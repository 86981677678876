























































import { Component, Vue } from 'vue-property-decorator';
import { MenuItem } from './MenuItem';
import { store } from '@/store/store';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import { PaymentsHistoryRoute } from '@/modules/payments-history/payments-history-router.enum';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';

@Component
export default class SideMenu extends Vue {
  store = store;
  attrs = false;
  on = false;
  items: MenuItem[] = [
    {
      title: 'Layout.Menu.GameInstances',
      icon: 'mdi-gamepad-variant-outline',
      routeName: GameInstanceRoute.gameInstanceList,
      disabled: false
    },
    {
      title: 'Layout.Menu.Professions',
      icon: 'mdi-professional-hexagon',
      routeName: ProfessionsRoute.professionList,
      disabled: false
    },
    {
      title: 'Layout.Menu.PaymentsHistory',
      icon: 'mdi-receipt',
      routeName: PaymentsHistoryRoute.paymentsHistoryList,
      disabled: false
    }
  ];
  mounted() {
    if (this.store.isAdmin) {
      this.items.push({
        title: 'Layout.Menu.AdminDashboard',
        icon: 'mdi-account-supervisor',
        routeName: AdminUsersRoute.adminDashboard,
        disabled: false
      });
    }
  }
}
