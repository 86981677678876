import { RouteConfig } from 'vue-router';
import { GameInstanceRoute } from './game-instance-router.enum';
import GameInstanceList from '@/modules/game-instance/views/GameInstanceList.vue';
import GameInstanceCreate from '@/modules/game-instance/views/GameInstanceCreate.vue';
import GameInstanceEdit from '@/modules/game-instance/views/GameInstanceEdit.vue';
import GameInstanceCreateOrder from '@/modules/game-instance/views/GameInstanceCreateOrder.vue';
import DefaultView from '@/modules/layout/DefaultView.vue';

export const gameInstanceRoutes: Array<RouteConfig> = [
  {
    path: '/game-instances',
    component: DefaultView,
    children: [
      {
        path: '',
        name: GameInstanceRoute.gameInstanceList,
        component: GameInstanceList
      },
      {
        path: 'create',
        name: GameInstanceRoute.gameInstanceCreate,
        component: GameInstanceCreate
      },
      {
        path: 'order=:gameInstanceId',
        name: GameInstanceRoute.gameInstanceCreateOrder,
        component: GameInstanceCreateOrder,
        props: true
      },
      {
        path: ':gameInstanceId',
        name: GameInstanceRoute.gameInstanceEdit,
        component: GameInstanceEdit,
        props: true
      }
    ]
  }
];
