export enum AdminUsersRoute {
  adminDashboard = 'Admin Dashboard',
  userAdmin = 'User Admin',
  adminEdit = 'Admin Edit',
  adminDetails = 'Admin Details',
  discountsList = 'Discounts List',
  discountEdit = 'Discount Edit',
  discountDetails = 'Discount Details',
  discountAdd = 'Discount Add',
  paymentsList = 'Payments List',
  paymentsDetails = 'Payments Details',
  gameInstances = 'Game Instances',
  adminGameInstanceDetails = 'Admin Game Instance Details'
}
