




































import StringValidators from '@/modules/layout/validators/string-validators';
import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/user-api.service';
import { store } from '@/store/store';
import NotificationService from '@/services/notification-service';
import AnonymousContainer from '../components/AnonymousContainer.vue';
import AnonymousHeader from '../components/AnonymousHeader.vue';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

@Component({
  components: {
    AnonymousContainer,
    AnonymousHeader
  }
})
export default class ForgotPasswordRequest extends Vue {
  loading = false;
  email = store.rememberedEmail ?? '';
  apiService = new UserApiService();
  rules = {
    required: StringValidators.required,
    email: StringValidators.email
  };

  async submit() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      return;
    }
    this.loading = true;

    const registeredUser = await this.apiService.forgetPasswordRequest(this.email);
    if (registeredUser) {
      NotificationService.success(this.$t('Modules.Login.PasswordResetSuccesfull').toString());
      this.$router.push({ name: UserManagementRoute.login });
    }
    this.loading = false;
  }
}
