












import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import { StringUtils } from '../utils/string-utils';

@Component
export default class Footer extends Vue {
  public store = store;

  public get year(): number {
    return new Date().getFullYear();
  }

  public get displayAppVersion(): boolean {
    return StringUtils.isNullOrWhiteSpace(this.store.appVersion) === false;
  }
}
