















































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StringValidators from '@/modules/layout/validators/string-validators';
import GameInstanceCustomizeForm from '@/modules/game-instance/components/GameInstanceCustomizeForm.vue';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { GameMainSettingsModel } from '@/modules/game-play/games/customize-settings.model';
import EditCardSection from '@/modules/layout/components/EditCardSection.vue';

@Component({
  components: { GameInstanceCustomizeForm }
})
export default class GameInstanceCustomize extends Vue {
  @Prop() readonly customizeSettings!: GameMainSettingsModel;
  answerLimitTimeLocal = this.customizeSettings.answerLimitTime;
  submitting = false;
  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 3),
    maxLength: (model: string) => StringValidators.maxLength(model, 50),
    maxLength100: (model: string) => StringValidators.maxLength(model, 100),
    maxLength200: (model: string) => StringValidators.maxLength(model, 200)
  };
  setAnswerLimitTime(value: number) {
    this.customizeSettings.answerLimitTime = value;
  }
  @Watch('customizeSettings.answerLimitFlag')
  setLimitless() {
    if (this.customizeSettings.answerLimitFlag === null) {
      this.customizeSettings.answerLimitTime = 0;
    } else {
      this.customizeSettings.answerLimitTime = 15;
      this.answerLimitTimeLocal = 15;
    }
  }
  onUpload(event: any) {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.customizeSettings.logo = reader.result!.toString();
    };
    if (file.size > 5242880) {
      NotificationService.error(translate('Modules.GameInstance.Customize.Menu.FileSizeError'));
      return;
    }
    reader.readAsDataURL(file);
  }
  onClear() {
    this.customizeSettings.logo = require('@/assets/cf-logo.svg');
  }
  openHtmlInput() {
    const element: HTMLElement = this.$refs.htmlInput as HTMLElement;
    element.click();
  }
  get uploadBtnLabel() {
    if (this.customizeSettings.logo == '') {
      return translate('Modules.GameInstance.Customize.Menu.LogoUploadButton');
    } else {
      return translate('Modules.GameInstance.Customize.Menu.LogoChangeButton');
    }
  }
  public expandAll() {
    (this.$refs.menuCustomize as EditCardSection)?.expand();
    (this.$refs.questionCustomize as EditCardSection)?.expand();
    (this.$refs.gameOverCustomize as EditCardSection)?.expand();
  }
}
