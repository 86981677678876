































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import GameApiService from '@/modules/game-instance/services/game-instance-api.service';
import { GameInstanceDeleteInputModel, PaymentBaseData } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { ShareImagesBase64 } from '../models/imageBase64';
import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import { Moment } from 'moment';
import moment from 'moment';
import { values } from 'node_modules/mobx/dist/internal';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { GamePlayRoute } from '@/modules/game-play/game-play-router.enum';
import { StatisticsRoute } from '@/modules/statistics/statistics-router.enum';
@Component({
  components: { ConfirmDeleteModal }
})
export default class GameInstanceListItem extends Vue {
  dialog = false;
  @Prop() gameInstanceId!: string;
  @Prop({ default: 'Financial Operations Analyst' }) gameInstanceTitle!: string;
  @Prop({ default: 'Galaxy Rescue' }) gameTemplateName!: string;
  @Prop({ default: false }) isPublic!: boolean;
  @Prop() Url!: string;
  @Prop() playableFrom!: Moment;
  @Prop() playableTo!: Moment;
  @Prop() validUntil!: Moment;
  @Prop() lastPaymentData!: PaymentBaseData;
  apiService = new GameApiService();
  HTMLEmbed: string | ArrayBuffer | undefined = '';
  loadingEmbed = true;
  public gameInstanceRoute = GameInstanceRoute;
  public gamePlayRoute = GamePlayRoute;
  public statisticsRoute = StatisticsRoute;
  get disableNewOrders(): boolean {
    return this.lastPaymentData != null && this.lastPaymentData.isPaymentActive;
  }

  get newOrderButtonTooltip(): string | null {
    return this.disableNewOrders ? translate('Modules.GameInstance.NewOrderDisabledTooltip') : null;
  }

  get expired() {
    return this.validUntil.isBefore(moment());
  }
  get license() {
    if (this.validUntil && this.validUntil.isAfter(moment())) {
      const result = {
        text: 'Per Game',
        icon: 'mdi-crown',
        color: '#D8A243'
      };
      return result;
    }
    const result = {
      text: 'Basic',
      icon: 'mdi-lock',
      color: 'grey'
    };
    return result;
  }
  get status() {
    if (this.isPublic) {
      const result = {
        text: 'Public',
        icon: 'mdi-earth',
        color: 'blue'
      };
      return result;
    }
    const result = {
      text: 'Non-public',
      icon: 'mdi-earth-off',
      color: 'grey'
    };
    return result;
  }

  async deleteInstance() {
    const result = await this.apiService.deleteGameInstance(
      new GameInstanceDeleteInputModel({
        gameId: this.gameInstanceId
      })
    );
    if (result) {
      this.closeMenus();
      this.$emit('game-instance-deleted', this.gameInstanceId);
      NotificationService.success(translate('Modules.GameInstance.Notifications.Success.GameDeleted'));
    }
  }
  copyToClipboard(value: string) {
    if (value) {
      navigator.clipboard.writeText(value);
      NotificationService.success(translate('Modules.GameInstance.Notifications.Success.CopyLink'));
    } else {
      NotificationService.error(translate('Modules.GameInstance.Notifications.Error.CopyEmpty'));
    }
  }
  async mounted() {
    this.HTMLEmbed += "<a href='" + this.createGameLink();
    this.HTMLEmbed += "'>Apply by playing</a>";

    this.loadingEmbed = false;
  }
  cancelDelete() {
    this.dialog = false;
    this.closeMenus();
  }
  async generateHTMLEmbed() {
    return await this.imageToBase64(ShareImagesBase64.baner1);
  }
  closeMenus() {
    this.dialog = false;
  }
  showDeleteConfirmation() {
    this.dialog = true;
  }
  async embedHTMLCopy() {
    let embed = '';
    embed += "<a href='" + this.createGameLink();
    embed += "'>Apply by playing</a>";
    navigator.clipboard.writeText(embed);
    NotificationService.success(translate('Modules.GameInstance.Notifications.Success.CopyLink'));
  }

  imageToBase64(file: string): Promise<string | ArrayBuffer | null> {
    const data = new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const blob = fetch(file).then(r => {
        r.blob().then(y => {
          const reader = new FileReader();
          reader.readAsDataURL(y);
          reader.onload = () => {
            resolve(reader.result);
          };
        });
      });
    });

    return data;
  }
  copyGameLink() {
    const gameLink = document.location.origin + '/play=' + this.gameInstanceId;
    navigator.clipboard.writeText(gameLink);
    NotificationService.success(translate('Modules.GameInstance.ListItem.LinkCopiedMsg'));
  }
  createGameLink() {
    return document.location.origin + '/play=' + this.gameInstanceId;
  }
}
