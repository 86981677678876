


























import { Component, Vue, Prop } from 'vue-property-decorator';
import AdminGameInstancesApiService from '../services/admin-game-instances-api.service';
import GameInstanceDetails from '@/modules/admin/game-instances/components/GameInstanceDetails.vue';
import GameInstancePaymentData from '@/modules/admin/game-instances/components/GameInstancePaymentData.vue';

import { AdminGameInstanceDto, OrderListItemAdminDataDto } from '@/services/Api';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { AdminUsersRoute } from '../../admin-users-router.enum';
import { store } from '@/store/store';
@Component({
  components: {
    GameInstanceDetails,
    GameInstancePaymentData
  }
})
export default class AdminGameInstanceDetails extends Vue {
  @Prop() readonly gameInstanceId!: string;
  private adminApiService = new AdminGameInstancesApiService();
  private loading = true;
  private gameInstanceData!: AdminGameInstanceDto | undefined;
  private gameInstancePaymentData!: OrderListItemAdminDataDto[] | undefined;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.GameInstancesAdmin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.gameInstances }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        exact: true,
        disabled: true
      }
    ];
  }

  async mounted() {
    const gameInstance: AdminGameInstanceDto | undefined = await this.adminApiService.getGameInstance(
      this.gameInstanceId
    );
    if (gameInstance) {
      this.gameInstanceData = gameInstance;
    }
    const paymentInstance:
      | OrderListItemAdminDataDto[]
      | undefined = await this.adminApiService.getPaymentOrdersForGameInstance(this.gameInstanceId);
    if (paymentInstance) {
      this.gameInstancePaymentData = paymentInstance;
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
}
