import { EnemyVehicleGroup } from './EnemyVehicleGroup';
import { SpawnPoint } from '../../Models/SpawnPoint';
export default class VehicleFactory {
  static spawnPoint: number;
  static arraySpawn: Array<number> = [0, 0, 0, 0];
  static blockSpawn = true;
  static blockLine = 0;
  public static Build(scene: any, name: string, width?: number, heigth?: number): EnemyVehicleGroup {
    scene.registry.events.on('unlockRoad', this.unlockRoad, this);
    scene.registry.events.on('nextLevel', this.nextLevel, this);
    return new EnemyVehicleGroup(scene, name, width, heigth);
  }

  public static CreateVehicle(
    vehicle: EnemyVehicleGroup,
    vehicleSpeed: number,
    objectSpeed: number,
    shouldRotate: boolean,
    lineChoose: number
  ) {
    if (this.arraySpawn != [0, 0, 0, 0]) {
      if (this.arraySpawn[lineChoose] != 0) {
        return;
      }
    }
    let numberFreeLines = 0;
    this.arraySpawn.forEach(val => (val == 1 ? (numberFreeLines += 1) : null));
    if (numberFreeLines == 3) {
      return;
    }
    let spawnPointnew: number;
    let currentSpawn = 0;
    const addToSpawnPoint = Phaser.Math.Between(-20, 20);
    const findInx = this.arraySpawn.findIndex(val => val == 0);
    if (findInx != undefined && findInx != -1) {
      do {
        spawnPointnew = Phaser.Math.Between(0, 100);
        if (spawnPointnew >= 0 && spawnPointnew < 15) {
          currentSpawn = 1;
        } else if (spawnPointnew >= 15 && spawnPointnew < 40) {
          currentSpawn = 2;
        } else if (spawnPointnew >= 40 && spawnPointnew < 75) {
          currentSpawn = 3;
        } else if (spawnPointnew >= 75 && spawnPointnew <= 100) {
          currentSpawn = 4;
        }
      } while (this.arraySpawn[currentSpawn - 1] == 1);
      if (spawnPointnew >= 0 && spawnPointnew < 15) {
        vehicle.createEnemys(SpawnPoint.position1 + addToSpawnPoint, vehicleSpeed, objectSpeed, shouldRotate);
        this.arraySpawn[currentSpawn - 1] = 1;
      } else if (spawnPointnew >= 15 && spawnPointnew < 40) {
        vehicle.createEnemys(SpawnPoint.position2 + addToSpawnPoint, vehicleSpeed, objectSpeed, shouldRotate);
        this.arraySpawn[currentSpawn - 1] = 1;
      } else if (spawnPointnew >= 50 && spawnPointnew < 75) {
        vehicle.createEnemys(SpawnPoint.position3 + addToSpawnPoint, vehicleSpeed, objectSpeed, false);
        this.arraySpawn[currentSpawn - 1] = 1;
      } else if (spawnPointnew >= 75 && spawnPointnew <= 100) {
        vehicle.createEnemys(SpawnPoint.position4 + addToSpawnPoint, vehicleSpeed, objectSpeed, false);
        this.arraySpawn[currentSpawn - 1] = 1;
      }
      this.blockLine++;
    }
  }

  static unlockRoad(line: number, emiter: any) {
    this.arraySpawn[line] = 0;
    emiter.emit('emiterBack');
  }
  static nextLevel() {
    this.arraySpawn = [0, 0, 0, 0];
  }
}
