









import { Component, Vue } from 'vue-property-decorator';
import AdminUserApiService from '../services/admin-user-api.service';
import { AdminUserListItemDto } from '@/services/Api';
import UserListRow from '../components/UserListRow.vue';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { AdminUsersRoute } from '../../admin-users-router.enum';
import { store } from '@/store/store';

@Component({
  components: { UserListRow }
})
export default class AdminUserList extends Vue {
  private apiService = new AdminUserApiService();
  private usersList!: AdminUserListItemDto[];
  private loading = true;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.UserAdmin'),
        exact: true,
        disabled: true
      }
    ];
  }

  async mounted() {
    await this.getUsers();
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  async userDeleted() {
    this.loading = true;
    await this.getUsers();
    this.loading = false;
  }
  async getUsers() {
    const userGetList = await this.apiService.GetUsersList();
    if (userGetList) {
      this.usersList = userGetList;
    }
  }
}
