import { Vue } from 'vue-property-decorator';

export default class FormHelper {
  public static scrollToNextError(view: Vue) {
    view.$nextTick(() => {
      const el = view.$el.querySelector('.v-input.error--text');
      if (el) {
        view.$vuetify.goTo(el as HTMLElement);
      }
      return;
    });
  }
}
