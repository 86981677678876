




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EmptyListContainer extends Vue {
  @Prop() text: string | undefined;
  @Prop() routeName: string | undefined;
}
