import { GameEngineBase } from '../game-engine-base';
import 'phaser';
import { GameScene } from './space-rescue-gameclass';
import { ImagesBase64 } from './imagesbase64';
import { SpaceRescueVariables } from './Global/SpaceRescueVariables';
import { translate } from '@/plugins/i18n';
import { GameCustomizableElement } from '../game-engine-customizable-element';

export class SpaceRescueEngine extends GameEngineBase {
  game!: Phaser.Game;
  private gameConfig!: Phaser.Types.Core.GameConfig;

  initialize(): void {
    this.gameConfig = {
      title: 'Space Rescue',
      width: 800,
      height: 600,
      type: Phaser.CANVAS,
      parent: 'game-window',
      transparent: true,
      backgroundColor: '#000000',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      },
      audio: {
        disableWebAudio: true
      },

      scene: [
        new GameScene(this.askQuestion, this.gameOverDialog, this.saveGameLog, this.getCustomizedElements(), this.theme)
      ]
    };
    this.game = new Phaser.Game(this.gameConfig);
    console.log('initialize SpaceRescueEngine');
  }

  getCustomziableTypes(): GameCustomizableElement[] {
    return [
      /**
     * Create text, number
     * @param {string} name
     * @param {string} value - translate value
     * @param {number} minLength - min lenght
     * @param {number} maxLength - max lenght
     * @param {bool} isCustomByUser - should be custom by user in menu website 
     * @param {number} position - position in menu website 
     */
      GameCustomizableElement.CreateImage(SpaceRescueVariables.shipSpriteName, ImagesBase64.ship, 34, 48,true,7),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.enemySpriteName, ImagesBase64.enemy, 40, 40,true,8),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.starsSpriteName, ImagesBase64.stars, 15, 15,false),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.bulletSpriteName, ImagesBase64.bullet, 20, 15,true,10),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.enemyBulletSpriteName, ImagesBase64.enemyBullet, 30, 20,true,11),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.heartSpriteName, ImagesBase64.heart, 30, 35,true,9),
      GameCustomizableElement.CreateImage(SpaceRescueVariables.enemyBgSpriteName, ImagesBase64.enemyBg, 150, 150,false),
      GameCustomizableElement.CreateText('AnswerTextCorrect', translate('Modules.Runtime.Game.CorrectAnswer'), 2, 20,true,1),
      GameCustomizableElement.CreateText('AnswerTextWrong', translate('Modules.Runtime.Game.WrongAnswer'), 2, 20,true,2),
      GameCustomizableElement.CreateText('StartText', translate('Modules.Runtime.Game.StartGame'), 2, 30,true,5),
      GameCustomizableElement.CreateText('NoMoreQuestions', translate('Modules.Runtime.Game.NoMoreQuestions'), 2, 40,true,4),
      GameCustomizableElement.CreateNumber(SpaceRescueVariables.LivesName, 5, 1, 5,true,3),
      GameCustomizableElement.CreateText('NextLevel', translate('Modules.Runtime.Game.Level'), 2, 20,true,6)
    ];
  }

  //Accept answere, outside class runs it
  acceptAnswer(isCorrectAnswer: boolean, answerPoolEmpty: boolean): void {
    this.game.registry.events.emit('AnswerEvent', isCorrectAnswer, answerPoolEmpty);
  }
}
