import { WallObjectsTilesprite } from './WallObjectsTilesprite';
export class WallObjectsGroup extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene, config: any) {
    super(scene, config);
    this.scene = scene;
  }
  scene!: Phaser.Scene;
  maxLength = 25;
  createTileSprite(speedLine: number, leftGrassLaneWidth: number, numberOfDivisions: number, maxNumberOfHoles: number) {
    if (this.getTotalUsed() + 5 > this.maxLength) {
      return;
    }

    const roadWidth = this.scene.game.canvas.width - (leftGrassLaneWidth * 2);
    const divisionWidth = roadWidth / numberOfDivisions;
    const divisionsArray = this.createArrayOfDivisions(numberOfDivisions);

    this.turnOffRandomDivisions(divisionsArray, maxNumberOfHoles);
    this.createAndActivateWallsInDivisions(divisionsArray, divisionWidth, leftGrassLaneWidth, speedLine);
  }

  private createAndActivateWallsInDivisions(divisionsArray: Division[], divisionWidth: number, leftGrassLaneWidth: number, speedLine: number) {
    divisionsArray.forEach((division, index) => {
      const startPosition = leftGrassLaneWidth + (index * divisionWidth);
      if (division.active) {
        this.add(new WallObjectsTilesprite(this.scene, divisionWidth, '__LINE'))
        const enemy = this.getFirstDead();
        enemy.createWall(startPosition, divisionWidth, speedLine);
        this.scene.physics.add.existing(enemy);
        enemy.body.velocity.x = 0;
      }
    })
  }

  private createArrayOfDivisions(numberOfDivisions: number) {
    const divisions: Division[] = [];
    for (let i=0; i < numberOfDivisions; i++){
      const division: Division = {active: true}
      divisions.push(division)
    }
    return divisions;
  }

  private turnOffRandomDivisions(divisionsArray: Division[], maxNumberOfHoles: number) {
    const divisionsToBeTurnedOff = this.getRandomDivisions(divisionsArray, Phaser.Math.Between(1, maxNumberOfHoles))
    divisionsToBeTurnedOff.forEach(division => {
      division.active = false;
    })
  }

  private getRandomDivisions(divisions: Division[], n: number) {
    const result = new Array(n);
    let len = divisions.length;
    const taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = divisions[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}

interface Division{
  active: boolean,
}
