



































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import PaymentStatusAdminData from '@/modules/admin/users/components/PaymentStatusAdminData.vue';
import { OrderListItemAdminDataDto } from '@/services/Api';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import { store } from '@/store/store';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
@Component({
  components: {
    PaymentStatusAdminData
  }
})
export default class GameInstancePaymentData extends Vue {
  @Prop() gameInstancePaymentData!: OrderListItemAdminDataDto[] | undefined;

  private expanded: [] = [];
  private singleExpand = false;
  private searchPayments = '';
  private tableSize = 10;
  public adminUsersRoute = AdminUsersRoute;
  get headersPayments() {
    return [
      { text: this.$t('Modules.AdminPanel.GameInstanceDetails.Order'), value: 'offerTitle', align: 'start' },
      { text: this.$t('Modules.AdminPanel.GameInstanceDetails.OrderDate'), value: 'createdAt' },
      { text: this.$t('Modules.AdminPanel.GameInstanceDetails.Price'), value: 'finalPrice' },
      { text: this.$t('Modules.AdminPanel.GameInstanceDetails.InvoiceCreated'), value: 'isInvoiceCreated' },
      { text: this.$t('Modules.AdminPanel.GameInstanceDetails.Status'), value: 'status' }
    ];
  }
  async mounted() {
    this.tableSize = store.getTableSize('Admin-game-instances');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-game-instances', this.tableSize);
  }
  customSort(items: any[], index: string[], isDesc: boolean[], locale: any, sorters: any) {
    if (index[0] === 'offerTitle') {
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByString(a.orderNumber ?? '', b.orderNumber ?? '', isDesc[0], locale);
      });
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByString(a.offerTitle ?? '', b.offerTitle ?? '', isDesc[0], locale);
      });
    } else if (index[0] === 'buyeridentifier') {
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByString(
          a.buyerIdentifier ?? '',
          b.buyerIdentifier ?? '',
          isDesc[0],
          locale
        );
      });
    } else if (index[0] === 'createdAt') {
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        console.log('sortPrice');
        return DataTableSortingHelper.compareByDate(a.createdAt, b.createdAt, isDesc[0]);
      });
    } else if (index[0] === 'finalPrice') {
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        console.log('sortPrice');
        const sortA = a.priceInfo ? a.priceInfo.finalPrice : '';
        const sortB = b.priceInfo ? b.priceInfo.finalPrice : '';
        return DataTableSortingHelper.compareByDefault(sortA ?? '', sortB ?? '', isDesc[0]);
      });
    } else if (index[0] === 'status') {
      console.log('sortStatus');
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByDate(a.updatedAt, b.updatedAt, isDesc[0]);
      });
      items.sort((a: OrderListItemAdminDataDto, b: OrderListItemAdminDataDto) => {
        return DataTableSortingHelper.compareByString(a.paymentStatus ?? '', b.paymentStatus ?? '', isDesc[0], locale);
      });
    } else {
      items = DataTableSortingHelper.sortByDefault(items, index, isDesc, locale, sorters);
    }
    return items;
  }
}
