



































































































































import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import AnalyticsGoogleHelper, { AnalyticsEvent, getPurchaseEventParams } from '@/plugins/analytics-google';
import { AnalyticsHelper, FacebookPixelEvent } from '@/plugins/analytics-facebook';
import { i18n, translate } from '@/plugins/i18n';
import { OrderDetailsDto } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PaymentDetail from '../components/PaymentDetail.vue';
import PaymentStatus from '../components/PaymentStatus.vue';
import PaymentsApiService from '../services/payments-api.service';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { PaymentsHistoryRoute } from '../payments-history-router.enum';
import { store } from '@/store/store';

@Component({ components: { PaymentStatus, PaymentDetail, ConfirmDeleteModal } })
export default class PaymentsHistoryDetails extends Vue {
  @Prop() readonly orderId!: string;

  /**
   * paymentStatus is set by router (callback from PayU)
   * * success - payment succeeded
   * * error - payment failed
   * * none - default value
   */
  @Prop({ default: 'none' }) readonly paymentStatus!: 'success' | 'error' | 'none';

  private readonly paymentsApi = new PaymentsApiService();
  private loading = true;
  private order?: OrderDetailsDto;
  private displayCancelOrderModal = false;
  public gameInstanceRoute = GameInstanceRoute;
  public isCancelling = false;
  public isCancelled = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.PaymentsHistory'),
        exact: true,
        disabled: false,
        to: { name: PaymentsHistoryRoute.paymentsHistoryList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        exact: true,
        disabled: true
      }
    ];
  }

  public async mounted(): Promise<void> {
    await this.loadData();
    store.setBreadcrumb(this.breadcrumbData);
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    const order = await this.paymentsApi.getOrder(this.orderId);
    this.order = order;

    if (this.paymentStatus !== 'none' && order != null) {
      if (this.paymentStatus === 'success') {
        AnalyticsGoogleHelper.event(AnalyticsEvent.purchase, getPurchaseEventParams(order));
        // params from: https://developers.facebook.com/docs/facebook-pixel/reference
        AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.purchase, AnalyticsHelper.getPaymentOptions(order));
      } else {
        AnalyticsGoogleHelper.event(AnalyticsEvent.purchaseFailed, getPurchaseEventParams(order));
      }
    }

    this.loading = false;
  }
  public cancelOrder() {
    this.displayCancelOrderModal = true;
  }

  public hideConfirmationModal(): void {
    this.displayCancelOrderModal = false;
  }

  public requestOrderCancellation(): void {
    this.displayCancelOrderModal = false;

    if (this.isCancelling) return;
    this.isCancelling = true;

    this.paymentsApi.cancelPayment(this.order?.orderNumber).then(isSuccess => {
      this.isCancelling = false;

      if (isSuccess) {
        NotificationService.success(translate('Modules.PaymentsHistory.CancelSuccessNotification'));
        this.isCancelled = true;
        this.loadData();
      }
    });
  }

  public async downloadInvoice() {
    let response = null;
    response = await this.paymentsApi.getInvoice(this.orderId);

    this.downloadFile(response?.value as string, response?.fileName as string);
  }

  downloadFile(value?: string, fileName?: string) {
    const linkSource = 'data:application/pdf;base64,' + value;
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  get finalPriceWithVatInfo() {
    const newPrice = this.$options.filters?.toCurrency(this.order?.priceInfo?.netPriceAfterDiscount, 'PLN');
    return `(${newPrice} + 23% VAT)`;
  }
  get discountValueFormatted() {
    return `${this.order?.priceInfo?.discountValue} %`;
  }
  get getOrderTitle() {
    return this.$i18n.locale === 'pl' ? this.order?.titlePl : this.order?.titleEng;
  }
}
