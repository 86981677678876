import { GameTheme } from '@/modules/game-play/models/game-theme';
import { translate } from '@/plugins/i18n';
export class TextController {
  constructor(scene: Phaser.Scene, theme?: GameTheme) {
    this.theme = theme;
    this.scene = scene;
    this.textFont = `${theme?.fontSize ?? '20px'} ${theme?.font ?? 'Play'}`;
    this.textFontStart = `${theme?.fontStartSize ?? '80px'} ${theme?.font ?? 'VT323'}`;
    this.depth = 4;
    this.depthBox = 2;
    this.boxwidth = 500;
    this.boxheight = 400;
    this.enemyDestroyedText = this.scene.add.text(250, 15, '', {
      font: this.textFont,
      color: this.theme?.textColor ?? '#ffffff'
    });
    this.enemyDestroyedText = this.enemyDestroyedText.setShadow(0, 0, this.theme?.textShadowColor ?? '#21ecf3', 4);
    this.levelText = this.scene.add.text(450, 15, '', {
      font: this.textFont,
      color: this.theme?.textColor ?? '#ffffff'
    });
    console.log('levelText', this.theme?.textColor);

    this.levelText = this.levelText.setShadow(0, 0, this.theme?.textShadowColor ?? '#21ecf3', 4);
    this.pointsText = this.scene.add.text(575, 15, '', {
      font: this.textFont,
      color: this.theme?.textColor ?? '#ffffff'
    });
    this.pointsText = this.pointsText.setShadow(0, 0, this.theme?.textShadowColor ?? '#21ecf3', 4);
    this.start = this.scene.add.text(575, 400, '', { font: this.textFont, color: this.theme?.textColor ?? '#ffffff' });
    this.start = this.start.setShadow(0, 0, this.theme?.textShadowColor ?? '#21ecf3', 4);
  }

  theme?: GameTheme;
  textFont: string;
  textFontStart: string;
  scene: Phaser.Scene;
  depth: number;
  depthBox: number;
  pauseText!: Phaser.GameObjects.Text;
  pauseBox!: Phaser.GameObjects.GameObject;
  boxwidth: number;
  boxheight: number;
  enemyDestroyedText: Phaser.GameObjects.Text;
  levelText: Phaser.GameObjects.Text;
  pointsText: Phaser.GameObjects.Text;
  start: Phaser.GameObjects.Text;

  //Run text in center screen and then start physic
  centerText(text: string | undefined, isEnd: boolean) {
    if (text == undefined) {
      return;
    }
    const temp = text;
    const startText = this.scene.add
      .text(this.scene.game.canvas.width / 2, this.scene.game.canvas.height / 2, '', {
        font: this.textFontStart,
        color: this.theme?.announcementColor ?? '#ffffffaa'
      })
      .setOrigin(0.5);
    startText.setText(temp).setDepth(this.depth);
    this.scene.tweens.add({
      targets: startText,
      ease: 'Linear',
      duration: 700,
      alpha: { from: 0, to: 1 },
      y: { from: this.scene.game.canvas.height / 2 + 50, to: this.scene.game.canvas.height / 2 - 50 },
      completeDelay: 20,
      onComplete: () => {
        this.scene.tweens.add({
          targets: startText,
          ease: 'Linear',
          duration: 700,
          alpha: { from: 1, to: 0 },
          y: { from: this.scene.game.canvas.height / 2 - 50, to: this.scene.game.canvas.height / 2 - 150 },
          completeDelay: 20,
          onComplete: () => {

            if (isEnd) {
              this.scene.registry.events.emit('endGameDialog');
            } else {
              startText.destroy();
              this.scene.registry.events.emit('unlockAll');
              this.scene.registry.events.emit('backToOldSpeed');
              this.scene.registry.events.emit('textControllerReplay');
            }
          }
        });
      }
    });
  }

  //Menu Update
  updateTimePoints(pionts: number, level: number, enemyDestroyed: number, maxEnemy: number) {
    this.enemyDestroyedText.setText(translate('Modules.Runtime.Game.Score') + pionts);
    this.levelText.setText(translate('Modules.Runtime.Game.Level') + level);
    this.pointsText.setText(translate('Modules.Runtime.Game.EnemiesKilled') + enemyDestroyed + '/' + maxEnemy);
  }

  //Menu Update
  updateTimeDistance(pionts: number, level: number, distance: number) {
    this.enemyDestroyedText.setText(translate('Modules.Runtime.Game.Score') + pionts).depth = 10;
    this.levelText.setText(translate('Modules.Runtime.Game.Level') + level).depth = 10;
    const kilometers: number = Math.round(distance * 10) / 10;
    this.pointsText.setText(translate('Modules.Runtime.Game.Distance') + ' ' + kilometers + ' km').depth = 10;
  }
}
