import { adminUsersRoutes } from '@/modules/admin/admin-users-router';
import { gameInstanceRoutes } from '@/modules/game-instance/game-instance-router';
import { gamePlayRoutes } from '@/modules/game-play/game-play-router';
import { paymentsHistoryRoutes } from '@/modules/payments-history/payments-history-routes';
import { professionsRoutes } from '@/modules/professions/professions-router';
import { statisticRoutes } from '@/modules/statistics/statistic-router';
import { userManagementRoutes } from '@/modules/user-management/user-management-router';
import { kitchenSinkRoutes } from '@/modules/kitchen-sink/kitchen-sink-router';
import { store } from '@/store/store';
import { Route } from '@/router/index.enum';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NotFoundPage from '@/modules/layout/components/NotFoundPage.vue';
import DownloadEbook from '@/modules/ebook-download/views/DownloadEbook.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...gameInstanceRoutes,
  ...userManagementRoutes,
  ...gamePlayRoutes,
  ...statisticRoutes,
  ...professionsRoutes,
  ...paymentsHistoryRoutes,
  ...adminUsersRoutes,
  ...kitchenSinkRoutes,
  {
    path: '/ebook=:id',
    name: Route.downloadEbook,
    component: DownloadEbook,
    props: true,
    meta: {
      allowAnonymous: true,
      technicalView: true
    }
  },
  {
    path: '/',
    redirect: '/game-instances'
  },
  {
    path: '/internal',
    redirect: {
      name: GameInstanceRoute.gameInstanceList,
      replace: true
    }
  },
  {
    path: '/notFoundPage',
    name: Route.notFound,
    component: NotFoundPage,
    meta: { allowAnonymous: true }
  },
  {
    path: '*',
    redirect: {
      name: GameInstanceRoute.gameInstanceList,
      replace: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      selector: '#scroll-anchor',
      behavior: 'smooth'
    };
  }
});

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.meta?.allowAnonymous;

  if (to.redirectedFrom === '/internal') {
    store.setInternalTrafficFlag();
  }

  if (allowAnonymous) {
    next();
  } else if (store.user) {
    next();
  } else {
    next({ name: UserManagementRoute.login });
  }
});

export default router;
