import { GameInstanceDto, PaymentBaseData } from '@/services/Api';
import moment, { Moment } from 'moment';

export class GameInstanceModel {
  public gameTitle!: string;
  public validUntil!: string;
  public isPremium!: boolean;
  public isPublic!: boolean;
  public playableFrom?: Moment;
  public localPlayableFrom?: Date;
  public isPlayableFrom!: boolean;
  public playableTo?: Moment;
  public localPlayableTo?: Date;
  public isPlayableTo!: boolean;
  public assignedProfessions!: string[];
  public gameInstanceId!: string;
  public gameMainSettings: string;
  public lastPaymentData?: PaymentBaseData | null;

  constructor(model: GameInstanceDto) {
    this.gameInstanceId = model.id;
    this.gameTitle = model.title as string;
    this.validUntil = model.validUntil ? model.validUntil.local().format('YYYY-MM-DD') : '';
    this.isPublic = model.isPublic;

    this.isPlayableFrom = model.playableFrom ? true : false;
    this.isPlayableTo = model.playableTo ? true : false;

    if (this.isPlayableTo) {
      this.playableTo = model.playableTo as Moment;
      this.localPlayableTo = moment(model.playableTo)
        .local()
        .toDate();
    }
    if (this.isPlayableFrom) {
      this.playableFrom = model.playableFrom as Moment;
      this.localPlayableFrom = moment(model.playableFrom)
        .local()
        .toDate();
    }
    this.assignedProfessions = model.assignedProfessions ?? [];

    if (this.validUntil && moment(this.validUntil).isAfter(moment())) {
      this.isPremium = true;
    } else {
      this.isPremium = false;
    }
    this.gameMainSettings = model.settings as string;

    this.lastPaymentData = model.lastPaymentBaseData;
  }

  public convertTime() {
    if (this.localPlayableFrom && moment(this.localPlayableFrom).isValid()) {
      this.playableFrom = moment(this.localPlayableFrom);
    } else {
      this.playableFrom = undefined;
    }
    if (this.localPlayableTo && moment(this.localPlayableTo).isValid()) {
      this.playableTo = moment(this.localPlayableTo);
    } else {
      this.playableTo = undefined;
    }
  }
}
