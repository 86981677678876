























































import StringValidators from '@/modules/layout/validators/string-validators';
import EditableQuestionList from '@/modules/professions/components/EditableQuestionList.vue';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n, translate } from '@/plugins/i18n';
import { ProfessionUpdateInputModel } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Vue } from 'vue-property-decorator';
import { VForm } from '../../layout/components/forms/v-form';
import ProfessionApiService from '../services/profession-api.service';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { PaymentsHistoryRoute } from '@/modules/payments-history/payments-history-router.enum';
import { store } from '@/store/store';

@Component({
  components: { EditableQuestionList }
})
export default class ProfessionDetails extends Vue {
  rules = {
    required: StringValidators.required,
    maxLength: (model: string) => StringValidators.maxLength(model, 50)
  };

  public name = '';
  public displayName = '';
  public isPredefined = true;

  private readonly apiService = new ProfessionApiService();
  private loading = true;
  private professionId!: string;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ProfessionList'),
        exact: true,
        disabled: false,
        to: { name: ProfessionsRoute }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        exact: true,
        disabled: true
      }
    ];
  }

  private async mounted() {
    this.loading = true;
    this.professionId = this.$route.params.id;

    const professionResult = await this.apiService.getProfessionById(this.professionId);

    if (professionResult) {
      const professionDetails = professionResult;

      this.name = professionDetails.title as NonNullable<string>;
      this.displayName = professionDetails.displayTitle as NonNullable<string>;
      this.isPredefined = professionDetails.isGlobal as NonNullable<boolean>;
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }

  private async save() {
    const form = this.$refs.form as VForm;

    if (!form.validate()) {
      FormHelper.scrollToNextError(this);
      return;
    }

    this.loading = true;

    const inputModel = new ProfessionUpdateInputModel({
      name: this.name,
      displayName: this.displayName,
      professionId: this.professionId
    });

    const result = await this.apiService.updateProfession(inputModel);

    if (result) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.updateProfession, {
        profession_id: inputModel.professionId,
        profession_name: inputModel.name
      });

      await this.$router.push({ name: ProfessionsRoute.professionList });
      NotificationService.success(translate('Modules.Professions.Notifications.ProfessionUpdated'));
    }

    this.loading = false;
  }

  private cancel() {
    this.$router.push({ name: ProfessionsRoute.professionList });
  }
}
