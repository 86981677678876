




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ListMenuPreview extends Vue {
  disabled = false;
}
