import { GameEngineBase } from '../game-engine-base';
import 'phaser';
import { GameScene } from './maze-game-gameclass';
import { translate } from '@/plugins/i18n';
import { GameCustomizableElement } from '../game-engine-customizable-element';
import { ImagesBase64 } from './imagesbase64';

import { MazeGameVariables } from './Global/MazeGameVariables';

export class MazeGameEngine extends GameEngineBase {
  game!: Phaser.Game;
  private gameConfig!: Phaser.Types.Core.GameConfig;

  initialize(): void {
    this.gameConfig = {
      title: 'Maze Game',
      width: 800,
      height: 600,
      type: Phaser.CANVAS,
      parent: 'game-window',
      transparent: true,
      backgroundColor: '#000000',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      },
      audio: {
        disableWebAudio: true
      },

      scene: [new GameScene(this.askQuestion, this.gameOverDialog, this.saveGameLog, this.getCustomizedElements())]
    };
    this.game = new Phaser.Game(this.gameConfig);
    console.log('initialize riverrungame');
  }

  getCustomziableTypes(): GameCustomizableElement[] {
    return [
      GameCustomizableElement.CreateText('AnswerTextCorrect', translate('Modules.Runtime.Game.CorrectAnswer'), 2, 512),
      GameCustomizableElement.CreateText('AnswerTextWrong', translate('Modules.Runtime.Game.WrongAnswer'), 2, 512),
      GameCustomizableElement.CreateText('StartText', translate('Modules.Runtime.Game.StartGame'), 2, 512),
      GameCustomizableElement.CreateNumber('LivesNumber', 5, 2, 5),
      GameCustomizableElement.CreateImage(MazeGameVariables.playerSpriteName, ImagesBase64.player, 20, 20),
      GameCustomizableElement.CreateImage(MazeGameVariables.heartSpriteName, ImagesBase64.heart, 30, 25),
      GameCustomizableElement.CreateImage(MazeGameVariables.line, ImagesBase64.line, 20, 20),
      GameCustomizableElement.CreateImage(MazeGameVariables.roadSpriteName, ImagesBase64.road, 30, 25),
      GameCustomizableElement.CreateImage(MazeGameVariables.BgBackSpriteName, ImagesBase64.BgBack, 30, 25)
    ];
  }

  acceptAnswer(isCorrectAnswer: boolean, answerPoolEmpty: boolean): void {
    this.game.registry.events.emit('AnswerEvent', isCorrectAnswer, answerPoolEmpty);
  }
}
