




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditCardMainPreview extends Vue {
  title = this.$t(`KitchenSink.Previews.EditCardMainPreview.Title`);
}
