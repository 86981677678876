



import { Component, Vue } from 'vue-property-decorator';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import EbookApiService from "@/modules/ebook-download/services/ebook-api.service";

@Component
export default class DownloadEbook extends Vue {
  public linkId = this.$route.params.id;
  public loading = true;
  private service = new EbookApiService();

  async activate() {
    this.loading = true;
    console.log(this.linkId);

    const result = await this.service.downloadEbook(this.linkId);
    if (result) {
      NotificationService.success(translate('Modules.Ebook.DownloadEbookSuccess'));
      this.downloadBlob(result?.data, result?.fileName)
    } else {
      NotificationService.error(translate('Modules.Ebook.DownloadEbookError'));
    }
    this.loading = false;
  }
  
  downloadBlob(blob?: Blob, filename?: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ?? 'ebook.pdf';
    link.type = 'application/pdf';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  mounted() {
    this.activate();
  }
}
