



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AdminGameInstanceDto } from '@/services/Api';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';

@Component
export default class GameInstanceDetails extends Vue {
  @Prop() gameInstanceDetailsData!: AdminGameInstanceDto;

  public adminUsersRoute = AdminUsersRoute;
}
