import { OrderDetailsDto, OrderListItemDto, PaymentsClient } from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class PaymentsApiService extends ApiServiceBase {
  private client = new PaymentsClient();

  public async getOrders(): Promise<OrderListItemDto[] | undefined> {
    try {
      const response = await this.client.getOrders();
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getOrder(orderId: string): Promise<OrderDetailsDto | undefined> {
    try {
      const response = await this.client.getOrder(orderId);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async cancelPayment(orderNumber: string | null | undefined): Promise<boolean> {
    try {
      await this.client.cancelPayment(orderNumber);
      return true;
    } catch (error) {
      this.HandleError(error);
    }

    return false;
  }

  public async getInvoice(id: string) {
    try {
      const response = await this.client.getInvoice(id);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
