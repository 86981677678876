




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EditCardSection extends Vue {
  @Prop({ default: '' }) title!: string;
  @Prop({ type: Boolean, default: true }) expanded!: boolean;
  @Prop({ type: Boolean, default: false }) collapsible!: boolean;
  show = true;

  mounted() {
    this.show = this.expanded;
  }

  public expand(){
    this.show = true;
  }
}
