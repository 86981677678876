








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class KitchenSinkHtml extends Vue {
  @Prop() html!: string;
  htmltemplate = '<template> \n' + this.html + '\n</template>';
}
