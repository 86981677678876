














































import UserApiService from '@/modules/statistics/services/statistic-api.service';
import {
  InstancePlayerGameStatisticsDto,
  InstancePlayerStatisticsDto,
  PlayerProfileDto,
  PlayerProfileUpdateInputModel
} from '@/services/Api';
import { InstanceGlobalStatisticsForPlayerDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import StatisticsPlayerTable from '../components/StatisticsPlayerTable.vue';
import StatisticsPlayerGamesTable from '../components/StatisticsPlayerGamesTable.vue';
import StatisticsPlayerOverview from '../components/StatisticsPlayerOverview.vue';
import { StatisticsRoute } from '@/modules/statistics/statistics-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { store } from '@/store/store';
import { i18n } from '@/plugins/i18n';

@Component({
  components: {
    StatisticsPlayerTable,
    StatisticsPlayerGamesTable,
    StatisticsPlayerOverview
  }
})
export default class StatisticsPlayer extends Vue {
  apiService = new UserApiService();
  @Prop() readonly gameInstanceId!: string;
  @Prop() readonly playerEmail!: string;
  @Prop() readonly playerProfession!: string;
  private globalPlayerInfo!: InstanceGlobalStatisticsForPlayerDto;
  private playerGamesListInfos!: InstancePlayerGameStatisticsDto[];
  private playerListInfos!: InstancePlayerStatisticsDto[];
  private playerProfile: PlayerProfileDto = new PlayerProfileDto();
  private loading = true;
  private loadingCVButton = false;
  private exporting = false;

  get profileUpdateModel(): PlayerProfileUpdateInputModel {
    return new PlayerProfileUpdateInputModel({
      gameInstanceId: this.gameInstanceId,
      email: this.playerProfile.email ?? '',
      rating: this.playerProfile.rating,
      isFavorite: this.playerProfile.isFavorite,
      notes: this.playerProfile.notes
    });
  }

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        exact: true,
        to: { name: GameInstanceRoute.gameInstanceList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Statistics', { gameName: this.playerProfile.gameInstanceTitle }),
        exact: true,
        to: { name: StatisticsRoute.statisticsMain, params: { gameInstanceId: this.gameInstanceId } }
      },
      { text: i18n.t('Layout.Toolbar.Routes.PlayerStatistics', { playerEmail: this.playerEmail }), disabled: true }
    ];
  }

  gameStatistics(instanceId: string) {
    this.$router.push({
      name: StatisticsRoute.statisticsGame,
      params: {
        gameInstanceId: this.gameInstanceId,
        instanceId: instanceId
      }
    });
  }
  back() {
    this.$router.push({
      name: StatisticsRoute.statisticsMain,
      params: {
        gameInstanceId: this.gameInstanceId
      }
    });
  }
  async mounted() {
    this.loading = true;

    const GamesListInfos = await this.apiService.getInstanceGameStatisticsByPlayer(
      this.gameInstanceId,
      this.playerEmail,
      this.playerProfession
    );
    if (GamesListInfos) {
      this.playerGamesListInfos = GamesListInfos;
    }
    const playersStats = await this.apiService.getInstancePlayerStatistics(this.gameInstanceId, this.playerEmail);
    if (playersStats) {
      this.playerListInfos = playersStats;
    }
    const globalInfos = await this.apiService.getInstanceGlobalStatisticsForPlayer(
      this.gameInstanceId,
      this.playerEmail
    );
    if (globalInfos) {
      this.globalPlayerInfo = globalInfos;
    }
    const playerProfile = await this.apiService.getPlayerProfile(this.playerEmail, this.gameInstanceId);
    if (playerProfile) {
      this.playerProfile = playerProfile;
    }
    store.setBreadcrumb(this.breadcrumbData);

    this.loading = false;
  }
  async exportCsvGame() {
    this.exporting = true;
    const timeDifference = new Date().getTimezoneOffset();
    const result = await this.apiService.getInstanceGameStatisticsCSVByPlayer(
      this.gameInstanceId,
      this.playerProfession,
      this.playerEmail,
      -timeDifference
    );
    this.downloadBlob(result?.data, result?.fileName);
    this.exporting = false;
  }
  async exportCsvPlayer() {
    this.exporting = true;
    const result = await this.apiService.getInstancePlayerStatisticsCSV(this.gameInstanceId, this.playerEmail);
    this.downloadBlob(result?.data, result?.fileName);
    this.exporting = false;
  }

  downloadBlob(blob?: Blob, filename?: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ?? 'export.csv';
    link.type = 'text/csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async downloadCVGame(instanceId: string, playerProfession: string) {
    this.loadingCVButton = true;
    const data = await this.apiService.getInstanceGameCV(this.gameInstanceId, instanceId);
    if (data) {
      if (data.cv) {
        const win = window.open();
        win?.document.write(
          '<iframe src="' +
            data.cv +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
        );
      }
    }
    this.loadingCVButton = false;
  }
  async downloadCV(playerEmail: string) {
    this.loadingCVButton = true;
    const data = await this.apiService.getInstancePlayersCV(this.gameInstanceId, playerEmail);
    if (data) {
      if (data.cv) {
        const win = window.open();
        win?.document.write(
          '<iframe src="' +
            data.cv +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
        );
      }
    }
    this.loadingCVButton = false;
  }

  async downloadLatestCV(playerEmail: string) {
    this.loadingCVButton = true;
    const data = await this.apiService.getInstancePlayersCV(this.gameInstanceId, playerEmail);
    if (data) {
      if (data.cv) {
        const win = window.open();
        win?.document.write(
          '<iframe src="' +
            data.cv +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
        );
      }
    }
    this.loadingCVButton = false;
  }

  async updateRating(newRating: number) {
    this.playerProfile.rating = newRating;
    const updatedPlayerProfile = await this.apiService.UpdatePlayerProfile(this.profileUpdateModel);
    if (updatedPlayerProfile) {
      this.playerProfile = updatedPlayerProfile;
    }
  }

  async updateIsFavorite() {
    this.playerProfile.isFavorite = !this.playerProfile.isFavorite;
    const updatedPlayerProfile = await this.apiService.UpdatePlayerProfile(this.profileUpdateModel);
    if (updatedPlayerProfile) {
      this.playerProfile = updatedPlayerProfile;
    }
  }

  async updateNotes(notes: string) {
    this.playerProfile.notes = notes;
    const updatedPlayerProfile = await this.apiService.UpdatePlayerProfile(this.profileUpdateModel);
    if (updatedPlayerProfile) {
      this.playerProfile = updatedPlayerProfile;
    }
  }
}
