import { ConsentBannerViewState } from '@/modules/layout/components/cookies-consent/cookies-consent.model';
import { i18n } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { AuthenticateResponse, PlayerInstanceInputModel, UserDto } from '@/services/Api';
import CookieService from '@/services/cookie-service';
import { action, autorun, computed, extendObservable, observable } from 'mobx';
import StoreTables from './Models/storetables';
import BreadcrumbData from './Models/breadcrumb-data';

export default class Store {
  private storeVersion = 'v1';
  private storeLoaded = false;

  constructor() {
    autorun(async () => {
      if (!this.storeLoaded) {
        const existingStore = localStorage.getItem('store');

        if (existingStore) {
          const parsedStore = JSON.parse(existingStore) as Store;
          this.loadCookieData(parsedStore);
          parsedStore.storeLoaded = false;

          if (parsedStore.storeVersion !== this.storeVersion) {
            console.info('invalid store version, resetting consent');
            parsedStore.consentBannerViewState = undefined;
            parsedStore.storeVersion = this.storeVersion;
          }

          extendObservable(this, parsedStore);
        } else {
          this.loadCookieData(this);
        }

        if (!this.consentBannerViewState) {
          this.consentBannerViewState = 'generalConsent';
        }

        this.storeLoaded = true;
      }

      this.saveState();
    });
  }

  loadCookieData(storeModel: Store) {
    const cookie = CookieService.getCookie();
    if (cookie) {
      storeModel.language = cookie.languageCode;
      // always override these values on init
      storeModel.isInternalTraffic = cookie?.internalTraffic === true ? true : undefined;
      storeModel.googleAnalyticsAccepted = cookie?.googleAnalyticsAccepted === true;
      storeModel.facebookPixelAccepted = cookie?.facebookPixelAccepted === true;
      storeModel.consentBannerViewState = cookie?.wasCookieBannerShown === true ? 'hidden' : 'generalConsent';
    }
  }

  saveState() {
    const serialized = JSON.stringify(this);
    localStorage.setItem('store', serialized);
  }

  @action setPlayerInstance(model: PlayerInstanceInputModel) {
    this.playerInputModelGameInstance = model;
    this.saveState();
  }
  @computed get getPlayerInstance() {
    return this.playerInputModelGameInstance;
  }

  @action setTables(idTable: string, value: number) {
    this.tables = this.tables.filter(e => e.idTable != idTable);
    this.tables.push(new StoreTables(idTable, value));
    this.saveState();
  }

  @computed getTableSize(idTable: string) {
    const value = this.tables.find(x => x.idTable == idTable)?.value;
    return value ? value : this.defaultTableSize;
  }
  @observable sizeCheckEnabled = true;
  @observable sideMenuOpen = true;
  @observable defaultTableSize = 5;
  @observable user?: UserDto = undefined;
  @observable token?: string = undefined;
  @observable rememberedEmail?: string = undefined;
  @observable intersectedComponent?: string = undefined;
  @observable appVersion?: string = undefined;
  @observable language?: string = undefined;
  @observable isInternalTraffic?: boolean = undefined;
  @observable googleAnalyticsAccepted?: boolean = undefined;
  @observable facebookPixelAccepted?: boolean = undefined;
  @observable consentBannerViewState?: ConsentBannerViewState = undefined;
  @observable playerInputModelGameInstance?: PlayerInstanceInputModel = undefined;
  @observable tables: StoreTables[] = [];
  @observable PPUrl?: string = undefined;
  @observable breadcrumbData?: () => BreadcrumbData[] = undefined;

  @computed get googleAnalyticsEnabled(): boolean {
    if (this.isInternalTraffic === true) return false;
    return this.googleAnalyticsAccepted === true;
  }

  @computed get facebookPixelEnabled(): boolean {
    if (this.isInternalTraffic === true) return false;
    return this.facebookPixelAccepted === true;
  }

  @computed get consentBannerState() {
    return this.consentBannerViewState;
  }

  @computed get isMenuOpen() {
    return this.sideMenuOpen;
  }
  @computed get isSizeChekcEnabled() {
    return this.sizeCheckEnabled;
  }

  @computed get isAdmin() {
    return this.user?.isAdmin ?? false;
  }

  @computed get isLoggedIn(): boolean {
    if (this.user && this.token) {
      return true;
    }
    return false;
  }

  @computed isIntersected(id: string): boolean {
    if (this.intersectedComponent === id) {
      return true;
    }
    return false;
  }
  @computed isStoreLoaded(): boolean {
    return this.storeLoaded;
  }

  @computed getBreadcrumb(): (() => BreadcrumbData[]) | undefined {
    return this.breadcrumbData;
  }

  @action setConsentBannerState(state: ConsentBannerViewState) {
    this.consentBannerViewState = state;
    this.saveState();
  }

  @action toggleSideMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;
    this.saveState();
  }

  @action logInUser(response: AuthenticateResponse) {
    this.user = response.user as UserDto;
    this.token = response.token as string;
    this.saveState();
  }

  @action logOutUser() {
    this.user = undefined;
    this.token = undefined;
    this.saveState();
  }

  @action rememberEmail(email: string) {
    this.rememberedEmail = email;
    this.saveState();
  }

  @action setIntersected(id: string) {
    this.intersectedComponent = id;
  }

  @action setAppVersion(appVersion: string | undefined | null) {
    this.appVersion = appVersion == null ? undefined : appVersion;
    this.saveState();
  }

  @action setPPUrl(input: string | undefined | null) {
    this.PPUrl = input == null ? undefined : input;
    this.saveState();
  }

  @computed get getLanguageCode() {
    return this.language;
  }

  @action setLanguage(code: string) {
    this.language = code;
    this.saveState();
    i18n.locale = code;
    vuetify.framework.lang.current = code;
    CookieService.setCookieProperty('languageCode', code);
  }

  @action setGoogleAnalyticsAcceptedFlag(accepted: boolean): void {
    this.googleAnalyticsAccepted = accepted;
    this.saveState();
    CookieService.setCookieProperty('googleAnalyticsAccepted', accepted);
  }
  @action setFacebookPixelAcceptedFlag(accepted: boolean): void {
    this.facebookPixelAccepted = accepted;
    this.saveState();
    CookieService.setCookieProperty('facebookPixelAccepted', accepted);
  }

  @action setInternalTrafficFlag(): void {
    this.isInternalTraffic = true;
    this.saveState();
    CookieService.setCookieProperty('internalTraffic', true);
  }

  @action setBreadcrumb(breadcrumbData: () => BreadcrumbData[]) {
    this.breadcrumbData = breadcrumbData;
    this.saveState();
  }
}
export const store = new Store();
