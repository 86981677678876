import { i18n } from '@/plugins/i18n';
import '@/plugins/layout';
import '@/plugins/v-tooltip.css';
import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import sanitizeHTML from 'sanitize-html';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import Notifications from 'vue-notification';
import VueProgressBar from 'vue-progressbar';
import App from './App.vue';
import filters from './plugins/filters';
import vuetify from './plugins/vuetify';
import router from './router';
import VueScrollTo from 'vue-scrollto';
import hljs from 'highlight.js';
import 'highlight.js/styles/a11y-light.css';

Vue.use(hljs.vuePlugin);

Vue.use(VueScrollTo);

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(VueProgressBar, {
  color: '#f16744',
  failedColor: 'red',
  height: '3px'
});
Vue.use(VTooltip);
Vue.use(filters);

// analytics are bootstrapped in the AppInitialzier.vue component
Vue.use(VueGtag, { bootstrap: false }, router);

// html-sanitizer: https://github.com/vuejs/vue/issues/6333#issuecomment-421262712
Vue.prototype.$sanitize = sanitizeHTML;

export const app = new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
