








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class KitchenSinkStyle extends Vue {
  @Prop() styles!: string;
  stylestemplate = '<style>\n' + this.styles + '\n<' + '/style>';
}
