var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Modules.AdminPanel.UserList.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"custom-filter":_vm.customFilter,"items-per-page":_vm.tableSize},on:{"update:itemsPerPage":function($event){_vm.tableSize=$event},"update:items-per-page":function($event){_vm.tableSize=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_c('table-item-link',{attrs:{"value":row.item.code,"routeTo":{ name: _vm.adminUsersRoute.discountDetails, params: { id: row.item.id } }}})],1),_c('td',[_vm._v(" "+_vm._s(row.item.email)+" ")]),_c('td',[_vm._v(_vm._s(row.item.discount)+" %")]),_c('td',[_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(row.item.isActive),callback:function ($$v) {_vm.$set(row.item, "isActive", $$v)},expression:"row.item.isActive"}})],1),_c('td',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"dense":"","justify":"space-around","align":"center"}},[_c('list-menu',[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                      name: _vm.adminUsersRoute.discountEdit,
                      params: {
                        id: row.item.id
                      }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.AdminPanel.UserList.Edit')))])],1),_c('v-list-item',{attrs:{"to":{
                      name: _vm.adminUsersRoute.discountDetails,
                      params: {
                        id: row.item.id
                      }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-text")])],1),_c('v-list-item-title',[_vm._v(_vm._s('Details'))])],1)],1)],1)],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }