













































































































































































































import {
  addressValidationRules,
  invoiceRecipientValidationRules,
  OrderDetails
} from '@/modules/game-instance/models/create-order.model';
import { StringUtils } from '@/modules/layout/utils/string-utils';
import AnalyticsGoogleHelper, {
  AnalyticsEvent,
  getAnalyticsEventParams,
  getAnalyticsEventParamsBase
} from '@/plugins/analytics-google';
import { AnalyticsHelper, FacebookPixelEvent } from '@/plugins/analytics-facebook';
import { i18n, translate } from '@/plugins/i18n';
import {
  CreateEmptyPurchaseInputModel,
  Currency,
  GameInstanceRecipientDataInputModel,
  InvoiceType
} from '@/services/Api';
import { CountriesProvider } from '@/services/countries.provider';
import { Component, Vue, Watch } from 'vue-property-decorator';
import GameApiService from '../services/game-instance-api.service';
import OrderSummary from '@/modules/game-instance/components/OrderSummary.vue';
import StringValidators from '@/modules/layout/validators/string-validators';
import { validatePolish } from 'validate-polish-ext';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { GameInstanceRoute } from '../game-instance-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

type VForm = Vue & { validate: () => boolean };

@Component({ components: { OrderSummary } })
export default class GameInstanceCreateOrder extends Vue {
  public readonly validationRules = invoiceRecipientValidationRules;
  public readonly addressRules = addressValidationRules;
  public readonly rules = {
    postCodePl: (value: string) => StringValidators.zipCodePl(value)
  };
  public nipValidationRules: any[] = [
    StringValidators.required,
    (value: string) =>
      validatePolish.nip(value) ? true : i18n.t('Modules.GameInstance.Order.Buyer.InvalidNipNumber').toString()
  ];

  private paymentsApi: GameApiService = new GameApiService();
  public readonly stringUtils = StringUtils;

  public countriesProvider = CountriesProvider;
  public countries = CountriesProvider.countries;

  public orderDetails: OrderDetails | null = null;
  public isLoadingData = true;
  public summaryLoading = false;

  public discountCode = 'INGAME2024PROMO';
  public isVerifyingDiscount = false;

  public displaySummary = false;

  public planType = 'PER GAME';
  public currencies = Object.keys(Currency).filter(x => x != 'PLN');

  public vatInfo = '';
  public vatInfoLabel = '';
  public finalPriceWithVatInfo = '';

  public termsAndConditionChecked = false;
  public privacyPolicyChecked = false;

  public orderNumber = '';

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        exact: true,
        disabled: false,
        to: { name: GameInstanceRoute.gameInstanceList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Order'),
        exact: true,
        disabled: true
      }
    ];
  }

  public async mounted(): Promise<void> {
    const gameInstanceId = this.$route.params['gameInstanceId'];

    if (StringUtils.isNullOrWhiteSpace(gameInstanceId)) {
      // using replace instead of push, to remove the current page (create order page, without gameInstanceId param) from the history stack
      this.$router.replace({ name: GameInstanceRoute.gameInstanceList });
      return;
    }

    const createEmptyPurchaseInputModel = new CreateEmptyPurchaseInputModel();
    createEmptyPurchaseInputModel.gameInstanceId = gameInstanceId;

    const result = await this.paymentsApi.createEmptyPurchase(createEmptyPurchaseInputModel);
    this.orderNumber = result?.purchaseNumber as string;

    store.setBreadcrumb(this.breadcrumbData);

    // todo: PLN and PL should come from the default form values
    this.paymentsApi.getInitialOrderData(gameInstanceId, this.countries[0].code).then(result => {
      this.isLoadingData = false;

      if (result == null) {
        // using replace instead of push, to remove the current page (create order page, without gameInstanceId param) from the history stack
        this.$router.replace({ name: GameInstanceRoute.gameInstanceList });
        return;
      }

      this.orderDetails = new OrderDetails(gameInstanceId, result);
      this.sendSelectItemAnalyticsEvent();
    });
  }

  public get serviceName() {
    const serviceName = i18n.t('Modules.GameInstance.Order.Service.ServiceName', {
      validUntilDate: Vue.filter('formatDate')(this.orderDetails?.newValidUntil)
    });
    return serviceName;
  }

  public get country(): string | null {
    if (this.orderDetails?.invoiceRecipientFormData.address.countryCode == null) return null;

    const viewModel = this.countriesProvider.getCountry(this.orderDetails.invoiceRecipientFormData.address.countryCode);
    return viewModel != null ? viewModel.name : null;
  }

  get currency() {
    return this.orderDetails?.priceInfo.currency;
  }

  get discountValueFormatted() {
    return `${this.orderDetails?.priceInfo?.discountValue} %`;
  }

  get countryCodeForVatNumber() {
    const code = this.orderDetails?.invoiceRecipientFormData.address.countryCode;
    return code == 'GR' ? 'EL' : code;
  }

  @Watch('orderDetails.invoiceRecipientFormData.address.countryCode')
  public onCountryChange(value: string) {
    if (this.orderDetails?.priceInfo) {
      this.orderDetails.priceInfo.currency = value == 'PL' ? Currency.PLN : Currency.EUR;
    }
    this.verifyDiscountCode();
  }
  @Watch('orderDetails.priceInfo.currency')
  public onCurrencyChange() {
    this.verifyDiscountCode();
  }

  public async updatePriceAndVatInfo() {
    const response = await this.paymentsApi.getPriceInfo(
      this.orderDetails?.priceInfo.netPrice as number,
      this.orderDetails?.invoiceRecipientFormData.address.countryCode as string,
      this.orderDetails?.priceInfo.currency as Currency,
      this.orderDetails?.priceInfo.discountValue as number
    );
    if (this.orderDetails?.priceInfo) {
      this.orderDetails.priceInfo.netPrice = response?.priceInfo?.netPrice as number;
      this.orderDetails.priceInfo.netPriceAfterDiscount = response?.priceInfo?.netPriceAfterDiscount as number;
      this.orderDetails.priceInfo.vatAmount = response?.priceInfo?.vatAmount as number;
      this.orderDetails.priceInfo.finalPrice = response?.priceInfo?.finalPrice as number;
      this.orderDetails.priceInfo.invoiceType = response?.priceInfo?.invoiceType as InvoiceType;
      this.updateVatInfo(this.orderDetails.priceInfo.invoiceType, this.orderDetails.priceInfo.vatAmount);
    }
  }

  public updateVatInfo(invoiceType: InvoiceType, vatAmount: number) {
    let newPrice: number;
    switch (invoiceType) {
      case InvoiceType.Polish:
        this.vatInfoLabel = 'Modules.GameInstance.Order.Billing.VAT23Label';
        this.vatInfo = this.$options.filters?.toCurrency(vatAmount, 'PLN');
        newPrice = this.$options.filters?.toCurrency(this.orderDetails?.priceInfo.netPriceAfterDiscount, 'PLN');
        this.finalPriceWithVatInfo = ` (${newPrice} + 23% VAT)`;
        break;

      default:
        this.vatInfoLabel = 'Modules.GameInstance.Order.Billing.VatLabel';
        this.vatInfo = 'Modules.GameInstance.Order.Billing.VatInfo';
        this.finalPriceWithVatInfo = '';
        break;
    }
  }

  public verifyDiscountCode(): void {
    if (StringUtils.isNullOrWhiteSpace(this.discountCode)) {
      if (this.orderDetails) {
        this.orderDetails.discountCode = null;
        this.orderDetails.priceInfo.discountValue = null;
        this.orderDetails.isDiscountSet = false;
        this.updatePriceAndVatInfo();
        return;
      }
    }

    this.isVerifyingDiscount = true;
    this.paymentsApi
      .verifyDiscountCode(
        this.discountCode,
        this.orderDetails?.priceInfo.currency as Currency,
        this.orderDetails?.invoiceRecipientFormData.address.countryCode as string
      )
      .then(result => {
        this.orderDetails?.setDiscount(result);
        this.isVerifyingDiscount = false;
        this.updatePriceAndVatInfo();
      });
  }

  public async goToSummary(): Promise<void> {
    this.summaryLoading = true;
    const form = this.$refs.invoiceRecipientForm as VForm;
    if (form.validate() === false) {
      FormHelper.scrollToNextError(this);
      this.summaryLoading = false;
      return;
    }
    const inputModel = new GameInstanceRecipientDataInputModel();
    inputModel.gameInstanceId = this.$route.params['gameInstanceId'];
    inputModel.orderNumber = this.orderNumber;
    inputModel.currency = this.orderDetails?.priceInfo.currency as Currency;
    inputModel.discountCode = this.orderDetails?.discountCode != null ? this.orderDetails?.discountCode : '';
    inputModel.companyName = this.orderDetails?.invoiceRecipientFormData.companyName;
    inputModel.vatNumber = this.orderDetails?.invoiceRecipientFormData.vatNumber;
    inputModel.address.countryCode = this.orderDetails?.invoiceRecipientFormData.address.countryCode as string;
    inputModel.address.city = this.orderDetails?.invoiceRecipientFormData.address.city as string;
    inputModel.address.postCode = this.orderDetails?.invoiceRecipientFormData.address.postCode as string;
    inputModel.address.street = this.orderDetails?.invoiceRecipientFormData.address.street as string;

    const result = await this.paymentsApi.showSummary(inputModel);
    if (!result) {
      this.summaryLoading = false;
      return;
    }

    this.displaySummary = true;
    this.summaryLoading = false;

    this.sendAddPaymentInfoAnalyticsEvent();
  }

  public goToEdit(): void {
    this.displaySummary = false;
  }

  private sendAddPaymentInfoAnalyticsEvent(): void {
    if (this.orderDetails == null) return;

    // params from: https://developers.google.com/gtagjs/reference/ga4-events#add_payment_info
    AnalyticsGoogleHelper.event(
      AnalyticsEvent.addPaymentInfo,
      getAnalyticsEventParams(this.orderNumber, this.orderDetails)
    );

    // params from: https://developers.facebook.com/docs/facebook-pixel/reference
    AnalyticsHelper.trackWithFacebookPixel(
      FacebookPixelEvent.addPaymentInfo,
      AnalyticsHelper.getPurchaseOptions(this.orderNumber, this.orderDetails)
    );
  }

  private sendSelectItemAnalyticsEvent(): void {
    if (this.orderDetails == null) return;

    // params from: https://developers.google.com/gtagjs/reference/ga4-events#select_item
    AnalyticsGoogleHelper.event(
      AnalyticsEvent.selectItem,
      getAnalyticsEventParamsBase(this.orderNumber, this.orderDetails)
    );
  }
}
