var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.ExtendStatistic.Header'))+" "),_c('v-btn',{staticClass:"text-capitalize justify-start ml-4",attrs:{"small":"","dark":"","color":"green","loading":_vm.exporting,"disabled":_vm.data.length === 0},on:{"click":_vm.emitExportCsv}},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.ExportBtn'))+" ")],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":_vm.tablesSize,"loading":_vm.localLoading,"footer-props":{
      'items-per-page-text': _vm.$t('dataIterator.rowsPerPageText'),
      'page-text': _vm.$t('dataIterator.pageText'),
      'custom-highlight-row': '',
      'items-per-page-options': [5, 10, 25, { text: this.$t('dataIterator.AllText'), value: -1 }]
    }},on:{"update:itemsPerPage":function($event){_vm.tablesSize=$event},"update:items-per-page":function($event){_vm.tablesSize=$event}},scopedSlots:_vm._u([{key:"item.profession",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.getColor(item.profession),"dark":""}},[_vm._v(" "+_vm._s(item.profession)+" ")])]}},{key:"item.totalTimeForGames",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.totalTimeForGames))+" ")]}},{key:"item.averageTimeForGame",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.averageTimeForGame))+" ")]}},{key:"item.cv",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"game-statistics-player-table-cv-btn",attrs:{"loading":_vm.loadingCVButton,"rounded":"","small":"","elevation":"0","disabled":!item.addedCV},on:{"click":function($event){return _vm.emitDownloadCv(item.email, item.profession)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""}},[_vm._v("mdi-chart-bar")]),_vm._v(_vm._s(_vm.$t('Buttons.Preview')))],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }