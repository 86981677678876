



import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/statistic-api.service';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { StatisticsRoute } from '@/modules/statistics/statistics-router.enum';

@Component
export default class DownloadCV extends Vue {
  public cvId = this.$route.params.id;
  public gameId = this.$route.params.gameid;
  public loading = true;
  private service = new UserApiService();

  async activate() {
    this.loading = true;

    const result = await this.service.getInstancePlayersCVById(this.cvId);
    if (result) {
      NotificationService.success(translate('Modules.Statistics.PlayerTable.DownloadCV'));
      if (result?.cv) {
        this.downloadFile(
          result?.cv,
          'InGame_CV_' +
            result.email
              ?.substring(0, result.email.indexOf('@'))
              .split('.')
              .join('') +
            '.pdf'
        );
      }
    } else {
      NotificationService.error(translate('Modules.Statistics.PlayerTable.DownloadCVError'));
    }
    this.$router.push({ name: StatisticsRoute.statisticsMain, params: { gameInstanceId: this.gameId } });
    this.loading = false;
  }

  downloadFile(value: string, fileName?: string) {
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = value;
    downloadLink.download = fileName ?? 'default.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  mounted() {
    this.activate();
  }
}
