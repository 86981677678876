








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class KitchenSinkTypeScript extends Vue {
  @Prop() typescript!: string;
  typescripttemplate = `<script lang="ts">\n` + this.typescript + `\n<` + `/script>`;
}
