




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IntersectMenuSectionPreview extends Vue {
  id = '';
}
