


























































import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import PaymentsHistoryListItem from '@/modules/payments-history/components/PaymentsHistoryListItem.vue';
import { i18n, translate } from '@/plugins/i18n';
import NotificationService from '@/services/notification-service';
import PaymentsApiService from '../services/payments-api.service';
import { Component, Vue } from 'vue-property-decorator';
import { OrderListItemDto } from '@/services/Api';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component({ components: { PaymentsHistoryListItem, ConfirmDeleteModal } })
export default class PaymentsHistoryList extends Vue {
  private readonly paymentsApi = new PaymentsApiService();

  public orders: OrderListItemDto[] = [];
  public loading = true;

  public orderToCancel?: OrderListItemDto | null = null;
  public displayCancelOrderModal = false;
  public isCancelling = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.PaymentsHistory'),
        exact: true,
        disabled: true
      }
    ];
  }

  public mounted(): void {
    this.reloadList();
    store.setBreadcrumb(this.breadcrumbData);
  }

  public showConfirmationModal(order: OrderListItemDto): void {
    this.orderToCancel = order;
    this.displayCancelOrderModal = true;
  }

  public hideConfirmationModal(): void {
    this.displayCancelOrderModal = false;
    this.orderToCancel = null;
  }

  public requestOrderCancellation(): void {
    this.displayCancelOrderModal = false;

    if (this.isCancelling) return;
    this.isCancelling = true;

    this.paymentsApi.cancelPayment(this.orderToCancel?.orderNumber).then(isSuccess => {
      this.orderToCancel = null;
      this.isCancelling = false;

      if (isSuccess) {
        NotificationService.success(translate('Modules.PaymentsHistory.CancelSuccessNotification'));
        this.reloadList();
      }
    });
  }

  public async downloadInvoice(order: OrderListItemDto) {
    let response = null;
    response = await this.paymentsApi.getInvoice(order.id);

    this.downloadFile(response?.value as string, response?.fileName as string);
  }

  downloadFile(value?: string, fileName?: string) {
    const linkSource = 'data:application/pdf;base64,' + value;
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private reloadList(): void {
    this.loading = true;
    this.paymentsApi.getOrders().then(orders => {
      this.orders = orders ?? [];
      this.loading = false;
    });
  }
}
