





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ToolBarLanguageSelector from '@/modules/layout/components/ToolBarLanguageSelector.vue';
import UserApiService from '../services/user-api.service';
import { store } from '@/store/store';

@Component({ components: { ToolBarLanguageSelector } })
export default class AnonymousContainer extends Vue {
  private PPUrl = store.PPUrl;
}
