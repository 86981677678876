import { Country, countries as allCountries } from 'countries-list';

const excludedCountries = ['BV', 'EH', 'GS', 'HM', 'TF', 'AQ'];

export interface CountryViewModel extends Country {
  code: string;
}

export class CountriesProvider {
  public static get countries(): CountryViewModel[] {
    if (this.countryList == null) this.countryList = this.mapCountries();

    return this.countryList;
  }

  private static countryList: CountryViewModel[] | undefined;

  public static getCountry(code: string): CountryViewModel | null {
    const country = (allCountries as any)[code];
    if (country == null) return null;

    return {
      code: code,
      ...country
    };
  }

  private static mapCountries(): CountryViewModel[] {
    const result: CountryViewModel[] = [];
    Object.keys(allCountries).forEach(key => {
      if (excludedCountries.includes(key)) return;
      if (Object.prototype.hasOwnProperty.call(allCountries, key)) {
        result.push({
          code: key,
          ...(allCountries as any)[key]
        });
      }
    });

    return result;
  }
}
