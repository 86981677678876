



























































import StringValidators from '@/modules/layout/validators/string-validators';
import { i18n } from '@/plugins/i18n';
import NotificationService from '@/services/notification-service';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/user-api.service';

@Component
export default class ChangePassword extends Vue {
  loading = false;
  oldPassword = '';
  newPassword = '';
  hidePassword = true;
  apiService = new UserApiService();
  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 8),
    maxLength: (model: string) => StringValidators.maxLength(model, 50),
    passwordRegex: (model: string) =>
      (!!model && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(model)) ||
      this.$t('Modules.Login.PasswordPatternError')
  };

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ChangePassword'),
        exact: true,
        disabled: true
      }
    ];
  }

  mounted() {
    store.setBreadcrumb(this.breadcrumbData);
  }

  async submit() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      return;
    }
    this.loading = true;

    const passwordChanged = await this.apiService.changePassword(this.oldPassword, this.newPassword);
    if (passwordChanged) {
      NotificationService.success(
        this.$t('Modules.UserManagement.ChangePassword.PasswordChangedSuccesfull').toString()
      );
      this.$router.push({ path: '/' });
    }
    this.loading = false;
  }
}
