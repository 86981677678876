
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormCheckbox extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String, default: 'I agree to the' }) label!: string;

  updateValue(value: boolean) {
    this.$emit('input', value);
  }
}
