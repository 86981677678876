













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueCropper, { VueCropperMethods } from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({ components: { VueCropper } })
export default class ImageCropModal extends Vue {
  @Prop() show!: boolean;
  @Prop() image!: string;
  @Prop() heigth!: number;
  @Prop() width!: number;
  get aspectRatio() {
    return this.width / this.heigth;
  }
  private zoomIn() {
    const cropper = (this.$refs.cropperElement as any) as VueCropperMethods;
    cropper.relativeZoom(0.1);
  }
  private zoomOut() {
    const cropper = (this.$refs.cropperElement as any) as VueCropperMethods;
    cropper.relativeZoom(-0.1);
  }
  private confirm() {
    const cropper = (this.$refs.cropperElement as any) as VueCropperMethods;
    const croppedImage = cropper.getCroppedCanvas({ width: this.width, height: this.heigth });
    const image = croppedImage.toDataURL();
    this.$emit('confirm', image);
  }
  private cancel() {
    this.$emit('cancel');
  }
  @Watch('image')
  imageChanged() {
    const cropper = (this.$refs.cropperElement as any) as VueCropperMethods;
    if (cropper) {
      cropper.replace(this.image);
    }
  }
}
