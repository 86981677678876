



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { StringUtils } from '../utils/string-utils';

@Component
export default class ConfirmDeleteModal extends Vue {
  @Prop() show = false;
  @Prop() customText!: string;
  @Prop() translationGroup: string | undefined;
  @Prop() deleteButtonLabelKey: string | undefined;

  public get translationTextGroup(): string {
    return this.translationGroup === undefined ? 'ConfirmDelete' : this.translationGroup;
  }
  public get deleteButtonLabel(): string {
    return this.deleteButtonLabelKey === undefined ? 'Buttons.Delete' : this.deleteButtonLabelKey;
  }

  private confirm() {
    this.$emit('confirm');
  }
  private cancel() {
    this.$emit('cancel');
  }
}
