






























































import { translate } from '@/plugins/i18n';
import NotificationService from '@/services/notification-service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameMainSettingsModel } from '../games/customize-settings.model';

@Component
export default class GameOverModal extends Vue {
  @Prop() readonly loadingCV!: boolean;
  @Prop() readonly wrongFileInput!: boolean;
  private cvPendingConvert = false;
  private displayed = false;
  private score = 0;
  private waitingTime = 1500;
  private maxSize = 2000000;
  cv = [];
  @Prop() readonly playerName!: string;
  @Prop() readonly theme!: { [id: string]: string };
  @Prop() readonly gameSettings?: GameMainSettingsModel;
  onClearClicked() {
    this.cv = [];
  }
  rules = {
    maxSize: (file: any) => !file || file.size < this.maxSize || translate('Modules.Statistics.PlayerTable.MaxFileSize')
  };
  private cvToBase64() {
    if (this.cvPendingConvert) {
      return;
    }
    if (!this.cv) {
      this.cv = [];
      return;
    }
    const temp = this.cv as unknown as File;
    if(temp.size >= this.maxSize ){
      NotificationService.error(translate('Modules.Statistics.PlayerTable.MaxFileSize'));
      return;
    }
    this.cvPendingConvert = true;
    const reader = new FileReader();
    reader.readAsDataURL(this.cv as any);
    reader.onload = () => {
      this.$emit('saveCV', reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
    reader.onabort = function(error) {
      console.log('sadasd: ', error);
    };
    this.cvPendingConvert = false;
  }
  public show(score: number) {
    this.score = score;
    this.displayed = true;
  }
  public async newGame() {
    if (this.loadingCV) {
      return;
    }
    if (this.cv && this.cv.length != 0) {
      await this.cvToBase64();
      return;
    }
    this.displayed = false;
    console.log('GameOverModal emit new game');
    this.$emit('newGame');
  }
}
