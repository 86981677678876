import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { ErrorControl } from '../control/errorControl';
export class LifeSystem {
  protected static heartData: any;
  protected static lives: Phaser.GameObjects.Group;

  static initialize(
    scene: Phaser.Scene,
    spriteName: string,
    maxLife: number,
    customziableTypes?: GameCustomizableElement[]
  ) {
    this.getCustomData(spriteName, customziableTypes);
    this.createGroup(scene, spriteName, maxLife);

    //add to group live images on certain positin and scale
    if (this.heartData != undefined) {
      for (let q = 0; q < maxLife; q++) {
        const tempImage = scene.add.image(40 * q + 30, 25, spriteName).setDepth(1);
        tempImage.displayHeight = this.heartData.heigth;
        tempImage.displayWidth = this.heartData.width;
        this.lives.add(tempImage);
      }
    } else {
      ErrorControl.createConsoleError(ErrorControl.getType().Add, 'group live', false);
      return false;
    }
    return true;
  }
  static getCustomData(spriteName: string, customziableTypes?: GameCustomizableElement[]) {
    //Get all data
    if (customziableTypes) {
      this.heartData = customziableTypes.find((entity: { name: string }) => {
        if (entity.name == spriteName) {
          return entity;
        }
      });
      if (!this.heartData) {
        ErrorControl.createConsoleError(ErrorControl.getType().Get, 'heartData', true);
      }
    } else {
      ErrorControl.createConsoleError(ErrorControl.getType().Get, 'Custom data', true);
    }
    return true;
  }

  static createGroup(scene: Phaser.Scene, spriteName: string, maxLife: number) {
    //create group, set it max vlaue and default key image
    this.lives = scene.add.group({
      defaultKey: spriteName,
      active: true,
      maxSize: maxLife
    });
  }
  static haveMoreLife() {
    if (this.lives.getTotalUsed() - 1 <= 0) {
      return true;
    }
    return false;
  }
  static lostLife() {
    const lastLife = this.lives.getLast(true);
    if (lastLife != null) {
      lastLife.destroy();
    } else {
      ErrorControl.createConsoleError(ErrorControl.getType().Update, 'Cant destroy life', true);
    }
  }
}
