





































import StringValidators from '@/modules/layout/validators/string-validators';
import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/user-api.service';
import NotificationService from '@/services/notification-service';
import AnonymousContainer from '../components/AnonymousContainer.vue';
import AnonymousHeader from '../components/AnonymousHeader.vue';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

@Component({
  components: {
    AnonymousContainer,
    AnonymousHeader
  }
})
export default class ForgotPasswordRequest extends Vue {
  public resetPasswordCode = this.$route.params.id;
  loading = false;
  newPassword = '';
  hidePassword = true;
  apiService = new UserApiService();
  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 8),
    passwordRegex: (model: string) =>
      (!!model && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(model)) ||
      this.$t('Modules.Login.PasswordPatternError')
  };

  async submit() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      return;
    }
    this.loading = true;

    const passwordChanged = await this.apiService.forgetPasswordConfirm(this.resetPasswordCode, this.newPassword);
    if (passwordChanged) {
      NotificationService.success(this.$t('Modules.Login.PasswordResetConfirmSuccesfull').toString());
    }
    this.$router.push({ name: UserManagementRoute.login });
    this.loading = false;
  }
}
