















































































































import PaymentStatus from '@/modules/admin/payments/components/PaymentStatus.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PaymentsApiService from '../services/admin-payments-api.service';
import { PaymentsAdminDto } from '@/services/Api';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import { store } from '@/store/store';
import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';

@Component({
  components: {
    PaymentStatus
  }
})
export default class PaymentsListRow extends Vue {
  @Prop() readonly data!: PaymentsAdminDto[];
  confirmTitle = '';
  private apiService = new PaymentsApiService();
  private searchPayments = '';
  private tableSize = 10;
  public adminUsersRoute = AdminUsersRoute;
  get headersPayments() {
    return [
      {
        text: this.$t('Modules.PaymentsHistory.Order'),
        value: 'gameInstanceTitle',
        align: 'start',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.Payments.Buyeridentifier'),
        value: 'buyerIdentifier',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.PaymentsHistory.Details.OrderDate'),
        value: 'createdAt',
        sort: DataTableSortingHelper.compareByDate
      },
      {
        text: this.$t('Modules.PaymentsHistory.Price'),
        value: 'priceInfo',
        sort: DataTableSortingHelper.compareByPriceInfo
      },
      {
        text: this.$t('Modules.PaymentsHistory.Status'),
        value: 'status'
      },
      { value: 'button', sortable: false },
      { value: 'button', sortable: false }
    ];
  }
  async mounted() {
    this.tableSize = store.getTableSize('Admin-payments');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-payments', this.tableSize);
  }
  async downloadInvoice(order: string) {
    let response = null;
    response = await this.apiService.getInvoice(order);

    this.downloadFile(response?.value as string, response?.fileName as string);
  }
  downloadFile(value?: string, fileName?: string) {
    const linkSource = 'data:application/pdf;base64,' + value;
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  customSort(items: any[], index: string[], isDesc: boolean[], locale: any, sorters: any) {
    if (index[0] === 'status') {
      items.sort((a: PaymentsAdminDto, b: PaymentsAdminDto) => {
        return DataTableSortingHelper.compareByDate(a.updatedAt, b.updatedAt, isDesc[0]);
      });
      items.sort((a: PaymentsAdminDto, b: PaymentsAdminDto) => {
        return DataTableSortingHelper.compareByString(a.paymentStatus ?? '', b.paymentStatus ?? '', isDesc[0], locale);
      });
    } else {
      items = DataTableSortingHelper.sortByDefault(items, index, isDesc, locale, sorters);
    }
    return items;
  }

  customFilter(value: any, search: string | null, item: PaymentsAdminDto) {
    if (!search) {
      return true;
    }

    return (
      DataTableFilterHelper.filterByString(item.gameInstanceTitle + '(' + item.offerTitle + ')', search) ||
      DataTableFilterHelper.filterByString(item.orderNumber ?? '', search) ||
      DataTableFilterHelper.filterByString(item.buyerIdentifier ?? '', search) ||
      DataTableFilterHelper.filterByString(item.paymentStatus ?? '', search) ||
      DataTableFilterHelper.filterByPriceInfo(item.priceInfo, search) ||
      DataTableFilterHelper.filterByDate(item.createdAt, search)
    );
  }
}
