import { render, staticRenderFns } from "./GameInstanceProfessionAssign.vue?vue&type=template&id=3be666ab&scoped=true&"
import script from "./GameInstanceProfessionAssign.vue?vue&type=script&lang=ts&"
export * from "./GameInstanceProfessionAssign.vue?vue&type=script&lang=ts&"
import style0 from "./GameInstanceProfessionAssign.vue?vue&type=style&index=0&id=3be666ab&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be666ab",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAutocomplete,VBtn,VCardText,VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VProgressCircular,VRow,VSpacer,VTreeview})
