


















































import { InstanceGameStatisticsDto, PlayerInstanceAnswersDto } from '@/services/Api';
import { Duration, Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import UserApiService from '@/modules/statistics/services/statistic-api.service';
import { store } from '@/store/store';

@Component
export default class StatisticsOneGameDeepTable extends Vue {
  @Prop() readonly data!: PlayerInstanceAnswersDto[];
  @Prop() readonly exporting!: boolean;
  private tablesSize = 5;
  private localLoading = false;
  apiService = new UserApiService();

  private search = '';
  goToGameStatistic(instanceId: string) {
    this.$emit('game-statistics', instanceId);
  }
  mounted() {
    this.localLoading = true;
    this.tablesSize = store.getTableSize('Players-Deep-One-Game-statistics');
    this.localLoading = false;
  }
  @Watch('tablesSize')
  onPropertyChanged(value: number, oldValue: string) {
    this.tablesSize = value;
    store.setTables('Players-Deep-One-Game-statistics', this.tablesSize);
  }
  get headers() {
    return [
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.Question'),
        align: 'left',
        value: 'answeredQuestion'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.Answer'),
        value: 'answer'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.IsCorrect'),
        value: 'isCorrectAnswer'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.DeepStatistics.AnswerTime'),
        value: 'dateTimeLog'
      }
    ];
  }

  formatDuration(item: Duration) {
    if (item == null) {
      return '00:00';
    }
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }

  emitExportCsv() {
    this.$emit('export-csv', 'gamesList');
  }
}
