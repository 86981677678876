









import { Component, Vue } from 'vue-property-decorator';
import { AdminUserUpdateInputModel, PaymentsAdminDto } from '@/services/Api';
import PaymentsApiService from '../services/admin-payments-api.service';
import PaymentsListRow from '../components/PaymentsListRow.vue';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { AdminUsersRoute } from '../../admin-users-router.enum';
import { store } from '@/store/store';
@Component({
  components: { PaymentsListRow }
})
export default class PaymentsList extends Vue {
  private apiService = new PaymentsApiService();
  private paymentsList!: PaymentsAdminDto[];
  private loading = true;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.PaymentsAdmin'),
        exact: true,
        disabled: true
      }
    ];
  }

  async mounted() {
    await this.getPayments();
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  async getPayments() {
    const paymentsGetList = await this.apiService.GetPaymentsList();
    if (paymentsGetList) {
      this.paymentsList = paymentsGetList;
    }
  }
}
