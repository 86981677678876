









import { Component, Vue } from 'vue-property-decorator';

import KitchenSinkShortCuts from '@/modules/kitchen-sink/components/KitchenSinkShortCuts.vue';
import { elementsList } from '@/modules/kitchen-sink/models/KitchenSinkElementsList';

import KitchenSinkList from '@/modules/kitchen-sink/components/KitchenSinkList.vue';
import SinkListModel from '@/modules/kitchen-sink/models/sink-list.model';
import { i18n } from '@/plugins/i18n';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component({
  components: {
    KitchenSinkShortCuts,
    KitchenSinkList
  }
})
export default class KitchenSink extends Vue {
  sinkItemList: SinkListModel[] = elementsList;
  navigationList: string[] = this.sinkItemList.map(x => x.navigation);
  tab = 0;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.KitchenSink'),
        exact: true,
        disabled: true
      }
    ];
  }

  mounted() {
    store.setBreadcrumb(this.breadcrumbData);
  }

  getId(num: number): string {
    return 'sink' + num;
  }
}
