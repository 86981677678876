








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class KitchenSinkAnippet extends Vue {
  @Prop() snippet!: string;
}
