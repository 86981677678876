

































import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store/store';
import ToolBarLanguageSelector from './ToolBarLanguageSelector.vue';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';

@Component({ components: { ToolBarLanguageSelector } })
export default class ToolBar extends Vue {
  store = store;
  items = [
    {
      text: 'Dashboard',
      disabled: false,
      to: { name: UserManagementRoute.changePassword }
    },
    {
      text: 'Link 1',
      disabled: false,
      to: { name: UserManagementRoute.changePassword }
    },
    {
      text: 'Link 2',
      disabled: true,
      href: 'breadcrumbs_link_2'
    }
  ];
  breadcrumbs: BreadcrumbData[] = [];

  toggleMenu() {
    store.toggleSideMenu();
  }

  changePassword() {
    this.$router.push({ name: UserManagementRoute.changePassword });
  }

  logOut() {
    store.logOutUser();
    this.$router.push({ name: UserManagementRoute.login });
  }

  currentUser() {
    return store.user?.email;
  }
  getCrumbs() {
    const crumbsFn = store.getBreadcrumb();
    if (!crumbsFn) {
      return [];
    }
    const crumbs = crumbsFn();
    return crumbs;
  }
}
