



































import GameOverModal from '@/modules/game-play/components/GameOverModal.vue';
import QuestionModal from '@/modules/game-play/components/QuestionModal.vue';
import { translate } from '@/plugins/i18n';
import { PlayerInstanceDetailsInputModel, PlayerInstanceLogInputModel, RuntimeGameInstanceDto } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AnalyticsGoogleHelper, { mapTriggerToAnalyticsEvent } from '../../plugins/analytics-google';
import { GameMainSettingsModel } from './games/customize-settings.model';
import { GameEngineBase, GameTriggerType } from './games/game-engine-base';
import { GameCustomizableElement } from './games/game-engine-customizable-element';
import { GameTheme } from './models/game-theme';
import GameApiService from './services/game-api.service';
import GameFactory from './services/game-factory.service';

@Component({
  components: { GameOverModal, QuestionModal }
})
export default class GamePlay extends Vue {
  @Prop({ required: true }) readonly playerInstanceId!: string;
  @Prop({ required: true }) readonly professionId!: string;
  @Prop({ required: true }) readonly playerName!: string;
  @Prop({ required: true }) readonly gameInstance!: RuntimeGameInstanceDto;
  @Prop({ required: true }) readonly theme!: GameTheme;

  public apiService = new GameApiService();
  private engine!: GameEngineBase;
  public testMode = false;
  private timeForQuestion = 10;
  private answerLimit = true;
  public wrongFileInput = false;
  public gameSettings: GameMainSettingsModel = new GameMainSettingsModel();
  loadingCV = false;
  mounted() {
    let customizableElements: GameCustomizableElement[] = [];

    if (this.gameInstance.settings) {
      this.gameSettings = JSON.parse(this.gameInstance.settings ?? '') as GameMainSettingsModel;
      console.log('customized elements ', this.gameSettings.gameCustomizableElements);
      customizableElements = this.gameSettings.gameCustomizableElements;
      this.answerLimit = this.gameSettings?.answerLimitFlag ?? false;
      this.timeForQuestion = this.gameSettings.answerLimitTime;
    }
    const engine = GameFactory.getEngine(
      this.gameInstance.type,
      this.askQuestion,
      this.gameOverDialog,
      this.saveGameLog,
      customizableElements
    );
    if (engine) {
      this.engine = engine;
    }
    this.engine.initialize();
  }

  public saveGameLog(score: number, trigger: GameTriggerType) {
    const inputModel = new PlayerInstanceLogInputModel({
      score: score,
      logTrigger: trigger,
      playerInstanceId: this.playerInstanceId
    });
    this.apiService.addPlayerInstanceLog(inputModel);
    this.sendGoogleAnalyticsGameEvent(score, trigger);
  }

  public async saveCV(base64: string) {
    this.wrongFileInput = false;
    this.loadingCV = true;
    const inputModel = new PlayerInstanceDetailsInputModel({
      playerInstanceId: this.playerInstanceId,
      cv: base64
    });
    const playerDetails = await this.apiService.addPlayerInstanceDetails(inputModel, this.gameSettings.gameOverEmailCV);
    if (playerDetails) {
      NotificationService.success(translate('Modules.Runtime.Game.Modal.SuccessAdd'));
      this.newGame();
    } else {
      this.wrongFileInput = true;
    }
    this.loadingCV = false;
  }

  public gameOverDialog(score: number): void {
    (this.$refs.gameOverModal as GameOverModal).show(score);
  }

  public askQuestion() {
    (this.$refs.questionModal as QuestionModal).askQuestion();
  }

  public acceptAnswer(answeredCorrectly: boolean, answerPoolEmpty: boolean) {
    this.engine.acceptAnswer(answeredCorrectly, answerPoolEmpty);
  }

  public newGame() {
    this.$emit('newGame');
  }

  private sendGoogleAnalyticsGameEvent(score: number, trigger: GameTriggerType): void {
    const event = mapTriggerToAnalyticsEvent(trigger);
    if (event == null) return;

    AnalyticsGoogleHelper.event(event, {
      player_instance_id: this.playerInstanceId,
      game_instance_id: this.gameInstance.gameInstanceId,
      game_type: this.gameInstance.type,
      score: score
    });
  }
}
