





































import UserApiService from '@/modules/statistics/services/statistic-api.service';
import { InstanceGameStatisticsDto, InstanceGlobalStatisticsDto, InstancePlayersStatisticsDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlobalCountsBar from '../components/GlobalCountsBar.vue';
import StatisticsPlayersTable from '../components/StatisticsPlayersTable.vue';
import StatisticsGamesTable from '../components/StatisticsGamesTable.vue';
import { StatisticsRoute } from '@/modules/statistics/statistics-router.enum';
import { store } from '@/store/store';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { i18n } from '@/plugins/i18n';

@Component({
  components: {
    GlobalCountsBar,
    StatisticsPlayersTable,
    StatisticsGamesTable
  }
})
export default class StatisticsMain extends Vue {
  apiService = new UserApiService();
  @Prop() readonly gameInstanceId!: string;
  private globalInfo!: InstanceGlobalStatisticsDto;
  private playerListInfos!: InstancePlayersStatisticsDto[];
  private gamesListInfos!: InstanceGameStatisticsDto[];
  private loading = true;
  private loadingCVButton = false;
  private exporting = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        to: { name: GameInstanceRoute.gameInstanceList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Statistics', {
          gameName: this.globalInfo.gameInstanceTitle
        }),
        disabled: true
      }
    ];
  }

  async mounted() {
    this.loading = true;

    const globalStats = await this.apiService.getInstanceGlobalStatistics(this.gameInstanceId);

    this.globalInfo = new InstanceGlobalStatisticsDto(globalStats);

    const playersStats = await this.apiService.getInstancePlayersStatistics(this.gameInstanceId);
    if (playersStats) {
      this.playerListInfos = playersStats;
    }

    const gamesStats = await this.apiService.getInstanceGameStatistics(this.gameInstanceId);

    if (gamesStats) {
      this.gamesListInfos = gamesStats;
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }

  playerstatistic(playerEmail: string, playerProfession: string) {
    this.$router.push({
      name: StatisticsRoute.statisticsPlayer,
      params: {
        gameInstanceId: this.gameInstanceId,
        playerEmail: playerEmail,
        playerProfession: playerProfession
      }
    });
  }
  gameStatistics(instanceId: string) {
    this.$router.push({
      name: StatisticsRoute.statisticsGame,
      params: {
        gameInstanceId: this.gameInstanceId,
        instanceId: instanceId
      }
    });
  }
  async exportCsv(value: string) {
    let result;
    this.exporting = true;
    if (value === 'gamesList') {
      const timeDifference = new Date().getTimezoneOffset();
      result = await this.apiService.exportGameStatistics(this.gameInstanceId, -timeDifference);
    }
    if (value === 'playersList') {
      result = await this.apiService.exportPlayersStatistics(this.gameInstanceId);
    }
    this.downloadBlob(result?.data, result?.fileName);
    this.exporting = false;
  }

  downloadBlob(blob?: Blob, filename?: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename ?? 'export.csv';
    link.type = 'text/csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async downloadCV(playerEmail: string, playerProfession: string) {
    this.loadingCVButton = true;
    const data = await this.apiService.getInstancePlayersCV(this.gameInstanceId, playerEmail);
    if (data) {
      if (data.cv) {
        this.downloadFile(
          data.cv,
          'InGame_CV_' +
            playerEmail
              .substring(0, playerEmail.indexOf('@'))
              .split('.')
              .join('')
        );
      }
    }
    this.loadingCVButton = false;
  }
  downloadFile(value: string, fileName?: string) {
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = value;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
