

























































































import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n, translate } from '@/plugins/i18n';
import { QuestionAnswerInputModel, QuestionCreateInputModel } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Vue } from 'vue-property-decorator';
import { VForm } from '../../layout/components/forms/v-form';
import QuestionApiService from '../services/question-api.service';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component
export default class CreateQuestion extends Vue {
  rules = {
    required: StringValidators.required,
    maxLength120: (model: string) => StringValidators.maxLength(model, 120),
    maxLength80: (model: string) => StringValidators.maxLength(model, 80)
  };

  public questionText = i18n.t('Modules.Professions.DefaultQuestion.QuestionText').toString();
  public answers: QuestionAnswerInputModel[] = [
    new QuestionAnswerInputModel({
      answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer1').toString(),
      isCorrect: true
    }),
    new QuestionAnswerInputModel({
      answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer2').toString(),
      isCorrect: false
    }),
    new QuestionAnswerInputModel({
      answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer3').toString(),
      isCorrect: false
    })
  ];

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ProfessionList'),
        exact: true,
        disabled: false,
        to: { name: ProfessionsRoute.professionList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Create'),
        exact: true,
        disabled: true
      }
    ];
  }

  mounted() {
    store.setBreadcrumb(this.breadcrumbData);
  }

  private readonly apiService = new QuestionApiService();
  private loading = false;

  private async createOne() {
    const professionId = this.$route.params.id;
    const result = await this.submit();
    if (result) {
      await this.$router.push({ name: ProfessionsRoute.professionDetails, params: { id: professionId } });
      NotificationService.success(translate('Modules.Professions.Notifications.QuestionCreated'));
    }

    this.loading = false;
  }

  private async addAnother() {
    const result = await this.submit();
    if (result) {
      this.resetForm();
      NotificationService.success(translate('Modules.Professions.Notifications.QuestionCreated'));
    }

    this.loading = false;
  }
  resetForm() {
    this.questionText = '';
    this.answers.forEach(a => {
      a.answerText = '';
      a.isCorrect = false;
    });
  }

  private async submit() {
    const form = this.$refs.form as VForm;
    const professionId = this.$route.params.id;

    if (!form.validate()) {
      FormHelper.scrollToNextError(this);
      return;
    }

    if (this.answers.length < 2) {
      NotificationService.error(translate('Modules.Professions.Errors.AtLeastTwoAnswersMustBeDefined'));
      return;
    }

    if (this.answers.length > 5) {
      NotificationService.error(translate('Modules.Professions.Errors.LessThanFiveAnswersMustBeDefined'));
      return;
    }

    if (!this.atLeastOneAnswerIsCorrect()) {
      NotificationService.error(translate('Modules.Professions.Errors.AtLeastOneAnswerMustBeMarkedAsCorrect'));
      return;
    }

    this.loading = true;

    const inputModel = new QuestionCreateInputModel({
      professionId,
      questionText: this.questionText,
      answers: this.answers
    });

    const result = await this.apiService.createQuestion(inputModel);

    if (result) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.addQuestion, {
        question_id: result.id,
        question_text: inputModel.questionText,
        profession_id: inputModel.professionId
      });
    }

    return result;
  }

  private async cancel() {
    await this.$router.back();
  }

  private addAnswer() {
    const newAnswer = new QuestionAnswerInputModel({
      answerText: 'New answer',
      isCorrect: false
    });

    this.answers.push(newAnswer);
  }

  private deleteAnswer(answer: QuestionAnswerInputModel) {
    const index = this.answers.indexOf(answer);

    this.answers.splice(index, 1);
  }

  private atLeastOneAnswerIsCorrect(): boolean {
    return this.answers.some(item => item.isCorrect);
  }
}
