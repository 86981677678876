import { WallObjectsGroup } from './WallObjectsGroup';
export default class WallObjectsFactory {
  public static Build(scene: any): WallObjectsGroup {
    return new WallObjectsGroup(scene, { maxSize: 25 });
  }
  public static createTileSprite(
    wall: WallObjectsGroup,
    speedLine: number,
    leftGrassLaneWidth: number,
    numberOfDivisions: number,
    maxNumberOfHoles: number
  ) {
    wall.createTileSprite(speedLine, leftGrassLaneWidth, numberOfDivisions, maxNumberOfHoles);
  }
}
