




import { Component, Vue } from 'vue-property-decorator';
import { KitchenSinkRoute } from '@/modules/kitchen-sink/kitchen-sink-router.enum';

@Component
export default class ListItemHeaderPreview extends Vue {
  title = this.$t('KitchenSink.Previews.ListItemHeaderPreview.Title');
  subTitle = this.$t('KitchenSink.Previews.ListItemHeaderPreview.subTitle');
  routeTo = KitchenSinkRoute.kitchenSink;
  icon = 'mdi-pencil';
}
