






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TableItemLink extends Vue {
  @Prop() public value?: string;
  @Prop() public routeTo?: any;
  @Prop({ default: 'mdi-open-in-new' }) public icon?: string;
}
