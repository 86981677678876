import WallObjectsFactory from '../Models/WallObjectsFactory';
import { WallObjectsGroup } from '../Models/WallObjectsGroup';
export class Levels {
  constructor(
    level: number,
    timeToNextLevel: number,
    spawnTime: Array<number>,
    scene: any,
    backgroundSpeed: number,
    numberOfDivisions: number,
    maxNumberOfHoles: number
  ) {
    this.level = level;
    this.timeToNextLevel = timeToNextLevel;
    this.spawnTime = spawnTime;
    this.wallFactory = WallObjectsFactory.Build(scene);
    this.backgroundSpeed = backgroundSpeed;
    this.numberOfDivisions = numberOfDivisions;
    this.maxNumberOfHoles = maxNumberOfHoles;
  }
  level!: number;
  timeToNextLevel!: number;
  spawnTime!: Array<number>;
  wallFactory!: WallObjectsGroup;
  backgroundSpeed!: number;
  numberOfDivisions!: number;
  maxNumberOfHoles!: number;
}
