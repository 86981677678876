import { SwaggerResponse } from './Api';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { store } from '@/store/store';
import router from '@/router';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

export default abstract class ApiServiceBase {
  protected HandleError(error: SwaggerResponse<string>) {
    if (error?.status == 404) {
      NotificationService.error(translate('Errors.CannotConnect'));
      return;
    } else if (error?.status == 401) {
      NotificationService.warning(translate('Warnings.TokenExpired'));
      store.logOutUser();
      router.push({ name: UserManagementRoute.login });
      return;
    } else if (error?.status == 403) {
      NotificationService.warning(translate('Warnings.InsufficientPermissions'));
      router.push({ name: GameInstanceRoute.gameInstanceList });
      return;
    }

    if ((error.result as any).title) {
      NotificationService.error((error.result as any).title);
      return;
    }

    NotificationService.error(translate(error.result));
  }
}
