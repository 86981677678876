














































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdminGameInstanceListItemDto } from '@/services/Api';
import AdminGameInstanceDetailsPage from '@/modules/admin/game-instances/views/AdminGameInstanceDetailsPage.vue';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';

import { Moment } from 'moment';
import moment from 'moment';
import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import { translate } from '@/plugins/i18n';
@Component({
  components: { AdminGameInstanceDetailsPage }
})
export default class GameInstancesListRow extends Vue {
  @Prop() readonly data!: AdminGameInstanceListItemDto[];
  private search = '';
  private tableSize = 10;
  public adminUsersRoute = AdminUsersRoute;
  get headers() {
    return [
      {
        text: this.$t('Modules.AdminPanel.GameInstances.Title'),
        align: 'start',
        value: 'instanceTitle',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.GameInstances.Plan'),
        value: 'validUntil',
        sort: DataTableSortingHelper.compareByDate
      },
      { text: this.$t('Modules.AdminPanel.GameInstances.TimePeriod'), value: 'timePeriod', sortable: false },
      {
        text: this.$t('Modules.AdminPanel.GameInstances.Owner'),
        value: 'owner',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.GameInstances.CreatedDate'),
        value: 'createdDate',
        sort: DataTableSortingHelper.compareByDate
      },
      {
        text: this.$t('Modules.AdminPanel.GameInstances.IsDeleted'),
        value: 'isDeleted',
        sort: DataTableSortingHelper.compareByString
      },
      { value: 'button', sortable: false }
    ];
  }
  get filters() {
    return [
      { value: 'owner', items: this.data.map(d => d.owner) },
      { value: 'isDeleted', items: this.data.map(d => d.isDeleted) },
      {
        value: 'validUntil',
        items: ['Basic', 'Per Game'],
        filter: (value: any, search: any, item: any, activeFilters: any) => {
          return activeFilters.validUntil.includes(this.license(value).text);
        }
      }
    ];
  }
  expired(validUntil: Moment) {
    return validUntil.isBefore(moment());
  }
  license(validUntil: Moment | null | undefined) {
    if (validUntil && validUntil.isAfter(moment())) {
      const result = {
        text: 'Per Game',
        icon: 'mdi-crown',
        color: '#D8A243'
      };
      return result;
    }
    const result = {
      text: 'Basic',
      icon: 'mdi-lock',
      color: 'grey'
    };
    return result;
  }

  customFilter(value: any, search: string | null, item: AdminGameInstanceListItemDto) {
    if (!search) {
      return true;
    }

    return (
      DataTableFilterHelper.filterByString(item.instanceTitle, search) ||
      DataTableFilterHelper.filterByString(item.gameTitle, search) ||
      DataTableFilterHelper.filterByString(item.owner, search) ||
      DataTableFilterHelper.filterByString(item.id, search) ||
      DataTableFilterHelper.filterByValid(item.validUntil, search) ||
      DataTableFilterHelper.filterByDate(item.playableTo, search) ||
      DataTableFilterHelper.filterByDate(item.playableFrom, search) ||
      DataTableFilterHelper.filterByDate(item.createdDate, search)
    );
  }
}
