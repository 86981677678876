import { RouteConfig } from 'vue-router';
import GameContainer from './GameContainer.vue';
import { GamePlayRoute } from './game-play-router.enum';

export const gamePlayRoutes: Array<RouteConfig> = [
  {
    path: '/play=:id',
    name: GamePlayRoute.gameContainer,
    component: GameContainer,
    meta: {
      allowAnonymous: true
    }
  }
];
