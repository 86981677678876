import { GameEngineBase } from '../game-engine-base';
import 'phaser';
import { GameScene } from './river-run-gameclass';
import { translate } from '@/plugins/i18n';
import { GameCustomizableElement } from '../game-engine-customizable-element';
import { ImagesBase64 } from './imagesbase64';

import { RiverRunVariables } from './Global/RiverRunVariables';

export class RiverRunEngine extends GameEngineBase {
  game!: Phaser.Game;
  private gameConfig!: Phaser.Types.Core.GameConfig;

  initialize(): void {
    this.gameConfig = {
      title: 'River Run',
      width: 800,
      height: 600,
      type: Phaser.CANVAS,
      parent: 'game-window',
      transparent: true,
      backgroundColor: '#000000',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      },

      scene: [
        new GameScene(this.askQuestion, this.gameOverDialog, this.saveGameLog, this.getCustomizedElements(), this.theme)
      ]
    };
    this.game = new Phaser.Game(this.gameConfig);
    console.log('initialize riverrungame');
  }

  getCustomziableTypes(): GameCustomizableElement[] {
    return [
      /**
     * Create text, number
     * @param {string} name
     * @param {string} value - translate value
     * @param {number} minLength - min lenght
     * @param {number} maxLength - max lenght
     * @param {bool} isCustomByUser - should be custom by user in menu website 
     * @param {number} position - position in menu website 
     */
      GameCustomizableElement.CreateText('AnswerTextCorrect', translate('Modules.Runtime.Game.CorrectAnswer'), 2, 20,true,1),
      GameCustomizableElement.CreateText('AnswerTextWrong', translate('Modules.Runtime.Game.WrongAnswer'), 2, 20,true,2),
      GameCustomizableElement.CreateText('StartText', translate('Modules.Runtime.Game.StartGame'), 2, 30,true,5),
      GameCustomizableElement.CreateNumber('LivesNumber', 5, 1, 5,true,3),
      GameCustomizableElement.CreateText('NoMoreQuestions', translate('Modules.Runtime.Game.NoMoreQuestions'), 2, 40,true,4),
      GameCustomizableElement.CreateText('NextLevel', translate('Modules.Runtime.Game.Level'), 2, 20,true,6),
      GameCustomizableElement.CreateImage(RiverRunVariables.enemy1SpriteName, ImagesBase64.enemy1, 40, 80,true,8),
      GameCustomizableElement.CreateImage(RiverRunVariables.enemy2SpriteName, ImagesBase64.enemy2, 50, 100,true,9),
      GameCustomizableElement.CreateImage(RiverRunVariables.enemy3SpriteName, ImagesBase64.enemy3, 40, 80,true,10),
      GameCustomizableElement.CreateImage(RiverRunVariables.playerSpriteName, ImagesBase64.player, 20, 20,true,7),
      GameCustomizableElement.CreateImage(RiverRunVariables.person1, ImagesBase64.people1, 30, 50,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.heartSpriteName, ImagesBase64.heart, 30, 25,true,12),
      GameCustomizableElement.CreateImage(RiverRunVariables.treeSpriteName, ImagesBase64.tree, 50, 50,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.object1SpriteName, ImagesBase64.object1, 50, 50,true,11),
      GameCustomizableElement.CreateImage(RiverRunVariables.bgBackSpriteNameLeft, ImagesBase64.bgBackLeft, 0, 0,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.bgBackSpriteNameRight, ImagesBase64.bgBackRight, 0, 0,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.roadSpriteName, ImagesBase64.road, 0, 0,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.endLevelObj, ImagesBase64.endLevelObj, 0, 0,true,13),
      GameCustomizableElement.CreateImage(RiverRunVariables.odometer, ImagesBase64.odometer, 160, 150,false),
      GameCustomizableElement.CreateImage(RiverRunVariables.odometerLine, ImagesBase64.odometerLine, 0, 0,false),
      GameCustomizableElement.CreateColor(RiverRunVariables.staminaColor, 0xffffff)
    ];
  }

  acceptAnswer(isCorrectAnswer: boolean, answerPoolEmpty: boolean): void {
    this.game.registry.events.emit('AnswerEvent', isCorrectAnswer, answerPoolEmpty);
  }
}
