import {
    FileResponse,
    LandingPageClient
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class EbookApiService extends ApiServiceBase {
    private client = new LandingPageClient();

    public async downloadEbook(linkId: string): Promise<FileResponse | undefined> {
        try {
            const response = await this.client.downloadEbook(linkId);
            return response.result;
        } catch (error) {
            this.HandleError(error);
        }
    }
}
