
















import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { Component, Prop, Vue } from 'vue-property-decorator';
import StringValidators from '@/modules/layout/validators/string-validators';
import { GameType } from '@/services/Api';
@Component
export default class TextTypeCustomize extends Vue {
  @Prop() readonly item!: GameCustomizableElement;
  @Prop() readonly type!: GameType;
  inputValue = '';
  rules = {
    minLength: (model: string) => StringValidators.minLength(model, this.item.minSize),
    maxLength: (model: string) => StringValidators.maxLength(model, this.item.maxSize)
  };

  mounted() {
    this.inputValue = this.item.value as string;
  }

  emitInput(value: string) {
    this.item.value = value;
  }
}
