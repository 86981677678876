import { GameInstanceAdminClient, AdminGameInstanceListItemDto, AdminGameInstanceDto, OrderListItemAdminDataDto } from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class AdminGameInstancesApiService extends ApiServiceBase {
  private client = new GameInstanceAdminClient();
  public async getGameInstancesList(): Promise<AdminGameInstanceListItemDto[] | undefined> {
    try {
      const gameInstancesList = await this.client.getGameInstancesList();
      return gameInstancesList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getGameInstance(inputModel: string): Promise<AdminGameInstanceDto | undefined> {
    try {
      const gameInstance = await this.client.getGameInstance(inputModel);
      return gameInstance.result;
    } catch (error) {
      this.HandleError(error);
    }

  }
  public async getPaymentOrdersForGameInstance(inputModel: string): Promise<OrderListItemAdminDataDto[] | undefined> {
    try {
      const paymentInstance = await this.client.getPaymentOrdersForGameInstance(inputModel);
      return paymentInstance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
