import { app } from '@/main';
import { PriceInfo } from '@/services/Api';
import moment from 'moment';
import { Moment } from 'moment';

export default class DataTableFilterHelper {
  public static filterByDate(value: Moment | null | undefined, search: string) {
    if (value) {
      const formatted = app.$options.filters?.formatDateTime(value) as string;
      return formatted?.includes(search);
    }
    return false;
  }

  public static filterByValid(value: Moment | null | undefined, search: string) {
    if (value) {
      const formatted = app.$options.filters?.formatDateTime(value) as string;
      const plan = value.isAfter(moment()) ? 'Per Game' : 'Basic';
      const combined = formatted + ' ' + plan;

      return combined.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    }
    return 'basic'.includes(search.toLocaleLowerCase());
  }

  public static filterByString(value: string | null | undefined, search: string) {
    if (value) {
      const formatted = value.toLocaleLowerCase();
      return formatted?.includes(search.toLocaleLowerCase());
    }

    return false;
  }

  public static filterByPriceInfo(value: PriceInfo | null | undefined, search: string) {
    if (value) {
      const currency = app.$options.filters?.toCurrency(value.finalPrice, value.currency);

      return currency?.includes(search.toLocaleLowerCase());
    }

    return false;
  }
}
