var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.GamesTable.Title'))+" "),_c('v-btn',{staticClass:"text-capitalize justify-start ml-4",attrs:{"small":"","dark":"","color":"green","loading":_vm.exporting,"disabled":_vm.data.length === 0},on:{"click":_vm.emitExportCsv}},[_c('v-icon',[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(_vm.$t('Modules.Statistics.ExportBtn'))+" ")],1)],1),_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"10"}},[_c('GlobalCountsBarForPlayer',{staticClass:"game-statistics-player-counts-bar",attrs:{"data":_vm.globalPlayerInfo}})],1)],1),_c('v-row',{staticClass:"ma-2"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Modules.Statistics.PlayerTable.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.localLoading,"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.tablesSize,"custom-sort":_vm.customSort,"sort-by":['score'],"sort-desc":[true],"footer-props":{
      'items-per-page-text': _vm.$t('dataIterator.rowsPerPageText'),
      'page-text': _vm.$t('dataIterator.pageText'),
      'items-per-page-options': [5, 10, 25, { text: this.$t('dataIterator.AllText'), value: -1 }]
    }},on:{"update:itemsPerPage":function($event){_vm.tablesSize=$event},"update:items-per-page":function($event){_vm.tablesSize=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"v-lowercase body-2",attrs:{"text":""},on:{"click":function($event){return _vm.goToGameStatistic(item.id)}}},[_vm._v(" "+_vm._s(item.email + ' ')+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey","small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.profession",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.getColor(item.profession),"dark":""}},[_vm._v(" "+_vm._s(item.profession)+" ")])]}},{key:"item.playingTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.playingTime))+" ")]}},{key:"item.startingTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.startingTime))+" ")]}},{key:"item.cv",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"loading":_vm.loadingCVButton,"rounded":"","small":"","elevation":"0","disabled":!item.addedCv},on:{"click":function($event){return _vm.emitDownloadCv(item.id, item.profession)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""}},[_vm._v("mdi-chart-bar")]),_vm._v(_vm._s(_vm.$t('Buttons.Preview')))],1)]}},{key:"item.button",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"dense":"","justify":"space-around","align":"center"}},[_c('list-menu',[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToGameStatistic(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-text")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.Statistics.ExtendStatistic.Details')))])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }