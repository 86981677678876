


























































































import { AssignableProfessionDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameInstanceModel } from '../models/game-instance.model';
import GameApiService from '../services/game-instance-api.service';

@Component
export default class GameInstanceProfessionAssign extends Vue {
  @Prop() readonly instanceSettings!: GameInstanceModel;
  @Prop() readonly gameInstanceId!: string;

  public loading = false;
  public professions?: AssignableProfessionDto[];
  public selectedProfession = '';
  private apiservice = new GameApiService();

  async mounted() {
    this.loading = true;

    const result = await this.apiservice.getAssignableProfessions(this.gameInstanceId);

    this.professions = result;
    if (this.instanceSettings.assignedProfessions.length == 0 && this.professions) {
      this.instanceSettings.assignedProfessions.push(this.professions[0].id);
    }
    this.instanceSettings.assignedProfessions = this.instanceSettings.assignedProfessions.filter(p =>
      this.professions?.some(e => e.id === p)
    );
    this.loading = false;
  }
  unassignProfession(professionId: string) {
    const index = this.instanceSettings.assignedProfessions?.indexOf(professionId);
    if (index >= 0) {
      this.instanceSettings.assignedProfessions?.splice(index, 1);
    }
  }
  selectedProfessions() {
    const filtered = this.professions?.filter(p => this.instanceSettings.assignedProfessions?.some(a => a === p.id));

    return filtered;
  }
  notSelectedProfessions() {
    const filtered = this.professions?.filter(
      p => this.instanceSettings.assignedProfessions?.some(a => a === p.id) === false
    );

    return filtered;
  }
  selectProfession() {
    if (!this.selectedProfession) {
      return;
    }
    const professionId = this.selectedProfession;
    const index = this.instanceSettings.assignedProfessions?.indexOf(professionId);
    if (index < 0) {
      this.instanceSettings.assignedProfessions?.push(professionId);
    }
  }
}
