import { render, staticRenderFns } from "./PaymentStatusAdminData.vue?vue&type=template&id=5e567666&scoped=true&"
import script from "./PaymentStatusAdminData.vue?vue&type=script&lang=ts&"
export * from "./PaymentStatusAdminData.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e567666",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
