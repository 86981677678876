import { BulletGroup } from './BulletGroup';
import { KeySide } from '../Models/KeySide';
import { SpaceRescueVariables } from '../Global/SpaceRescueVariables';
import { GameTheme } from '@/modules/game-play/models/game-theme';
export class Ship extends Phaser.Physics.Arcade.Sprite {
  bullets: BulletGroup;
  speed: number;
  maxspeed: number;
  texturess!: Phaser.Textures.Texture;
  playerBorderMax!: number;
  playerBorderMin!: number;
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    speed: number,
    maxspeed: number,
    playerBorderMax: number,
    playerBorderMin: number,
    widthPlayer: number,
    heightPlayer: number,
    bulletWidth: number,
    bulletHeight: number,
    theme?: GameTheme
  ) {
    super(scene, x, y, SpaceRescueVariables.shipSpriteName);
    scene.physics.add.existing(this);
    this.speed = speed;
    this.maxspeed = speed;
    this.bullets = new BulletGroup(scene, SpaceRescueVariables.bulletSpriteName, bulletWidth, bulletHeight);
    scene.add.existing(this);
    this.displayHeight = heightPlayer;
    this.displayWidth = widthPlayer;
    if (theme?.playerAnimation) {
      scene.tweens.add({
        targets: this,
        y: this.y - 10,
        ease: 'Linear',
        duration: 500,
        repeat: -1,
        yoyo: true
      });
    }

    this.playerBorderMax = playerBorderMax;
    this.playerBorderMin = playerBorderMin;
  }

  //Move player with animation
  move(side: KeySide, delta: number) {
    switch (side) {
      case KeySide.right:
        if (this.body.x <= this.playerBorderMax) {
          if (this.body.velocity.x <= this.maxspeed) {
            this.setVelocityX((this.speed * delta));
            this.scene.tweens.add({
              targets: this,
              angle: { from: 0, to: 15 },
              ease: 'Linear',
              duration: 300
            });
          }
        } else {
          this.angle = 0;
          this.body.velocity.x = 0;
        }

        break;
      case KeySide.left:
        if (this.body.x >= this.playerBorderMin) {
          if (this.body.velocity.x >= -this.maxspeed) {
            this.setVelocityX((-this.speed * delta));
            this.scene.tweens.add({
              targets: this,
              angle: { from: 0, to: -15 },
              ease: 'Linear',
              duration: 300
            });
          }
        } else {
          this.angle = 0;
          this.body.velocity.x = 0;
        }

        break;
      case KeySide.stop:
        this.scene.tweens.killTweensOf(this);

        this.angle = 0;
        this.body.velocity.x = 0;
        break;
    }
  }
}
