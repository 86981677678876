























































import { Component, Prop, Vue } from 'vue-property-decorator';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import { QuestionDeleteInputModel, QuestionDto } from '@/services/Api';
import QuestionApiService from '@/modules/professions/services/question-api.service';

@Component({
  components: { ConfirmDeleteModal }
})
export default class QuestionListItem extends Vue {
  @Prop() public professionId!: string;
  @Prop() public data!: QuestionDto;
  @Prop() public isReadOnly!: boolean;
  @Prop() public questionListLength!: number;

  private readonly apiClient = new QuestionApiService();
  private deleteDialog = false;

  private showDeleteConfirmation() {
    this.deleteDialog = true;
  }

  private editQuestion() {
    if (this.isReadOnly) {
      return;
    }

    this.$emit('edit', this.data);
  }

  private async deleteInstance(): Promise<void> {
    const inputModel = new QuestionDeleteInputModel({
      questionId: this.data.id
    });

    const result = await this.apiClient.deleteQuestion(inputModel);

    if (result) {
      this.$emit('question-deleted', this.data.id);
      NotificationService.success(translate('Modules.Professions.Notifications.QuestionDeleted'));
    }

    this.closeDialogs();
  }

  private cancelDelete() {
    this.closeDialogs();
  }

  private closeDialogs() {
    this.deleteDialog = false;
  }
}
