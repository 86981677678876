
















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PlayerProfileDto } from '@/services/Api';
import { VueEditor } from 'vue2-editor';
import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';

@Component({
  components: {
    VueEditor,
    ConfirmDeleteModal
  }
})
export default class StatisticsPlayerOverview extends Vue {
  @Prop() readonly data!: PlayerProfileDto;

  public notes = this.data.notes;
  public showEditor = false;
  public showDeleteModal = false;
  public expanded = false;
  public showExpandButton = true;

  customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],
    ['clean'] // remove formatting button
  ];

  get nicknames() {
    return this.data.nicknames?.join(', ');
  }
  get professions() {
    return this.data.professions?.join(', ');
  }
  get nicknamesTooltip() {
    return this.data.nicknames?.join('<br />');
  }
  get professionsTooltip() {
    return this.data.professions?.join('<br />');
  }
  get emailMessage() {
    return `mailto:${this.data.email}?subject=${this.$t('Modules.Statistics.PlayerProfile.EmailSubject')}&body=${
      this.emailMessageBody
    }`;
  }
  get emailMessageBody() {
    return this.$t('Modules.Statistics.PlayerProfile.EmailBody', {
      name: this.data.nicknames ? this.data.nicknames[0] : ''
    });
  }
  get avatarColor() {
    return this.data.isFavorite ? '#F7EBD6' : '#F1F5F5';
  }
  get avatarIconColor() {
    return this.data.isFavorite ? '#cfcbc3' : '#C7CACA';
  }

  mounted() {
    this.$nextTick(() => this.isTextClamped());
  }

  updated() {
    this.$nextTick(() => this.isTextClamped());
  }

  clearNotes() {
    this.notes = '';
    this.showDeleteModal = false;
    this.$emit('updateNotes', this.notes);
  }

  downloadCV(email: string) {
    this.$emit('downloadCV', email);
  }

  updateRating(newRating: number) {
    this.$emit('updateRating', newRating);
  }

  updateIsFavorite() {
    this.$emit('updateIsFavorite');
  }

  cancelNotes() {
    this.showEditor = false;
    this.notes = this.data.notes;
  }

  updateNotes() {
    this.showEditor = false;
    this.$emit('updateNotes', this.notes);
  }

  async isTextClamped() {
    const element: any = this.$refs.notes;
    if (!element || this.expanded) return true;

    this.showExpandButton = element.clientHeight < element.scrollHeight;
  }
}
