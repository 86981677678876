
































































































import { InstancePlayerGameStatisticsDto } from '@/services/Api';
import GlobalCountsBarForPlayer from '@/modules/statistics/components/GlobalCountsBarForPlayer.vue';
import { Duration, Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import UserApiService from '@/modules/statistics/services/statistic-api.service';
import { store } from '@/store/store';

@Component({
  components: {
    GlobalCountsBarForPlayer
  }
})
export default class StatisticsPlayerGamesTable extends Vue {
  @Prop() readonly data!: InstancePlayerGameStatisticsDto[];
  @Prop() readonly exporting!: boolean;
  @Prop() readonly loadingCVButton!: boolean;
  @Prop() readonly globalPlayerInfo!: GlobalCountsBarForPlayer;
  private tablesSize = 5;
  private search = '';
  private localLoading = true;
  goToGameStatistic(instanceId: string) {
    this.$emit('game-statistics', instanceId);
  }
  mounted() {
    this.localLoading = true;
    this.tablesSize = store.getTableSize('Players-Games-statistics');
    this.localLoading = false;
  }
  @Watch('tablesSize')
  onPropertyChanged(value: number, oldValue: string) {
    this.tablesSize = value;
    store.setTables('Players-Games-statistics', this.tablesSize);
  }
  get headers() {
    return [
      {
        text: 'Email',
        align: 'left',
        value: 'email'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Profession'),
        value: 'profession'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.StartingTime'),
        value: 'startingTime'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.PlayingTime'),
        value: 'playingTime'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.TotalAnswers'),
        value: 'totalAnswers'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.CorrectAnswers'),
        value: 'correctAnswers'
      },
      {
        text: this.$t('Modules.Statistics.GamesTable.Score'),
        value: 'score'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.CV'),
        value: 'cv'
      },
      { value: 'button', sortable: false }
    ];
  }
  private availableColors = [
    'teal',
    'light-green',
    'purple',
    'blue-grey',
    'brown',
    'yellow',
    'purple',
    'cyan',
    'red',
    'deep-orange',
    'deep-purple',
    'blue',
    '#9089E7',
    '#E85C39'
  ];
  takenColors: { [id: string]: string } = {};

  getColor(profession: string) {
    if (this.takenColors[profession]) {
      return this.takenColors[profession];
    } else {
      if (profession) {
        const color = this.availableColors.pop() ?? 'pink';
        this.takenColors[profession] = color;
      }
    }
    return this.takenColors[profession];
  }

  formatDuration(item: Duration) {
    if (item == null) {
      return '00:00';
    }
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }

  emitExportCsv() {
    this.$emit('export-csv');
  }

  customSort(items: any[], index: any, isDesc: boolean[]) {
    const isDescending = isDesc[0];

    items.sort((a, b) => {
      if (index[0] === 'startingTime') {
        if (a[index] == null) {
          return isDescending ? -1 : 1;
        }
        if (b[index] == null) {
          return isDescending ? 1 : -1;
        }

        const left = a[index] as Moment;
        const right = b[index] as Moment;

        const compare = isDescending ? left.isAfter(right) : left.isBefore(right);

        return compare ? -1 : 1;
      } else if (index[0] === 'playingTime') {
        if (a[index] == undefined || b[index] == undefined) {
          return isDescending ? -1 : 1;
        }
        const left = (a[index] as Duration)?.asSeconds();
        const right = (b[index] as Duration)?.asSeconds();
        console.log({ left });
        console.log({ right });
        const compare = isDescending ? left > right : right > left;

        return compare ? -1 : 1;
      } else {
        if (!isDescending) {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      }
    });
    return items;
  }
  emitDownloadCv(instanceId: string, playerProfession: string) {
    this.$emit('download-cv', instanceId, playerProfession);
  }
}
