

















































import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n, translate } from '@/plugins/i18n';
import {
  ProfessionCreateInputModel,
  ProfessionDto,
  QuestionAnswerInputModel,
  QuestionCreateInputModel,
  QuestionDto
} from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Vue } from 'vue-property-decorator';
import { VForm } from '../../layout/components/forms/v-form';
import ProfessionApiService from '../services/profession-api.service';
import QuestionApiService from '@/modules/professions/services/question-api.service';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component
export default class CreateProfession extends Vue {
  rules = {
    required: StringValidators.required,
    maxLength: (model: string) => StringValidators.maxLength(model, 50)
  };

  public name = 'new-profession';
  public displayName = 'New profession';
  public areTemplatesAvailable = false;
  public copyFromTemplate = false;
  public professionTemplates: Array<ProfessionDto> = [];
  public selectedTemplate: string | null = null;
  public templateSelectorLabel = '';

  private readonly apiService = new ProfessionApiService();
  private readonly questionApiService = new QuestionApiService();
  private loading = true;
  private questions: Array<QuestionDto> = [];
  private dialog = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ProfessionList'),
        exact: true,
        disabled: false,
        to: { name: ProfessionsRoute.professionList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Create'),
        exact: true,
        disabled: true
      }
    ];
  }

  private async mounted() {
    this.loading = true;

    this.professionTemplates = await this.fetchProfessionTemplates();
    this.selectedTemplate = this.getDefaultSelectedTemplate();
    this.areTemplatesAvailable = this.professionTemplates.length > 0;
    this.templateSelectorLabel = this.areTemplatesAvailable
      ? translate('Modules.Professions.CopyQuestionsFrom')
      : translate('Modules.Professions.TemplatesNotAvailable');

    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }

  private cancel() {
    this.$router.push({ name: ProfessionsRoute.professionList });
  }

  private async fetchProfessionTemplates(): Promise<ProfessionDto[]> {
    const result = await this.apiService.getProfessionsOfCurrentUser();

    return result ? result : [];
  }

  private getDefaultSelectedTemplate(): string | null {
    return this.professionTemplates.length > 0 ? this.professionTemplates[0].id : null;
  }

  private async submit() {
    const form = this.$refs.form as VForm;

    if (!form.validate()) {
      FormHelper.scrollToNextError(this);
      return;
    }

    this.loading = true;

    const inputModel = new ProfessionCreateInputModel({
      name: this.name,
      displayName: this.displayName,
      copiedProfessionId: this.copyFromTemplate ? this.selectedTemplate ?? null : null
    });

    const result = await this.apiService.createProfession(inputModel);

    if (result) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.addProfession, {
        profession_id: result.id,
        profession_name: inputModel.name,
        template_id: inputModel.copiedProfessionId
      });
      if (!this.copyFromTemplate) {
        this.createDefaultQuestion(result.id);
      }

      await this.$router.push({ name: ProfessionsRoute.professionList });
      NotificationService.success(translate('Modules.Professions.Notifications.ProfessionCreated'));
    }

    this.loading = false;
  }

  private async createDefaultQuestion(id: string) {
    const inputModel = new QuestionCreateInputModel({
      professionId: id,
      questionText: i18n.t('Modules.Professions.DefaultQuestion.QuestionText').toString(),
      answers: [
        new QuestionAnswerInputModel({
          answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer1').toString(),
          isCorrect: true
        }),
        new QuestionAnswerInputModel({
          answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer2').toString(),
          isCorrect: false
        }),
        new QuestionAnswerInputModel({
          answerText: i18n.t('Modules.Professions.DefaultQuestion.Answer3').toString(),
          isCorrect: false
        })
      ]
    });

    const result = await this.questionApiService.createQuestion(inputModel);
    return result;
  }
}
