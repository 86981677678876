



















import GameInstanceCustomizeForm from '@/modules/game-instance/components/GameInstanceCustomizeForm.vue';
import { GameInstanceModel } from '@/modules/game-instance/models/game-instance.model';
import { GameTemplatesModel } from '@/modules/game-instance/models/game-templates.model';
import { GameMainSettingsModel } from '@/modules/game-play/games/customize-settings.model';
import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n } from '@/plugins/i18n';
import { GameInstanceCreateInputModel, GameInstanceDto } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { Component, Vue } from 'vue-property-decorator';
import UserApiService from '../services/game-instance-api.service';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component({
  components: { GameInstanceCustomizeForm }
})
export default class GameInstanceCreate extends Vue {
  public instanceSettings: GameInstanceModel = {} as GameInstanceModel;
  public gameTemplates: GameTemplatesModel = {} as GameTemplatesModel;
  private loading = false;
  rules = {
    required: StringValidators.required
  };
  apiService = new UserApiService();

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        exact: true,
        disabled: false,
        to: { name: GameInstanceRoute.gameInstanceList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Create'),
        exact: true,
        disabled: true
      }
    ];
  }

  async mounted() {
    this.instanceSettings = new GameInstanceModel({} as GameInstanceDto);
    this.instanceSettings.isPublic = false;
    const games = await this.apiService.getGameTemplatesForCreateDropdown();
    if (games) {
      this.gameTemplates = new GameTemplatesModel(games);
    }
    store.setBreadcrumb(this.breadcrumbData);
  }

  async submit() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      FormHelper.scrollToNextError(this);
      return;
    }
    this.loading = true;

    const result = await this.apiService.createGameInstance(
      new GameInstanceCreateInputModel({
        gameTemplateId: this.gameTemplates.selectedGame,
        title: this.instanceSettings.gameTitle,
        isPublic: this.instanceSettings.isPublic,
        validUntil: null,
        settings: JSON.stringify(new GameMainSettingsModel())
      })
    );

    if (result) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.addGameInstance, {
        instance_id: result.id,
        instance_title: result.title,
        template_id: result.gameTemplateId,
        template_title: result.gameTemplate?.title
      });

      await this.$router.push({
        name: GameInstanceRoute.gameInstanceEdit,
        params: { gameInstanceId: result.id }
      });
    }

    this.loading = false;
    NotificationService.success(i18n.t('Modules.GameInstance.Notifications.Success.GameCreated').toString());
  }
}
