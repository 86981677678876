import SinkListModel from './sink-list.model';

import EditCardMainPreview from '@/modules/kitchen-sink/Previews/EditCardMainPreview.vue';
import EditCardSectionPreview from '@/modules/kitchen-sink/Previews/EditCardSectionPreview.vue';
import EmptyListContainerPreview from '@/modules/kitchen-sink/Previews/EmptyListContainerPreview.vue';
import ListMenuPreview from '@/modules/kitchen-sink/Previews/ListMenuPreview.vue';
import ListItemHeaderPreview from '@/modules/kitchen-sink/Previews/ListItemHeaderPreview.vue';
import TableItemHeaderPreview from '@/modules/kitchen-sink/Previews/TableItemHeaderPreview.vue';
import FormTextInputPreview from '@/modules/kitchen-sink/Previews/FormTextInputPreview.vue';
import FormTextInfoPreview from '@/modules/kitchen-sink/Previews/FormTextInfoPreview.vue';
import FormAutocompleteSelectPreview from '@/modules/kitchen-sink/Previews/FormAutocompleteSelectPreview.vue';
import FormSelectPreview from '@/modules/kitchen-sink/Previews/FormSelectPreview.vue';
import FormConditionsCheckboxPreview from '@/modules/kitchen-sink/Previews/FormConditionsCheckboxPreview.vue';
import SkeletonLoaderDetailsPreview from '@/modules/kitchen-sink/Previews/SkeletonLoaderDetailsPreview.vue';
import IntersectMenuSectionPreview from '@/modules/kitchen-sink/Previews/IntersectMenuSectionPreview.vue';
import ListContainerPreview from '@/modules/kitchen-sink/Previews/ListContainerPreview.vue';
import FormCheckboxPreview from '@/modules/kitchen-sink/Previews/FormCheckboxPreview.vue';
import DetailRowPreview from '@/modules/kitchen-sink/Previews/DetailRowPreview.vue';
import CustomDataTablePreview from '@/modules/kitchen-sink/Previews/CustomDataTablePreview.vue';
/*
Kitchen sink element entry: cf-ts-kitchen-sink-element-entry
*/
export const elementsList: SinkListModel[] = [
  {
    title: `Edit card main`,
    description: ``,
    navigation: `Edit main`,
    html: `   <edit-card-main :title="title"> </edit-card-main>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component  
    export default class EditCardMainPreview extends Vue {
        title = this.$t('KitchenSink.Previews.EditCardMainPreview.Title');
    }`,
    snippet: ``,
    preview: EditCardMainPreview
  },
  {
    title: `Edit card section`,
    description: ``,
    navigation: `Edit section`,
    html: `   <edit-card-section :title="title" :expanded="expanded" :collapsible="collapsible"> </edit-card-section>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component   
    export default class EditCardSectionPreview extends Vue {
        title = this.$t('KitchenSink.Previews.EditCardSectionPreview.Title');
        expanded = true;
        collapsible = false;
    }`,
    snippet: ``,
    preview: EditCardSectionPreview
  },
  {
    title: `Empty list container`,
    description: ``,
    navigation: `Empty container`,
    html: `   <empty-list-container :text="text" :routeName="routeName"> </empty-list-container>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';
    import { KitchenSinkRoute } from '@/modules/kitchen-sink/kitchen-sink-router.enum';

    @Component
    export default class EmptyListContainerPreview extends Vue {
        text = this.$t('KitchenSink.Previews.EmptyListContainerPreview.Text');
        routeName = KitchenSinkRoute.kitchenSink;
    }`,
    snippet: `cf-html-container-no-items-display`,
    preview: EmptyListContainerPreview
  },
  {
    title: `List menu`,
    description: ``,
    navigation: `List menu`,
    html: `   <list-menu :disabled="disabled"></list-menu>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class ListMenuPreview extends Vue {
        disabled = false;
    }`,
    snippet: ``,
    preview: ListMenuPreview
  },
  {
    title: `List item header`,
    description: ``,
    navigation: `List header`,
    html: `   <list-item-header :title="title" :subTitle="subTitle" :routeTo="{ name: routeTo }" :icon="icon"></list-item-header>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';
    import { KitchenSinkRoute } from '@/modules/kitchen-sink/kitchen-sink-router.enum';

    @Component
    export default class ListItemHeaderPreview extends Vue {
        title = this.$t('KitchenSink.Previews.ListItemHeaderPreview.Title');
        subTitle = this.$t('KitchenSink.Previews.ListItemHeaderPreview.subTitle');
        routeTo = KitchenSinkRoute.kitchenSink;
        icon = 'mdi-pencil';
    }`,
    snippet: `cf-list-item-header`,
    preview: ListItemHeaderPreview
  },
  {
    title: `Table item header`,
    description: ``,
    navigation: `Table header`,
    html: `   <table-item-header :title="offerTitle" :subTitle="orderNumber"></table-item-header>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class TableItemHeaderPreview extends Vue {
        offerTitle = this.$t('KitchenSink.Previews.TableItemHeaderPreview.Title');
        orderNumber = this.$t('KitchenSink.Previews.TableItemHeaderPreview.SubTitle');
    }`,
    snippet: ``,
    preview: TableItemHeaderPreview
  },
  {
    title: `Form text input`,
    description: ``,
    navigation: `Form input`,
    html: `   <form-text-input :lable="lable" :value="value" :rules="rules"></form-text-input>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class FormTextInputPreview extends Vue {
        lable = this.$t('KitchenSink.Previews.FormTextInputPreview.Label');
        value = this.$t('KitchenSink.Previews.FormTextInputPreview.Value');
        rules: object[] = [];
    }`,
    snippet: ``,
    preview: FormTextInputPreview
  },
  {
    title: `Form text info`,
    description: ``,
    navigation: `Form info`,
    html: `   <form-text-info :label="label" :value="value" :currency="currency"></form-text-info>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';
    import { Currency } from '@/services/Api';
        
    @Component
    export default class FormTextInfoPreview extends Vue {
        currency: Currency = Currency.EUR;
        value = 82.21;
        label = this.$t('KitchenSink.Previews.FormTextInfoPreview.Label');
    }`,
    snippet: ``,
    preview: FormTextInfoPreview
  },
  {
    title: `Form autocomplete select`,
    description: ``,
    navigation: `Form autocomplete`,
    html: `  <form-autocomplete-select
        :label="label"
        :value="value"
        :items="items"
        :itemText="itemText"
        :itemValue="itemValue"
    ></form-autocomplete-select>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class FormAutocompleteSelectPreview extends Vue {
        label = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Label');
        value = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString();
        items: string[] = [
        this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString(),
        this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value1').toString(),
        this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value2').toString()
        ];
        itemText = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Text').toString();
        itemValue = this.$t('KitchenSink.Previews.FormAutocompleteSelectPreview.Value').toString();
    }`,
    snippet: ``,
    preview: FormAutocompleteSelectPreview
  },
  {
    title: `Form select`,
    description: ``,
    navigation: `Form select`,
    html: `   <form-select :label="label" :value="value" :items="items" :itemText="itemText" :itemValue="itemValue"></form-select>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class FormSelectPreview extends Vue {
        label = this.$t('KitchenSink.Previews.FormSelectPreview.Label');
        value = this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString();
        items: string[] = [
        this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString(),
        this.$t('KitchenSink.Previews.FormSelectPreview.Value1').toString(),
        this.$t('KitchenSink.Previews.FormSelectPreview.Value2').toString()
        ];
        itemText = this.$t('KitchenSink.Previews.FormSelectPreview.Text').toString();
        itemValue = this.$t('KitchenSink.Previews.FormSelectPreview.Value').toString();
    }`,
    snippet: ``,
    preview: FormSelectPreview
  },
  {
    title: `Form conditions checkbox`,
    description: ``,
    navigation: `Form checkbox`,
    html: `   <form-conditions-checkbox
        :value="value"
        :newTab="newTab"
        :disabled="disabled"
        :link="link"
        :linkText="linkText"
        :label="label"
    ></form-conditions-checkbox>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class FormConditionsCheckboxPreview extends Vue {
        value = true;
        newTab = true;
        disabled = false;
        link = 'http://www.google.pl';
        linkText = this.$t('KitchenSink.Previews.FormConditionsCheckboxPreview.PrivacyPolicy');
        label = this.$t('KitchenSink.Previews.FormConditionsCheckboxPreview.Iagreeto');
    }`,
    snippet: ``,
    preview: FormConditionsCheckboxPreview
  },
  {
    title: `Form checkbox`,
    description: ``,
    navigation: `Form checkbox 2`,
    html: `   
  <form-checkbox v-model="checkboxValue" label="Label for Checkbox" :disabled="disabled"></form-checkbox>
`,
    style: ``,
    typescript: `
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormCheckboxPreview extends Vue {
  checkboxValue: bool;
  disabled: bool;
}
`,
    snippet: ``,
    preview: FormCheckboxPreview
  },
  {
    title: `Skeleton loader details`,
    description: ``,
    navigation: `skeleton loader`,
    html: `   <skeleton-loader-details></skeleton-loader-details>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class SkeletonLoaderDetailsPreview extends Vue {}
</script>`,
    snippet: `cf-html-container-details`,
    preview: SkeletonLoaderDetailsPreview
  },
  {
    title: `Intersect menu section`,
    description: ``,
    navigation: `Intersect menu`,
    html: `   <intersect-menu-section :id="id"></intersect-menu-section>`,
    style: ``,
    typescript: `    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class IntersectMenuSectionPreview extends Vue {
        id = '';
    }`,
    snippet: ``,
    preview: IntersectMenuSectionPreview
  },
  {
    title: `List`,
    description: ``,
    navigation: `List`,
    html: `
<template>
  <list-container>
    <template v-slot:header>
    // This header have two columns with 6 cols each. 
    // Remember to use the same setup for list item
      <v-col cols="6">
        Header 1
      </v-col>
      <v-col cols="6">
        Header 2
      </v-col>
    </template>
    <template v-slot:items>
      <list-item v-for="item in items" :key="item.id">
        // If you want to extract list item to component you must wrap it with
        // v-row with margin and padding set to 0 and with align="center"
        // like below:
        <v-row class="pa-0 ma-0" align="center">
          <v-col cols="6">
            {{ item.column1 }}
          </v-col>
          <v-col cols="6">
            {{ item.column2 }}
          </v-col>
        </v-row>    
      </list-item>
    </template>
  </list-container>
</template>
`,
    style: ``,
    typescript: `    
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ListContainerPreview extends Vue {
  items = [
    {
      column1: 'Item 1 Column 1',
      column2: 'Item 1 Column 2'
    },
    {
      column1: 'Item 2 Column 1',
      column2: 'Item 2 Column 2'
    }
  ];
}
`,
    snippet: ``,
    preview: ListContainerPreview
  },
  {
    title: `Detail row`,
    description: ``,
    navigation: `Detail row`,
    html: `<detail-row :labelIcon="'mdi-earth'" :label="label" :value="value"></detail-row>`,
    style: ``,
    typescript: `import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class DetailRowPreview extends Vue {
      label = this.$t('KitchenSink.Previews.DetailRowPreview.Label');
      value = this.$t('KitchenSink.Previews.DetailRowPreview.Value');
    }`,
    snippet: ``,
    preview: DetailRowPreview
  },
  {
    title: `Custom data table`,
    description: ``,
    navigation: `Custom data table`,
    html: `<template>
    <custom-data-table
      :id="'Custom-data-table-preview'"
      :headers="headers"
      :items="data"
      :customSearch="customFilter"
      :columnFilters="filters"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.value }}</td>
          <td>{{ row.item.number }}</td>
        </tr>
      </template>
    </custom-data-table>
  </template>`,
    style: ``,
    typescript: `import { Component, Prop, Vue } from 'vue-property-decorator';
    import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
    import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';
    
    @Component
    export default class CustomDataTablePreview extends Vue {
      @Prop() data: any;
    
      created() {
        this.data = [
          { id: 1, value: 'value 1', number: 10 },
          { id: 2, value: 'value 2', number: 15 },
          { id: 3, value: 'value 3', number: 25 }
        ];
      }
    
      get headers() {
        return [
          {
            text: 'Id',
            value: 'id',
            sort: DataTableSortingHelper.sortByDefault
          },
          { text: 'Value', value: 'value', sort: DataTableSortingHelper.sortByDefault },
          { text: 'Number', value: 'number', sort: DataTableSortingHelper.sortByDefault }
        ];
      }
    
      get filters() {
        return [
          { value: 'value', items: this.data.map((d: any) => d.value) },
          {
            value: 'number',
            items: ['<=10'],
            filter: (value: any, search: any, item: any, activeFilters: any) => {
              if (activeFilters.number.includes('<=10')) {
                return value <= 10;
              } else {
                return true;
              }
            }
          }
        ];
      }
    
      customFilter(value: any, search: string | null, item: any) {
        if (!search) {
          return true;
        }
    
        return (
          DataTableFilterHelper.filterByString(item.id, search) || DataTableFilterHelper.filterByString(item.value, search)
        );
      }
    }`,
    snippet: ``,
    preview: CustomDataTablePreview
  }
];
