






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { ProfessionDeleteInputModel } from '@/services/Api';
import QuestionList from '@/modules/professions/components/QuestionList.vue';
import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import ProfessionApiService from '../services/profession-api.service';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';

@Component({
  components: { QuestionList, ConfirmDeleteModal }
})
export default class ProfessionListItem extends Vue {
  @Prop() public id!: string;
  @Prop() public name!: string;
  @Prop() public displayName!: string;
  @Prop() public isGlobal!: boolean;

  private readonly apiClient = new ProfessionApiService();
  private deleteDialog = false;
  private menuIsShowed = false;
  private questionDialog = false;
  public professionsRoute = ProfessionsRoute;

  private showDeleteConfirmation(): void {
    this.deleteDialog = true;
  }

  private async deleteInstance(): Promise<void> {
    const inputModel = new ProfessionDeleteInputModel({
      professionId: this.id
    });

    const result = await this.apiClient.deleteProfession(inputModel);

    if (result) {
      this.$emit('profession-deleted', this.id);
      NotificationService.success(translate('Modules.Professions.Notifications.ProfessionDeleted'));
    }

    this.closeMenus();
  }

  private cancelDelete(): void {
    this.closeMenus();
  }

  private closeMenus(): void {
    this.questionDialog = false;
    this.deleteDialog = false;
    this.menuIsShowed = false;
  }
}
