import { EnemyVehicle } from './Models/EnemyVehicle';
export class EnemyVehicleGroup extends Phaser.Physics.Arcade.Group {
  rightOrLeft: boolean;
  enemyWidth!: number;
  enemyHeight!: number;
  spriteNameVehicle!: string;
  increaseSizeSprite = 1.7;
  constructor(scene: Phaser.Scene, spriteName: string, width?: number, height?: number) {
    super(scene.physics.world, scene);
    this.rightOrLeft = true;
    if (height != undefined) {
      this.enemyHeight = height;
    }
    if (width != undefined) {
      this.enemyWidth = width;
    }
    this.spriteNameVehicle = spriteName;
  }
  start(name: string) {
    this.createMultiple({
      quantity: 3,
      key: name,
      active: false,
      visible: false,
      classType: EnemyVehicle
    });
  }
  deleteObject() {
    this.children.each(val => {
      const val2 = val as EnemyVehicle;
      val2.destroyAll();
    });
  }
  createEnemys(position: number, vehicleSpeed: number, objectSpeed: number, rotate: boolean) {
    this.start(this.spriteNameVehicle);
    const enemy = this.getFirstDead(false);
    enemy.addSprite(this.spriteNameVehicle, this.enemyHeight, this.enemyWidth,rotate);

    enemy.displayHeight = this.enemyHeight;
    enemy.displayWidth = this.enemyWidth;

    enemy.createEnemy(position, objectSpeed);
    if (objectSpeed == 0) {
      enemy.body.setSize(this.enemyWidth * this.increaseSizeSprite, this.enemyHeight * this.increaseSizeSprite);
      enemy.setFirstSpeed(vehicleSpeed);
      if(rotate){
        enemy.setVelocityY(vehicleSpeed * 1.6);
      }else{
        enemy.setVelocityY(vehicleSpeed);
      }
      
    } else {
      enemy.body.setSize(this.enemyWidth / 2, this.enemyHeight / 2);
    }
  }
}
