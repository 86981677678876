import { KeySide } from '../Models/KeySide';
import { MazeGameVariables } from '../Global/MazeGameVariables';
export class Player extends Phaser.Physics.Arcade.Sprite {
  speedOnX: number;
  maxspeedOnX: number;
  speedOnY: number;
  maxspeedOnY: number;
  texturess!: Phaser.Textures.Texture;
  playerBorderMaxOnX!: number;
  playerBorderMinOnX!: number;
  playerBorderMaxOnY!: number;
  playerBorderMinOnY!: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    speedOnX: number,
    maxspeedOnX: number,
    speedOnY: number,
    maxspeedOnY: number,
    playerBorderMaxOnX: number,
    playerBorderMinOnX: number,
    playerBorderMaxOnY: number,
    playerBorderMinOnY: number,
    widthPlayer: number,
    heightPlayer: number
  ) {
    super(scene, x, y, '__PLAYER');
    scene.physics.add.existing(this);
    this.speedOnX = speedOnX;
    this.maxspeedOnX = maxspeedOnX;
    this.speedOnY = speedOnY;
    this.maxspeedOnY = maxspeedOnY;
    scene.add.existing(this);
    this.displayHeight = heightPlayer;
    this.displayWidth = widthPlayer;

    this.playerBorderMaxOnX = playerBorderMaxOnX;
    this.playerBorderMinOnX = playerBorderMinOnX;
    this.playerBorderMaxOnY = playerBorderMaxOnY;
    this.playerBorderMinOnY = playerBorderMinOnY;
  }

  //Move player with animation
  move(side: KeySide, delta: number) {
    switch (side) {
      case KeySide.right:
        if (this.body.x <= this.playerBorderMaxOnX) {
          if (this.body.velocity.x <= this.maxspeedOnX) {
            this.body.velocity.x += this.speedOnX * delta;
            this.scene.tweens.add({
              targets: this,
              angle: { from: 0, to: 15 },
              ease: 'Linear',
              duration: 300
            });
          }
        } else {
          this.angle = 0;
          this.body.velocity.x = 0;
        }
        
        break;
      case KeySide.left:
        if (this.body.x >= this.playerBorderMinOnX) {
          if (this.body.velocity.x >= -this.maxspeedOnX) {
            this.body.velocity.x += -this.speedOnX * delta;
            this.scene.tweens.add({
              targets: this,
              angle: { from: 0, to: -15 },
              ease: 'Linear',
              duration: 300
            });
          }
        } else {
          this.angle = 0;
          this.body.velocity.x = 0;
        }

        break;
      case KeySide.up:
        if (this.body.y >= this.playerBorderMinOnY) {
          if (this.body.velocity.y >= -this.maxspeedOnY) {
            this.body.velocity.y += -this.speedOnY * delta;
          }
        } else {
          this.body.velocity.y = 0;
        }

        break;
      case KeySide.down:
        if (this.body.y <= this.playerBorderMaxOnY) {
          if (this.body.velocity.y <= this.maxspeedOnY) {
            this.body.velocity.y += this.speedOnY * delta;
          }
        } else {
          this.body.velocity.y = 0;
        }

        break;
      case KeySide.stop:
        this.angle = 0;
        this.body.velocity.x = 0;
        this.body.velocity.y = 0;
        break;
    }
  }
}
