
































































































import { PlayerInstanceInputModel, RuntimeGameInstanceDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameMainSettingsModel } from './games/customize-settings.model';
import { GameCustomizableElement, GameCustomizableElementType } from './games/game-engine-customizable-element';
import GameApiService from './services/game-api.service';
import GameFactory from './services/game-factory.service';
import { store } from '@/store/store';

@Component
export default class GameMenu extends Vue {
  @Prop({ required: true }) readonly gameInstance!: RuntimeGameInstanceDto;
  @Prop({ required: false }) readonly oldPlayerModel?: PlayerInstanceInputModel;

  public playerModel = new PlayerInstanceInputModel();
  public checked = false;
  public message = '';
  private loading = false;
  private apiService = new GameApiService();
  private gameSettings = new GameMainSettingsModel();

  mounted() {
    this.playerModel = this.setUpPlayerModel();

    if (this.oldPlayerModel) {
      this.checked = true;
    }

    if (this.gameInstance.settings) {
      const gameSettings = JSON.parse(this.gameInstance.settings ?? '') as GameMainSettingsModel;
      this.gameSettings = gameSettings;
    }

    this.$nextTick(() => this.preloadGameImages());
  }
  private setUpPlayerModel(): PlayerInstanceInputModel {
    const playerModalSaved = store.getPlayerInstance;

    if (playerModalSaved) {
      return new PlayerInstanceInputModel({
        professionId: this.getSavedProfession(playerModalSaved),
        email: playerModalSaved.email ?? '',
        name: playerModalSaved.name ?? '',
        gameInstanceId: this.$route.params.id
      });
    } else {
      return new PlayerInstanceInputModel({
        professionId: this.gameInstance.professions ? this.gameInstance.professions[0].id : '',
        email: '',
        name: '',
        gameInstanceId: this.$route.params.id
      });
    }
  }
  private getSavedProfession(playerModalSaved: PlayerInstanceInputModel): string {
    if (!this.gameInstance.professions) {
      return '';
    }
    if (this.gameInstance.professions.find(x => x.id === playerModalSaved.professionId)) {
      return playerModalSaved.professionId;
    }
    return this.gameInstance.professions[0].id;
  }

  private preloadGameImages() {
    const elements = GameFactory.getCustomizableElements(this.gameInstance.type);
    if (elements) {
      this.preloadCustomizableElements(elements);
    }
    if (this.gameSettings.gameCustomizableElements) {
      this.preloadCustomizableElements(this.gameSettings.gameCustomizableElements);
    }
  }

  private preloadCustomizableElements(elements: GameCustomizableElement[]) {
    const imageElements = elements.filter(e => e.type === GameCustomizableElementType.Image);
    imageElements?.forEach(element => {
      const img = new Image();
      img.src = element.value as string;
    });
  }

  async submit() {
    if (this.validate() == false || this.loading) {
      return;
    }

    this.loading = true;

    const result = await this.apiService.createPlayerInstance(this.playerModel);
    if (result) {
      this.startGame(result.id, this.playerModel);
    }

    store.setPlayerInstance(this.playerModel);

    this.loading = false;
  }

  startGame(playerInstanceId: string, playerModel: PlayerInstanceInputModel): void {
    this.$emit('startGame', playerInstanceId, playerModel);
  }

  private validate(): boolean {
    if (!this.validateEmail(this.playerModel.email)) {
      this.message = this.$t('Modules.Runtime.Menu.WarningEmail').toString();
      return false;
    } else if (this.playerModel.name == null || this.playerModel.name == '') {
      this.message = this.$t('Modules.Runtime.Menu.WarningName').toString();
      return false;
    } else if (this.playerModel.professionId == null) {
      this.message = this.$t('Modules.Runtime.Menu.WarningJob').toString();
      return false;
    } else if (this.checked == false) {
      this.message = this.$t('Modules.Runtime.Menu.WarningConsent').toString();
      return false;
    }
    this.message = '';

    return true;
  }
  private validateEmail(email: string | null | undefined): boolean {
    if (!email) {
      return false;
    }
    return !!email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
}
