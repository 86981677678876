export class RiverRunVariables {
  static readonly playerSpriteName = '__PLAYER';
  static readonly enemy1SpriteName = '__ENEMY1';
  static readonly enemy2SpriteName = '__ENEMY2';
  static readonly enemy3SpriteName = '__ENEMY3';
  static readonly object1SpriteName = '__OBJ1';
  static readonly roadSpriteName = '__ROAD';
  static readonly bgBackSpriteNameLeft = '__BG2Left';
  static readonly bgBackSpriteNameRight = '__BG2Right';
  static readonly treeSpriteName = '__TREE';
  static readonly heartSpriteName = '__HEART';
  static readonly LivesName = 'LivesNumber';
  static readonly person1 = '__PERSON1';
  static readonly endLevelObj = '__ENDLEVELOBJ';
  static readonly odometer = '__ODOMETER';
  static readonly odometerLine = '__ODOMETERLINE';
  static readonly staminaBottom = '__STAMINABOTTOM';
  static readonly staminaTop = '__STAMINATOP';
  static readonly staminaColor = '__STAMINACOLOR';
}
