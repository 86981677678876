




















import { Component, Vue } from 'vue-property-decorator';
import { PaymentsAdminDto } from '@/services/Api';
import PaymentsApiService from '../services/admin-payments-api.service';
import PaymentsDetails from '../components/PaymentsDetails.vue';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store/store';

@Component({
  components: { PaymentsDetails }
})
export default class PaymentsDetailsPage extends Vue {
  private apiService = new PaymentsApiService();
  private paymentDetails!: PaymentsAdminDto;
  private loading = true;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.PaymentsAdmin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.paymentsList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Details'),
        exact: true,
        disabled: true
      }
    ];
  }
  async mounted() {
    await this.getPayment();
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  async getPayment() {
    const paymentDetails = await this.apiService.getPayment(this.$route.params.id);
    if (paymentDetails) {
      this.paymentDetails = paymentDetails;
    }
  }
  private cancel() {
    this.$router.push({ name: AdminUsersRoute.paymentsList });
  }
}
