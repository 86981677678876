import { Levels } from './Levels';
import { MazeGameVariables } from './MazeGameVariables';
import 'phaser';
//Class responsible for base game values
export class Base {
  constructor(lives: number, scene: Phaser.Scene) {
    this.values(lives, scene);
  }

  scene!: Phaser.Scene;
  objectsSpawnTime!: number;
  backgroundWidth!: number;
  vehicleSpeed!: number;
  backgroundHeigth!: number;
  backgroundBackWidth!: number;
  backgroundBackHeigth!: number;
  backgroundSpeed!: number;
  playerPozX!: number;
  playerPozY!: number;
  playerSpeedOnX!: number;
  playerMaxSpeedOnX!: number;
  playerSpeedOnY!: number;
  playerMaxSpeedOnY!: number;
  playerBorderMaxOnX!: number;
  playerBorderMinOnX!: number;
  playerBorderMaxOnY!: number;
  playerBorderMinOnY!: number;
  playerWidth!: number;
  playerHeigth!: number;
  life!: number;
  levels: Array<Levels> = [];
  values(lives: number, scene: Phaser.Scene) {
    this.scene = scene;
    this.objectsSpawnTime = 3000;
    this.vehicleSpeed = 150;
    this.backgroundWidth = 500;
    this.backgroundHeigth = 800;
    this.backgroundBackWidth = 800;
    this.backgroundBackHeigth = 800;
    this.backgroundSpeed = 5;
    this.playerPozX = 400;
    this.playerPozY = 530;
    this.life = lives;
    this.playerSpeedOnX = 30;
    this.playerMaxSpeedOnX = 250;
    this.playerSpeedOnY = 30;
    this.playerMaxSpeedOnY = 150;
    this.playerBorderMaxOnX = 600;
    this.playerBorderMinOnX = 150;
    this.playerBorderMaxOnY = 520;
    this.playerBorderMinOnY = 300;
    this.playerWidth = 50;
    this.playerHeigth = 80;

    const spawnTime: Array<number> = [1500, 1200, 1800];
    const wallHeight = 50;
    this.levels.push(new Levels(1, 10000,  spawnTime, this.scene, 3, 5, 2));
    this.levels.push(new Levels(2, 20000,  spawnTime, this.scene, 4, 4, 1));
    this.levels.push(new Levels(3, 20000,  spawnTime, this.scene, 5, 6, 2));
    this.levels.push(new Levels(4, 40000,  spawnTime, this.scene, 7, 7, 1));
    this.levels.push(new Levels(5, 50000,  spawnTime, this.scene, 8, 8, 1));
  }
}
