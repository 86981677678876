import { errorTypes } from './errorTypes';
import NotificationService from '@/services/notification-service';
export class ErrorControl {
  static createConsoleError(errorType: errorTypes, errorMessage: string, showNotofocation: boolean) {
    if (showNotofocation) {
      this.createNotificationError(errorType, errorMessage);
    } else {
      console.error('Error ' + errorType + ': ' + errorMessage);
    }
  }
  static createNotificationError(errorType: errorTypes, errorMessage: string) {
    NotificationService.error('Error ' + errorType + ': ' + errorMessage);
  }
  static getType() {
    return errorTypes;
  }
}
