import {
    PaymentsAdminClient,
    PaymentsAdminDto,
    PaymentsClient
  } from '@/services/Api';
  import ApiServiceBase from '@/services/api-service.base';
  
  export default class PaymentsApiService extends ApiServiceBase {
    private client = new PaymentsAdminClient();
    private clientInvoice = new PaymentsClient();
    public async GetPaymentsList(): Promise<PaymentsAdminDto[] | undefined> {
      try {
        const discountList = await this.client.getPaymentsList();
        return discountList.result;
      } catch (error) {
        this.HandleError(error);
      }
    }
   
    public async getPayment(inputModel: string): Promise<PaymentsAdminDto | undefined> {
      try {
        const user = await this.client.getPayment(inputModel);
        return user.result;
      } catch (error) {
        this.HandleError(error);
      }
    }
    public async getInvoice(id: string) {
      try {
        const response = await this.clientInvoice.getInvoice(id);
        return response.result;
      } catch (error) {
        this.HandleError(error);
      }
    }
  }
  