import 'phaser';
export default class Animations {
  static animateOilSpin(player: any, scene: Phaser.Scene) {
    scene.tweens.add({
      targets: player,
      angle: { from: 0, to: 250 },
      ease: 'Linear',
      duration: 500
    });
  }
}
