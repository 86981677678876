import {
  AuthenticateRequest,
  AuthenticateResponse,
  ChangePasswordInputModel,
  ForgotPasswordConfirmationInputModel,
  UserClient,
  UserDto,
  UserRegisterInputModel
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class UserApiService extends ApiServiceBase {
  private client = new UserClient();

  public async register(userData: UserRegisterInputModel): Promise<UserDto | undefined> {
    try {
      const user = await this.client.register(userData);
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async login(userData: AuthenticateRequest): Promise<AuthenticateResponse | undefined> {
    try {
      const user = await this.client.authenticate(userData);
      return user.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async activate(activationId: string): Promise<boolean | undefined> {
    try {
      await this.client.activate(activationId);
      return true;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async forgetPasswordRequest(email: string): Promise<boolean | undefined> {
    try {
      await this.client.forgotPasswordRequest(email);
      return true;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async forgetPasswordConfirm(code: string, newPassword: string): Promise<boolean | undefined> {
    try {
      await this.client.forgotPasswordConfirmation(new ForgotPasswordConfirmationInputModel({ code, newPassword }));
      return true;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async changePassword(oldPassword: string, newPassword: string): Promise<boolean | undefined> {
    try {
      await this.client.changePassword(new ChangePasswordInputModel({ oldPassword, newPassword }));
      return true;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async updateLanguage(): Promise<void> {
    try {
      await this.client.updateLanguage();
    } catch (error) {
      this.HandleError(error);
    }
  }
}
