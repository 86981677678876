


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { store } from '@/store/store';
@Component
export default class CustomDataTable extends Vue {
  @Prop() id!: string;
  @Prop() headers: any;
  @Prop() items: any;
  @Prop() customSearch: any;
  @Prop() columnFilters!: any[];

  private activeFilters: any = {};
  private search = '';
  private tableSize = 10;

  created() {
    this.initFilters();
  }
  mounted() {
    this.tableSize = store.getTableSize(this.id);
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables(this.id, this.tableSize);
  }

  private uniq(a: any) {
    const seen: any = {};
    return a.filter(function(item: any) {
      return Object.prototype.hasOwnProperty.call(seen, item) ? false : (seen[item] = true);
    });
  }

  private initFilters() {
    this.columnFilters.forEach(f => {
      this.$set(this.activeFilters, f.value, this.uniq(f.items));
    });
    this.headers.forEach((h: any) => {
      if (this.activeFilters[h.value]) {
        const columnFilter = this.getColumnFilter(h.value);
        if (columnFilter.filter) {
          this.$set(h, 'filter', (value: any, search: any, item: any) => {
            return columnFilter.filter(value, search, item, this.activeFilters);
          });
        } else {
          this.$set(h, 'filter', (value: any) => {
            return this.activeFilters[h.value].includes(value);
          });
        }
      }
    });
  }

  private toggleAll(col: string) {
    this.$set(this.activeFilters, col, this.uniq(this.columnFilters.find(f => f.value === col).items));
  }

  private clearAll(col: string) {
    this.$set(this.activeFilters, col, []);
  }

  private getColumnFilter(col: string) {
    return this.columnFilters.find(f => f.value === col);
  }
}
