import { Enemy } from './Enemy';
import { SpaceRescueVariables } from '../Global/SpaceRescueVariables';
import { BulletGroup } from './BulletGroup';
export class EnemyGroup extends Phaser.Physics.Arcade.Group {
  bullets: BulletGroup;
  rightOrLeft: boolean;
  emiterEvent: any;
  enemyWidth!: number;
  enemyHeight!: number;
  enemyBgWidth!: number;
  enemyBgHeight!: number;
  rowCount!: number;
  colCount!: number;

  readonly leftSideOffset: number = 150;
  readonly upSideOffset: number = 150;
  readonly downSideOffsetCollision: number = 50;
  readonly redZoneStartOffset = 420; // The red zone is the zone where enemies can't shoot anymore, because they're too close to the player

  constructor(scene: Phaser.Scene, emiterEvent: any, width: number, height: number, bgWidth: number, bgHeigth: number, bulletWidth: number, bulletHeight: number) {
    super(scene.physics.world, scene);
    this.rightOrLeft = true;
    this.emiterEvent = emiterEvent;
    this.enemyHeight = height;
    this.enemyWidth = width;
    this.enemyBgHeight = bgHeigth;
    this.enemyBgWidth = bgWidth;
    this.bullets = new BulletGroup(scene, SpaceRescueVariables.enemyBulletSpriteName, bulletWidth, bulletHeight);
  }
  start(countX: number, countY: number) {
    this.createMultiple({
      quantity: countX * countY,
      key: SpaceRescueVariables.enemySpriteName,
      active: false,
      visible: false,
      classType: Enemy
    });
  }
  checkAllEnemy() {
    this.getChildren().forEach(value => {
      const val = value as Enemy;
      if (val.x >= this.scene.game.canvas.width - this.enemyWidth && this.rightOrLeft) {
        this.rightOrLeft = false;
        this.setToAll();
      } else if (val.x <= this.enemyWidth && !this.rightOrLeft) {
        this.rightOrLeft = true;
        this.setToAll();
      }
      if (val.y >= this.scene.game.canvas.height - this.downSideOffsetCollision) {
        this.emiterEvent.emit('UnderCollisionEnemy');
      }
    });
  }

  deleteObject() {
    this.children.each(val => {
      const val2 = val as Enemy;
      val2.destroyAll();
    });
  }
  setToAll() {
    this.getChildren().forEach(function(value) {
      const val = value as Enemy;
      val.speedX = val.speedX * -1;
      val.setVelocityX(val.speedX);
    });
  }

  createEnemys(xCount: number, yCount: number, speedX?: number, speedY?: number) {
    this.deleteObject();
    this.start(xCount, yCount);
    for (let q = 0; q < yCount; q++) {
      for (let w = 0; w < xCount; w++) {
        const enemy = this.getFirstDead(false);
        enemy.body.collideWorldBounds = true;
        enemy.displayHeight = this.enemyHeight;
        enemy.displayWidth = this.enemyWidth;
        enemy.enemyBg.displayHeight = this.enemyBgHeight;
        enemy.enemyBg.displayWidth = this.enemyBgWidth;
        if (speedX != undefined) {
          enemy.speedX = speedX;
        }
        if (speedY != undefined) {
          enemy.speedY = speedY;
        }
        this.rightOrLeft = true;
        if (enemy.speedX <= 0) {
          enemy.speedX *= -1;
        }
        // 1.3 value is responsible for free space between enemys
        const betweenSpaceMultiplier = 1.3;
        if (w <= (xCount - 1) / 2) {
          enemy.createEnemy(
            this.enemyWidth * w * betweenSpaceMultiplier + this.leftSideOffset,
            this.enemyHeight * q * betweenSpaceMultiplier + this.upSideOffset,
            q, w
          );
        } else if (w > (xCount - 1) / 2) {
          enemy.createEnemy(
            this.enemyWidth * w * betweenSpaceMultiplier + this.leftSideOffset,
            this.enemyHeight * q * betweenSpaceMultiplier + this.upSideOffset,
            q, w
          );
        } else {
          enemy.createEnemy(
            this.enemyWidth * w * betweenSpaceMultiplier + this.leftSideOffset,
            this.enemyHeight * q * betweenSpaceMultiplier + this.upSideOffset,
            q, w
          );
        }
      }
    }
    this.rowCount = yCount;
    this.colCount = xCount;
  }

  fireRandomBullet(speed: number): void {
    const validShooterIds = this.getValidShootingEnemyIds();

    if(validShooterIds.length > 0) {
      const randomShooterId = validShooterIds[Math.floor(Math.random() * validShooterIds.length)];
      const enemy = this.getChildren()[randomShooterId] as Enemy;
      this.bullets.fireBullet(enemy.x, enemy.y + enemy.height / 2, -90, speed);
    }
  }

  getValidShootingEnemyIds(): Array<number> {
    const validShooters = new Array<number>(); 

    for (let x = 0; x < this.colCount; x++) {
      const validShooterIdInColumn = this.getValidShootingEnemyIdInColumn(x);

      if (validShooterIdInColumn != -1) {
        validShooters.push(validShooterIdInColumn);
      } 
    }

    return validShooters;
  }

  getValidShootingEnemyIdInColumn(column: number): number {
    const colEnemies = this.getChildren().filter(object => (object as Enemy).colPos == column);

    for (let y = this.rowCount - 1; y >= 0; y--) {
      const enemy = colEnemies[y] as Enemy;

      if (enemy != undefined && !enemy.isDestroyed) { // Is valid enemy object && Is enemy alive
        if (enemy.y >= this.redZoneStartOffset) { // Is enemy in red zone
          return -1; // Ignore whole column
        } else {
          return this.getChildren().indexOf(enemy);
        }  
      }    
    }

    return -1;
  }
}
