import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { ErrorControl } from '../control/errorControl';
export class AudioSystem {
  static initialize(scene: Phaser.Scene, audioNamespaceClass: any, overridenSounds?: { [id: string]: string }) {
    if (!overridenSounds) {
      ErrorControl.createConsoleError(ErrorControl.getType().Get, 'overridenSounds', false);
      return false;
    }
    const arrayNames = audioNamespaceClass.getVariableName();
    const arrayPath = audioNamespaceClass.getVariablePath();
    const totalProps = Object.keys(overridenSounds).length;
    for (let q = 0; q <= totalProps; q++) {
      const tempPath = arrayPath[q];
      scene.load.audio(arrayNames[q], overridenSounds[arrayNames[q]] ?? tempPath);
    }
    return true;
  }
  static addAudio(scene: Phaser.Scene, audioNamespaceClass: any, overridenSounds?: { [id: string]: string }) {
    if (!overridenSounds) {
      ErrorControl.createConsoleError(ErrorControl.getType().Get, 'overridenSounds', false);
      return false;
    }
    const arrayNames = audioNamespaceClass.getVariableName();
    const totalProps = Object.keys(overridenSounds).length;
    for (let q = 0; q <= totalProps; q++) {
      scene.sound.add(arrayNames[q]);
    }
    return true;
  }
  static playAudio(scene: Phaser.Scene, name: string) {
    const audio = scene.sound.get(name);
    if (!audio) {
      return false;
    }
    if (!audio.isPlaying) {
      audio.play();
      return true;
    }
    return false;
  }

  static checkAudioStatus(scene: Phaser.Scene, name: string) {
    const audio = scene.sound.get(name);
    if (!audio) {
      return false;
    }
    if (audio.isPlaying) {
      return true;
    }
    return false;
  }
}
