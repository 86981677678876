




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditCardSectionPreview extends Vue {
  title = this.$t('KitchenSink.Previews.EditCardSectionPreview.Title');
  expanded = true;
  collapsible = false;
}
