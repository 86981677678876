



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AdminUserDeleteUserInputModel, AdminUserListItemDto } from '@/services/Api';
import AdminUserApiService from '../services/admin-user-api.service';
import ConfirmDeleteModal from '@/modules/layout/components/ConfirmDeleteModal.vue';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import NotificationService from '@/services/notification-service';
import { translate } from '@/plugins/i18n';
import { Moment } from 'moment';
import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';

import { store } from '@/store/store';
@Component({
  components: { ConfirmDeleteModal }
})
export default class UserListRow extends Vue {
  @Prop() readonly data!: AdminUserListItemDto[];
  confirmTitle = '';
  private apiService = new AdminUserApiService();
  private search = '';
  private tableSize = 10;
  private toDeleteId = '';
  public adminUsersRoute = AdminUsersRoute;
  dialog = false;
  get headers() {
    return [
      {
        text: this.$t('Modules.AdminPanel.UserList.Email'),
        align: 'start',
        value: 'email',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserList.BuyerIdentifier'),
        value: 'buyerIdentifier',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserList.IsAdmin'),
        value: 'isAdmin',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserList.Active'),
        value: 'active',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserList.Deleted'),
        value: 'isDeleted',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserList.CreateDate'),
        value: 'createdDate',
        sort: DataTableSortingHelper.compareByDate
      },
      { value: 'button', sortable: false }
    ];
  }

  async mounted() {
    this.tableSize = store.getTableSize('Admin-users');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-users', this.tableSize);
  }
  showDeleteConfirmation(id: string, email: string) {
    this.dialog = true;
    this.toDeleteId = id;
    this.confirmTitle = email;
  }
  cancelDelete() {
    this.dialog = false;
    this.toDeleteId = '';
    this.confirmTitle = '';
    this.closeMenus();
  }
  closeMenus() {
    this.toDeleteId = '';
    this.confirmTitle = '';
    this.dialog = false;
  }
  async deleteUser() {
    const result = await this.apiService.deleteUser(
      new AdminUserDeleteUserInputModel({
        id: this.toDeleteId
      })
    );
    if (result) {
      this.$emit('user-deleted');
      this.closeMenus();
      NotificationService.success(translate('Modules.AdminPanel.UserList.Notifications.SuccessDelete'));
    }
  }

  customFilter(value: any, search: string | null, item: AdminUserListItemDto) {
    if (!search) {
      return true;
    }

    return (
      DataTableFilterHelper.filterByString(item.email ?? '', search) ||
      DataTableFilterHelper.filterByString(item.buyerIdentifier ?? '', search) ||
      DataTableFilterHelper.filterByDate(item.createdDate, search)
    );
  }
}
