export class Background extends Phaser.Physics.Arcade.Group {
  constructor(scene: Phaser.Scene, width: number, height: number) {
    super(scene.physics.world, scene);
    this.scene = scene;
    this.group = this.scene.add.group({
      defaultKey: '__STARS',
      maxSize: 100
    });
    this.scene.time.addEvent({
      delay: 200,
      loop: true,
      callback: () => {
        // Random position above screen
        const x = Phaser.Math.Between(0, this.scene.game.canvas.width);
        const y = Phaser.Math.Between(-64, 0);

        // Find first inactive sprite in group or add new sprite, and set position
        const stars = this.group.get(x, y);

        // None free or already at maximum amount of sprites in group
        if (!stars) return;

        this.activateStars(stars, width, height);
      }
    });
  }
  activateStars(stars: any, width: number, height: number) {
    stars
      .setActive(true)
      .setVisible(true)
      .setTint(Phaser.Display.Color.RandomRGB().color);
    stars.displayHeight = height;
    stars.displayWidth = width;
  }
  scene: Phaser.Scene;
  group: any;
}
