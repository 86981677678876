var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('list-item-header',{attrs:{"title":((_vm.order.offerTitle) + " (" + (_vm.order.gameInstanceTitle) + ")"),"subTitle":_vm.order.orderNumber,"icon":'mdi-eye',"routeTo":{
        name: _vm.paymentsHistoryRoute.paymentsHistoryDetails,
        params: { orderId: _vm.order.id }
      }}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text--disabled",attrs:{"cols":"7"}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[(_vm.order.createdAt !== undefined && _vm.order.createdAt !== null)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.order.createdAt))+" ")]):_vm._e()]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.order.priceInfo.finalPrice,_vm.order.priceInfo.currency))+" ")]),_c('v-col',[_c('PaymentStatus',{attrs:{"order":_vm.order},on:{"cancelOrder":function($event){return _vm.cancelOrder()}}})],1),_c('v-col',{staticClass:"d-none d-xl-flex"},[_c('v-btn',{attrs:{"disabled":_vm.order.gameInstanceDeleted,"rounded":"","small":"","elevation":"0","to":{
            name: _vm.gameInstanceRoute.gameInstanceEdit,
            params: { gameInstanceId: _vm.order.gameInstanceId }
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t('Modules.PaymentsHistory.GameInstance'))+" ")],1)],1),_c('v-col',{staticClass:"d-none d-xl-flex"},[_c('v-btn',{staticClass:"invoice-btn",attrs:{"rounded":"","small":"","elevation":"0","disabled":_vm.order.isInvoiceCreated === false || _vm.order.priceInfo.discountValue === 100},on:{"click":_vm.downloadInvoice}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t('Modules.PaymentsHistory.Invoice'))+" ")],1)],1),_c('list-menu',[_c('v-list',[_c('v-list-item',{staticClass:"invoice-list-details-btn",attrs:{"to":{
              name: _vm.paymentsHistoryRoute.paymentsHistoryDetails,
              params: { orderId: _vm.order.id }
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tune")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Buttons.Details')))])],1),_c('v-list-item',{attrs:{"disabled":_vm.order.gameInstanceDeleted,"to":{
              name: _vm.gameInstanceRoute.gameInstanceEdit,
              params: { gameInstanceId: _vm.order.gameInstanceId }
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-gamepad-square")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.PaymentsHistory.GameInstance')))])],1),_c('v-list-item',{staticClass:"invoice-list-invoice-btn",attrs:{"disabled":_vm.order.isInvoiceCreated === false || _vm.order.priceInfo.discountValue === 100},on:{"click":_vm.downloadInvoice}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.PaymentsHistory.Invoice'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }