
import { ObjectsModel } from './ObjectsModel';
export class ObjectsGroup extends Phaser.Physics.Arcade.Group {
  rightOrLeft: boolean;
  enemyWidth!: number;
  enemyHeight!: number;
  spriteName!: string;

  constructor(scene: Phaser.Scene, width: number, height: number, spriteName: string) {
    super(scene.physics.world, scene);
    this.rightOrLeft = true;
    this.enemyHeight = height;
    this.enemyWidth = width;
    this.spriteName = spriteName;
  }
  start(name: string) {
    this.createMultiple({
      quantity: 10,
      key: name,
      active: false,
      visible: false,
      classType: ObjectsModel
    });
  }

  createObjects(position: number,speed?:number) {
    this.start(this.spriteName);
    const enemy = this.getFirstDead(false);
    enemy.addSprite(this.spriteName,this.enemyHeight,this.enemyWidth);
    enemy.displayHeight = this.enemyHeight;
    enemy.displayWidth = this.enemyWidth;
    enemy.createEnemy(position,speed);
  }
}
