




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormTextInputPreview extends Vue {
  label = this.$t('KitchenSink.Previews.FormTextInputPreview.Label');
  value = this.$t('KitchenSink.Previews.FormTextInputPreview.Value');
  rules: object[] = [];
}
