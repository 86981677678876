






















import { Component, Vue } from 'vue-property-decorator';
import EditUser from '../components/EditUser.vue';
import AdminUserApiService from '../services/admin-user-api.service';
import NotificationService from '@/services/notification-service';
import { AdminUserUpdateInputModel } from '@/services/Api';
import { i18n, translate } from '@/plugins/i18n';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component({
  components: {
    EditUser
  }
})
export default class AdminEditPage extends Vue {
  private apiService = new AdminUserApiService();
  private loading = true;
  userData!: AdminUserUpdateInputModel;
  private submitting = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.UserAdmin'),
        exact: true,
        disabled: false,
        to: { name: AdminUsersRoute.adminEdit }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Edit'),
        exact: true,
        disabled: true
      }
    ];
  }

  private cancel() {
    this.$router.push({ name: AdminUsersRoute.userAdmin });
  }
  async mounted() {
    const userInstance = await this.apiService.getUser(this.$route.params.id);
    if (userInstance) {
      this.userData = new AdminUserUpdateInputModel({
        id: userInstance.id,
        email: userInstance.email,
        isAdmin: userInstance.isAdmin,
        active: userInstance.active
      });
    } else {
      await this.$router.push({ name: AdminUsersRoute.userAdmin });
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  async save() {
    if (this.submitting) return;
    this.submitting = true;
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      this.submitting = false;
      return;
    }
    if (this.userData.email == null) {
      NotificationService.error("Email can't be empty");
    }

    const editInstance = await this.apiService.updateDataUser(this.userData);

    if (editInstance) {
      await this.$router.push({ name: AdminUsersRoute.userAdmin });
      this.submitting = false;
      NotificationService.success(translate('Modules.AdminPanel.UserList.Success.UserEdited'));
    }
  }
}
