



























































import { Component, Vue } from 'vue-property-decorator';
import { GameInstanceDto } from '@/services/Api';
import UserApiService from '../services/game-instance-api.service';
import GameInstanceListItem from '@/modules/game-instance/components/GameInstanceListItem.vue';
import ListContainer from '@/modules/layout/components/ListContainer.vue';
import ListItem from '@/modules/layout/components/ListItem.vue';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store/store';
@Component({
  components: { ListItem, ListContainer, GameInstanceListItem }
})
export default class GameInstanceList extends Vue {
  apiService = new UserApiService();
  private gameInstances: Array<GameInstanceDto> = [];
  private loading = true;
  public gameInstanceRoute = GameInstanceRoute;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        to: { name: GameInstanceRoute.gameInstanceList }
      }
    ];
  }

  async mounted() {
    this.loading = true;

    const result = await this.apiService.getGameInstancesByUser();

    if (result) {
      this.gameInstances = result;
      this.gameInstances.sort(function(a, b) {
        if (a.title && b.title) {
          return a.title.localeCompare(b.title);
        }
        return -1;
      });

      store.setBreadcrumb(this.breadcrumbData);
      this.loading = false;
    }
  }

  gameInstanceDeleted(gameInstanceId: string) {
    const gIndex = this.gameInstances.findIndex(g => g.id === gameInstanceId);
    this.gameInstances.splice(gIndex, 1);
  }
}
