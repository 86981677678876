import { GameTemplateDto } from '@/services/Api';

export class GameTemplatesModel {
  public games?: Array<GameTemplateDto>;
  public selectedGame!: string;

  constructor(model: Array<GameTemplateDto>) {
    this.games = model;
    this.selectedGame = this.games[0].id;
  }

  public getSelectedGameTemplate(): GameTemplateDto | undefined {
    return this.games?.find(g => g.id === this.selectedGame);
  }
}
