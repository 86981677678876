import { GameType } from '@/services/Api';
import { GameEngineBase, GameTriggerType } from '../games/game-engine-base';
import { GameCustomizableElement } from '../games/game-engine-customizable-element';
import { SpaceRescueEngine } from '../games/space-rescue/space-rescue-engine';
import { RiverRunEngine } from '../games/river-run/river-run-engine';
import { MazeGameEngine } from '../games/maze-game/maze-game-engine';
import GameThemeService from './game-theme.service';

export default class GameFactory {
  public static getEngine(
    type: GameType,
    askQuestion: () => void,
    gameOverDialog: (score: number) => void,
    saveGameLog: (score: number, trigger: GameTriggerType) => void,
    customizedElements: GameCustomizableElement[]
  ): GameEngineBase | undefined {
    switch (type) {
      case GameType.SpaceRescue:
      case GameType.DesertAdventure:
      case GameType.LittleWitch:
      case GameType.FroggyPrincess:
        return new SpaceRescueEngine(
          askQuestion,
          gameOverDialog,
          saveGameLog,
          customizedElements,
          GameThemeService.getTheme(type)
        );
      case GameType.Pirates:
      case GameType.SpeedRace:
      case GameType.SkyFlight:
        return new RiverRunEngine(askQuestion, gameOverDialog, saveGameLog, customizedElements, GameThemeService.getTheme(type));
      case GameType.MazeGame:
        return new MazeGameEngine(askQuestion, gameOverDialog, saveGameLog, customizedElements, GameThemeService.getTheme(type));
      case GameType.Undefined:
        throw console.error('Unidentified game type: ', type);
    }
  }
  public static getCustomizableElements(type: GameType): GameCustomizableElement[] {
    const empty = () => {
      console.log('im just a mock');
    };
    const engine = this.getEngine(type, empty, empty, empty, []);
    const baseElements = engine?.getCustomziableTypes() ?? [];
    const themedElements = GameThemeService.getTheme(type)?.overridenElements ?? [];
    const elements = GameCustomizableElement.mergeWithOverride(baseElements, themedElements);

    return elements;
  }
}
