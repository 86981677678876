










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StringValidators from '@/modules/layout/validators/string-validators';
import { GameTemplatesModel } from '../models/game-templates.model';
import { GameInstanceModel } from '../models/game-instance.model';
import { translate } from '@/plugins/i18n';
import GameSelect from './GameSelect.vue';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';

@Component({ components: { GameSelect } })
export default class GameInstanceCustomizeForm extends Vue {
  @Prop() instanceSettings!: GameInstanceModel;
  @Prop() gameTemplates!: GameTemplatesModel;
  @Prop({ type: Boolean, default: false }) edit?: boolean;
  modal = false;
  public gameInstanceRoute = GameInstanceRoute;
  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 3),
    maxLength: (model: string) => StringValidators.maxLength(model, 30)
  };
  timeProps: any = {
    format: '24h'
  };
  getTitle() {
    if (this.edit) {
      return this.$t('Modules.GameInstance.Create.BasicDataInfoEdit');
    } else {
      return this.$t('Modules.GameInstance.Create.BasicDataInfo');
    }
  }
  public get isPaymentActive(): boolean {
    return this.instanceSettings?.lastPaymentData?.isPaymentActive === true;
  }

  public get paymentStatus(): string | null {
    const status = this.instanceSettings?.lastPaymentData?.activePaymentStatus;
    return status != null ? translate(`PaymentsStatus.${status}`) : null;
  }

  public onGameSelected(gameId: string) {
    this.$emit('game-selected', gameId);
  }

  @Watch('instanceSettings.localPlayableFrom')
  @Watch('instanceSettings.localPlayableTo')
  localChange() {
    this.instanceSettings.convertTime();
  }
}
