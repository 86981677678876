import {
  ProfessionClient,
  QuestionClient,
  QuestionCreateInputModel,
  QuestionDeleteInputModel,
  QuestionDto,
  QuestionUpdateInputModel
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class QuestionApiService extends ApiServiceBase {
  private readonly professionClient = new ProfessionClient();
  private readonly questionClient = new QuestionClient();

  public async getQuestionsForProfession(professionId: string): Promise<QuestionDto[] | undefined> {
    try {
      const questions = await this.professionClient.getQuestionsForProfession(professionId);

      return questions.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async createQuestion(inputModel: QuestionCreateInputModel): Promise<QuestionDto | undefined> {
    try {
      const created = await this.questionClient.createQuestion(inputModel);

      return created.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async readQuestion(questionId: string): Promise<QuestionDto | undefined> {
    try {
      const question = await this.questionClient.getQuestion(questionId);

      return question.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async updateQuestion(inputModel: QuestionUpdateInputModel): Promise<QuestionDto | undefined> {
    try {
      const updated = await this.questionClient.updateQuestion(inputModel);

      return updated.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async deleteQuestion(inputModel: QuestionDeleteInputModel): Promise<QuestionDto | undefined> {
    try {
      const deleted = await this.questionClient.deleteQuestion(inputModel);

      return deleted.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
