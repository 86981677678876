







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdminUserUpdateInputModel } from '@/services/Api';
@Component
export default class EditUser extends Vue {
  @Prop() readonly data!: AdminUserUpdateInputModel;
}
