import { KeySide } from './KeySide';
import { MazeGameVariables } from '../Global/MazeGameVariables';
export class BackgroundRoad extends Phaser.GameObjects.TileSprite {
  constructor(scene: Phaser.Scene, widthBg: number, heightBg: number, backgroundName: string) {
    super(scene, 400, 300, widthBg, heightBg, backgroundName);
    scene.physics.add.existing(this);
    scene.add.existing(this);
    this.displayHeight = heightBg;
    this.displayWidth = widthBg;
  }
}
