


















import { Component, Prop, Vue } from 'vue-property-decorator';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import DataTableFilterHelper from '@/modules/layout/helpers/data-table-filter.helper';

@Component
export default class CustomDataTablePreview extends Vue {
  @Prop() data: any;

  created() {
    this.data = [
      { id: 1, value: 'value 1', number: 10 },
      { id: 2, value: 'value 2', number: 15 },
      { id: 3, value: 'value 3', number: 25 }
    ];
  }

  get headers() {
    return [
      {
        text: 'Id',
        value: 'id',
        sort: DataTableSortingHelper.sortByDefault
      },
      { text: 'Value', value: 'value', sort: DataTableSortingHelper.sortByDefault },
      { text: 'Number', value: 'number', sort: DataTableSortingHelper.sortByDefault }
    ];
  }

  get filters() {
    return [
      { value: 'value', items: this.data.map((d: any) => d.value) },
      {
        value: 'number',
        items: ['Less than 15'],
        filter: (value: any, search: any, item: any, activeFilters: any) => {
          if (activeFilters.number.includes('Less than 15')) {
            return value < 15;
          } else {
            return true;
          }
        }
      }
    ];
  }

  customFilter(value: any, search: string | null, item: any) {
    if (!search) {
      return true;
    }

    return DataTableFilterHelper.filterByString(item.value, search);
  }
}
