
























































































































import { InstancePlayersStatisticsDto } from '@/services/Api';
import { Duration, Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import StoreTables from '@/store/Models/storetables';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';
import { store } from '@/store/store';
import { Data } from 'phaser';
@Component
export default class StatisticsPlayersTable extends Vue {
  @Prop() readonly data!: InstancePlayersStatisticsDto[];
  @Prop() readonly exporting!: boolean;
  @Prop() readonly loadingCVButton!: boolean;
  private selected = [];
  private tablesSize = 5;
  private localLoading = false;
  private search = '';

  goToPlayerStatistic(playerEmail: string, playerProfession: string) {
    this.$emit('player-statistic', playerEmail, playerProfession);
  }
  async mounted() {
    this.localLoading = true;
    this.tablesSize = store.getTableSize('Players-statistics');
    this.localLoading = false;
  }
  @Watch('tablesSize')
  onPropertyChanged(value: number, oldValue: string) {
    this.tablesSize = value;
    store.setTables('Players-statistics', this.tablesSize);
  }
  get selectedEmails() {
    return this.selected.map((s: InstancePlayersStatisticsDto) => s.email).join(', ');
  }
  get headers() {
    return [
      {
        text: 'Email',
        align: 'left',
        value: 'email'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.CountProfession'),
        value: 'profession'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.Favorite'),
        value: 'isFavorite'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.Rating'),
        value: 'rating'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.PercentOfGoodAnswers'),
        value: 'percentOfGoodAnswers'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.StartedGames'),
        value: 'finishedGames'
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.AvgTime'),
        value: 'averageTimeForGame',
        sort: DataTableSortingHelper.compareByDuration
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.TotalTime'),
        value: 'totalTimeForGames',
        sort: DataTableSortingHelper.compareByDuration
      },
      {
        text: this.$t('Modules.Statistics.PlayerTable.CV'),
        value: 'addedCV'
      },
      { value: 'button', sortable: false }
    ];
  }

  rating(value: number) {
    if (value <= 1) {
      return {
        icon: 'mdi-star-outline',
        color: '#A8BFC2'
      };
    } else if (value <= 2.5) {
      return {
        icon: 'mdi-star-outline',
        color: '#DCA547'
      };
    } else if (value <= 4) {
      return {
        icon: 'mdi-star-half',
        color: '#DCA547'
      };
    } else {
      return {
        icon: 'mdi-star',
        color: '#DCA547'
      };
    }
  }

  customSort(items: any[], index: string[], isDesc: boolean[], locale: any, sorters: any) {
    if (index[0] === 'percentOfGoodAnswers') {
      items.sort((a: InstancePlayersStatisticsDto, b: InstancePlayersStatisticsDto) => {
        const left = parseInt(a.percentOfGoodAnswers ? a.percentOfGoodAnswers.toString().replace(/\D/g, '') : '');
        const right = parseInt(b.percentOfGoodAnswers ? b.percentOfGoodAnswers.toString().replace(/\D/g, '') : '');

        return DataTableSortingHelper.compareByDefault(left, right, isDesc[0]);
      });
    } else {
      items = DataTableSortingHelper.sortByDefault(items, index, isDesc, locale, sorters);
    }

    return items;
  }

  formatDuration(item: Duration) {
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }
  emitExportCsv() {
    this.$emit('export-csv', 'playersList');
  }
  emitDownloadCv(playerEmail: string, playerProfession: string) {
    this.$emit('download-cv', playerEmail, playerProfession);
  }
}
