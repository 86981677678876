








































import { GameCustomizableElement } from '@/modules/game-play/games/game-engine-customizable-element';
import { translate } from '@/plugins/i18n';
import NotificationService from '@/services/notification-service';
import ImageCropModal from './ImageCropModal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameType } from '@/services/Api';

@Component({ components: { ImageCropModal } })
export default class ImageTypeCustomize extends Vue {
  @Prop() readonly item!: GameCustomizableElement;
  @Prop() readonly type!: GameType;
  inputValue = '';
  showCrop = false;
  tmpImage = '';
  mounted() {
    this.inputValue = this.item.value as string;
  }
  onUpload(event: any) {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      this.tmpImage = reader.result?.toString() ?? '';
      this.showCrop = true;
    };
    if (file.size > 5242880) {
      NotificationService.error(translate('Modules.GameInstance.Customize.Menu.FileSizeError'));
      return;
    }
    reader.readAsDataURL(file);
  }

  confirmedCrop(image: string) {
    console.log('confirmedCrop', image);
    this.item.value = image;
    this.cancelCrop();
  }
  cancelCrop() {
    this.showCrop = false;
    this.tmpImage = '';
  }

  onClear() {
    this.item.value = '';
  }
  openHtmlInput() {
    const element: HTMLElement = this.$refs.htmlInput as HTMLElement;
    element.click();
  }
  get uploadBtnLabel() {
    if (this.item.value == '') {
      return translate('Buttons.UploadImage');
    } else {
      return translate('Buttons.ChangeImage');
    }
  }
}
