


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableItemHeader extends Vue {
  @Prop() public title!: any;
  @Prop() public subTitle?: string;
  @Prop() public routeTo?: any;

  @Prop({ default: false }) readonly useCheckBox!: boolean;

  checkIsBoolean(): boolean {
    if (this.useCheckBox && typeof this.title === typeof this.useCheckBox) {
      return true;
    }
    return false;
  }
}
