


































































































import { OrderListItemDto } from '@/services/Api';
import PaymentStatus from './PaymentStatus.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { PaymentsHistoryRoute } from '@/modules/payments-history/payments-history-router.enum';

@Component({ components: { PaymentStatus } })
export default class PaymentsHistoryListItem extends Vue {
  @Prop() public order!: OrderListItemDto;

  public gameInstanceRoute = GameInstanceRoute;
  public paymentsHistoryRoute = PaymentsHistoryRoute;

  public cancelOrder() {
    return this.$emit('cancelOrder');
  }

  public downloadInvoice() {
    return this.$emit('downloadInvoice');
  }
}
