import { SpaceRescueVariables } from '../Global/SpaceRescueVariables';
export class Enemy extends Phaser.Physics.Arcade.Sprite {
  speedY: number;
  speedX: number;
  isDestroyed: boolean;
  enemyBg: any;
  rowPos!: number;
  colPos!: number;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, SpaceRescueVariables.enemySpriteName);
    this.speedY = 60;
    this.speedX = 120;
    scene.tweens.add({
      targets: this,
      ease: 'Linear',
      duration: 500,
      repeat: -1,
      yoyo: true,
      angle: { from: -10, to: 10 }
    });
    this.isDestroyed = false;
    if (scene.textures.get(SpaceRescueVariables.enemyBgSpriteName)) {
      this.enemyBg = this.scene.add.sprite(x, y, SpaceRescueVariables.enemyBgSpriteName);
    }
    this.enemyBg.setOrigin(0.5);
    this.scene.registry.events.on('speedUpEnemy', this.changeSpeed, this);
  }
  changeSpeed(parameter: any) {
    if (this.body) {
      this.speedX = this.speedX * parameter.speed;
      this.setVelocityX(this.speedX);
    }
  }
  createEnemy(x: number, y: number, row: number, col: number) {
    this.body.reset(x, y);
    this.setActive(true);
    this.setVisible(true);
    this.setVelocityY(this.speedY);
    if (this.speedX >= 0) {
      this.setVelocityX(this.speedX);
    } else {
      this.setVelocityX(this.speedX * -1);
    }
    this.setDepth(1);
    this.rowPos = row;
    this.colPos = col;
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);
    this.enemyBg.x = this.body.x + 20;
    this.enemyBg.y = this.body.y + 20;
    if (this.y >= this.scene.game.canvas.height + 30) {
      this.setActive(false);
      this.setVisible(false);
    }
  }
  destroyAll() {
    this.enemyBg.destroy();
    this.destroy();
  }
  destroyObject() {
    if (this.isDestroyed) {
      return;
    }
    this.isDestroyed = true;
    this.scene.tweens.add({
      targets: this,
      ease: 'Linear',
      duration: 50,
      alpha: { from: 1, to: 0 },
      completeDelay: 50,
      onComplete: () => {
        this.enemyBg.destroy();
        this.destroy();
      }
    });
  }
}
