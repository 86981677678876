import { Currency } from '@/services/Api';
import { CountriesProvider } from '@/services/countries.provider';
import moment from 'moment';
import { Moment } from 'moment';
import { VueConstructor } from 'vue/types/umd';

export default {
  install(Vue: VueConstructor) {
    Vue.filter('formatDateTime', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }
      const parsed = moment(value).local();
      return parsed.format('YYYY-MM-DD HH:mm');
    });
    Vue.filter('formatDate', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }

      const parsed = moment(value).local();
      return parsed.format('YYYY-MM-DD');
    });
    Vue.filter('formatHours', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }

      const parsed = moment(value).local();
      return parsed.format('HH:mm');
    });
    Vue.filter('formatNormal', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }

      const parsed = moment(value).local();
      return parsed.format('HH:mm:ss');
    });
    Vue.filter('toCurrency', (value: any, currency: Currency) => {
      if (typeof value !== 'number') return value;
      let locale: string;

      switch (currency) {
        case Currency.PLN:
          locale = 'pl-PL';
          break;
        case Currency.EUR:
          locale = 'de-DE';
          break;
        case Currency.USD:
          locale = 'en-US';
          break;
      }

      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return formatter.format(value);
    });
    Vue.filter('toCountryName', (value: string) => {
      const country = CountriesProvider.getCountry(value);

      return country?.native ?? '-';
    });
    Vue.filter('formatNumber', (value: number) => {
      const formatter = new Intl.NumberFormat('en-GB', { minimumFractionDigits: 1 });
      return formatter.format(value);
    });
  }
};
