import IEnemyVerhicle from '../IEnemyVehicle';
import { SpawnPoint } from '../../../Models/SpawnPoint';
//import Base from "../../ ../Global/BaseControllClass"
export class EnemyVehicle extends Phaser.Physics.Arcade.Sprite implements IEnemyVerhicle {
  isDestroyed: boolean;
  unlockRoad = false;
  emiterBack = new Phaser.Events.EventEmitter();
  isObject = false;
  objectSpeed = 5;
  stopAllObjects = false;
  firstSpeed = 5;
  firstSpeedObject = 5;
  passingSound = false;
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, '');
    this.isDestroyed = false;
    this.unlockRoad = false;
    this.emiterBack.on('emiterBack', this.unlockBack, this);
    this.scene.registry.events.on('stopAll', this.stopAll, this);
    this.scene.registry.events.on('backToOldSpeed', this.backToOldSpeed, this);
    this.scene.registry.events.on('changeSpeed', this.changeSpeed, this);
    this.scene.registry.events.on('unlockAll', this.unlockAll, this);
    this.scene.registry.events.on('deleteEnemy', this.destroyAll, this);
    this.scene.registry.events.on('objectChangeSpeed', this.objectChangeSpeed, this);
  }
  objectChangeSpeed(parameter: any){
    if(this.isObject && this.body != undefined){
      this.setVelocityY(0);
      this.firstSpeedObject = parameter.speed;
      this.objectSpeed = parameter.speed;
    }
  }
  backToOldSpeed() {
    if (this.body != undefined) {
      if (this.isObject) {
        this.objectSpeed = this.firstSpeedObject;
        this.setVelocityY(0);
      } else {
        this.setVelocityY(this.firstSpeed);
      }
    }
  }
  setFirstSpeed(speed: number) {
    this.firstSpeed = speed;
  }
  unlockBack() {
    this.unlockRoad = true;
  }
  addSprite(name: string, width: number, heigth: number, rotate: boolean) {
    this.setTexture(name).angle = 0;
    if (rotate) {
      this.setTexture(name).angle = 180;
    }
    this.unlockRoad = false;
  }
  createEnemy(x: number, objectSpeedInside: number) {
    this.body.reset(x, -50);
    this.setActive(true);
    this.setVisible(true);
    this.unlockRoad = false;
    this.passingSound = false;
    if (objectSpeedInside != 0) {
      this.isObject = true;
      this.objectSpeed = objectSpeedInside;
      this.firstSpeedObject = objectSpeedInside;
      this.setDepth(0.6);
    }else{
      this.setDepth(1);
    }
  }
  changeSpeed(parameter: any) {
    this.objectSpeed = (parameter.speed / 100) * 3;
    if (this.body != undefined) {
      if (parameter.back) {
        this.setVelocityY(parameter.speed * 1.6);
      } else {
        if(this.isObject){
          this.setVelocityY(parameter.speed * 2);
        }else{
          this.setVelocityY(parameter.speed * 2 + this.firstSpeed);
        }
        
      }
    }
  }
  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);
    if (!this.unlockRoad && this.y >= this.scene.game.canvas.height * 0.55) {
      if (this.x - 20 <= SpawnPoint.position1 && this.x + 20 >= SpawnPoint.position1) {
        this.scene.registry.events.emit('unlockRoad', 0, this.emiterBack);
      } else if (this.x - 20 <= SpawnPoint.position2 && this.x + 20 >= SpawnPoint.position2) {
        this.scene.registry.events.emit('unlockRoad', 1, this.emiterBack);
      } else if (this.x - 20 <= SpawnPoint.position3 && this.x + 20 >= SpawnPoint.position3) {
        this.scene.registry.events.emit('unlockRoad', 2, this.emiterBack);
      } else if (this.x - 20 <= SpawnPoint.position4 && this.x + 20 >= SpawnPoint.position4) {
        this.scene.registry.events.emit('unlockRoad', 3, this.emiterBack);
      }
    }
    if (this.y >= this.scene.game.canvas.height + 50) {
      this.setActive(false);
      this.setVisible(false);
      this.body.reset(0, -50);
    }
    if (this.isObject && !this.stopAllObjects) {
      this.y += this.objectSpeed;
    }
  }

  destroyAll() {
    this.destroy();
    this.unlockRoad = false;
  }

  stopAll() {
    this.stopAllObjects = true;
  }

  unlockAll() {
    this.stopAllObjects = false;
  }

  destroyObject() {
    if (this.isDestroyed) {
      return;
    }
    this.unlockRoad = false;
    this.isDestroyed = true;
    this.scene.tweens.add({
      targets: this,
      ease: 'Linear',
      duration: 50,
      alpha: { from: 1, to: 0 },
      completeDelay: 50,
      onComplete: () => {
        this.destroy();
      }
    });
  }
}
