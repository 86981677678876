import { app } from '@/main';
import { PriceInfo } from '@/services/Api';
import { Duration, Moment } from 'moment';
import { sortItems } from 'vuetify/lib/util/helpers';

export default class DataTableSortingHelper {
  //Use when '>' and '<' operators give satisfying results
  public static compareByDefault(value1: any, value2: any, isDesc: boolean) {
    if (value1 === null && value2 === null) {
      return 0;
    }
    if (isDesc) {
      return value1 > value2 ? -1 : 1;
    } else {
      return value2 > value1 ? -1 : 1;
    }
  }
  public static compareByString(value1: string, value2: string, isDesc: boolean, locale: string) {
    const stringCollator = new Intl.Collator();
    if (value1 === null && value2 === null) {
      return 0;
    }

    if (isDesc) {
      [value1, value2] = [value2, value1];
    }
    [value1, value2] = [value1, value2].map(s =>
      (s || '')
        .toString()
        .toLocaleLowerCase()
        .trim()
    );

    if (value1 !== value2) {
      return stringCollator.compare(value1, value2);
    }
    return 0;
  }
  public static compareByPriceInfo(value1: PriceInfo, value2: PriceInfo) {
    if (value1 === null && value2 === null) {
      return 0;
    }

    return DataTableSortingHelper.compareByDefault(value1.finalPrice, value2.finalPrice, false);
  }
  public static compareByDate(value1: Moment | undefined | null, value2: Moment | undefined | null, isDesc: boolean) {
    if (value1 && value2) {
      if (!isDesc) {
        return value1.isBefore(value2) ? -1 : 1;
      } else {
        return value2.isBefore(value1) ? -1 : 1;
      }
    } else if (!value1 && value2) {
      if (!isDesc) {
        return -1;
      } else {
        return 1;
      }
    } else if (!value2 && value1) {
      if (!isDesc) {
        return 1;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  }

  public static compareByDuration(value1: Duration, value2: Duration, isDesc: boolean) {
    return DataTableSortingHelper.compareByDefault(value1.asSeconds(), value2.asSeconds(), isDesc);
  }

  public static sortByDefault(items: any[], sortBy: string[], sortDesc: boolean[], locale: any, customSorters?: any) {
    return sortItems(items, sortBy, sortDesc, locale, customSorters);
  }
}
