










































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { GameInstanceAdminDataDto } from '@/services/Api';

import { Moment } from 'moment';
import moment from 'moment';
import { store } from '@/store/store';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import DataTableSortingHelper from '@/modules/layout/helpers/data-table-sorting.helper';

@Component({
  components: {}
})
export default class GameInstanceAdminData extends Vue {
  @Prop() userGameInstancesData!: GameInstanceAdminDataDto[] | undefined;
  private tableSize = 5;
  public adminUsersRoute = AdminUsersRoute;
  get headersGames() {
    return [
      {
        text: this.$t('Modules.AdminPanel.UserDetails.GameTitle'),
        value: 'title',
        sort: DataTableSortingHelper.compareByString
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Plan'),
        value: 'validUntil',
        sort: DataTableSortingHelper.compareByDate
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Status'),
        value: 'isPublic',
        sort: DataTableSortingHelper.compareByString
      },
      { text: this.$t('Modules.AdminPanel.UserDetails.TimePeriod'), value: 'createdDate', sortable: false },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Players'),
        value: 'playerInstancesAmmount',
        sort: DataTableSortingHelper.compareByDefault
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Professions'),
        value: 'professionsAmmount',
        sort: DataTableSortingHelper.compareByDefault
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.Purchases'),
        value: 'purchasesAmmount',
        sort: DataTableSortingHelper.compareByDefault
      },
      {
        text: this.$t('Modules.AdminPanel.UserDetails.IsDeleted'),
        value: 'isDeleted',
        sort: DataTableSortingHelper.compareByString
      }
    ];
  }
  async mounted() {
    this.tableSize = store.getTableSize('Admin-user-game-instances');
  }
  @Watch('tableSize')
  onPropertyChanged(value: number) {
    this.tableSize = value;
    store.setTables('Admin-user-game-instances', this.tableSize);
  }
  expired(validUntil: Moment) {
    return validUntil.isBefore(moment());
  }

  license(validUntil: Moment) {
    if (validUntil && validUntil.isAfter(moment())) {
      const result = {
        text: 'Per Game',
        icon: 'mdi-crown',
        color: '#D8A243'
      };
      return result;
    }
    const result = {
      text: 'Basic',
      icon: 'mdi-lock',
      color: 'grey'
    };
    return result;
  }
  status(isPublic: boolean) {
    if (isPublic) {
      const result = {
        text: 'Public',
        icon: 'mdi-earth',
        color: 'blue'
      };
      return result;
    }
    const result = {
      text: 'Non-public',
      icon: 'mdi-earth-off',
      color: 'grey'
    };
    return result;
  }
}
