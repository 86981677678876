




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormCheckboxPreview extends Vue {
  checkboxValue!: boolean;
  disabled!: boolean;
}
