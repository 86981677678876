import 'phaser';
import { GameTriggerType } from '../game-engine-base';
import { GameCustomizableElement, GameCustomizableElementType } from '../game-engine-customizable-element';
import { Player } from './Models/playercontroller';
import { MazeGameVariables } from './Global/MazeGameVariables';
import { Base } from './Global/BaseControllClass';
import { KeySide } from './Models/KeySide';
import WallObjectsFactory from './Models/WallObjectsFactory';
import { TextController } from './Models/textcontroller';
import { GameTheme } from '../../models/game-theme';
import { ImagesBase64 } from './imagesbase64';
import { WallObjectsTilesprite } from './Models/WallObjectsTilesprite';
import { BackgroundRoad } from './Models/backgroundRoad';
import { BackgroundBack } from './Models/backgroundBack';
//import Animations from './Global/Animations';
//Game Main class
export class GameScene extends Phaser.Scene {
  constructor(
    protected askQuestion: () => void,
    protected gameOverDialog: (score: number) => void,
    protected saveGameLog: (score: number, trigger: GameTriggerType) => void,
    getCustomziableTypes: GameCustomizableElement[],
    protected theme?: GameTheme
  ) {
    super({
      key: 'Game',
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      }
    });
    this.getCustomziableTypes = getCustomziableTypes;
    this.textFont = '20px Play, sans-serif';
    this.textFontStart = '80px Play, sans-serif';
  }
  test!: WallObjectsTilesprite;
  lineEvent!: any;
  backgroundRoad!: BackgroundRoad;
  backgroundBack!: BackgroundBack;
  level!: number;
  levelEvent!: any;
  playTime = 0;
  respawnEvent!: any;
  respawnObjectEvent!: any;
  spawnPointRand!: number;
  points!: number;
  player!: Player;
  emiterEvent: any;
  getCustomziableTypes!: any;
  textFont: string;
  textFontStart: string;
  cursorKeys!: any;
  cursorKeysIsDown!: KeySide;
  baseControllClass!: Base;
  lives!: Phaser.GameObjects.Group;
  textObj!: TextController;
  allSpritesImages: GameCustomizableElement[] = [];
  allText!: GameCustomizableElement[];
  randomObjects = 0;
  //Init all parametrs
  init(params: any): void {
    this.points = 0;
    this.level = 1;
  }

  //Load all texture before launch game
  preload() {
    this.physics.world.setFPS(60);
    this.physics.world.setBoundsCollision();

    this.allText = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Text
    );

    this.allSpritesImages = this.getCustomziableTypes.filter(
      (entity: { type: GameCustomizableElementType }) => entity.type == GameCustomizableElementType.Image
    );

    this.allSpritesImages.forEach(element => {
      if (element.value != null || element.value != '') {
        if(element.name == MazeGameVariables.line){
          this.load.image(element.name, element.value.toString());
        }else{
          this.textures.addBase64(element.name, element.value);
        }
        
      }
    });
   
    //Set black background
    //this.cameras.main.setBackgroundColor('#000000');

    //Load scene data
    this.data.set('pause', false);
  }

  //Main function scene to create GameObjects
  create() {
    this.baseControllClass = new Base(
      this.getCustomziableTypes.find((element: GameCustomizableElement) => element.name == 'Lives').value,
      this
    );
    //Create background
    this.backgroundBack = new BackgroundBack(
      this,
      this.baseControllClass.backgroundBackWidth,
      this.baseControllClass.backgroundHeigth,
      MazeGameVariables.BgBackSpriteName
    );
    //create bacgroun for this scene
    this.backgroundRoad = new BackgroundRoad(
      this,
      this.baseControllClass.backgroundWidth,
      this.baseControllClass.backgroundHeigth,
      MazeGameVariables.roadSpriteName
    );
    //this.test = new WallObjectsTilesprite(this,100,100,"__PLAYER");
    //create ship and asigns it position and speed
    this.player = new Player(
      this,
      this.baseControllClass.playerPozX,
      this.baseControllClass.playerPozY,
      this.baseControllClass.playerSpeedOnX,
      this.baseControllClass.playerMaxSpeedOnX,
      this.baseControllClass.playerSpeedOnY,
      this.baseControllClass.playerMaxSpeedOnY,
      this.baseControllClass.playerBorderMaxOnX,
      this.baseControllClass.playerBorderMinOnX,
      this.baseControllClass.playerBorderMaxOnY,
      this.baseControllClass.playerBorderMinOnY,
      this.baseControllClass.playerWidth,
      this.baseControllClass.playerHeigth
    );
    //

    //initialize emiter used on scene
    this.emiterEvent = new Phaser.Events.EventEmitter();
    //sets behavior if UnderCollisionEnemy string is emited
    this.emiterEvent.on('UnderCollisionEnemy', this.underCollisionEnemy, this);
    //Create time
    this.time.addEvent({
      delay: 1000,
      loop: true,
      callback: () => {
        this.playTime += 1;
      }
    });

    if (this.checkLevelReturn()) {
      this.createLevelEvent(this.baseControllClass.levels[this.level - 1].timeToNextLevel);
      this.createRespawnEvent();
      this.cursorKeys = this.input.keyboard.createCursorKeys();
    }

    this.registry.events.on('AnswerEvent', this.respondAnswer, this);
    this.createLives();

    this.saveGameLog(1, GameTriggerType.GameStart);

    //create text controler resposible for displaing scores and mesages
    this.textObj = new TextController(this, this.emiterEvent, this.theme);

    this.createOverlapWall();
  }
  createOverlapWall() {
    this.physics.add.overlap(
      this.player,
      this.baseControllClass.levels[this.level - 1].wallFactory,
      (player: any, enemy: any) => {
        if (!this.data.get('pause')) {
          this.registry.events.emit('stopAll');
          this.data.set('pause', true);
          this.physics.pause();
          this.makeQuestion();
        }
      }
    );
  }
  //Update function scene
  update(time: number, delta: number): void {
    if (!this.data.get('pause')) {
      this.points += 5;
      //Manage keyboard and player movement
      if (
        (this.cursorKeys.left.isDown && this.cursorKeysIsDown == KeySide.stop) ||
        (this.cursorKeys.left.isDown && this.cursorKeysIsDown == KeySide.left)
      ) {
        this.player.move(KeySide.left, delta);
        this.cursorKeysIsDown = KeySide.left;
      } else if (
        (this.cursorKeys.right.isDown && this.cursorKeysIsDown == KeySide.stop) ||
        (this.cursorKeys.right.isDown && this.cursorKeysIsDown == KeySide.right)
      ) {
        this.player.move(KeySide.right, delta);
        this.cursorKeysIsDown = KeySide.right;
      } else if (
        (this.cursorKeys.up.isDown && this.cursorKeysIsDown == KeySide.stop) ||
        (this.cursorKeys.up.isDown && this.cursorKeysIsDown == KeySide.up)
      ) {
        this.player.move(KeySide.up, delta);
        this.cursorKeysIsDown = KeySide.up;
      } else if (
        (this.cursorKeys.down.isDown && this.cursorKeysIsDown == KeySide.stop) ||
        (this.cursorKeys.down.isDown && this.cursorKeysIsDown == KeySide.down)
      ) {
        this.player.move(KeySide.down, delta);
        this.cursorKeysIsDown = KeySide.down;
      } else {
        this.player.move(KeySide.stop, delta);
        this.cursorKeysIsDown = KeySide.stop;
      }
    }

    this.updatePlayersScoreUI();

    if (!this.data.get('pause')) {
      this.backgroundRoad.tilePositionY -= this.baseControllClass.levels[this.level - 1].backgroundSpeed;
      this.backgroundBack.tilePositionY -= this.baseControllClass.levels[this.level - 1].backgroundSpeed;
    }
  }

  //Update bar
  updatePlayersScoreUI(): void {
    if (this.checkLevelReturn()) {
      this.textObj.timeTextUpdate(this.points, this.level, this.playTime);
    } else {
      this.textObj.timeTextUpdate(this.points, this.level, this.playTime);
    }
  }

  //Runs if enemy and player colides
  underCollisionEnemy() {
    if (!this.data.get('pause')) {
      this.data.set('pause', true);
      this.physics.pause();
      this.makeQuestion();
    }
  }

  //check if we dont overstep baseControllClass array
  checkLevelReturn() {
    if (this.level - 1 <= this.baseControllClass.levels.length - 1) {
      return true;
    }
    return false;
  }

  //Respond answer from server
  respondAnswer(data: boolean, answerPoolEmpty: boolean) {
    this.data.set('pause', true);
    this.physics.pause();
    if (answerPoolEmpty) {
      this.liveLost();
      return;
    }
    switch (data) {
      case true:
        this.points += 500;
        this.saveGameLog(this.points, GameTriggerType.LiveSaved);
        this.nextLevel(this.allText.find((val: { name: string }) => val.name == 'AnswerTextCorrect')?.value.toString());
        break;
      case false:
        this.saveGameLog(this.points, GameTriggerType.LiveLost);
        this.liveLost();
        this.nextLevel(this.allText.find((val: { name: string }) => val.name == 'AnswerTextWrong')?.value.toString());
        break;
    }
    this.eventInit();
  }

  createLevelEvent(delay: number) {
    //Event for level
    this.levelEvent = this.time.addEvent({
      delay: delay,
      loop: true,
      callback: () => {
        this.registry.events.emit('stopAll');
        this.nextLevelIncrease('Next Level');
      }
    });
  }
  createRespawnEvent() {
    const tempDelay = this.baseControllClass.levels[this.level - 1].spawnTime[
      Phaser.Math.Between(0, this.baseControllClass.levels[this.level - 1].spawnTime.length - 1)
    ];
    this.lineEvent = this.time.addEvent({
      delay: tempDelay,
      loop: true,
      callback: () => {
        WallObjectsFactory.createTileSprite(
          this.baseControllClass.levels[this.level - 1].wallFactory,
          this.baseControllClass.levels[this.level - 1].backgroundSpeed,
          this.baseControllClass.playerBorderMinOnX,
          this.baseControllClass.levels[this.level - 1].numberOfDivisions,
          this.baseControllClass.levels[this.level - 1].maxNumberOfHoles
        );
      }
    });
  }

  //Set new level after question or player destroyed all enemys
  nextLevel(textToShow: string | undefined) {
    //pause game
    this.data.set('pause', true);
    this.player.move(KeySide.stop, 0);
    this.saveGameLog(this.points, GameTriggerType.NewLevel);
    //pause game physic
    this.physics.pause();
    this.registry.events.emit('deleteAll');
    this.registry.events.emit('nextLevel');

    this.textObj.centerText(textToShow);
  }
  eventInit() {
    this.lineEvent.remove(false);
    this.levelEvent.remove(false);
    if (this.checkLevelReturn()) {
      this.createLevelEvent(this.baseControllClass.levels[this.level - 1].timeToNextLevel);
      this.createRespawnEvent();
    } else {
      this.createLevelEvent(this.baseControllClass.levels[this.baseControllClass.levels.length - 1].timeToNextLevel);
      this.createRespawnEvent();
    }
    this.createOverlapWall();
  }
  nextLevelIncrease(textToShow: string | undefined) {
    this.data.set('pause', true);
    this.physics.pause();
    this.level = this.level + 1;
    this.registry.events.emit('deleteAll');
    this.eventInit();
    this.textObj.centerText(textToShow);
  }

  //Live lost
  liveLost() {
    const t = this.lives.getFirstAlive();
    if (t != null) {
      t.destroy();
    }
    if (this.lives.getTotalUsed() == 0) {
      this.gameOverDialog(this.points);
      this.scene.pause();
      this.saveGameLog(this.points, GameTriggerType.GameOver);
      this.game.destroy(true, false);
      return;
    }
  }

  //Create Lives to display on screen
  createLives(): void {
    //Get all data
    const heartData = this.getCustomziableTypes.find((entity: { name: string }) => {
      if (entity.name == MazeGameVariables.heartSpriteName) {
        return entity;
      }
    });

    //create group, set it max vlaue and default key image
    this.lives = this.add.group({
      defaultKey: MazeGameVariables.heartSpriteName,
      active: true,
      maxSize: this.baseControllClass.life
    });

    //add to group live images on certain positin and scale
    for (let q = 0; q < this.baseControllClass.life; q++) {
      const tempImage = this.add.image(40 * q + 30, 25, MazeGameVariables.heartSpriteName);
      tempImage.displayHeight = heartData.heigth;
      tempImage.displayWidth = heartData.width;
      this.lives.add(tempImage);
    }
  }

  //Mark Question
  makeQuestion() {
    this.physics.pause();
    this.data.set('pause', true);
    this.levelEvent.remove(false);
    this.lineEvent.remove(false);
    this.askQuestion();
  }
}
