import { RouteConfig } from 'vue-router';
import ProfessionList from '@/modules/professions/views/ProfessionList.vue';
import CreateProfession from '@/modules/professions/views/CreateProfession.vue';
import ProfessionDetails from '@/modules/professions/views/ProfessionDetails.vue';
import CreateQuestion from '@/modules/professions/views/CreateQuestion.vue';
import EditQuestion from '@/modules/professions/views/EditQuestion.vue';
import DefaultView from '@/modules/layout/DefaultView.vue';
import { ProfessionsRoute } from './professions-router.enum';

export const professionsRoutes: Array<RouteConfig> = [
  {
    path: '/professions',
    component: DefaultView,
    children: [
      {
        path: '',
        name: ProfessionsRoute.professionList,
        component: ProfessionList
      },
      {
        path: 'create',
        name: ProfessionsRoute.createProfession,
        component: CreateProfession
      },
      {
        path: ':id',
        name: ProfessionsRoute.professionDetails,
        component: ProfessionDetails
      },
      {
        path: ':id/create-question',
        name: ProfessionsRoute.createQuestion,
        component: CreateQuestion
      },
      {
        path: ':id/edit-question/:questionId',
        name: ProfessionsRoute.editQuestion,
        component: EditQuestion
      }
    ]
  }
];
