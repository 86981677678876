






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DiscountAdminUpdateInputModel } from '@/services/Api';
import StringValidators from '@/modules/layout/validators/string-validators';
import { translate } from '@/plugins/i18n';
@Component
export default class EditDiscount extends Vue {
  @Prop() readonly data!: DiscountAdminUpdateInputModel;
  rules = {
    required: StringValidators.required,
    emailCheck: (model: string) => {
      if (model) return /.+@.+/.test(model) || translate('Modules.Validation.Email');
      else return false;
    },
    minLength: (model: number) => StringValidators.minNumber(model, 1),
    maxLength: (model: number) => StringValidators.maxNumber(model, 100)
  };
}
