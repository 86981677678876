import { init, track } from 'fbq';
import { OrderDetails } from '@/modules/game-instance/models/create-order.model';
import { OrderDetailsDto } from '@/services/Api';
import { store } from '@/store/store';
export class AnalyticsHelper {
  private static initialized = false;
  public static initFacebookPixel(id: string) {
    init(id);
    this.initialized = true;
  }

  public static trackWithFacebookPixel(eventType: FacebookPixelEvent, options?: any) {
    if (!this.initialized || !store.facebookPixelEnabled) {
      return;
    }
    track(eventType, options);
  }

  public static getPurchaseOptions(orderNumber: string | null | undefined, orderDetails: OrderDetails){
    let discountValue: number | undefined = undefined;

    if (orderDetails?.priceInfo?.netPriceAfterDiscount != null) {
      const result = orderDetails.priceInfo.netPrice - orderDetails.priceInfo.netPriceAfterDiscount;
      if (result > 0) discountValue = result;
    }
    
    return {
      content_ids: [orderNumber],
      contents: [
        {
        id: 'per_game_premium_service',
        quantity: 1,
        coupon: orderDetails?.discountCode == null ? undefined : orderDetails.discountCode,
        discount: discountValue == null ? undefined : discountValue, // this is amount, not percent value
        tax: orderDetails?.priceInfo?.vatAmount == null ? undefined : orderDetails.priceInfo.vatAmount
        }
      ],
      currency: orderDetails?.priceInfo?.currency,
      value: orderDetails?.priceInfo?.finalPrice
    }
  }

  public static getPaymentOptions(order: OrderDetailsDto){
    return AnalyticsHelper.getPurchaseOptions(order.orderNumber, {
      discountCode: null,
      priceInfo: order.priceInfo
    } as OrderDetails);
  }
}

export enum FacebookPixelEvent {
  pageView = 'PageView',
  completeRegistration = 'CompleteRegistration',
  addPaymentInfo = 'AddPaymentInfo',
  initiateCheckout = 'InitiateCheckout',
  purchase = 'Purchase',
}
