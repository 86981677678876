export enum GameCustomizableElementType {
  Text,
  Number,
  Image,
  Color,
}

export class GameCustomizableElement {
  private constructor(public name: string, public value: string | number, public type: GameCustomizableElementType) {}
  public width!: number;
  public heigth!: number;
  public maxSize!: number;
  public minSize!: number;
  public isCustomByUser = true;
  public position!: number;

  public static CreateImage(name: string, value: string, width: number, heigth: number, isCustomByUser = true, position = 0): GameCustomizableElement {
    const element = new GameCustomizableElement(name, value, GameCustomizableElementType.Image);
    element.width = width;
    element.heigth = heigth;
    element.isCustomByUser = isCustomByUser;
    element.position = position;
    return element;
  }
  public static CreateText(name: string, value: string, minLength: number, maxLength: number, isCustomByUser = true, position = 0): GameCustomizableElement {
    const element = new GameCustomizableElement(name, value, GameCustomizableElementType.Text);
    element.maxSize = maxLength;
    element.minSize = minLength;
    element.isCustomByUser = isCustomByUser;
    element.position = position;
    return element;
  }
  public static CreateNumber(name: string, value: number, minSize: number, maxSize: number, isCustomByUser = true, position = 0): GameCustomizableElement {
    const element = new GameCustomizableElement(name, value, GameCustomizableElementType.Number);
    element.maxSize = maxSize;
    element.minSize = minSize;
    element.isCustomByUser = isCustomByUser;
    element.position = position;
    return element;
  }
  public static CreateColor(name: string, value: number, isCustomByUser = false, position = 0): GameCustomizableElement {
    const element = new GameCustomizableElement(name, value, GameCustomizableElementType.Color);
    element.isCustomByUser = isCustomByUser;
    element.position = position;
    return element;
  }

  public static mergeWithOverride(
    base: GameCustomizableElement[],
    additional: GameCustomizableElement[]
  ): GameCustomizableElement[] {
    const filteredDefault = base.filter(d => !additional.some(e => e.name === d.name));
    const merged = filteredDefault.concat(additional);

    return merged;
  }
}
