






































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PaymentsApiService from '../services/admin-payments-api.service';
import { PaymentsAdminDto } from '@/services/Api';
import PaymentDetail from '../components/PaymentDetail.vue';
import PaymentStatus from '../components/PaymentStatus.vue';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
@Component({
  components: {
    PaymentDetail,
    PaymentStatus
  }
})
export default class PaymentsDetails extends Vue {
  @Prop() readonly order!: PaymentsAdminDto;
  confirmTitle = '';
  private apiService = new PaymentsApiService();
  loading = true;
  private searchPayments = '';
  public adminUsersRoute = AdminUsersRoute;
  mounted() {
    this.loading = false;
  }
  async downloadInvoice(order: string) {
    let response = null;
    response = await this.apiService.getInvoice(order);

    this.downloadFile(response?.value as string, response?.fileName as string);
  }
  downloadFile(value?: string, fileName?: string) {
    const linkSource = 'data:application/pdf;base64,' + value;
    const downloadLink = document.createElement('a');
    downloadLink.type = 'application/pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName ?? 'default.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  get getOrderTitle() {
    return this.$i18n.locale === 'pl' ? this.order?.titlePl : this.order?.titleEng;
  }
  get finalPriceWithVatInfo() {
    const newPrice = this.$options.filters?.toCurrency(this.order?.priceInfo?.netPriceAfterDiscount, 'PLN');
    return `(${newPrice} + 23% VAT)`;
  }
  get discountValueFormatted() {
    return `${this.order?.priceInfo?.discountValue} %`;
  }
}
