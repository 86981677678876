






import { Component, Prop, Vue } from 'vue-property-decorator';
import { store } from '@/store/store';

@Component
export default class IntersectMenuSection extends Vue {
  @Prop({ default: '' }) id!: string;
  onIntersect(entries: any[], invoker: any, isIntersected: boolean) {
    if (isIntersected) {
      store.setIntersected(this.id);
    }
  }
}
