





























import { GameTemplateDto, GameType } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GameTemplatesModel } from '../models/game-templates.model';

@Component
export default class GameSelect extends Vue {
  @Prop() gameTemplates!: GameTemplatesModel;
  selectedGame?: GameTemplateDto = new GameTemplateDto();

  mounted() {
    this.selectedGame = this.gameTemplates.getSelectedGameTemplate();
  }

  public selectGame(game: GameTemplateDto) {
    if (this.selectedGame !== game) {
      this.selectedGame = game;
      this.gameTemplates.selectedGame = game.id;
      this.$emit('game-selected', game.id);
    }
  }

  public getGamePreview(type: GameType): string {
    switch (type) {
      case GameType.DesertAdventure:
        return require('@/assets/games/Desert-adventure-preview.png');
      case GameType.SpaceRescue:
        return require('@/assets/games/Space-rescue-preview.png');
      case GameType.LittleWitch:
        return require('@/assets/games/little-witch-preview.png');
      case GameType.FroggyPrincess:
        return require('@/assets/games/froggy-princess-preview.png');
      case GameType.Pirates:
        return require('@/assets/games/pirates-preview.png');
      case GameType.SpeedRace:
        return require('@/assets/games/Speed-race-preview.png');
      case GameType.SkyFlight:
        return require('@/assets/games/skyFlight-preview.png');
      default:
        return require('@/assets/games/Speed-race-preview.png');
    }
  }
}
