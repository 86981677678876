import { RouteConfig } from 'vue-router';
import StatisticsMain from './views/StatisticsMain.vue';
import DownloadCV from './views/DownloadCV.vue';
import StatisticsPlayer from './views/StatisticsPlayer.vue';
import StatisticsGame from './views/StatisticsGame.vue';
import DefaultView from '@/modules/layout/DefaultView.vue';
import { StatisticsRoute } from './statistics-router.enum';
export const statisticRoutes: Array<RouteConfig> = [
  {
    path: '/statistics=:gameInstanceId',
    component: DefaultView,
    children: [
      {
        path: '',
        name: StatisticsRoute.statisticsMain,
        component: StatisticsMain,
        meta: { allowAnonymous: false },
        props: true
      },
      {
        path: 'statisticsplayer=:playerEmail',
        name: StatisticsRoute.statisticsPlayer,
        component: StatisticsPlayer,
        props: true,
        meta: { allowAnonymous: false }
      },
      {
        path: 'statisticsplayergame=:instanceId',
        name: StatisticsRoute.statisticsGame,
        component: StatisticsGame,
        props: true,
        meta: { allowAnonymous: false }
      }
    ]
  },
  {
    path: '/cv/download=:id&:gameid',
    name: StatisticsRoute.downloadCV,
    component: DownloadCV,
    props: true,
    meta: { breadCrumb: 'Layout.Toolbar.Routes.DownloadCV', allowAnonymous: true }
  }
];
