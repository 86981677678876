import IObject from './IObject';

export class ObjectsModel extends Phaser.Physics.Arcade.Sprite implements IObject {
  private speedY = 200;
  isDestroyed: boolean;
  stopAllObjects = false;
  firstSpeed = 4;
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, '');
    this.setDepth(0);
    this.isDestroyed = false;
    scene.registry.events.on('stopAll', this.stopAll, this);
    scene.registry.events.on('unlockAll', this.unlockAll, this);
    this.scene.registry.events.on('backToOldSpeed', this.backToOldSpeed, this);
    this.scene.registry.events.on('changeSpeed', this.changeSpeed, this);
    this.scene.registry.events.on('objectChangeSpeed', this.objectChangeSpeed, this);
    this.scene.registry.events.on('changeSpeedObj', this.changeSpeed, this);
    this.scene.registry.events.on('firstSpeed', this.firstSpeedChange, this);
  }
  firstSpeedChange(parameter: any) {
    console.log(parameter.speed);
    
    this.setVelocityY(0);
    this.firstSpeed = parameter.speed;
    this.speedY = parameter.speed;
  }
  objectChangeSpeed(parameter: any){
    this.setVelocityY(0);
    this.firstSpeed = parameter.speed;
    this.speedY = parameter.speed;
  }
  backToOldSpeed() {
    if (this.body != undefined) {
      this.setVelocityY(0);
      this.speedY = this.firstSpeed;
    }
  }
  
  changeSpeed(parameter: any) {
    this.speedY = (parameter.speed / 100) * 3;
    if (this.body != undefined) {
      if(parameter.back){
        this.setVelocityY(parameter.speed * 1.60);
      }else{
        this.setVelocityY(parameter.speed * 2);
      }
    }
  }
  addSprite(name: string, width: number, heigth: number) {
    this.setTexture(name).setDepth(0);
    this.setBodySize(width / 2, heigth);
    
  }
  createEnemy(x: number,speed?:number) {
    this.body.reset(x, -50);
    this.setActive(true);
    this.setVisible(true);
    if(speed){
      this.firstSpeed = speed;
    }
    this.speedY =this.firstSpeed;

  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);
    if (this.y >= this.scene.game.canvas.height + 70) {
      this.setActive(false);
      this.setVisible(false);
      this.body.reset(0, -50);
    } else if (!this.stopAllObjects) {
      
      this.y += this.speedY;
    }
  }

  stopAll() {
    this.stopAllObjects = true;
  }

  unlockAll() {
    this.stopAllObjects = false;
  }

  destroyAll() {
    this.destroy();
  }
  destroyObject() {
    if (this.isDestroyed) {
      return;
    }
    this.isDestroyed = true;
    this.scene.tweens.add({
      targets: this,
      ease: 'Linear',
      duration: 50,
      alpha: { from: 1, to: 0 },
      completeDelay: 50,
      onComplete: () => {
        this.destroy();
      }
    });
  }
}
