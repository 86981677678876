import {
  ProfessionClient,
  ProfessionDto,
  ProfessionCreateInputModel,
  ProfessionUpdateInputModel,
  ProfessionDeleteInputModel
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class ProfessionApiService extends ApiServiceBase {
  private readonly client = new ProfessionClient();

  public async getProfessionById(professionId: string): Promise<ProfessionDto | undefined> {
    try {
      const profession = await this.client.getProfession(professionId);

      return profession.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async getProfessionsOfCurrentUser(): Promise<ProfessionDto[] | undefined> {
    try {
      const professions = await this.client.getProfessionsOfCurrentUser();

      return professions.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async createProfession(inputModel: ProfessionCreateInputModel): Promise<ProfessionDto | undefined> {
    try {
      const profession = await this.client.createProfession(inputModel);

      return profession.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async updateProfession(inputModel: ProfessionUpdateInputModel): Promise<ProfessionDto | undefined> {
    try {
      const profession = await this.client.updateProfession(inputModel);

      return profession.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async deleteProfession(inputModel: ProfessionDeleteInputModel): Promise<ProfessionDto | undefined> {
    try {
      const profession = await this.client.deleteProfession(inputModel);

      return profession.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
