






























































import ImageTypeCustomize from '@/modules/game-instance/components/game-customize-types/ImageTypeCustomize.vue';
import NumberTypeCustomize from '@/modules/game-instance/components/game-customize-types/NumberTypeCustomize.vue';
import TextTypeCustomize from '@/modules/game-instance/components/game-customize-types/TextTypeCustomize.vue';
import GameInstanceCustomize from '@/modules/game-instance/components/GameInstanceCustomize.vue';
import GameInstanceCustomizeForm from '@/modules/game-instance/components/GameInstanceCustomizeForm.vue';
import GameInstanceProfessionAssign from '@/modules/game-instance/components/GameInstanceProfessionAssign.vue';
import { GameInstanceModel } from '@/modules/game-instance/models/game-instance.model';
import { GameTemplatesModel } from '@/modules/game-instance/models/game-templates.model';
import { GameMainSettingsModel } from '@/modules/game-play/games/customize-settings.model';
import {
  GameCustomizableElement,
  GameCustomizableElementType
} from '@/modules/game-play/games/game-engine-customizable-element';
import GameFactory from '@/modules/game-play/services/game-factory.service';
import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { i18n } from '@/plugins/i18n';
import { GameInstanceUpdateInputModel, GameType } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserApiService from '../services/game-instance-api.service';
import { VForm } from '@/modules/layout/components/forms/v-form';
import FormHelper from '@/modules/layout/components/forms/form-helper';
import EditCardSection from '@/modules/layout/components/EditCardSection.vue';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { GamePlayRoute } from '@/modules/game-play/game-play-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';

@Component({
  components: {
    GameInstanceCustomizeForm,
    GameInstanceProfessionAssign,
    GameInstanceCustomize,
    ImageTypeCustomize,
    TextTypeCustomize,
    NumberTypeCustomize
  }
})
export default class GameInstanceEdit extends Vue {
  @Prop() readonly gameInstanceId!: string;
  public instanceSettings: GameInstanceModel = {} as GameInstanceModel;
  public gameTemplates: GameTemplatesModel = {} as GameTemplatesModel;
  private loading = true;
  private submitting = false;
  public gameMainSettings = new GameMainSettingsModel();
  public gameCustomizableElements: GameCustomizableElement[] = [];
  public selectedGameTemplate?: GameType;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Games'),
        exact: true,
        disabled: false,
        to: { name: GameInstanceRoute.gameInstanceList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Edit'),
        exact: true,
        disabled: true
      }
    ];
  }

  rules = {
    required: StringValidators.required
  };
  apiService = new UserApiService();

  async mounted() {
    this.loading = true;

    const games = await this.apiService.getGameTemplatesForCreateDropdown();
    if (games) {
      this.gameTemplates = new GameTemplatesModel(games);
    }
    const gameInstance = await this.apiService.getGameInstanceById(this.gameInstanceId);
    if (gameInstance) {
      this.instanceSettings = new GameInstanceModel(gameInstance);

      if (this.instanceSettings.gameMainSettings) {
        this.gameMainSettings = JSON.parse(this.instanceSettings.gameMainSettings);
      } else {
        this.gameMainSettings = new GameMainSettingsModel();
      }

      this.gameTemplates.selectedGame = gameInstance.gameTemplateId;
      this.selectedGameTemplate = this.gameTemplates.getSelectedGameTemplate()?.type;
      this.resetCustomizeElements();
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }
  public onGameSelected(gameId: string) {
    this.gameTemplates.selectedGame = gameId;
    this.selectedGameTemplate = this.gameTemplates.getSelectedGameTemplate()?.type;
    this.resetCustomizeElements();
  }

  private resetCustomizeElements() {
    const selectedGame = this.gameTemplates.getSelectedGameTemplate();

    if (selectedGame) {
      this.gameCustomizableElements = this.getCustomizedElements(
        GameFactory.getCustomizableElements(selectedGame.type),
        this.gameMainSettings.gameCustomizableElements ?? []
      ).sort((a, b) => {
        if (a.position > b.position) {
          return 1;
        }

        if (a.position < b.position) {
          return -1;
        }

        return 0;
      });
    }
  }

  protected getCustomizedElements(
    defaultElements: GameCustomizableElement[],
    customElements: GameCustomizableElement[]
  ): GameCustomizableElement[] {
    const filteredDefault = defaultElements.filter(d => !customElements.some(e => e.name === d.name));
    const filteredCustom = customElements.filter(c => defaultElements.some(e => e.name === c.name));
    const merged = filteredDefault.concat(filteredCustom);

    return merged;
  }
  getCustomizedElementsToSave() {
    const nonEmptyElements = this.gameCustomizableElements.filter(
      e => !(e.type === GameCustomizableElementType.Image && !e.value)
    );
    const selectedGame = this.gameTemplates.getSelectedGameTemplate();
    if (selectedGame) {
      const defaultElements = GameFactory.getCustomizableElements(selectedGame.type);
      const customElements = nonEmptyElements.filter(
        e => !defaultElements.some(d => d.name === e.name && d.value === e.value)
      );

      return customElements;
    }
    return [];
  }
  async saveAndPlay() {
    const saveResult = await this.gameInstanceSave();
    if (saveResult) {
      const routeData = await this.$router.resolve({
        name: GamePlayRoute.gameContainer,
        params: { id: this.gameInstanceId }
      });

      window.open(routeData.href, '_blank');
    }
  }
  async submit() {
    const saveResult = await this.gameInstanceSave();
    if (saveResult) {
      await this.$router.push({ name: GameInstanceRoute.gameInstanceList });
    }
  }
  async gameInstanceSave(): Promise<boolean> {
    if (this.submitting) return false;
    this.submitting = true;
    const form: VForm = this.$refs.form as VForm;
    if (form.validate() == false) {
      (this.$refs.gameCustomize as EditCardSection)?.expand();
      (this.$refs.instanceCustomize as GameInstanceCustomize)?.expandAll();
      this.submitting = false;
      FormHelper.scrollToNextError(this);
      return false;
    }
    if (
      this.instanceSettings.playableFrom &&
      this.instanceSettings.playableTo &&
      this.instanceSettings.playableFrom.isSameOrAfter(this.instanceSettings.playableTo)
    ) {
      NotificationService.error(i18n.t('Modules.GameInstance.Notifications.Error.DateFromLaterThanTo').toString());
      this.submitting = false;
      return false;
    }
    if (this.instanceSettings.assignedProfessions.length == 0) {
      NotificationService.error(i18n.t('Modules.GameInstance.Notifications.Error.ProfessionNotChosen').toString());
      this.submitting = false;
      return false;
    }
    if (!this.gameTemplates.selectedGame) {
      NotificationService.error(i18n.t('Modules.GameInstance.Notifications.Error.GameNotChosen').toString());
      this.submitting = false;
      return false;
    }
    this.gameMainSettings.gameCustomizableElements = this.getCustomizedElementsToSave();

    const inputModel = new GameInstanceUpdateInputModel({
      gameId: this.gameInstanceId,
      gameTemplateId: this.gameTemplates.selectedGame,
      isPublic: this.instanceSettings.isPublic,
      playableFrom: null,
      playableTo: null,
      title: this.instanceSettings.gameTitle,
      validUntil: moment(this.instanceSettings.validUntil).utc(),
      assignedProfessions: this.instanceSettings.assignedProfessions,
      settings: JSON.stringify(this.gameMainSettings)
    });
    if (this.instanceSettings.playableFrom) {
      inputModel.playableFrom = moment(this.instanceSettings.playableFrom).utc();
    }
    if (this.instanceSettings.playableTo) {
      inputModel.playableTo = moment(this.instanceSettings.playableTo).utc();
    }
    const gameInstance = await this.apiService.updateGameInstance(inputModel);
    if (gameInstance) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.updateGameInstance, {
        instance_id: gameInstance.id,
        instance_title: gameInstance.title,
        template_id: gameInstance.gameTemplateId,
        template_title: gameInstance.gameTemplate?.title
      });

      this.submitting = false;
      NotificationService.success(i18n.t('Modules.GameInstance.Notifications.Success.GameEdited').toString());
      return true;
    }
    this.submitting = false;
    return false;
  }

  getComponent(type: GameCustomizableElementType) {
    if (type === GameCustomizableElementType.Image) {
      return 'ImageTypeCustomize';
    }
    if (type === GameCustomizableElementType.Text) {
      return 'TextTypeCustomize';
    }
    if (type === GameCustomizableElementType.Number) {
      return 'NumberTypeCustomize';
    }
  }
}
