import { app } from '@/main';
import { OrderDetails } from '@/modules/game-instance/models/create-order.model';
import { GameTriggerType } from '@/modules/game-play/games/game-engine-base';
import { OrderDetailsDto } from '@/services/Api';
import { store } from '@/store/store';

/**
 * Events reference: https://developers.google.com/gtagjs/reference/ga4-events
 *
 * Some events are from existing events reference and some events are custom ones.
 */
export enum AnalyticsEvent {
  gameStart = 'game_start',
  gameOver = 'game_over',
  levelUp = 'level_up',
  correctAnswer = 'correct_answer',
  incorrectAnswer = 'incorrect_answer',
  addGameInstance = 'add_game_instance',
  updateGameInstance = 'update_game_instance',
  addProfession = 'add_profession',
  updateProfession = 'update_profession',
  addQuestion = 'add_question',
  updateQuestion = 'update_question',
  login = 'login',
  signUp = 'sign_up',
  /**
   * Cart button clicked (user goes into the order form)
   */
  selectItem = 'select_item',
  /**
   * User went to the payment form summary
   */
  addPaymentInfo = 'add_payment_info',
  /**
   * User clicked Pay button (redirect to PayU)
   */
  beginCheckout = 'begin_checkout',
  /**
   * User comes back from PayU (successful payment)
   */
  purchase = 'purchase',
  /**
   * User comes back from PayU (failed payment)
   */
  purchaseFailed = 'purchase_failed'
}

export function mapTriggerToAnalyticsEvent(trigger: GameTriggerType): AnalyticsEvent | null {
  switch (trigger) {
    case GameTriggerType.GameStart:
      return AnalyticsEvent.gameStart;
    case GameTriggerType.GameOver:
      return AnalyticsEvent.gameOver;
    case GameTriggerType.NewLevel:
      return AnalyticsEvent.levelUp;
  }

  return null;
}

export function getAnalyticsEventParamsBase(orderNumber: string | null | undefined, orderDetails: OrderDetails): any {
  let discountValue: number | undefined = undefined;

  if (orderDetails?.priceInfo?.netPriceAfterDiscount != null) {
    const result = orderDetails.priceInfo.netPrice - orderDetails.priceInfo.netPriceAfterDiscount;
    if (result > 0) discountValue = result;
  }

  return {
    transaction_id: orderNumber,
    items: [
      {
        item_id: 'per_game_premium_service',
        quantity: 1,
        coupon: orderDetails?.discountCode == null ? undefined : orderDetails.discountCode,
        discount: discountValue == null ? undefined : discountValue, // this is amount, not percent value
        currency: orderDetails?.priceInfo?.currency,
        price: orderDetails?.priceInfo?.finalPrice == null ? undefined : orderDetails.priceInfo.finalPrice,
        tax: orderDetails?.priceInfo?.vatAmount == null ? undefined : orderDetails.priceInfo.vatAmount
      }
    ]
  };
}

export function getAnalyticsEventParams(orderNumber: string | null | undefined, orderDetails: OrderDetails): any {
  return {
    ...getAnalyticsEventParamsBase(orderNumber, orderDetails),
    coupon: orderDetails?.discountCode == null ? undefined : orderDetails.discountCode,
    currency: orderDetails?.priceInfo?.currency,
    value: orderDetails?.priceInfo?.finalPrice == null ? undefined : orderDetails.priceInfo.finalPrice,
    tax: orderDetails?.priceInfo?.vatAmount == null ? undefined : orderDetails.priceInfo.vatAmount
  };
}

export function getPurchaseEventParams(order: OrderDetailsDto): any {
  return getAnalyticsEventParams(order.orderNumber, {
    discountCode: null,
    priceInfo: order.priceInfo
  } as OrderDetails);
}

export default class AnalyticsGoogleHelper {
  public static analyticsBootstrapped = false;

  public static event(eventType: AnalyticsEvent, data?: any): void {
    if (this.analyticsBootstrapped) {
      app.$gtag.event(eventType, data);
    }
  }
}
