



































import { ProfessionDto, QuestionDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuestionListItem from '@/modules/professions/components/QuestionListItem.vue';
import ProfessionApiService from '@/modules/professions/services/profession-api.service';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';

@Component({
  components: { QuestionListItem }
})
export default class EditableQuestionList extends Vue {
  @Prop() public professionId!: string;
  private readonly apiService = new ProfessionApiService();
  private loading = true;

  private isViewOnly = true;
  private profession!: ProfessionDto;
  private questions: Array<QuestionDto> = [];
  public professionsRoute = ProfessionsRoute;

  private async mounted() {
    this.loading = true;

    const result = await this.apiService.getProfessionById(this.professionId);

    if (result) {
      this.profession = result;
      this.isViewOnly = result.isGlobal as NonNullable<boolean>;
      this.questions = result.questions as NonNullable<QuestionDto[]>;
    }

    this.loading = false;
  }

  private onQuestionEdit(question: QuestionDto) {
    this.$router.push({
      name: ProfessionsRoute.editQuestion,
      params: {
        id: this.professionId,
        questionId: question.id
      }
    });
  }

  private onQuestionDeleted(questionId: string) {
    const index = this.questions.findIndex(item => item.id === questionId);
    this.questions.splice(index, 1);
  }
}
