















































import { InstanceGlobalStatisticsForPlayerDto } from '@/services/Api';
import moment from 'moment';
import { Duration } from 'node_modules/moment/ts3.1-typings/moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlobalCountsBarItem from './GlobalCountsBarItem.vue';

@Component({
  components: { GlobalCountsBarItem }
})
export default class GlobalCountsBarForPlayer extends Vue {
  @Prop() readonly data!: InstanceGlobalStatisticsForPlayerDto;
  formatDuration(item: Duration) {
    if (item == null) {
      return '00:00';
    }
    return moment.utc(item.asMilliseconds()).format('mm:ss');
  }
}
