













































import { ProfessionDto } from '@/services/Api';
import { Component, Vue } from 'vue-property-decorator';
import ProfessionListItem from '@/modules/professions/components/ProfessionListItem.vue';
import ProfessionApiService from '../services/profession-api.service';
import { ProfessionsRoute } from '@/modules/professions/professions-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store/store';

@Component({
  components: { ProfessionListItem }
})
export default class ProfessionList extends Vue {
  private readonly apiService = new ProfessionApiService();
  private loading = true;
  private professions: Array<ProfessionDto> = [];
  public professionsRoute = ProfessionsRoute;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.ProfessionList'),
        exact: true,
        disabled: true
      }
    ];
  }

  private async mounted() {
    this.loading = true;

    const result = await this.apiService.getProfessionsOfCurrentUser();

    if (result) {
      this.professions = result;
    }

    store.setBreadcrumb(this.breadcrumbData);
    this.loading = false;
  }

  private goToCreateProfession(): void {
    this.$router.push({ name: ProfessionsRoute.createProfession });
  }

  private professionDeleted(professionId: string): void {
    const index = this.professions.findIndex(item => item.id === professionId);
    this.professions.splice(index, 1);
  }
}
