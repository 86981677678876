
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ListItemHeader extends Vue {
  @Prop() public title?: string;
  @Prop() public subTitle?: string;
  @Prop() public routeTo?: any;
  @Prop({ default: 'mdi-pencil' }) public icon?: string;
}
