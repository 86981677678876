import { EnemyVehicleGroup } from '../Models/Enemy/EnemyVehicleGroup';
import EnemyVehicleFactory from '../Models/Enemy/EnemyVehicleFactory';

export class Car {
  constructor(
    speedVehicle: number,
    speedObject: number,
    spriteName: string,
    scene: any,
    width?: number,
    heigth?: number
  ) {
    this.speedVehicle = speedVehicle;
    this.speedObject = speedObject;
    this.spriteName = spriteName;
    this.factoryVehicle = EnemyVehicleFactory.Build(scene,spriteName, width, heigth);
  }
  factoryVehicle!: EnemyVehicleGroup;
  speedVehicle!: number;
  spriteName!: string;
  speedObject!: number;
}
