



































































import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { AuthenticateRequest } from '@/services/Api';
import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import AnonymousContainer from '../components/AnonymousContainer.vue';
import AnonymousHeader from '../components/AnonymousHeader.vue';
import UserApiService from '../services/user-api.service';
import { i18n } from '@/plugins/i18n';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

@Component({
  components: {
    AnonymousContainer,
    AnonymousHeader
  }
})
export default class Login extends Vue {
  loading = false;
  label = i18n.t('Modules.Login.EmailLabel');
  userEmail = store.rememberedEmail ?? '';
  password = '';
  rules = {
    required: StringValidators.required,
    email: StringValidators.email
  };
  apiService = new UserApiService();
  public userManagementRoute = UserManagementRoute;

  async login() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      return;
    }
    this.loading = true;

    const result = await this.apiService.login(
      new AuthenticateRequest({
        email: this.userEmail,
        password: this.password
      })
    );
    if (result) {
      store.logInUser(result);
      AnalyticsGoogleHelper.event(AnalyticsEvent.login);
      this.$router.push({ name: GameInstanceRoute.gameInstanceList });
    }

    this.loading = false;
  }
}
