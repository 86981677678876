import { SpawnPointObjects } from './SpawnPointObjects';
import { ObjectsGroup } from './ObjectsGroup';
export default class ObjectFactory {
  static spawnPoint: number;
  public static Build(scene: any, width: number, heigth: number, name: string): ObjectsGroup {
    return new ObjectsGroup(scene, width, heigth, name);
  }
  public static CreateObject(vehicle: ObjectsGroup, speed?: number) {
    let spawnPointnew: number;
    do {
      spawnPointnew = Phaser.Math.Between(0, 100);
    } while (this.spawnPoint == spawnPointnew);
    if (spawnPointnew >= 0 && spawnPointnew < 25) {
      vehicle.createObjects(SpawnPointObjects.position1,speed);
    } else if (spawnPointnew >= 25 && spawnPointnew < 50) {
      vehicle.createObjects(SpawnPointObjects.position2,speed);
    } else if (spawnPointnew >= 50 && spawnPointnew < 75) {
      vehicle.createObjects(SpawnPointObjects.position3,speed);
    } else if (spawnPointnew >= 75 && spawnPointnew <= 100) {
      vehicle.createObjects(SpawnPointObjects.position4,speed);
    }
  }
  public static CreateEndLevel(vehicle: ObjectsGroup) {
    vehicle.createObjects(SpawnPointObjects.endLevelPosition);
  }
}
