var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.customSearch)?_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Modules.AdminPanel.GameInstances.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"custom-filter":_vm.customSearch,"items-per-page":_vm.tableSize,"footer-props":{
      itemsPerPageText: _vm.$t('dataIterator.rowsPerPageText'),
      itemsPerPageAllText: _vm.$t('dataIterator.AllText'),
      pageText: _vm.$t('dataIterator.pageText')
    }},on:{"update:itemsPerPage":function($event){_vm.tableSize=$event},"update:items-per-page":function($event){_vm.tableSize=$event}},scopedSlots:_vm._u([_vm._l((_vm.columnFilters),function(filter){return {key:("header." + (filter.value)),fn:function(ref){
    var header = ref.header;
return [_c('div',{key:filter.value,staticClass:"header-text mr-2"},[_vm._v(_vm._s(header.text))]),_c('div',{key:filter.value + 'key',staticClass:"filter-icon"},[_c('v-menu',{staticStyle:{"position":"absolute","right":"0"},attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"slide-y-transition","left":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":'default'}},[_vm._v("> mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"flat":"","dense":""}},[_c('v-list-item-group',{staticClass:"py-2",attrs:{"multiple":""},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}},[_vm._l((_vm.uniq(filter.items)),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":item,"color":"primary","ripple":false,"dense":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true)})]})],2),_c('v-divider'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"success"},on:{"click":function($event){return _vm.toggleAll(header.value)}}},[_vm._v("Toggle all")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"warning"},on:{"click":function($event){return _vm.clearAll(header.value)}}},[_vm._v("Clear all")])],1)],1)],1)],1)],1)]}}}),{key:"item",fn:function(row){return [_vm._t("item",null,{"item":row.item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }