var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Modules.AdminPanel.UserList.Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"custom-filter":_vm.customFilter,"items-per-page":_vm.tableSize},on:{"update:itemsPerPage":function($event){_vm.tableSize=$event},"update:items-per-page":function($event){_vm.tableSize=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_c('table-item-link',{attrs:{"value":row.item.email,"routeTo":{ name: _vm.adminUsersRoute.adminDetails, params: { id: row.item.id } }}})],1),_c('td',[_vm._v(_vm._s(row.item.buyerIdentifier))]),_c('td',[_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(row.item.isAdmin),callback:function ($$v) {_vm.$set(row.item, "isAdmin", $$v)},expression:"row.item.isAdmin"}})],1),_c('td',[_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(row.item.active),callback:function ($$v) {_vm.$set(row.item, "active", $$v)},expression:"row.item.active"}})],1),_c('td',[_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(row.item.isDeleted),callback:function ($$v) {_vm.$set(row.item, "isDeleted", $$v)},expression:"row.item.isDeleted"}})],1),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTime")(row.item.createdDate)))]),_c('td',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"dense":"","justify":"space-around","align":"center"}},[_c('list-menu',[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                      name: _vm.adminUsersRoute.adminEdit,
                      params: {
                        id: row.item.id
                      }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.AdminPanel.UserList.Edit')))])],1),_c('v-list-item',{attrs:{"to":{
                      name: _vm.adminUsersRoute.adminDetails,
                      params: {
                        id: row.item.id
                      }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-text")])],1),_c('v-list-item-title',[_vm._v(_vm._s('Details'))])],1),(!row.item.isDeleted)?_c('v-list-item',{on:{"click":function($event){return _vm.showDeleteConfirmation(row.item.id, row.item.email)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Modules.AdminPanel.UserList.Delete')))])],1):_vm._e()],1)],1)],1)],1)],1)]),_c('ConfirmDeleteModal',{attrs:{"show":_vm.dialog,"customText":_vm.confirmTitle},on:{"confirm":function($event){return _vm.deleteUser()},"cancel":function($event){return _vm.cancelDelete()}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }