import { Car } from './Car';
import { ObjectsOutside } from './ObjectsOutside';
export class Levels {
  constructor(
    level: number,
    backgroundSpeed: number,
    arrayCars: Array<Car>,
    arrayObjects: Array<ObjectsOutside>,
    spawnTime: Array<number>,
    distanceToNextLevel: number,
    odometerSpeed: number
  ) {
    this.level = level;
    this.backgroundSpeed = backgroundSpeed;
    this.arrayCars = arrayCars;
    this.arrayObjects = arrayObjects;
    this.spawnTime = spawnTime;
    this.distanceToNextLevel = distanceToNextLevel;
    this.odometerSpeed = odometerSpeed;
  }
  level!: number;
  backgroundSpeed!: number;
  arrayCars!: Array<Car>;
  arrayObjects!: Array<ObjectsOutside>;
  spawnTime!: Array<number>;
  distanceToNextLevel!: number;
  odometerSpeed!: number;
}
