






















































import CookiesConsentGroup from '@/modules/layout/components/cookies-consent/CookiesConsentGroup.vue';
import CookieService from '@/services/cookie-service';
import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import { ConsentGroupViewModel, ConsentViewModel } from './cookies-consent.model';

@Component({ components: { CookiesConsentGroup } })
export default class CookiesConsent extends Vue {
  public store = store;
  private firstLoad = true;
  public essentialCookies = new ConsentGroupViewModel('Essential', true, [
    new ConsentViewModel('SessionCookieConsent'),
    new ConsentViewModel('SettingsCookieConsent'),
    new ConsentViewModel('ARRAfinityCookieConsent')
  ]);

  public statisticsCookies = new ConsentGroupViewModel('Statistics', false, [
    new ConsentViewModel('GoogleAnalyticsConsent'),
    new ConsentViewModel('FacebookPixelConsent')
  ]);

  public cookieGroups: ConsentGroupViewModel[] = [];

  public mounted(): void {
    this.cookieGroups = [this.essentialCookies, this.statisticsCookies];
  }

  public get isOpen(): boolean {
    return this.store.consentBannerState !== 'hidden';
  }

  public acceptAll(): void {
    this.essentialCookies.accepted = true;
    this.statisticsCookies.accepted = true;

    this.save();
  }

  public save(): void {
    const analyticsConsent = this.statisticsCookies.elements.find(i => i.consentName === 'GoogleAnalyticsConsent');
    const pixelConsent = this.statisticsCookies.elements.find(i => i.consentName === 'FacebookPixelConsent');

    const isAnalyticsConsentAccepted = analyticsConsent != null && analyticsConsent.accepted;
    const isPixelConsentAccepted = pixelConsent != null && pixelConsent.accepted;
    this.store.setGoogleAnalyticsAcceptedFlag(isAnalyticsConsentAccepted);
    this.store.setFacebookPixelAcceptedFlag(isPixelConsentAccepted);

    CookieService.setCookieProperty('wasCookieBannerShown', true);
    this.store.setConsentBannerState('hidden');
    // This needs to stay, because there is no other simple way to disable pixel when it was activated.
    window.location.reload();
  }

  public toggleDetailsVisibility(item: ConsentGroupViewModel): void {
    const newState = !item.expanded;
    this.cookieGroups.forEach(i => (i.expanded = false));
    item.expanded = newState;
  }
}
