






















import { Component, Vue } from 'vue-property-decorator';
import AddDiscount from '../components/AddDiscount.vue';
import DiscountApiService from '../services/admin-discount-api.service';
import NotificationService from '@/services/notification-service';
import { i18n, translate } from '@/plugins/i18n';
import { DiscountAdminDto } from '@/services/Api';
import StringValidators from '@/modules/layout/validators/string-validators';
import { AdminUsersRoute } from '@/modules/admin/admin-users-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { store } from '@/store/store';
@Component({
  components: {
    AddDiscount
  }
})
export default class DiscountAddPage extends Vue {
  private apiService = new DiscountApiService();
  private loading = true;
  discountData!: DiscountAdminDto;
  private submitting = false;

  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.Admin'),
        exact: true,
        to: { name: AdminUsersRoute.adminDashboard }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Discounts'),
        exact: true,
        to: { name: AdminUsersRoute.discountsList }
      },
      {
        text: i18n.t('Layout.Toolbar.Routes.Create'),
        disabled: true
      }
    ];
  }

  private cancel() {
    this.$router.push({ name: AdminUsersRoute.discountsList });
  }
  async mounted() {
    this.discountData = new DiscountAdminDto();
    this.loading = false;
  }
  async save() {
    if (this.submitting) return;
    this.submitting = true;
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      this.submitting = false;
      return;
    }
    const addInstance = await this.apiService.addDiscountData(this.discountData);

    if (addInstance) {
      await this.$router.push({ name: AdminUsersRoute.discountsList });
      NotificationService.success(translate('Modules.AdminPanel.Discounts.Success.DiscountAdded'));
    }
    store.setBreadcrumb(this.breadcrumbData);
    this.submitting = false;
  }
}
