




import { Component, Vue } from 'vue-property-decorator';
import { Currency } from '@/services/Api';

@Component
export default class FormTextInfoPreview extends Vue {
  currency: Currency = Currency.EUR;
  value = 82.21;
  label = this.$t('KitchenSink.Previews.FormTextInfoPreview.Label');
}
