import { Levels } from './Levels';
//Class responsible for base game values
export class Base {
  constructor(lives: number) {
    this.values(lives);
  }
  backgroundSpeed!: number;
  playerPozX!: number;
  playerPozY!: number;
  playerSpeed!: number;
  playerMaxSpeed!: number;
  playerBorderMax!: number;
  playerBorderMin!: number;
  life!: number;
  levels: Array<Levels> = [];
  enemySpeedMultiplicator!: number;
  enemySpeedBarier = [[7,1.3],[4,1.6]];
  values(lives: number) {
    this.backgroundSpeed = 6;
    this.playerPozX = 400;
    this.playerPozY = 575;
    this.life = lives;
    this.playerSpeed = 35;
    this.playerMaxSpeed = 1000;
    this.playerBorderMax = 755;
    this.playerBorderMin = 5;
    
    //Level #1
    this.levels.push(new Levels(1, 6, 4, 1, 100, 10, false, false));
    //Level #2
    this.levels.push(new Levels(2, 8, 8, 2, 150, 20, false, false));
    //Level #3
    this.levels.push(new Levels(3, 8, 3, 5, 170, 40, false, false));
    //Level #4
    this.levels.push(new Levels(4, 8, 7, 3, 300, 30, false, true, 250));
    //Level #5
    this.levels.push(new Levels(5, 14, 7, 4, 220, 40, true, true, 250));
    //Level #6
    this.levels.push(new Levels(6, 15, 8, 5, 220, 20, true, true, 300));
    //Level #7
    this.levels.push(new Levels(7, 16, 8, 5, 240, 30,true, true, 300));
    //Level #8
    this.levels.push(new Levels(8, 16, 8, 5, 320, 30, true, true, 350));
    //Level #9
    this.levels.push(new Levels(9, 17, 9, 5, 260, 35,true, true, 350));
    //Level #10
    this.levels.push(new Levels(10, 18, 10, 4, 300, 35, true, true, 400));
    //Level #11
    this.levels.push(new Levels(11, 18, 10, 5, 310, 36, true, true, 400));
    //Level #12
    this.levels.push(new Levels(12, 20, 10, 5, 320, 38, true, true, 400));
    //Level #13
    this.levels.push(new Levels(13, 24, 10, 5, 330, 40, true, true, 400));
    //Level #14
    this.levels.push(new Levels(14, 24, 10, 5, 340, 45, true, true, 500));
    //Level #15
    this.levels.push(new Levels(15, 24, 10, 5, 350, 50, true, true, 500));
    
    this.enemySpeedMultiplicator = 0.1;
  }
}
