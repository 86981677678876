
























import AppInitializer from '@/modules/layout/components/AppInitializer.vue';
import NotificationHolder from '@/modules/layout/components/NotificationHolder.vue';
import SideMenu from '@/modules/layout/components/SideMenu.vue';
import ToolBar from '@/modules/layout/components/ToolBar.vue';
import Footer from '@/modules/layout/components/Footer.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  components: { SideMenu, ToolBar, NotificationHolder, AppInitializer, Footer }
});
