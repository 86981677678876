





















import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store/store';
import { GameInstanceRoute } from '@/modules/game-instance/game-instance-router.enum';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';
import BreadcrumbData from '@/store/Models/breadcrumb-data';
import { i18n } from '@/plugins/i18n';

@Component
export default class NotFoundPage extends Vue {
  private backRouterDestination: string = UserManagementRoute.login;
  private backButtonText = 'Back to Home page';
  get breadcrumbData(): () => BreadcrumbData[] {
    return () => [
      {
        text: i18n.t('Layout.Toolbar.Routes.NotFoundPage'),
        exact: true,
        disabled: true
      }
    ];
  }
  mounted() {
    if (store.isLoggedIn) {
      this.backButtonText = 'Back to games list';
      this.backRouterDestination = GameInstanceRoute.gameInstanceList;
    }
  }
}
