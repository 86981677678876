














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EditCardMain extends Vue {
  @Prop({ default: '$MainCardTitle' }) title!: string;
}
