export class ImagesBase64 {
    public static readonly enemy1 = require('@/assets/games/river-run/themes/speed-race/sprites/car_white.png');
    public static readonly enemy2 = require('@/assets/games/river-run/themes/speed-race/sprites/car_white.png');
    public static readonly enemy3 = require('@/assets/games/river-run/themes/speed-race/sprites/car_white.png');
    public static readonly object1 = require('@/assets/games/river-run/themes/speed-race/sprites/oil.png');
    public static readonly player = require('@/assets/games/river-run/themes/speed-race/sprites/car_white.png');
    public static readonly people1 = require('@/assets/games/river-run/themes/speed-race/sprites/small-plants.svg');
    public static readonly tree = require('@/assets/games/river-run/themes/speed-race/sprites/small-plants.svg');
    public static readonly road = require('@/assets/games/river-run/themes/speed-race/sprites/road.png');
    public static readonly bgBackLeft = require('@/assets/games/river-run/themes/speed-race/sprites/grass.png');
    public static readonly bgBackRight = require('@/assets/games/river-run/themes/speed-race/sprites/grass.png');
    static readonly heart = require('@/assets/games/space-rescue/ui/life.png');
    static readonly endLevelObj = require('@/assets/games/river-run/themes/speed-race/sprites/flag.png');
    static readonly odometer = require('@/assets/games/river-run/themes/speed-race/sprites/odometer.png');
    static readonly odometerLine = require('@/assets/games/river-run/themes/speed-race/sprites/odometerline.png');
  }
  