import {
  GameClient,
  GameInstanceDto,
  GameTemplateDto,
  GameInstanceCreateInputModel,
  GameInstanceUpdateInputModel,
  GameInstanceDeleteInputModel,
  AssignableProfessionDto,
  PaymentsClient,
  Currency,
  GetInitialOrderDataDto,
  VerifyDiscountResultDto,
  GetPriceInfoDto,
  CreateEmptyPurchaseDto,
  CreateEmptyPurchaseInputModel,
  GameInstanceRecipientDataInputModel,
  PayInputModel
} from '@/services/Api';
import ApiServiceBase from '@/services/api-service.base';

export default class GameApiService extends ApiServiceBase {
  private client = new GameClient();
  private paymentClient = new PaymentsClient();

  public async getGameInstanceById(gameInstanceId: string): Promise<GameInstanceDto | undefined> {
    try {
      const gameList = await this.client.getGameInstanceById(gameInstanceId);
      return gameList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getGameInstancesByUser(): Promise<GameInstanceDto[] | undefined> {
    try {
      const gameList = await this.client.getGameInstancesByUser();
      return gameList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async getGameTemplatesForCreateDropdown(): Promise<GameTemplateDto[] | undefined> {
    try {
      const gameTemplatesList = await this.client.getGameTemplatesForCreateDropdown();
      return gameTemplatesList.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async createGameInstance(inputModel: GameInstanceCreateInputModel): Promise<GameInstanceDto | undefined> {
    try {
      const gameInstance = await this.client.createGameInstance(inputModel);
      return gameInstance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async updateGameInstance(inputModel: GameInstanceUpdateInputModel): Promise<GameInstanceDto | undefined> {
    try {
      const gameInstance = await this.client.updateGameInstance(inputModel);
      return gameInstance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async deleteGameInstance(inputModel: GameInstanceDeleteInputModel): Promise<GameInstanceDto | undefined> {
    try {
      const gameInstance = await this.client.deleteGameInstance(inputModel);
      return gameInstance.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
  public async getAssignableProfessions(gameInstancId: string): Promise<AssignableProfessionDto[] | undefined> {
    try {
      const professions = await this.client.getAssignableProfessions(gameInstancId);
      return professions.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async getInitialOrderData(
    gameInstanceId: string,
    defaultCountryCode: string
  ): Promise<GetInitialOrderDataDto | undefined> {
    try {
      const response = await this.paymentClient.getInitialOrderData(gameInstanceId, defaultCountryCode);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async createEmptyPurchase(
    inputModel: CreateEmptyPurchaseInputModel
  ): Promise<CreateEmptyPurchaseDto | undefined> {
    try {
      const response = await this.paymentClient.createEmptyPurchase(inputModel);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async showSummary(inputModel: GameInstanceRecipientDataInputModel): Promise<boolean | undefined> {
    try {
      const response = await this.paymentClient.showSummary(inputModel);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async verifyDiscountCode(
    code: string,
    currency: Currency,
    countryCode: string
  ): Promise<VerifyDiscountResultDto | undefined> {
    try {
      const response = await this.paymentClient.verifyDiscount(code, currency, countryCode);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async pay(payInputModel: PayInputModel): Promise<string | undefined> {
    try {
      const response = await this.paymentClient.pay(payInputModel);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async getPriceInfo(
    netPrice: number,
    countryCode: string,
    currency: Currency,
    discount?: number
  ): Promise<GetPriceInfoDto | undefined> {
    try {
      const response = await this.paymentClient.getPriceInfo(netPrice, countryCode, discount, currency);
      return response.result;
    } catch (error) {
      this.HandleError(error);
    }
  }
}
