import { StringUtils } from '@/modules/layout/utils/string-utils';
import StringValidators from '@/modules/layout/validators/string-validators';
import { GetInitialOrderDataDto, PriceInfo, VerifyDiscountResultDto } from '@/services/Api';
import { CountriesProvider } from '@/services/countries.provider';
import moment, { Moment } from 'moment';
export interface AddressFormData {
  countryCode: string;
  city: string;
  street: string;
  postCode: string;
}

export interface InvoiceRecipientFormData {
  companyName: string;
  vatNumber: string;
  address: AddressFormData;
}

export class OrderDetails {
  public priceInfo: PriceInfo;

  public discountCode: string | null = null;
  public isDiscountSet = false;

  public newValidUntil: Moment;

  public invoiceRecipientFormData: InvoiceRecipientFormData = {
    companyName: '',
    vatNumber: '',
    address: {
      countryCode: '',
      city: '',
      street: '',
      postCode: ''
    }
  };

  constructor(public gameInstanceId: string, dataDto: GetInitialOrderDataDto) {
    this.priceInfo = dataDto.priceInfo;
    this.newValidUntil = moment(dataDto.newValidUntil).local();

    const data = dataDto.invoiceRecipient;
    const country = CountriesProvider.getCountry(data.address.countryCode);

    this.invoiceRecipientFormData = {
      companyName: data.companyName,
      vatNumber: data.vatNumber || '',
      address: {
        countryCode: country?.code || CountriesProvider.countries[0].code,
        city: data.address.city || '',
        street: data.address.street || '',
        postCode: data.address.postCode || ''
      }
    };
  }

  public setDiscount(resultDto: VerifyDiscountResultDto | undefined): void {
    if (resultDto == null) return;

    this.discountCode = resultDto.code;
    this.priceInfo.discountValue = resultDto.value;
    this.isDiscountSet = StringUtils.isNullOrWhiteSpace(this.discountCode) === false;
  }
}

type ValidationRule = string | boolean | ((inputValue: string) => string | boolean);
type InvoiceRecipientValidationRules = { [key in keyof InvoiceRecipientFormData]: null | ValidationRule[] };
type AddressValidationRules = { [key in keyof AddressFormData]: null | ValidationRule[]};

export const invoiceRecipientValidationRules: InvoiceRecipientValidationRules = {
  companyName: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 150)],
  vatNumber: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 30)],
  address: null
};

export const addressValidationRules: AddressValidationRules = {
  countryCode: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 10)],
  city: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 150)],
  street: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 150)],
  postCode: [StringValidators.required, (value: string) => StringValidators.maxLength(value, 150)]
};
