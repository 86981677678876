














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConsentGroupViewModel } from './cookies-consent.model';

@Component
export default class CookiesConsentGroup extends Vue {
  @Prop({ default: () => new ConsentGroupViewModel('None', true, []) }) public group!: ConsentGroupViewModel;

  public get translationKey(): string {
    return `CookiesConsent.PrivacySettings.${this.group.groupName}`;
  }
}
