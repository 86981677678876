



















































import StringValidators from '@/modules/layout/validators/string-validators';
import AnalyticsGoogleHelper, { AnalyticsEvent } from '@/plugins/analytics-google';
import { AnalyticsHelper, FacebookPixelEvent } from '@/plugins/analytics-facebook';
import { UserRegisterInputModel } from '@/services/Api';
import NotificationService from '@/services/notification-service';
import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import AnonymousContainer from '../components/AnonymousContainer.vue';
import AnonymousHeader from '../components/AnonymousHeader.vue';
import UserApiService from '../services/user-api.service';
import { UserManagementRoute } from '@/modules/user-management/user-management-router.enum';

@Component({
  components: {
    AnonymousContainer,
    AnonymousHeader
  }
})
export default class Register extends Vue {
  loading = false;
  conditionsAgree = false;
  userModel = new UserRegisterInputModel();
  apiService = new UserApiService();
  hidePassword = true;
  rules = {
    required: StringValidators.required,
    email: StringValidators.email,
    minLength: (model: string) => StringValidators.minLength(model, 8),
    maxLength: (model: string) => StringValidators.maxLength(model, 50),
    passwordRegex: (model: string) =>
      (!!model && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(model)) ||
      this.$t('Modules.Login.PasswordPatternError')
  };
  public userManagementRoute = UserManagementRoute;

  async register() {
    const form: any = this.$refs.form;
    if (form.validate() == false) {
      return;
    }
    this.loading = true;

    const registeredUser = await this.apiService.register(this.userModel);
    if (registeredUser) {
      AnalyticsGoogleHelper.event(AnalyticsEvent.signUp);
      AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.completeRegistration);
      store.rememberEmail(registeredUser.email as string);
      NotificationService.success(this.$t('Modules.Login.AccountCreated').toString());
      this.$router.push({ name: UserManagementRoute.login });
    }
    this.loading = false;
  }
}
