export class BackgroundBack extends Phaser.GameObjects.TileSprite {
  constructor(
    scene: Phaser.Scene,
    width: number,
    heigth: number,
    backgroundName: string,
    widthCenter: number,
    heightCenter: number
  ) {
    super(scene, widthCenter, heightCenter, width, heigth, backgroundName);
    scene.physics.add.existing(this);
    scene.add.existing(this);
  }
}
