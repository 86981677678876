








import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue/types/umd';

@Component({
  components: {}
})
export default class KitchenSinkPreview extends Vue {
  @Prop() preview!: VueConstructor;
}
