import { Levels } from './Levels';
import { Car } from './Car';
import { ObjectsOutside } from './ObjectsOutside';
import { RiverRunVariables } from './RiverRunVariables';
import 'phaser';
import { GameCustomizableElement } from '../../game-engine-customizable-element';
//Class responsible for base game values
export class Base {
  constructor(lives: number, scene: Phaser.Scene, customziableTypes?: GameCustomizableElement[]) {
    this.values(lives, scene, customziableTypes);
  }

  scene!: Phaser.Scene;
  objectsSpawnTime!: number;
  backgroundWidth!: number;
  vehicleSpeed!: number;
  backgroundHeigth!: number;
  backgroundBackWidth!: number;
  backgroundBackHeigth!: number;
  backgroundSpeed!: number;
  playerPozX!: number;
  playerPozY!: number;
  playerSpeedOnX!: number;
  playerMaxSpeedOnX!: number;
  playerSpeedOnY!: number;
  playerMaxSpeedOnY!: number;
  playerBorderMaxOnX!: number;
  playerBorderMinOnX!: number;
  playerBorderMaxOnY!: number;
  playerBorderMinOnY!: number;
  playerWidth!: number;
  playerHeigth!: number;
  life!: number;
  /**
     * Create level data
     * @param {number} level - current level
     * @param {number} backgroundSpeed - background Speed on current level
     * @param {Array.<Car>} arrayCars - array vehicle and objects on road
     * @param {Array.<ObjectsOutside>} arrayObjects - array objects outside. Objects move with background.
     * @param {Array.<number>} spawnTime - time to create spawn event to objects and vehicle on road.
     * @param {number} distanceToNextLevel - distance to next level. Example: 0.5 KM
     * @param {number} odometerSpeed - Set range between 30 - 150. On speed up to default speed is added 20 and if slow down is minus 15.
     */
  levels: Array<Levels> = [];
  flagSpawn!: number;
  showOdometer!: boolean;
  rotateSprite!: boolean;
  flagWidth!: number;
  flagHeight!: number;
  turnOnSoundPassing!: boolean;
  defaultSpawnTime = 1000;
  values(lives: number, scene: Phaser.Scene, customziableTypes?: GameCustomizableElement[]) {
    this.scene = scene;
    this.objectsSpawnTime = 3000;
    this.vehicleSpeed = 150;
    this.backgroundWidth = 500;
    this.backgroundHeigth = 800;
    this.backgroundBackWidth = 150;
    this.backgroundBackHeigth = 800;
    this.backgroundSpeed = 5;
    this.playerPozX = 400;
    this.playerPozY = 530;
    this.life = lives;
    this.playerSpeedOnX = 10;
    this.playerMaxSpeedOnX = 200;
    this.playerSpeedOnY = 10;
    this.playerMaxSpeedOnY = 100;
    this.playerBorderMaxOnX = 600;
    this.playerBorderMinOnX = 150;
    this.playerBorderMaxOnY = 500;
    this.playerBorderMinOnY = 400;
    this.playerWidth = 60;
    this.playerHeigth = 100;
    this.flagSpawn = 115;
    this.showOdometer = true;
    this.turnOnSoundPassing = false;
    //Flag size
    this.flagHeight = 80;
    this.flagWidth = 100;
    //Rotate on left line
    this.rotateSprite = false;
    /**
     * Create car object or normal object on map with background speed
     * @param {number} speedVehicle - set speed between range 100-1000 if it is vehicle and set speedObject to 0
     * @param {number} speedObject - set background speed on current level if it object on map and should move with background speed
     * @param {string} spriteName - get from file RiverRunVariables.ts
     * @param {Phaser.Scene} scene - default from file (don't change it)
     * @param {number} width 
     * @param {number} heigth 
     */
    let arrayCars: Array<Car> = [];
    /**
     * Create objects outside. Objects move with background.
     * @param {string} spriteName - get from file RiverRunVariables.ts
     * @param {number} width 
     * @param {number} heigth 
     * @param {Phaser.Scene} scene - default from file (don't change it)
     */
    const arrayObjects: Array<ObjectsOutside> = [];
    /**
     * spawn time to create event respawn object and vehicle on road.
     * If spawn time will be empty default value is 1000.
     */
    let spawnTime: Array<number> = [1800];

    //Add objects to current level
    arrayObjects.push(new ObjectsOutside(RiverRunVariables.person1, 50, 150, this.scene));
    arrayObjects.push(new ObjectsOutside(RiverRunVariables.treeSpriteName, 60, 60, this.scene));

    //Add vehicle or object to current level
    const objects1Data = customziableTypes?.find(val => val.name == RiverRunVariables.object1SpriteName);
    const enemy1Data = customziableTypes?.find(val => val.name == RiverRunVariables.enemy1SpriteName);
    const enemy2Data = customziableTypes?.find(val => val.name == RiverRunVariables.enemy2SpriteName);
    const enemy3Data = customziableTypes?.find(val => val.name == RiverRunVariables.enemy3SpriteName);

    //Level #1
    arrayCars = [];
    arrayCars.push(
      new Car(340, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [1100, 1200, 1300];
    this.levels.push(new Levels(1, 8, arrayCars, arrayObjects, spawnTime, 0.4, 30));

    //Level #2
    arrayCars = [];
    arrayCars.push(
      new Car(340, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(350, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [900, 1000, 1100];
    this.levels.push(new Levels(2, 10, arrayCars, arrayObjects, spawnTime, 0.6, 60));

    //Level #3
    arrayCars = [];
    arrayCars.push(
      new Car(340, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 8, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [800, 900, 1000];
    this.levels.push(new Levels(3, 14,arrayCars, arrayObjects, spawnTime, 1, 70));

    //Level #4
    arrayCars = [];
    arrayCars.push(
      new Car(0, 12, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [660, 650, 540];
    this.levels.push(new Levels(4, 12, arrayCars, arrayObjects, spawnTime, 1, 60));

    //Level #5
    arrayCars = [];
    arrayCars.push(
      new Car(300, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );    
    arrayCars.push(
      new Car(360, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 12, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );    
    spawnTime = [];
    spawnTime = [550, 520, 480];
    this.levels.push(new Levels(5, 12, arrayCars, arrayObjects, spawnTime, 1, 60));

    //Level #6
    arrayCars = [];
    arrayCars.push(
      new Car(380, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(400, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(420, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 14, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [800, 850, 900];
    this.levels.push(new Levels(6, 14, arrayCars, arrayObjects, spawnTime, 1.5, 80));

    //Level #7
    arrayCars = [];
    arrayCars.push(
      new Car(420, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(440, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(460, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 16, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [700, 720, 740];
    this.levels.push(new Levels(7, 16, arrayCars, arrayObjects, spawnTime, 2, 100));

    //Level #8
    arrayCars = [];
    arrayCars.push(
      new Car(410, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(420, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(430, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 16, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [400, 450, 500];
    this.levels.push(new Levels(8, 16, arrayCars, arrayObjects, spawnTime, 2.5, 100));

    //Level #9
    arrayCars = [];
    arrayCars.push(
      new Car(400, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(420, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(430, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 18, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [400, 400, 400];
    this.levels.push(new Levels(9, 18, arrayCars, arrayObjects, spawnTime, 3, 110));

    //Level #10
    arrayCars = [];
    arrayCars.push(
      new Car(380, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(390, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(460, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 18, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [380, 390, 400];
    this.levels.push(new Levels(10, 20, arrayCars, arrayObjects, spawnTime, 3, 120));

    //Level #11
    arrayCars = [];
    arrayCars.push(
      new Car(400, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(440, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(470, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [300, 300, 300];
    this.levels.push(new Levels(11, 24, arrayCars, arrayObjects, spawnTime, 3, 130));

    //Level #12
    arrayCars = [];
    arrayCars.push(
      new Car(400, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(440, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(470, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 26, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [200, 200, 200];
    this.levels.push(new Levels(12, 26, arrayCars, arrayObjects, spawnTime, 3, 140));

    //Level #13
    arrayCars = [];
    arrayCars.push(
      new Car(470, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(500, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(530, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 28, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [200, 200, 200];
    this.levels.push(new Levels(13, 28, arrayCars, arrayObjects, spawnTime, 3, 150));

    //Level #14
    arrayCars = [];
    arrayCars.push(
      new Car(510, 0, RiverRunVariables.enemy1SpriteName, this.scene, enemy1Data?.width, enemy1Data?.heigth)
    );
    arrayCars.push(
      new Car(560, 0, RiverRunVariables.enemy2SpriteName, this.scene, enemy2Data?.width, enemy2Data?.heigth)
    );
    arrayCars.push(
      new Car(590, 0, RiverRunVariables.enemy3SpriteName, this.scene, enemy3Data?.width, enemy3Data?.heigth)
    );
    arrayCars.push(
      new Car(0, 28, RiverRunVariables.object1SpriteName, this.scene, objects1Data?.width, objects1Data?.heigth)
    );
    spawnTime = [];
    spawnTime = [200, 200, 200];
    this.levels.push(new Levels(14, 28, arrayCars, arrayObjects, spawnTime, 3, 150));
  }
}
