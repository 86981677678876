


















import { QuestionDto } from '@/services/Api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuestionApiService from '../services/question-api.service';

@Component
export default class QuestionList extends Vue {
  @Prop() professionId!: string;

  private readonly apiService = new QuestionApiService();
  private loading = true;
  private questions: Array<QuestionDto> = [];

  async mounted() {
    this.loading = true;

    const result = await this.apiService.getQuestionsForProfession(this.professionId);

    if (result) {
      this.questions = result;
    }

    this.loading = false;
  }
}
